<style>
.main_esg_scores {
  margin-bottom: 0px !important;
}
.main_lines_pocket {
  flex-grow: 1;
}
.main_lines_pocket h2 {
  font-size: 10px !important;
  margin-top: 0px !important;
}
.main_lines_pocket .pdf-table .c1 {
  max-width: 125px !important;
}
.pdf-esg-diversifie .cell:not(:first-child) div {
  display: flex;
  justify-content: center;
}
.pdf-esg-diversifie .esg_converage {
  flex-grow: 1;
}
.pdf-esg-diversifie .carbon_converage {
  flex-grow: 1;
}
.pdf-esg-diversifie .esg_score_by_pocket {
  flex-grow: 1;
}
</style>

<template lang="pug">
.pdf-esg-diversifie
  .subtitle.row(v-if="data.share.characteristics.benchmark_esg") {{ (t['univers_definition'] || 'univers_definition') + ': ' + t[data.share.characteristics.benchmark_esg] }}
  .row
    brick.esg_converage.no-bold.center-value(:title="t[data.share.characteristics.esg_coverage_table] || data.share.characteristics.esg_coverage_table" subtitle="subtitle_esg_coverage" :data="data.tables.esg_coverage_table")
      div.source(v-if="$root.lang.includes('be')") {{ t.source + ' ' + t[data.share.characteristics.management_company] }}
    brick.no-bold.center-value(:title="t['esg_score_by_pocket'] || 'esg_score_by_pocket'" :subtitle="t['esg_score_by_pocket_subtitle'] || 'esg_score_by_pocket_subtitle'" :data="data.esg.esg_score_by_pocket")
      div.source(v-if="$root.lang.includes('be')") {{ t.source + ' ' + t[data.share.characteristics.management_company] }}
    .column
    brick(title="carbon_intensity" subtitle="subtitle_carbon_intensity")
      pdf-horizontalbar-icon(:data="data.esg.carbon_intensity.data" :options="{ format: '.0f'}")
      .sfdr-block(v-if="data.share.characteristics.display_carbon_intensity_bench && data.share.characteristics.benchmark_esg && ['fund', 'univers'].every(d => data.esg.carbon_intensity.data.map(d => d.key).includes(d))")
        .row
          .sfdr-text {{ t.carbon_intensity_difference || 'carbon_intensity_difference' }}
          .sfdr-value {{ format('.0%')(data.esg.carbon_intensity_difference) }}
      div.source(v-if="$root.lang.includes('be')") {{ t.source + ' ' + t[data.share.characteristics.management_company] }}
  .row
    brick.no-bold.center-value(:title="t['main_esg_scores'] || 'main_esg_scores'" :subtitle="t['main_esg_scores_subtitle'] || 'main_esg_scores_subtitle'")
      div.source(v-if="$root.lang.includes('be')") {{ t.source + ' ' + t[data.share.characteristics.management_company] }}
  .row
    .column.main_lines_pocket
      brick.no-bold(:title="t['action_pocket'] || 'action_pocket'" :data="data.esg.main_lines_table_esg_action.desc" :options="{ limit: 6 }")
      brick.no-bold(:data="data.esg.main_lines_table_esg_action.asc" :options="{ limit: 6 }")
      div.source(v-if="$root.lang.includes('be')") {{ t.source + ' ' + t[data.share.characteristics.management_company] }}
    .column.main_lines_pocket
      brick.no-bold(:title="t['credit_pocket'] || 'credit_pocket'" :data="data.esg.main_lines_table_esg_credit.desc" :options="{ limit: 6 }")
      brick.no-bold(:data="data.esg.main_lines_table_esg_credit.asc" :options="{ limit: 6 }")
      div.source(v-if="$root.lang.includes('be')") {{ t.source + ' ' + t[data.share.characteristics.management_company] }}
    .column.main_lines_pocket
      brick.no-bold(:title="t['govies_pocket'] || 'govies_pocket'" :data="data.esg.main_lines_table_esg_govies.desc" :options="{ limit: 6 }")
      brick.no-bold(:data="data.esg.main_lines_table_esg_govies.asc" :options="{ limit: 6 }")
      div.source(v-if="$root.lang.includes('be')") {{ t.source + ' ' + t[data.share.characteristics.management_company] }}
  .column
    brick.risk_disclaimer(title="definitions_esg" v-if="(data.share.characteristics.definition_esg_list || []).length > 0")
      div(v-html="t[definition] || definition" v-for="definition in data.share.characteristics.definition_esg_list.split(',')")

</template>

<script>
export const additions = {}
export default {
  props: { data: Object },
}
</script>
