<template>
  <section class="flex flex-col gap-2" v-if="template && theme">
    <slot></slot>
    <div class="flex items-center gap-4" v-for="variant in variants" :key="variant">
      <div class="flex w-48 justify-between text-sm">
        <span>{{ variant.titleize() }} style</span>
      </div>
      <builderui-style-input
        class="w-full rounded border border-gray-100 bg-neutral-600"
        :template="template"
        v-model="theme[variant]"
        @add-style="addStyle(variant)"
        @edit-style="style => (activeStyle = style)"
      />
    </div>
  </section>
  <builderui-style-edit
    v-model="activeStyle"
    :template="template"
    @close="() => (activeStyle = undefined)"
    @delete="deleteStyle"
  />
</template>
<script setup lang="ts">
import { ref } from 'vue'
import type { TemplateLayout, TemplateTheme } from '../../builder'
import { createStyle, removeStyle } from '../../composables/theme'
interface IProps {
  template: TemplateLayout
  variants: string[]
}
const props = defineProps<IProps>()
const theme = defineModel<TemplateTheme>()
// debugger
// const activeStyle = ref<undefined | { name: string; css: string }>(undefined)
// const emit = defineEmits(['addStyle', 'editStyle'])
function addStyle(variant: string) {
  const styleName = prompt('Enter style name')
  if (!styleName) return
  // emit('addStyle', styleName)
  createStyle(styleName, props.template)
  activeStyle.value = styleName
  theme.value[variant] = styleName
}
function deleteStyle(styleName: string) {
  removeStyle(styleName, props.template)
  activeStyle.value = undefined
}
const activeStyle = ref<string>()
</script>
