import { onMounted, onUnmounted, ref } from 'vue'
import { commandrSDK } from '../../../features/commandr'
const impressions = ref([])
const initialized = ref(false)
export function useImpressions() {
  const loaded = ref(false)
  const onUpdate = snap => {
    impressions.value = (snap || {}).v()
    loaded.value = true
    initialized.value = true
  }
  onMounted(() => {
    loaded.value = false
    commandrSDK.on('/data/impressions', 'value', onUpdate)
  })
  onUnmounted(() => commandrSDK.off('/data/impressions', 'value'))
  return {
    impressions,
    loaded,
    initialized,
  }
}
