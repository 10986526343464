import type { BuilderOptions } from '../builder'

export function getComponentData(path: string, data: Record<string, any>) {
  const dataPath = (path || '').split('.')
  if (dataPath.length <= 1) return
  const dataValue = data[dataPath[0]]?.[dataPath.slice(1).join('.')]
  if (!dataValue?.vizType) {
    return { data: dataValue }
  }
  return {
    data: dataValue.data,
    options: dataValue.options,
    vizType: dataValue.vizType,
  }
}

export function getDefaultOptions(api: BuilderOptions, options: Record<string, any>, data: any) {
  Object.entries(api).forEach(([k, v]) => {
    if (!v.default || options.hasOwnProperty(k)) return
    options[k] = v.default({ data_component: data, ...report })
  })
  return options
}
