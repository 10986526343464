<template>
  <builder-chart v-bind="{ ...props, data: parsedData, nxChartOptions, chartOptions }">
    <template v-for="(_, name) in $slots" #[name]="slotData">
      <slot :name="name" v-bind="slotData"></slot>
    </template>
  </builder-chart>
</template>

<script setup lang="ts">
import { line } from '../composables/builderOptions'
import { line as story } from './stories'
import { BuilderProps } from '../composables/builderComponent'
import { dateFormatFactory } from '../lib/format'
import { INxChartOptions } from '@100-m/hauru/src/components/common/nx-chart/nx-chart'
import { computed } from 'vue'

const props = defineProps<BuilderProps>()
const parsedData = computed(() => {
  if (!props.data || !props.data.length) return
  if (props.data?.[0]?.date) {
    return props.data?.map((d: any) => {
      return {
        ...d,
        date: new Date(d.date),
      }
    })
  }
  return props.data
})
const nxChartOptions = {
  viz: 'line',
  lib: 'nx',
  limit: undefined,
  sort: props.data?.[0]?.date ? 'date' : null,
  // legend: false,
  formatX: dateFormatFactory(props?.data, props.options),
}
function legendStyleFn({ data, options }: { data: any; options?: INxChartOptions }): Record<string, string> {
  if (!options) return {}
  const highestPoint = data.reduce((acc: any, v: any) => {
    if (v[options.y] > acc[options.y]) {
      return v
    }
    return acc
  }, data[0])
  const halfPointDate = data.slice(Math.floor(data.length / 2), Math.floor(data.length / 2) + 1)[0][options.x]
  if (highestPoint[options.x] >= halfPointDate) {
    return { left: '20px' }
  }
  return { right: '0' }
}
function parseLegendPosition(position?: string) {
  if (!position || position === 'default' || position === 'floating') return 'absolute'
  return position
}
const chartOptions = computed(() => {
  return {
    legendOptions: {
      shape: props.options?.legendShape || 'line',
      position: parseLegendPosition(props?.options?.legendPosition),
      orientation: props.options?.legendOrientation || 'horizontal',
      styleFn: legendStyleFn,
    },
  }
})
</script>

<script lang="ts">
export default {
  api: line,
  styles: {
    'legend-color': `width: 12px;
height: 2px;`,
    'legend-label': '',
  },
  story,
}
</script>
