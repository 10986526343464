import { commandrSDKFileSystem } from '../../../features/commandr'

interface Asset {
  name: string
  bucketPath: string
}
export const loadAssetFromUrl = async (asset: Asset) => {
  try {
    if (asset.bucketPath) {
      // @ts-expect-error commandrSDKFileSystem not typed
      const blob = await await commandrSDKFileSystem.get({ filenameWithPath: asset.bucketPath, isPrivateFile: true })
      const cachedAsset = (await loadAssetInCache(blob)) || {}

      return {
        ...asset,
        ...cachedAsset,
        name: getFileName(asset.bucketPath),
      }
    }
    return asset
  } catch (e) {
    return {
      name: getFileName(asset.bucketPath),
    }
  }
}

export const getFileName = (path: string) => {
  return path.split('/').at(-1)
}

export const loadAssetInCache = (file: File) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.onload = e => {
      if (!e.target) return reject(new Error('no target'))
      const result = { src: e.target.result, size: Math.round(file.size / 1000) + ' ko' }
      resolve(result)
    }
    reader.onerror = e => reject(e)
    reader.readAsDataURL(file)
  })
}

export async function uploadFile(ev: Event) {
  if (!ev.target) return
  const file = (ev.target as HTMLInputElement).files?.[0]
  if (!file) return
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.onload = e => {
      if (!e?.target?.result) return
      resolve(JSON.parse(e.target.result as string))
    }
    reader.onerror = e => reject(e)
    reader.readAsText(file)
  })
}

export async function uploadAsset(arrayBuffer: number[], templateName: string, templateId: any, file: File) {
  const uint8Array = new Uint8Array(arrayBuffer)
  const filenameWithPath = `assets/${templateName}/${templateId}/${file.name}`

  await commandrSDKFileSystem.upload({ buffer: uint8Array, filenameWithPath, isPrivateFile: true })
  const asset = await loadAssetInCache(file)
  return { ...asset, bucketPath: filenameWithPath, name: getFileName(filenameWithPath) }
}
