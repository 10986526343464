export function objectEqual(a: any, b: any): boolean {
  if (a === b) return true
  const ta = Object.prototype.toString.call(a)
  if (ta !== Object.prototype.toString.call(b)) return false
  if (!['[object Object]', '[object Array]'].includes(ta)) return a.toString() === b.toString()
  if (Object.keys(a).length !== Object.keys(b).length) return false
  return Object.keys(a).every(k => objectEqual(a[k], b[k]))
}

type Func<T extends any[], R> = (...args: T) => R

export function debounce<T extends any[], R>(func: Func<T, R>, timeout = 300): Func<T, void> {
  let timer: ReturnType<typeof setTimeout>

  return (...args: T) => {
    clearTimeout(timer)
    timer = setTimeout(() => {
      func.apply(this, args)
    }, timeout)
  }
}

export function traverseObject(
  obj: any,
  fnLeaf: any,
  fnNode = (v: any, path: string[], root?: any) => null,
  path = [],
  root = null,
): void {
  if (!obj) return
  if (obj instanceof Object) {
    fnNode(obj, path, root)
    return Object.keys(obj).forEach((k, i) => traverseObject(obj[k], fnLeaf, fnNode, path.concat(k), root || obj))
  }
  return fnLeaf(obj, path, root)
}

export function onlyUnique(arr: any[]) {
  return arr.filter((d: any, idx: number) => arr.indexOf(d) === idx)
}
