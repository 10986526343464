import config from '../../../config'
import { useGraphQL } from '../../../utils/axios'

export function useAllocation() {
  // TODO: put this in a data report
  const getAllocation = async ({ isinShare, fundId, benchmarkId, dimensions, domain, compareDate, asof }) => {
    const { sendQuery } = useGraphQL(config)
    const ids = benchmarkId
      ? [
          {
            fuid: isinShare,
            name: 'fund',
          },
          {
            fuid: benchmarkId,
            name: 'benchmark',
          },
        ]
      : [
          {
            fuid: isinShare,
            name: 'fund',
          },
        ]
    const query = `#graphql
    query Allocation($ids: FuidInput!, $domain: Domain!, $dimensions: [String], $asof: Date) {
      inventory {
        allocation(fuid: $ids, domain: $domain, dimensions: $dimensions, asof: $asof) {
          date
          name
          weight
          valuation
          axis
        }
      }
    }`
    const variables = {
      ids,
      domain,
      asof,
      dimensions,
    }
    const resp = await sendQuery(query, variables)
    const valueKeys = ['valuation', 'weight']
    return resp.inventory.allocation.map(d => {
      valueKeys.forEach(key => {
        d[`${key}_fund`] = d[key].fund
        d[`${key}_benchmark`] = d[key].benchmark
        d[`${key}_diff`] = d[key].fund - d[key].benchmark
      })
      return d
    })
  }
  return {
    getAllocation,
  }
}
