<template lang="pug">
.relative
  slot(name="legend" :legends="legends")
    legends(:legends="legends")
  canvas(ref="canvas")
  slot(name="tooltip" :tooltip="tooltip")
    table-tooltip(v-if="tooltip.shown" :title='tooltip.title' :rows='tooltip.rows')
</template>
<script>
import { toRefs } from 'vue'
import useDatasets from './composables/useDatasets'
import useCreateChart from './composables/useCreateChart'
import usePieTooltip from './composables/usePieTooltip'
import useCustomLegend from './composables/useCustomLegend'

export default {
  props: {
    /**
     * Array of data eg. [{date: '2020-01-01', fund: '105', benchmark: '102'}, ...]
     */
    data: {
      type: Array,
      default: () => [],
    },
    /**
     * Metadata
     */
    options: {
      type: Object,
      default: () => ({}),
    },
  },
  setup(props) {
    const { data, options } = toRefs(props)

    const { tooltip, tooltipOptions } = usePieTooltip()
    const { legends } = useCustomLegend()

    const chartOptions = {
      plugins: {
        tooltip: tooltipOptions,
        legend: {
          display: false,
        },
        customLegend: {
          legends,
        },
      },
      interaction: {
        intersect: false,
        axis: 'x',
      },
    }
    const { datasets, labels } = useDatasets(
      data,
      options,
      { borderColor: undefined, hoverOffset: 5 },
      { multicolor: true },
    )
    const { canvas } = useCreateChart({ type: 'pie', datasets, labels, chartOptions })

    return {
      canvas,
      tooltip,
      legends,
    }
  },
}
</script>
