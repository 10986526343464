<template>
  <story-template :story="[buttonStory]" class="h-80 flex-col" :max-examples="5">
    <div class="flex gap-16">
      <div class="flex flex-col p-2" :class="[props.isPreview ? 'gap-3' : 'gap-2']">
        <div class="flex items-center gap-2">
          <div class="w-32 font-bold" v-if="!props.isPreview">NX Theme</div>
          <nx-button>Basic</nx-button>
          <nx-button type="outlined">Outlined</nx-button>
          <nx-button type="flat">Flat</nx-button>
        </div>
        <div class="h-4" v-if="!props.isPreview"></div>
        <div class="flex items-center gap-2">
          <div class="w-32 font-bold" v-if="!props.isPreview">Sizes</div>
          <nx-button type="outlined" size="xs" class="w-12">xs</nx-button>
          <nx-button type="outlined" size="sm" class="w-12">sm</nx-button>
          <nx-button type="outlined" size="md" class="w-12">md</nx-button>
          <nx-button type="outlined" class="w-12">base</nx-button>
          <nx-button type="outlined" size="lg" class="w-12">lg</nx-button>
        </div>
        <div class="h-4" v-if="!props.isPreview"></div>
        <div class="flex items-center gap-2">
          <div class="w-32 font-bold" v-if="!props.isPreview">Shapes</div>
          <nx-button type="outlined" shape="rectangle">Rectangle</nx-button>
          <nx-button type="outlined" shape="rounded">Rounded</nx-button>
          <nx-button type="outlined" shape="circle">Circle</nx-button>
        </div>
        <div class="h-4" v-if="!props.isPreview"></div>
        <div class="flex items-center gap-2">
          <div class="w-32 font-bold" v-if="!props.isPreview">Icons</div>
          <nx-button type="outlined" icon="i-[ic/baseline-add]">Add</nx-button>
          <nx-button type="outlined" icon="i-[ic/baseline-add]" icon-placement="right">Add</nx-button>
          <nx-button type="outlined" shape="circle" icon="i-[ic/baseline-add]" icon-placement="center"></nx-button>
        </div>
      </div>
      <div class="flex flex-col gap-2 p-2" v-if="!props.isPreview">
        <div class="flex items-center gap-2">
          <div class="w-32 font-bold">Visage Theme</div>
          <nx-button theme="visage">Basic</nx-button>
          <nx-button theme="visage" type="outlined">Outlined</nx-button>
          <nx-button theme="visage" type="flat">Flat</nx-button>
        </div>
        <div class="h-4"></div>
        <div class="flex items-center gap-2">
          <div class="w-32 font-bold"></div>
          <nx-button theme="visage" type="outlined" size="xs" class="w-12">xs</nx-button>
          <nx-button theme="visage" type="outlined" size="sm" class="w-12">sm</nx-button>
          <nx-button theme="visage" type="outlined" size="md" class="w-12">md</nx-button>
          <nx-button theme="visage" type="outlined" class="w-12">base</nx-button>
          <nx-button theme="visage" type="outlined" size="lg" class="w-12">lg</nx-button>
        </div>
        <div class="h-4"></div>
        <div class="flex items-center gap-2">
          <div class="w-32 font-bold"></div>
          <nx-button theme="visage" type="outlined" shape="rectangle">Rectangle</nx-button>
          <nx-button theme="visage" type="outlined" shape="rounded">Rounded</nx-button>
          <nx-button theme="visage" type="outlined" shape="circle">Circle</nx-button>
        </div>
        <div class="h-4"></div>
        <div class="flex items-center gap-2">
          <div class="w-32 font-bold"></div>
          <nx-button theme="visage" type="outlined" icon="i-[ic/baseline-add]">Add</nx-button>
          <nx-button theme="visage" type="outlined" icon="i-[ic/baseline-add]" icon-placement="right">Add</nx-button>
          <nx-button
            theme="visage"
            type="outlined"
            shape="circle"
            icon="i-[ic/baseline-add]"
            icon-placement="center"
          ></nx-button>
        </div>
      </div>
    </div>
  </story-template>
</template>

<script setup lang="ts">
import { INxButtonProps, useTheme } from '@hauru/common'
import { createStory } from '@storytime'

const props = withDefaults(
  defineProps<{
    isPreview: boolean
  }>(),
  {},
)

const themeConfig = useTheme()
themeConfig.addToTheme({
  $default: 'nx',
  themes: {
    visage: {
      button: {
        default: 'basic',
        basic: {
          idle: 'text-gray-900 hover:bg-accent hover:border-accent hover:text-white',
        },
        outlined: {
          idle: 'text-gray-900 border border-solid border-accent duration-75 hover:bg-accent hover:text-white',
        },
        flat: {
          idle: 'text-gray-900 bg-accent duration-75 hover:bg-accent hover:text-white',
        },
      },
    },
  },
})

const buttonStory = createStory<INxButtonProps>('nx-button')
</script>
