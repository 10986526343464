<template>
  <div class="lfr-header flex h-fit flex-col items-center justify-between bg-white">
    <div class="flex w-full flex-row justify-between px-6 text-[11px]">
      <div class="text-primary flex font-bold">
        {{ data?.data?.header?.share_id }}
      </div>
      <div class="text-primary flex font-bold">
        {{ data.translation[data.data.header.period] || data.data.header.period }}
        - {{ date }}
      </div>
    </div>
    <div class="bg-primary flex h-fit w-full flex-row items-center justify-between text-white shadow-sm">
      <div class="flex w-full flex-row items-center justify-between px-6">
        <div class="my-2 flex h-16 w-16">
          <img :src="headerImg" alt="Logo LFR" />
        </div>
        <div class="m-2 flex flex-col">
          <div class="flex text-lg font-semibold uppercase">
            {{ data.data.header?.company_name }}
          </div>
          <div class="flex text-2xl font-bold">
            {{ data.data.header?.fund_name }}
          </div>
        </div>
        <div class="ml-auto flex">
          <img
            class="max-h-[50px] max-w-[50px]"
            v-for="label in data['nxpack-fund-ref'].label.split(',')"
            :src="label"
          />
        </div>
      </div>
    </div>
    <div class="flags flex" v-if="data['nxpack-fund-ref'].country_registration">
      <div class="flex items-center font-bold">{{ data.translation.country_registration }}:</div>
      <div class="flex items-center" v-for="flag in data['nxpack-fund-ref'].country_registration.split('-')">
        <svg-icon :name="['flag', flag.lower().trim()].join('-')"></svg-icon>
        <div>{{ flag }}</div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed } from 'vue'
const { data, options } = defineProps(['data', 'options'])
const date = computed(() =>
  new Intl.DateTimeFormat(data.data.header.lang.slice(0, 2), {
    day: 'numeric',
    month: 'long',
    year: 'numeric',
  }).format(new Date(data.data.header.domainArray[1])),
)
const headerImg = computed(
  () => options.layout.theme?.assets?.find(el => el.name === 'logo-header.png')?.src || '/logo-header.png',
)
</script>

<style>
.lfr-header,
.lfr-footer {
  flex: 0;
  width: calc(100% + var(--spacing) * 2);
  margin-left: calc(var(--spacing) * -1);
  margin-right: calc(var(--spacing) * -1);
}
.lfr-header {
  margin-top: calc(var(--spacing) * -1) !important;
  margin-bottom: 0 !important;
}
.flags {
  height: 20px;
  align-self: flex-start;
  padding: 5px;
  margin-left: calc(var(--spacing));
}
.flags .svg-icon {
  width: 25px;
}
</style>
