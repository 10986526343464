import * as fb from 'firebase/app'
import 'firebase/database'
import AbstractDatabase from '../AbstractDatabase.js'

const firebase = fb.default

export default class FirebaseDatabase extends AbstractDatabase {
  constructor(config) {
    super()
    this.initDB(config)
  }

  // generic public methods
  initDB({ root, name, project, apiKey }) {
    if (!root && !name && !project) return

    if (!firebase.apps.length) {
      const clientName = name.replace('-default-rtdb', '')

      firebase.initializeApp({
        apiKey,
        authDomain: `${clientName}.firebaseapp.com`,
        databaseURL: `https://${name}.firebaseio.com`
      })
    }
    if (!firebase.database) {
      return console.log('ERROR: firebase.database is not defined')
    }

    this.setDbRef(firebase.database().ref(root).child('public'))
    return true
  }

  async get(path, callback) {
    const value = (
      await this.accessToSpecificPartOfFirebase(this.formatPath(path)).get()
    ).val()

    if (callback) {
      return callback(value)
    }
    return value
  }

  async getPartialRunsPerYear(year, limit, callback) {
    const maxDelayMs = 5000
    let waitedMs = 0
    const getValue = async () => {
      const delayMs = 500
      await new Promise((r) => setTimeout(r, delayMs))
      waitedMs += delayMs
      try {
        return (
          await this.accessToSpecificPartOfFirebase(
            this.formatPath('/data/runs')
          )
            .orderByChild('year')
            .equalTo(year)
            .limitToLast(limit)
            .get()
        ).val()
      } catch (e) {
        // For some reason Firebase throws a "client is offline" error
        console.log(
          `Firebase client may not be ready yet, trying again in ${delayMs}ms.\n`,
          e
        )
        return waitedMs < maxDelayMs ? await getValue() : null
      }
    }
    const value = await getValue()
    if (callback) {
      return callback(value)
    }
    return value
  }

  update(path, value) {
    return this.accessToSpecificPartOfFirebase(this.formatPath(path)).update(
      value
    )
  }

  set(path, value) {
    return this.accessToSpecificPartOfFirebase(this.formatPath(path)).set(value)
  }

  on(path, event, callback, preset) {
    const wrappedCallback = (snap) => callback(this.formatValue(snap))

    if (preset) {
      return preset.on(event, wrappedCallback)
    }

    return this.accessToSpecificPartOfFirebase(this.formatPath(path)).on(
      event,
      wrappedCallback
    )
  }

  off(path, event, preset) {
    if (preset) {
      return preset.off(event)
    }

    return this.accessToSpecificPartOfFirebase(this.formatPath(path)).off(event)
  }

  // specific public methods
  async incrementAndGetRunId() {
    const res = await this.transaction('/data/run_id', (id) => {
      if (!id) return '10000'
      return `${+id + 1}`
    })

    return +res
  }

  syncRunsForYear(year, event, callback) {
    return this.on('', event, callback, this.presetRunsForYear(year))
  }

  unSyncRunsForYear(year, event) {
    return this.off('', event, this.presetRunsForYear(year))
  }

  // private specific methods
  presetRunsForYear(year) {
    if (year === 'all') {
      return this.accessToSpecificPartOfFirebase(this.formatPath('/data/runs'))
    }

    return this.accessToSpecificPartOfFirebase(this.formatPath('/data/runs'))
      .orderByChild('year')
      .equalTo(year)
  }

  async transaction(path, t) {
    return (
      await this.accessToSpecificPartOfFirebase(
        this.formatPath(path)
      ).transaction(t)
    ).snapshot.val()
  }

  formatPath(path) {
    if (typeof path === 'string') {
      return path.split('.')
    } else if (Array.isArray(path)) {
      return path
    }
    throw new TypeError('Invalid path type, must be string or array')
  }

  formatValue(snap) {
    return snap.val()
  }

  accessToSpecificPartOfFirebase(path) {
    const dbRef = this.getDbRef()
    return path.reduce((acc, p) => (!p ? acc : acc.child(p)), dbRef)
  }
}
