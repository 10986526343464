import { ref, computed } from 'vue'
import { merge } from '../utils/merge'
import { useGraphQL } from '../utils/axios'
import config from '../config'
const translations = ref({})

export function setTranslation(translation, key = 'default') {
  translations.value[key] = merge(translations.value[key] || {}, translation)
}

/**
 * Get parsed query params
 * @return {{}}
 */
function getQueries() {
  return location.search
    .replace('?', '')
    .split('&')
    .reduce((acc, b) => {
      const q = b.split('=')
      acc[q[0]] = q[1] || undefined
      return acc
    }, {})
}

export function useTranslation() {
  const query = getQueries()
  const lang = ref(query.lang || localStorage.LANG || 'en')
  const t = computed(() => {
    // TODO don't rely on $root.app
    const app = $root.app || 'default'
    let l = lang.value.toLowerCase()
    if (!translations.value[app][l]) l = 'en'
    const current = translations.value[app][l] || {}
    const def = translations.value.default[l] || {}
    return { ...def, ...current }
  })
  const langs = computed(() => {
    if (config.langSelector) return config.langSelector
    const app = $root.app || 'default'
    return Object.keys(translations.value[app])
  })
  return {
    translations,
    t,
    lang,
    langs,
  }
}

let last_downloaded
export const downloadMixin = {
  methods: {
    async getTranslations(lang) {
      const { sendQuery } = useGraphQL(config)
      const query = `#graphql
        query TranslationsQuery($lang: Lang!) {
              config {
                translations(lang: $lang)
              }
            }`
      const variables = { lang }
      const resp = await sendQuery(query, variables)
      if (resp && resp.config) {
        setTranslation({ [lang]: resp.config.translations }, 'digital-edition')
      }
    },
  },
  watch: {
    '$root.lang': {
      handler(lang) {
        if (last_downloaded === lang) return
        last_downloaded = lang
        this.getTranslations(lang)
      },
      immediate: true,
    },
  },
}
