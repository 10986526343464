<template>
  <div v-if="imageFileName === 'error'" class="rounded-md !bg-red-50 p-2 !outline !outline-red-500">
    Error with image
  </div>
  <img v-else class="m-auto w-[100%] object-contain" :src="imageFileName" />
</template>
<script setup>
import { computed } from 'vue'
const { data, options } = defineProps(['data', 'options'])

const imageFileName = computed(() => {
  if (options.layout.theme.assets?.[+data - 1]) {
    // legacy image management with ID (Keep to prevent breaking existing reports)
    return options.layout.theme.assets?.[+data - 1]?.src
  }
  return options.layout.theme.assets.find(d => d.name === data.name)?.src || 'error'
})
</script>
