/**
 * Configuration manager.
 */

/**
 *
 * @type {{}}
 */
const config = {}

/**
 * Extend the configuration
 * @param cfg
 */
export function extendConfig(cfg) {
  Object.entries(cfg).forEach(([k, v]) => (config[k] = v))
}

/**
 * Configuration object
 */
const configProxy = new Proxy(config, {
  get: (target, prop) => target[prop],
  set: (target, prop, value) => {
    throw new Error('It s not a store.')
  },
})

export default configProxy

// Expose config on window for debug purpose
window.config = configProxy
