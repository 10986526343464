import { GraphQlService } from './GraphQlService'

export class TechnicalAuditService extends GraphQlService {
  async checkDataSourceWebServices() {
    return (
      await this.query(`
      query CheckDataSourceWebServices {
        rawData {
          checkDataSourceWebServices
        }
      }`)
    ).data.rawData.checkDataSourceWebServices
  }

  async getMissingColumnDefinitions(tableName) {
    return (
      await this.query(
        `
      query MissingColumnDefinitions($tableName: String!) {
        rawData {
          missingColumnDefinitions(tableName: $tableName)
        }
      }`,
        { tableName },
      )
    ).data.rawData.missingColumnDefinitions
  }
}

const service = new TechnicalAuditService()
export default service
