<template>
  <div class="nx-bar flex w-full flex-col divide-y self-center">
    <div
      class="bar-group flex w-full items-center space-x-2 py-1"
      :class="item.name?.format('dash')"
      v-for="(item, index) in barData"
      :key="index"
    >
      <slot name="bar-label" :label="item[options.x]">
        <div class="bar-label w-1/3">{{ item[options.x] }}</div>
      </slot>
      <div class="bar-line flex h-full w-2/3 flex-col justify-center">
        <div class="bar-item flex h-full flex-row items-center" v-for="(cat, idx) in categories" :key="cat">
          <slot
            name="bar"
            :value="item[cat]"
            :category="cat"
            :options="options"
            :index="index"
            :categoryIndex="idx"
            :size="size"
            :offset="offset"
            :formattedValue="options.formatX(item[cat])"
          >
            <div class="bar-wrapper w-3/4">
              <div
                class="bar-bar relative h-2 rounded-sm"
                :style="{
                  width: size(item[cat]) + '%',
                  backgroundColor: options.palette[idx],
                  left: offset(item[cat]),
                }"
              ></div>
            </div>
          </slot>
          <slot
            name="bar-value"
            :value="item[cat]"
            :formattedValue="options.formatLabel(item[cat])"
            :size="size"
            :offset="offset"
          >
            <div class="bar-value w-1/4 text-right">
              <div class="leading-none">{{ options.formatLabel(item[cat]) }}</div>
            </div>
          </slot>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
import { getUniqueCategories, pivotData } from './data-utils'
import { computed } from 'vue'

const props = defineProps(['data', 'options'])
const categories = computed(() => getUniqueCategories(props.data, props.options))
const barData = computed(() => pivotData(props.data, props.options, categories.value))
const max = computed(() => props.data.flatMap((d: any) => d.group.map((g: any) => g[props.options.y] || 0)).max())
const min = computed(() =>
  Math.min(props.data.flatMap((d: any) => d.group.map((g: any) => g[props.options.y] || 0)).min(), 0),
)
const maxDelta = computed(() => max.value - min.value)
const offsetValue = computed(() => (min.value < 0 ? Math.abs(min.value) : 0))
const size = computed(() => (val: number) => Math.round((Math.abs(val) / maxDelta.value) * 100))
const offset = computed(() => (val: number) => {
  if (val > 0) {
    return `${size.value(offsetValue.value)}%`
  } else {
    return `${size.value(offsetValue.value) - size.value(val)}%`
  }
})
</script>
