<style>
.pdf footer {
  flex-direction: row;
  margin-top: auto;
  min-width: calc(100% + calc(2 * var(--margin))) !important;
  margin: auto calc(-1 * var(--margin)) calc(-1 * var(--margin)) calc(-1 * var(--margin)) !important;
  padding: 10px var(--margin);
  border-top: 1px solid var(--colors-primary-default);
  border-radius: 0;
  font-size: 7px;
}
.pdf footer .disclaimer {
  flex: 1;
}
.pdf footer img {
  height: 40px;
}
.pdf footer .square {
  height: 40px;
}
.pdf footer .logo {
  align-items: center;
}
.pdf footer .logo img {
  height: 40px;
  align-self: flex-end;
}
.pdf footer .pagenum {
  margin-left: 8px;
  font-size: 10px;
  font-weight: 600;
  color: var(--colors-primary-default);
}
</style>

<template lang="pug">
footer.row.center
  .logo.row.center(v-if="share.logo2_footer && share.investment_manager !== 'investment_manager_jk_capital_management_limited' ")
    img(:src="share.logo2_footer")
  .disclaimer(v-html="t[share.disclaimer_footer]")
  .logo.row.center(v-if="!share.logo2_footer || (share.logo2_footer !== 'cholet_dupont.png' && share.management_company!=='management_company_nord_est_asset_management' && share.investment_manager !== 'investment_manager_jk_capital_management_limited') ")
    img(:src="mgmt_logo")
  .logo.row.center(v-if="share.logo2_footer && share.investment_manager === 'investment_manager_jk_capital_management_limited' ")
    .column
      img.mgmt_logo(:src="mgmt_logo")
      img.logo2_footer(:src="logo2_footer")
  .pagenum {{ pagenum }}/{{ pagelen }}
</template>

<script>
export const additions = {}
export default {
  props: {
    share: {
      type: Object,
      required: true,
    },
    show_agec_logo: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      pagenum: 0,
      pagelen: 0,
      mgmt_logo: 'Logo_New_CMAM.png',
    }
  },
  mounted() {
    this.pagenum = Array.from(this.$el.parentNode.parentNode.children).findIndex(el => el === this.$el.parentNode) + 1
    this.pagelen = Array.from(this.$el.parentNode.parentNode.children).length
    if (['new_alpha_am', 'cholet_dupont'].includes(this.share.theme)) {
      this.mgmt_logo = `${this.share.theme}.png`
    }
    //! hack: to be deleted once theme is fixed for new version
    if (this.share.fund_id === 147456) {
      this.mgmt_logo = 'new_alpha_am.png'
    }
  },
  computed: {
    //! hack: to be deleted/fixed for new version refactor
    logo2_footer() {
      if (this.share.logo2_footer === 'jk_cm.png') return 'jkc.png'
      return this.share.logo2_footer
    },
  },
}
</script>
