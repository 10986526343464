<template>
  <lfr-block :data="_data" :options="options">
    <div class="esgscore-scale text-primary py-2 leading-[11.2px]">
      <div class="scale-legend flex justify-around">
        <div class="flex">
          <svg xmlns="http://www.w3.org/2000/svg">
            <circle cx="50%" cy="50%" r="3" :fill="options.store.versions[0].layout.theme.primary" />
          </svg>
          <p>
            {{
              `${options.data.translation['score_esg']} ${options.data.translation['fund']} ${scores.fund.toFixed(2)}`
            }}
          </p>
        </div>
        <div class="flex" v-if="scores.benchmark">
          <svg xmlns="http://www.w3.org/2000/svg">
            <circle cx="50%" cy="50%" r="3" :fill="options.store.versions[0].layout.theme.secondary" />
          </svg>
          <p>
            {{
              `${options.data.translation['score_esg']} ${options.data.translation['univers']} ${scores.benchmark.toFixed(2)}`
            }}
          </p>
        </div>
      </div>
      <div class="mb-0 flex justify-between">
        <p>
          {{ options.data.translation['subtitle_esg_coverage'].split(' / ')[0] }}
        </p>
        <p>
          {{ options.data.translation['subtitle_esg_coverage'].split(' / ')[1] }}
        </p>
      </div>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 400 25"
        :fill="options.store.versions[0].layout.theme.primary"
      >
        <path d="M0 9 9 0 18 9 9 18 0 9M15 6H385V12H15V6M382 9 391 0 400 9 391 18 382 9" />
        <circle
          v-if="scores.benchmark"
          :cx="scores.benchmark * 10 + '%'"
          cy="9px"
          r="9px"
          :fill="options.store.versions[0].layout.theme.secondary"
        />
        <circle :cx="scores.fund * 10 + '%'" cy="9px" r="9px" :fill="options.store.versions[0].layout.theme.primary" />
      </svg>
      <div class="flex justify-between">
        <p v-for="(n, i) in 11">{{ i % 2 === 0 ? i : '' }}</p>
      </div>
    </div>
  </lfr-block>
</template>

<script setup>
import { computed } from 'vue'
const { data, options } = defineProps(['data', 'options'])
const scores = computed(() => ({
  fund: data,
  benchmark: parseFloat(options.data['nxpack-table'].esg_coverage_table_standard[2][2].replace(',', '.')) || 0,
}))
</script>

<style>
.esgscore-scale {
  width: 100%;
}
.scale-legend svg {
  width: 10px;
  height: 10px;
}
</style>
