<template>
  <!-- nx-dropdown -->
  <VDropdown
    v-if="isRendered"
    v-bind="sanitizedProps"
    :nx-theme="props.type ?? props.theme"
    :popperClass="['focus:outline-none', props.popperClass, currentTheme?.popperClass]"
  >
    <!-- @slot Button content of the dropdown -->
    <template #default="{ shown, show, hide }">
      <slot name="button" :show="show" :shown="shown" :hide="hide"></slot>
    </template>
    <template #popper>
      <!-- @slot Content of the dropdown used to place the items that will be displayed when the dropdown is opened -->
      <slot></slot>
    </template>
  </VDropdown>
  <div v-else @mouseover="isRendered = true">
    <slot name="button" :show="() => {}" :shown="false" :hide="() => {}"></slot>
  </div>
</template>

<script setup lang="ts">
/* eslint-disable @typescript-eslint/no-unused-vars */

import { useTheme } from '@hauru/common'
import { computed, ref } from 'vue'
import { useAttrs } from 'vue'

const attrs = useAttrs()

interface IProps {
  /**
   * Allows to manually bypass the theme set as default, among the themes provided by the theme config
   */
  theme?: string
  /**
   * The type of the dropdown among the types defined in the theme config
   */
  type?: string
  shown?: boolean
  showGroup?: string
  /**
   * Boolean indicitating whether the dropdown should be disabled
   */
  disabled?: boolean
  /**
   * Boolean indicating whether the automatic positioning of the dropdown should be disabled
   */
  positioningDisabled?: boolean
  /**
   * Tooltip placement relative to target element
   */
  placement?:
    | 'auto'
    | 'top'
    | 'top-start'
    | 'top-end'
    | 'bottom'
    | 'bottom-start'
    | 'bottom-end'
    | 'right'
    | 'right-start'
    | 'right-end'
    | 'left'
    | 'left-start'
    | 'left-end'
  /**
   * Delay to show and hide the tooltip (in ms)
   */
  delay?: number | { show: number; hide: number }
  /**
   * Position offset along main axis (in px)
   */
  distance?: number | string
  /**
   * Position offset along cross axis (px)
   */
  skidding?: number | string
  /**
   * Events that trigger the tooltip to show / hide (ex. 'hover', 'focus', 'touch')
   */
  triggers?: string[]
  /**
   * Events that trigger the tooltip to show (ex. 'hover', 'focus', 'touch')
   */
  showTriggers?: string[]
  /**
   * Events that trigger the tooltip to show (ex. 'hover', 'focus', 'touch')
   */
  hideTriggers?: string[]
  popperHideTriggers?: string[] | ((currentTriggers: string[]) => string[])
  /**
   * Class selector or HTMLElement of the container we will append the dropdown to
   * If false, it will be appended to the parent element
   */
  container?: string | HTMLElement | false
  /**
   * Class selector or HTMLElement of element used to compute position and size boundaries
   */
  boundary?: string | HTMLElement
  /**
   * Positioning strategy of the dropdown
   */
  strategy?: 'absolute' | 'fixed'
  /**
   * Boolean indicating whether the dropdown should be hidden when clicking outside
   */
  autoHide?: boolean | ((event: string) => boolean)
  /**
   * Boolean indicating whether the dropdown should be updated on content resize
   */
  handleResize?: boolean
  /**
   * Skip delay & CSS transitions when another popper is shown, so that the popper appears to instanly move to the new position
   */
  instantMove?: boolean
  /**
   * Classes to apply to the dropdown element
   */
  popperClass?: string | string[]
}

const props = withDefaults(defineProps<IProps>(), {
  shown: false,
  disabled: false,
  distance: 5,
  skidding: 0,
  container: 'body',
  instantMove: false,
})

const isRendered = ref(false)

const themeConfig = useTheme()
const currentTheme = themeConfig.computedThemeType('dropdown', props)

const sanitizedProps = computed(() => {
  const { theme, type, ...rest } = props
  const { $extends, ...rest2 } = currentTheme.value ?? {}
  return { ...rest, ...rest2, attrs }
})
</script>

<style>
.resize-observer[data-v-b329ee4c] {
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  width: 100%;
  height: 100%;
  border: none;
  background-color: transparent;
  pointer-events: none;
  display: block;
  overflow: hidden;
  opacity: 0;
}
.resize-observer[data-v-b329ee4c] object {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  overflow: hidden;
  pointer-events: none;
  z-index: -1;
}
.v-popper__popper {
  z-index: 150000;
  top: 0;
  left: 0;
  outline: none;
}
.v-popper__popper.v-popper__popper--hidden {
  visibility: hidden;
  opacity: 0;
  transition:
    opacity 0.15s,
    visibility 0.15s;
  pointer-events: none;
}
.v-popper__popper.v-popper__popper--shown {
  visibility: visible;
  opacity: 1;
  transition: opacity 0.15s;
}
.v-popper__popper.v-popper__popper--skip-transition,
.v-popper__popper.v-popper__popper--skip-transition > .v-popper__wrapper {
  transition: none !important;
}
.v-popper__backdrop {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: none;
}
.v-popper__inner {
  position: relative;
  box-sizing: border-box;
  overflow-y: auto;
}
.v-popper__inner > div {
  position: relative;
  z-index: 1;
  max-width: inherit;
  max-height: inherit;
}
.v-popper__arrow-container {
  position: absolute;
  width: 10px;
  height: 10px;
}
.v-popper__popper--arrow-overflow .v-popper__arrow-container,
.v-popper__popper--no-positioning .v-popper__arrow-container {
  display: none;
}
.v-popper__arrow-inner,
.v-popper__arrow-outer {
  border-style: solid;
  position: absolute;
  top: 0;
  left: 0;
  width: 0;
  height: 0;
}
.v-popper__arrow-inner {
  visibility: hidden;
  border-width: 7px;
}
.v-popper__arrow-outer {
  border-width: 6px;
}
.v-popper__popper[data-popper-placement^='top'] .v-popper__arrow-inner,
.v-popper__popper[data-popper-placement^='bottom'] .v-popper__arrow-inner {
  left: -2px;
}
.v-popper__popper[data-popper-placement^='top'] .v-popper__arrow-outer,
.v-popper__popper[data-popper-placement^='bottom'] .v-popper__arrow-outer {
  left: -1px;
}
.v-popper__popper[data-popper-placement^='top'] .v-popper__arrow-inner,
.v-popper__popper[data-popper-placement^='top'] .v-popper__arrow-outer {
  border-bottom-width: 0;
  border-left-color: transparent !important;
  border-right-color: transparent !important;
  border-bottom-color: transparent !important;
}
.v-popper__popper[data-popper-placement^='top'] .v-popper__arrow-inner {
  top: -2px;
}
.v-popper__popper[data-popper-placement^='bottom'] .v-popper__arrow-container {
  top: 0;
}
.v-popper__popper[data-popper-placement^='bottom'] .v-popper__arrow-inner,
.v-popper__popper[data-popper-placement^='bottom'] .v-popper__arrow-outer {
  border-top-width: 0;
  border-left-color: transparent !important;
  border-right-color: transparent !important;
  border-top-color: transparent !important;
}
.v-popper__popper[data-popper-placement^='bottom'] .v-popper__arrow-inner {
  top: -4px;
}
.v-popper__popper[data-popper-placement^='bottom'] .v-popper__arrow-outer {
  top: -6px;
}
.v-popper__popper[data-popper-placement^='left'] .v-popper__arrow-inner,
.v-popper__popper[data-popper-placement^='right'] .v-popper__arrow-inner {
  top: -2px;
}
.v-popper__popper[data-popper-placement^='left'] .v-popper__arrow-outer,
.v-popper__popper[data-popper-placement^='right'] .v-popper__arrow-outer {
  top: -1px;
}
.v-popper__popper[data-popper-placement^='right'] .v-popper__arrow-inner,
.v-popper__popper[data-popper-placement^='right'] .v-popper__arrow-outer {
  border-left-width: 0;
  border-left-color: transparent !important;
  border-top-color: transparent !important;
  border-bottom-color: transparent !important;
}
.v-popper__popper[data-popper-placement^='right'] .v-popper__arrow-inner {
  left: -4px;
}
.v-popper__popper[data-popper-placement^='right'] .v-popper__arrow-outer {
  left: -6px;
}
.v-popper__popper[data-popper-placement^='left'] .v-popper__arrow-container {
  right: -10px;
}
.v-popper__popper[data-popper-placement^='left'] .v-popper__arrow-inner,
.v-popper__popper[data-popper-placement^='left'] .v-popper__arrow-outer {
  border-right-width: 0;
  border-top-color: transparent !important;
  border-right-color: transparent !important;
  border-bottom-color: transparent !important;
}
.v-popper__popper[data-popper-placement^='left'] .v-popper__arrow-inner {
  left: -2px;
}
.v-popper--theme-tooltip .v-popper__arrow-outer {
  border-color: #000c;
}
</style>
