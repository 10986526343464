<template lang="pug">
.donut-wrapper.relative
  slot(name="legend" :legends="legends")
    legends(:legends="legends")
  .relative
    canvas(ref="canvas")
    slot(name="tooltip" :tooltip="tooltip")
      donut-tooltip(v-if="tooltip.shown" :title='tooltip.title' :rows='tooltip.rows')
</template>
<script>
import { toRefs } from 'vue'
import useDatasets from './composables/useDatasets'
import useCreateChart from './composables/useCreateChart'
import usePieTooltip from './composables/usePieTooltip'
import useCustomLegend from './composables/useCustomLegend'

export default {
  props: {
    /**
     * Array of data eg. [{date: '2020-01-01', fund: '105', benchmark: '102'}, ...]
     */
    data: {
      type: Array,
      default: () => [],
    },
    /**
     * Metadata
     */
    options: {
      type: Object,
      default: () => ({}),
    },
  },
  emits: ['seriesClick'],
  setup(props, { emit }) {
    const { data, options } = toRefs(props)

    const { tooltip, tooltipOptions } = usePieTooltip()
    const { legends } = useCustomLegend()

    const chartOptions = {
      cutout: '70%',
      plugins: {
        tooltip: tooltipOptions,
        legend: {
          display: false,
          labels: {
            usePointStyle: true,
          },
          align: 'start',
        },
        customLegend: {
          legends,
        },
      },
      interaction: {
        intersect: false,
        axis: 'x',
      },
    }
    chartOptions.onClick = e => {
      const activePoints = e.chart.getElementsAtEventForMode(e, 'nearest', {}, false)
      const [{ index }] = activePoints
      emit('seriesClick', e.chart.data.labels[index])
    }
    const { datasets, labels } = useDatasets(
      data,
      options,
      { borderColor: undefined, hoverOffset: 5 },
      { multicolor: true, borderColor: false },
    )
    const { canvas } = useCreateChart({ type: 'doughnut', datasets, labels, chartOptions })

    return {
      canvas,
      tooltip,
      legends,
    }
  },
}
</script>
