<style>
.pdf-text {
  display: table;
  width: 100%;
  text-align: justify;
  white-space: pre-wrap;
}
.pdf-text > * {
  display: table-row;
}
.pdf-text > * > * {
  display: table-cell;
  max-width: 0px;
}
</style>

<template lang="pug">
.pdf-text
  div
    div(v-html="data")
</template>

<script>
export default {
  props: {
    data: {
      type: String,
      default: () => '',
    },
  },
}
</script>
