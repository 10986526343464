<template>
  <div class="fixed inset-0 z-10 w-screen overflow-y-auto bg-opacity-75" v-if="style">
    <div class="flex min-h-full items-end justify-center text-center sm:items-center sm:p-0">
      <div
        class="relative transform overflow-hidden rounded bg-white text-left text-black shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg"
      >
        <div class="flex flex-col gap-4 px-4 py-2">
          <!-- <div>{{ previousStyle }}</div>
          <div>{{ style }}</div> -->
          <div class="flex flex-row items-center gap-2">
            <label>Name</label>
            <input
              class="border-1 w-48 rounded border-gray-100 p-1 [font-size:inherit] focus:outline-none focus-visible:ring-1"
              v-model="style.name"
            />
          </div>
          <div class="flex flex-col gap-2">
            <label>Properties</label>
            <div v-for="k in filteredAttributes" :key="k" class="flex flex-row items-center gap-2">
              <nx-button @click="deleteProperty(k)" class="!h-6 !w-6 !p-1 hover:bg-gray-100">
                <span class="i-[mdi/close] text-gray-500"></span>
              </nx-button>
              <label class="min-w-[140px]">{{ k.titleize() }}</label>
              <div
                class="flex flex-row items-center"
                v-if="styleAttributes[k].type === 'pixel' || styleAttributes[k].type === 'float'"
              >
                <input
                  class="border-1 w-16 rounded border-gray-100 p-1 text-center [font-size:inherit] focus:outline-none focus-visible:ring-1"
                  v-model="style.attributes[k]"
                  type="number"
                />
                <!-- <span>px</span> -->
              </div>
              <div v-else-if="styleAttributes[k].type === 'color'">
                <div class="border-1 rounded border border-gray-100">
                  <input class="cursor-pointer" v-model="style.attributes[k]" type="color" />
                </div>
              </div>
              <div v-else-if="styleAttributes[k].type === 'select'">
                <select
                  class="border-1 w-32 rounded border-gray-100 p-1 [font-size:inherit] focus:outline-none focus-visible:ring-1"
                  v-model="style.attributes[k]"
                >
                  <option v-for="(_, key) in styleAttributes[k].values" :key="key" :value="key">{{ key }}</option>
                </select>
              </div>
            </div>
            <div class="flex flex-row items-center gap-2">
              <div>Add property</div>
              <select
                class="border-1 w-48 rounded border-gray-100 p-1 [font-size:inherit] focus:outline-none focus-visible:ring-1"
                @input="ev => addAttribute((ev?.target as HTMLInputElement).value)"
              >
                <option value=""></option>
                <option v-for="k in availableAttributes" :key="k" :value="k">{{ k.titleize() }}</option>
              </select>
              <!-- <nx-button
                @click="addAttribute(newAttribute)"
                class="border px-3 py-2 text-sm text-black shadow-sm hover:bg-gray-100"
              >
                Add property
              </nx-button> -->
            </div>
          </div>
          <div class="flex flex-row items-center gap-2">
            <label>CSS</label>
            <textarea
              class="border-1 w-48 rounded border-gray-100 p-1 [font-size:inherit] focus:outline-none focus-visible:ring-1"
              v-model="style.css"
            ></textarea>
          </div>
        </div>
        <div class="flex flex-row items-center justify-between gap-2 bg-gray-50 px-4 py-3">
          <nx-button @click="deleteStyle" class="bg-red-600 px-3 py-2 text-sm text-white shadow-sm hover:bg-red-500">
            Delete
          </nx-button>
          <div class="flex flex-row gap-2">
            <nx-button @click="cancel" class="px-3 py-2 text-sm hover:bg-gray-200">Cancel</nx-button>
            <nx-button
              :disabled="!activeChanges"
              @click="save"
              class="py-22 bg-emerald-200 px-3 hover:bg-emerald-100 disabled:bg-gray-200"
            >
              Save
            </nx-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
import { ref, computed, watch } from 'vue'
import { TemplateLayout } from '../../builder'
import { styleAttributes } from '../../composables/theme'
interface IProps {
  template: TemplateLayout
  // style: string
}
const props = defineProps<IProps>()

const styleName = defineModel<string>()
const emit = defineEmits(['close', 'delete'])
const newAttribute = ref()

const style = computed(() => props.template?.theme?.styles?.[styleName.value])

const availableAttributes = computed(() => {
  return Object.keys(styleAttributes).filter(k => !Object.keys(style.value?.attributes ?? {}).includes(k))
})
const filteredAttributes = computed(() => {
  return Object.keys(styleAttributes).filter(k => Object.keys(style.value?.attributes ?? {}).includes(k))
})
function addAttribute(key: string) {
  if (!key || !style.value) return
  if (!style.value?.attributes) style.value.attributes = {}
  style.value.attributes[key] = styleAttributes[key].default
  newAttribute.value = null
}
const previousStyle = ref()
watch(
  () => style.value,
  () => {
    console.log('watch')
    if (!style.value || previousStyle.value) {
      return
    }
    console.log('resetting', previousStyle.value)
    previousStyle.value = JSON.parse(JSON.stringify(style.value))
  },
  { immediate: true },
)
// onMounted(() => {
//   previousStyle.value = JSON.parse(JSON.stringify(style.value))
// })
const activeChanges = computed(() => {
  return JSON.stringify(previousStyle.value) !== JSON.stringify(style.value)
})

function save() {
  previousStyle.value = null
  emit('close')
}
function cancel() {
  if (activeChanges.value) {
    const confirmCancel = confirm('Delete unsaved changes?')
    if (!confirmCancel) return
    style.value.name = previousStyle.value.name
    style.value.attributes = JSON.parse(JSON.stringify(previousStyle.value.attributes))
    style.value.css = previousStyle.value.css
    previousStyle.value = null
  }
  emit('close')
}
function deleteStyle() {
  const confirmDelete = confirm('Delete style? This action cannot be undone.')
  if (!confirmDelete) return
  emit('delete', styleName.value)
  styleName.value = undefined
}
function deleteProperty(key: string) {
  delete style.value.attributes[key]
}
</script>
