<style>
.bar-chart .row {
  align-items: center;
}
.bar-chart .key {
  flex: 2;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
  text-align: right;
}
.bar-chart .column {
  flex: 1;
  padding: 2px 5em 2px 1em;
}
.bar-chart .rect {
  position: relative;
  width: var(--value);
  height: calc(var(--size) * 1em);
  line-height: calc(var(--size) * 1em);
  margin: 1px 0 1px var(--offset);
  background: var(--colors-primary-default);
  border-radius: 2px;
}
.bar-chart .value {
  position: absolute;
  right: -4px;
  transform: translate(100%);
}
.bar-chart .column .value:nth-child(2) {
  right: 30px;
}
</style>

<template lang="pug">
.bar-chart(:style="{ '--size': (data.__.v().length > 8 ? .5 : 1) * (data.__.v().__.find(v => typeof v !== 'number' && v.__.keys().length > 1) ? 0.5 : 1) * 2  }")
  .row.legend
    .div(:class="legend" v-for="legend in (data.__.v().__.filter(d => d.__.keys().length >=2).__.v().first() || {}).__.keys()" v-if="typeof data.__.v().first() !== 'number' && data.__.v().__.filter(d => d.__.keys().length >=2).__.v().length > 1")
      .color
      .label {{ t[legend] || legend }}
  .row(v-for="v, k in data.__.filter(d => ( (typeof d === 'number' && d) || (d.__.v().unique().__.filter(x => x !=0 ).length))).__.filter()")
    .key(:class="k") {{ t[k] || k }}
    .column(v-if="!options.direction || options.direction==='column'")
      .rect(:class="k" :style="{ '--value': Math.abs(60 * v / diff) + '%', '--offset': Math.abs(60 * (Math.min(0, v) - min) / diff) + '%' }" v-if="typeof v === 'number'")
        .value {{ format(format_)(v) }}
      .rect(:class="k" :style="{ '--value': Math.abs(60 * v / diff) + '%', '--offset': Math.abs(60 * (Math.min(0, v) - min) / diff) + '%' }" v-for="v, k in v" v-else)
        .value(v-if="v") {{ format(format_)(v) }}
    .column(v-else)
        .rect(:class="k" :style="{ '--value': Math.abs(60 * v / diff) + '%', '--offset': Math.abs(60 * (Math.min(0, v) - min) / diff) + '%' }" v-if="typeof v === 'number'")
          .value {{ format(format_)(v) }}
        .rect.row(:class="k" :style="{ '--value': Math.abs(60 * v.__.v()[0] / diff) + '%', '--offset': Math.abs(60 * (Math.min(0, v.__.v()[0]) - min) / diff) + '%' }" v-else)
        .value(v-for="v, k in v") {{ format(options && options.format && (options.format[k] || options.format) || '.2%')(v) }}
</template>

<script>
export default {
  props: ['data', 'options'],
  data() {
    return { min: null, max: null, diff: null }
  },
  computed: {
    format_() {
      if (this.options && this.options.format && typeof this.options.format !== 'object') return this.options.format // format: .2%
      if (this.options && this.options.format) return this.options.format.__.v().first() // format: {fund: .2% }
      return '.2%'
    },
  },
  created() {
    this.$watch(
      'data',
      data => {
        const traverse = x =>
          (
            ({
              Object: x => x.__.v().__.map(traverse),
            })[Object.prototype.toString.call(x).slice(8, -1)] || (x => x)
          )(x)
        const list = traverse(this.data.__.filter()).flat(Infinity).__.filter()
        this.min = Math.min(0, list.min())
        this.max = list.max()
        this.diff = this.max - this.min
      },
      { immediate: true },
    )
  },
}
</script>
