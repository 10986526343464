import FirebaseDatabase from './database/firebase/FirebaseDatabase.js'
import PostgresAcrossHasura from './database/postgres/PostgresAcrossHasura.js'
import DockerFileSystem from './filesystem/docker/index.js'
import AWSFileSystem from './filesystem/aws/index.js'
import GoogleFileSystem from './filesystem/google/index.js'
import HasuraBrowser from './database/postgres/HasuraBrowser.js'

export function initCommandrSDK({
  mode,
  root,
  name,
  project,
  apiKey,
  hasuraEndpoint,
  hasuraAdminSecret,
  hasuraDataSourceName,
  env
}) {
  switch (mode) {
    case 'firebase':
      return new FirebaseDatabase({ root, name, project, apiKey })
    case 'hasura':
      return new PostgresAcrossHasura({
        hasuraEndpoint,
        hasuraAdminSecret,
        hasuraDataSourceName
      })
    case 'hasura-browser':
      if (env !== 'docker') {
        throw new TypeError(
          `For commandrSDK mode "${mode}" the env must be "docker"`
        )
      }
      return new HasuraBrowser({
        hasuraEndpoint,
        hasuraDataSourceName
      })
    default:
      throw new TypeError(
        `for commandrSDK db mode "${mode}" doesn't have a implemented value`
      )
  }
}

export function initCommandrSDKFileSystem({
  endpoint,
  token,
  mode,
  awsRegion,
  awsBucketName,
  awsCredentials,
  awsAssumeRole,
  googleConfig
}) {
  switch (mode) {
    case 'docker':
      return new DockerFileSystem({ endpoint, token })
    case 'aws':
      return new AWSFileSystem({
        awsRegion,
        awsBucketName,
        awsCredentials,
        awsAssumeRole
      })
    case 'google':
      return new GoogleFileSystem(googleConfig)
    default:
      throw new TypeError(
        `For commandrSDK file system mode "${mode}" doesn't have an implemented value.`
      )
  }
}
