<style scoped>
h1 .search-filters {
  display: flex;
  flex: 1;
  margin-left: 10px;
}
h1 .search-filters .autocomplete {
  min-width: 90% !important;
}
.autocomplete {
  position: absolute;
  right: 20px;
}

.spreadsheet {
  max-height: 30vh !important;
}

.sheet {
  flex: 1;
}

.row .label {
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
}

.row select {
  display: flex;
  flex: 2;
}

.ghost {
  margin-left: 12px !important;
  width: 24px;
  height: 24px;
}

.search {
  font-size: 16px;
  font-weight: 400;
}
:deep() .cell-status {
  max-width: 110px;
}
.status {
  display: flex;
  justify-content: flex-end;
  align-items: baseline;
}
</style>
<template lang="pug">
transition(@leave='leave')
  loader(v-if="isLoading")
h1
  span {{ t[$root.screen.path] }}
  .search-filters
    autocomplete.right(:data="autocompleteData" :options="{ placeholder: t.search }" v-model="search" )

block(title="files_information" class="expand")
  spreadsheet(:data="filteredFiles" :options="{ key: 'data_audit_files', editable: false, sort: ['-date'], columns: [{name: 'name', fix: true}, {name: 'status', fix: true},{name: 'type'},{name: 'duration'},{name: 'date'}]}")
    template(v-slot:cell-status="s")
      .row.status
        div {{s.line.status}}
        div.task(:class="{success: s.line.status === 'OK', error: s.line.status === 'NOK', running: s.line.status === 'PROCESSING'}")
    template(v-slot:cell-date="s")
      .row.date
        div {{s.line.date.toLocaleString()}}
</template>

<script>
import config from '../../../config'
import useAuth from '../../../features/auth'
import { useConfig } from '../composables/useConfig'
import { useNxPack } from '../composables/useNxPack'
import { useProgress } from '../composables/useProgress'
import { computed, ref } from 'vue'
import { useShares } from '../composables/useShares'
import { useFunds } from '../composables/useFunds'
import { useFiles } from '../composables/useFiles'
import prettyMilliseconds from 'pretty-ms'

export default {
  icon: 'pt-icon-database',
  setup() {
    const { profile } = useAuth()
    const { funds, loaded: fLoaded } = useFunds()
    const { shares, loaded: sLoaded } = useShares()
    const { config, loaded: confLoaded } = useConfig()
    const { nxpack, loaded: nxLoaded } = useNxPack()
    const { files, loaded: fiLoaded } = useFiles({ limit: 100, type: 'NXPACK', order: 'DESC' })
    const { progress } = useProgress([confLoaded, nxLoaded, sLoaded, fLoaded, fiLoaded])
    const isLoading = computed(() => progress.value !== 1)

    const search = ref(null)

    const formattedFiles = computed(() =>
      files.value.map(({ startDate, date, duration, ...f }) => {
        return {
          duration: prettyMilliseconds(duration),
          date: new Date(startDate),
          ...f,
        }
      }),
    )

    const autocompleteData = computed(() => {
      return formattedFiles.value.reduce(
        (acc, f) => {
          acc.name[f.name.split('.')[0]] = f.name
          acc.status[f.status] = f.status
          acc.type[f.type] = f.type
          return acc
        },
        { name: {}, type: {}, status: {} },
      )
    })

    const filteredFiles = computed(() => {
      if (!search.value) return formattedFiles.value
      const [key, value] = search.value.split('.')
      return formattedFiles.value.filter(f => (key === 'name' ? f.name.split('.')[0] === value : f[key] === value))
    })
    return {
      profile,
      config,
      nxpack,
      isLoading,
      shares,
      funds,
      fLoaded,
      files,
      formattedFiles,
      search,
      autocompleteData,
      filteredFiles,
    }
  },
  data() {
    return {
      fund: null,
      share: null,
      data: [],
      simple_filter: '',
      project: config.project,
    }
  },
  watch: {
    fLoaded(v) {
      if (v === true) {
        this.fund = this.$route.query.fund ? this.funds.find(f => f.slug === this.$route.query.fund) : this.funds[0]
        this.share = this.$route.query.share
          ? this.fund.shares.find(s => s.share_id === this.$route.query.share)
          : this.fund.shares[0]
      }
    },
  },
  methods: {
    handleUpdateFund() {
      update_query({ fund: this.fund.slug, share: null })
      this.share = this.fund.shares[0]
    },
  },
  computed: {
    urls() {
      if (!this.fund || !this.share || !this.config.datasets) return {}
      const userflow = `${this.fund.slug}-${this.share.share_id}`
      return this.config.datasets
        .map(
          ds =>
            eval(ds.url_fn)({
              query: {},
              params: { userflow },
            })[0],
        )
        .reduce((acc, v, k) => {
          if (!acc.find(v)) acc[k] = v
          return acc
        }, {})
    },
    url() {
      return this.urls[$root.query.dataset || 'mapping']
    },
    nxpack() {
      return this.nxpack
        .map((v, k) => ({
          dataset: k,
          filename: v['file_name'],
          integration_date: v['date_integration'],
          time_integration: v['time_integration'],
        }))
        .v()
    },
  },
}
</script>
