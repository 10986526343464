import { GraphQlService } from './GraphQlService'

/**
 * Date service
 */
export class DateService extends GraphQlService {
  /**
   * List all the positions dates
   * @param fund fund name
   * @param share share isin
   * @return {Promise<*>} Array of date string
   */
  async list({ fund, share, dataset }) {
    if (!fund || !share) throw new Error('Missing parameters')

    return (
      await this.query(
        `
        query DatesQuery($fund: String!, $share: String!, $dataset: String) {
          dates(fund: $fund, share: $share, dataset: $dataset)
        }
    `,
        { fund, share, dataset },
      )
    ).data.dates
  }
}

const dateService = new DateService()
export default dateService
