<template>
  <div
    class="border-primary bg-primary/10 !mb-0 flex h-fit w-[calc(100%+var(--page-margin)*2)] flex-col items-center justify-between border-0 border-b-2"
    style="margin: calc(-1 * var(--page-margin))"
  >
    <div v-if="headerData.error">{{ headerData.error }}</div>
    <div class="flex h-fit w-full flex-row items-center justify-between bg-transparent">
      <div class="flex flex-row items-center justify-between">
        <div class="m-2 flex h-12 w-12">
          <img :src="logoImg" alt="logo" />
        </div>
        <div class="flex flex-col">
          <div class="flex text-sm font-semibold">{{ headerData.fundName }}</div>
          <div class="flex font-bold">Reporting</div>
        </div>
      </div>
      <div class="flex flex-col items-end justify-between p-2 text-sm">
        <div class="flex">{{ translate(headerData.period) }} - {{ formatDate(headerData.endDate) }}</div>
        <div class="flex">
          {{ [headerData.shareLetter && 'Share ' + headerData.shareLetter, headerData.isinShare].filter().join(' - ') }}
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue'
// import usePagination from '@100-m/hauru/src/applications/builder/composables/pagination'
import { getHeaderData, getLogoImg } from '@100-m/hauru/src/applications/builder/lib/header'
import useTranslations from '../composables/translations'

const props = defineProps(['data', 'options', 'context'])

const { translate } = useTranslations(props)
// const { pageCount, pageNumber } = usePagination(props)

function formatDate(date) {
  return new Intl.DateTimeFormat(props.context?.variables?.lang?.slice(0, 2), {
    day: 'numeric',
    month: 'long',
    year: 'numeric',
  }).format(new Date(date))
}

const headerData = computed(() => {
  try {
    return getHeaderData(props.context)
  } catch (e) {
    return { error: e }
  }
})
const logoImg = computed(() => getLogoImg(props.context))
</script>
<script lang="ts">
export default {
  viz: 'header',
  name: 'Default header',
}
</script>
