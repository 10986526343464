<template lang="pug" v-if="!loaded">
transition(@leave='leave')
  loader(v-if="!loaded")
h1
  .row
    span {{t.data_quality_report_dashboard}}
  .search-filters
    autocomplete.right(:data="autocompletedData(dataQualityReportResults)" :options="{ placeholder: t.search }" :modelValue="autocompletedModel($root.filters)" @update:modelValue="autocompleteUpdateModel($root.$router, $root.filters, $event)")
.row
  subtitle(style="font-style: italic; margin-top: -10px;") {{t.data_quality_report_dashboard_subtitle}}
.row.stretch
  .boutton(@click="kpiFilters('OK')")
    kpi(:data="[{title: t.successful_tests, value:  filtered_reports.filter(e=>e.status === 'OK').length}]" :class="{ active: ($root.query.status || '').includes('OK') }")
  .boutton(@click="kpiFilters(errorStatus)")
    kpi(:data="[{title: t.failed_tests, value:  filtered_reports.filter(e=>errorStatus.includes(e.status)).length}]" :class="{ active: ($root.query.status || '').includes(errorStatus.join('|')) }")
  .boutton(@click="kpiFilters('not_executed')")
    kpi(:data="[{title: t.not_executed_tests, value: filtered_reports.filter(e=>e.status === 'not_executed').length}]" :class="{ active: ($root.query.status || '').includes('not_executed') }")

.drawer.column.expand(v-if="shouldShowDrawer()")
  .row.left.center
    .dqc-report-status.large(:class="{[getNormalizedFrontEndStatus(drawerData.status)]:true}")
    h1 {{ (t.details || 'details').titleize() }}
    .button.closeButton(@click="closeDetails()") {{ t.close_drawer }}
  .column.dqc-details
    .row(v-for="key in ['domain', 'isin', 'language', 'jurisdiction', 'fund_id', 'format']")
      div {{ key.titleize() + ':'}}
      div {{ drawerData[key] }}
  .block.details-check-table(v-if="drawerData.status !== 'not_executed'")
    .row.header-check
      .detail-check-status {{ t.status }}
      .detail-check-rule  {{ t.rule }}
      .detail-check-selector  {{ t.selector }}
    .column(v-for="check in drawerData.trace")
      .row
        .detail-check-status
          .status.small(:class="{[getNormalizedFrontEndStatus(check.status)]:true}")
        .detail-check-rule {{ check.ruleName }}
        .detail-check-selector {{ check.path }}
      .row(v-if=' errorStatus.includes(check.status)')
        .column.detail-check-trace
          .header-check {{  t.error_details|| "Error Details" }} :
          .trace-text {{ check.trace }}
  .block.details-check-table(v-if="drawerData.status === 'not_executed'")
    div {{ $root.t['not_execute_yet'] }}

.block.expand
  .control-bar
    .column.left
      label {{ t.domain }} 
      select(v-model="selectedDomain" @change="updateSelectedExecutionContexts")
        option(v-for="(option, index) in executionContexts" :key="index" :value="option") {{ option }}
    .column.left(v-if="selected.length")
      div {{ t.execution || "execution" }}
      button.center.tiny.ghost(tt="rerun" @click.stop="rerunSelected()")
        svg-icon(class="icon" style="fill: var(--cat10);" name="pt-icon-play")

  spreadsheet.stripped.expand.nosort(:data="filtered_reports" :options="{ columns: [...fixedColumns, ...readeableColumns], editable: false }")
    template(v-slot:header-check)
      input(type="checkbox" :checked="selected.length === dataQualityReportResults.length" v-indeterminate="selected.length > 0 && selected.length !== dataQualityReportResults.length" @click="selected = selected.length === dataQualityReportResults.length ? [] : dataQualityReportResults.map('id')" @mousedown.prevent.stop="")
    template(v-slot:cell-details="s")
      div(style="overflow: unset; ")
        .details(@click="openDetails(s.line.id)") {{ (t.details || 'details').titleize() }}
          .details-hover {{ t.open_details }}
    template(v-slot:cell-check="{ column, line }")
      input(type="checkbox" :checked="selected.includes(line.id)" @click.stop="selected = selected.map().toggle(line.id)" @mousedown.prevent.stop="")
    template(v-slot:cell-domain="{ column, line }")
      div {{ line.domain }}
    template(v-slot:cell-fund-id="{ column, line }")
      div {{ line.fundId }}
    template(v-slot:cell-isin-share="{ column, line }")
      div {{ line.isin }}
    template(v-slot:cell-template="{ column, line }")
      div {{ line.template }}
    template(v-slot:cell-dqc-report="{ column, line }")
      div {{ line.dqcReport }}
    template(v-slot:cell-lang="{ column, line }")
      .tt(style="display: flex;" :tt="line.language.toUpperCase()")
        svg-icon(class="icon" :name="'flag-' + line.language.toLowerCase().slice(0, 2)")
    template(v-slot:cell-jurisdiction="{ column, line }")
      .tt(style="display: flex;" :tt="line.language.toUpperCase()")
        svg-icon(class="icon" :name="'flag-' + line.language.toLowerCase().slice(-2)")
    template(v-slot:cell-format="{ column, line }")
      .tt(style="display: flex;" :tt="line.format.upper()")
        ui-asset.file_icon(:name="'icon_' + line.format.lower()")
    template(v-slot:cell-last-execution="{ column, line }")
      div {{ line.lastExecution }}
    template(v-slot:cell-status="{ column, line }")
      div(style="overflow: visible; position: relative;")
        .dqc-report-status(:class="{[getNormalizedFrontEndStatus(line.status)]:true}")
    template(v-slot:cell-actions="{ column, line }")
      .row
        button.ghost(tt="Edit" @click="edit(line)")
          svg-icon(name="pt-icon-edit")
        button.ghost(tt="t.duplicate" @click="duplicate(line)")
          svg-icon(name="ic_file_copy")
        button.ghost(tt="Delete" @click="confirmRemoveId=line.id" v-if="line.dataQualityCheckReports?.length === 0")
          svg-icon(name="pt-icon-trash")
  .row
    button.primary(@click="this.manageReports()") {{ t.manage_data_quality_reports }}
    .total(style="margin-left: auto;") {{ 'Total : ' + filtered_reports.length }}
</template>

<script>
import { ref, computed, onMounted, watch } from 'vue'
import { useDataQualityRule } from '../composables/useDataQualityRule'
import { usePresses } from '../composables/usePresses'
import dataQualityReportService from '../../../services/DataQualityReportService'
import { useTemplates } from '../composables/useTemplates'
import { useShares } from '../composables/useShares'
import { useRoute, useRouter } from 'vue-router'

export default {
  icon: 'playlist_add_check',
  setup() {
    const route = useRoute()
    const router = useRouter()
    const { rules, loaded } = useDataQualityRule()
    const { presses, loaded: prLoaded, initialized } = usePresses()
    const { templates, loaded: tplLoaded } = useTemplates()
    const { shares, loaded: sharesLoaded } = useShares()
    const getLastSixMonths = () => {
      const result = []
      const startDate = new Date()
      for (let i = 0; i < 13; i++) {
        let month = startDate.getMonth() - i
        let year = startDate.getFullYear()

        if (month < 0) {
          month += 12
          year--
        }

        const formattedMonth = month + 1 < 10 ? `0${month + 1}` : month + 1
        result.push(`${year}-${formattedMonth}`)
      }

      return result
    }
    const errorStatus = ['KO', 'ERROR'] || []
    const fixedColumns = [
      { name: 'check', freeze: true },
      { name: 'Details', freeze: true },
    ]
    const columns = [
      'status',
      'domain',
      'fundId',
      'isinShare',
      'template',
      'dqcReport',
      'language',
      'jurisdiction',
      'format',
      'lastExecution',
    ]
    const readeableColumns = [
      'Status',
      'Domain',
      'Fund Id',
      'isin Share',
      'Template',
      'DQC Report',
      'Lang',
      'Jurisdiction',
      'Format',
      'Last Execution',
    ]
    const executionContexts = ref(getLastSixMonths())
    const selectedExecutionContexts = ref([executionContexts.value[0]]) // Set default to current month
    const selectedDomain = ref(executionContexts.value[0])
    const dropdownOpen = ref(false)
    const selected = ref([])
    const results = ref([])
    const dataQualityReportList = ref([]) // Reactive reference for fetched data
    const updatedDataQualityResultIds = ref([])
    const rerunningIds = ref([]) // New state to track rerunning rows
    const drawerData = ref(null)

    const allLoaded = computed(() => loaded.value && prLoaded.value && initialized.value && tplLoaded.value)

    const toggleDropdown = () => {
      dropdownOpen.value = !dropdownOpen.value
    }

    const selectOption = option => {
      if (!selectedExecutionContexts.value.includes(option)) {
        selectedExecutionContexts.value = [...selectedExecutionContexts.value, option]
        executionContexts.value = executionContexts.value.filter(item => item !== option)
        dropdownOpen.value = false // Close dropdown after selection
      }
    }

    const removeSelected = option => {
      selectedExecutionContexts.value = selectedExecutionContexts.value.filter(item => item !== option)
      executionContexts.value = [...executionContexts.value, option]
    }

    const updateSelectedExecutionContexts = () => {
      if (selectedDomain.value) {
        selectedExecutionContexts.value = [selectedDomain.value]
      }
    }

    const filteredExecutionContexts = computed(() => {
      return executionContexts.value.filter(item => !selectedExecutionContexts.value.includes(item))
    })

    const fetchResults = async () => {
      if (!selectedExecutionContexts.value.length || !presses.value.length) return
      const pressIds = presses.value.map(press => press.id)
      const domains = selectedExecutionContexts.value
      results.value = await dataQualityReportService.getResultsByPressIdsAndDomains(pressIds, domains)
    }

    const rerunSelected = async () => {
      const selectedDataQualityResult = dataQualityReportResults.value.filter(press =>
        selected.value.includes(press.id),
      )

      console.log('Rerunning selected', selectedDataQualityResult)

      // Set rerunning state
      rerunningIds.value = selectedDataQualityResult.map(p => p.id)

      // Temporarily update the status and lastExecution for the selected rows
      selectedDataQualityResult.forEach(press => {
        const result = results.value.find(r => r.pressId === press.id && r.domain === press.domain)
        if (result) {
          result.status = 'running'
          result.lastExecution = 'Running'
        }
      })

      // this function should be improved
      const getEndDate = domain => {
        const [year, month] = domain.split('-').map(Number)
        const lastDay = new Date(Date.UTC(year, month, 0)).toISOString().split('T')[0]
        return lastDay
      }
      // this function should be improved
      const mergeVariables = dataQualityResult => {
        const ret = { ...dataQualityResult.dqcReportVariables, ...dataQualityResult }

        ret.lang = dataQualityResult.language
        ret.shareId = ret.isinShare
        ret.endDate = getEndDate(ret.domain)
        return ret
      }

      await Promise.all(
        selectedDataQualityResult.map(dqResult =>
          dataQualityReportService.run(dqResult.dqcReportId, {
            ...mergeVariables(dqResult),
            domain: dqResult.domain,
            pressId: dqResult.pressId,
          }),
        ),
      )

      const updatedResults = await dataQualityReportService.getResultsByPressIdsAndDomains(
        selectedDataQualityResult.map(p => p.id),
        selectedExecutionContexts.value,
      )
      updatedResults.forEach(result => {
        const index = results.value.findIndex(r => r.pressId === result.pressId && r.domain === result.domain)
        if (index !== -1) {
          results.value[index] = result
        } else {
          results.value.push(result)
        }
      })
      updatedDataQualityResultIds.value = selectedDataQualityResult.map(p => p.id)
      rerunningIds.value = [] // Clear rerunning state
    }

    const fetchDataQualityReportList = async () => {
      dataQualityReportList.value = await dataQualityReportService.all()
    }

    const updateDrawerData = () => {
      const idDQR = route.query.idDQR
      if (idDQR) {
        drawerData.value = dataQualityReportResults.value.find(e => '' + e.id === idDQR)
      } else {
        drawerData.value = null
      }
    }

    const autocompletedData = data => {
      return data.reduce((acc, v) => {
        const keys = [...fixedColumns, ...columns]
        keys
          .filter(k => k && typeof v[k] === 'string')
          .map(k => {
            acc[k] = acc[k] || {}
            acc[k][v[k]] = k === 'schedule' ? cron2def[v[k]] : v[k]
          })

        return acc
      }, {})
    }

    const autocompletedModel = filters => {
      return filters
        .filter((v, k) => k !== 'domain' && k !== 'year')
        .reduce((acc, v, k) => {
          if (v) {
            v.map(val => acc.push([k, val].join('.')))
          }
          return acc
        }, [])
    }

    const autocompleteUpdateModel = (router, filters, event) => {
      return router.push({
        query: Object.assign(
          $root.query.filter((v, k) => !filters.keys().toggle('domain').includes(k)),
          event
            .map(v => v.split('.'))
            .group('0')
            .map(g => g.map('1').join('|')),
        ),
      })
    }

    onMounted(async () => {
      await fetchDataQualityReportList() // Fetch data report list before other operations

      // Wait for all data to be loaded before fetching results
      const loadInterval = setInterval(async () => {
        if (allLoaded.value) {
          clearInterval(loadInterval)
          await fetchResults() // Initial fetch
          updateDrawerData()
        }
      }, 100)
    })

    watch(selectedExecutionContexts, fetchResults, { deep: true }) // Re-fetch when selectedExecutionContexts changes
    watch(route, updateDrawerData)

    const dataQualityReportResults = computed(() => {
      const resultData = results.value
      const ret = []
      for (const domain of selectedExecutionContexts.value) {
        ret.push(
          ...(presses.value
            ?.filter(e => !e.disabled)
            .map(press => {
              const dataQualityCheckReportId = templates.value[press.template]?.dataQualityCheckReportId
              const dataQualityReport = dataQualityReportList.value.find(dr => dr.id === dataQualityCheckReportId)
              const dataQualityReportName = dataQualityReport?.name
              const result = resultData?.find(r => r.pressId === press.id && r.domain === domain)
              const share = shares.value.find(s => s.share_id === press.isin)

              let localCreatedAt = '-'
              if (result?.createdAt) {
                const utcDate = new Date(result.createdAt)
                localCreatedAt = utcDate.toLocaleString('sv-SE') // Convert to local date string
              }

              const isRerunning = rerunningIds.value.includes(press.id)

              return {
                ...press,
                fundId: press.fund_id,
                isinShare: press.isin,
                template: press.template,
                dqcReport: dataQualityReportName || '-',
                dqcReportId: dataQualityCheckReportId,
                dqcReportVariables: dataQualityReport?.dataReport?.variables,
                language: press.language,
                jurisdiction: press.language?.slice(-2),
                format: press.format,
                status: isRerunning ? 'running' : result?.status || 'not_executed',
                lastExecution: isRerunning ? 'Running' : localCreatedAt, // Use local date string or "Running"
                domain,
                trace: result?.results ? JSON.parse(result?.results) : null,
                pressId: press.id,
                benchmarkId: share?.benchmark_id, // this should be improved: we import shares just for that
              }
            }) || []),
        )
      }
      const filteredRet = ret.filter(e => e.dqcReportId)
      // Update only the rows that have been updated
      updatedDataQualityResultIds.value.forEach(id => {
        const index = ret.findIndex(r => r.id === id)
        if (index !== -1) {
          const resultIndex = results.value.findIndex(r => r.pressId === id)
          if (resultIndex !== -1) {
            const result = results.value[resultIndex]
            ret[index].status = result.status
            ret[index].lastExecution = new Date(result.createdAt).toLocaleString('sv-SE')
          }
        }
      })
      return filteredRet
    })

    const filtered_reports = computed(() => {
      const authorized_filter = columns
      const filters = Object.entries($root.query)
        .filter(([k, v]) => !['domain', 'search', 'selected', 'id'].includes(k))
        .filter(d => authorized_filter.includes(d[0]))
        .map(([k, v]) => [k, v.split('|')])

      return dataQualityReportResults.value
        .filter(d => !d.disabled)
        .filter(d =>
          filters.every(([k, vs]) =>
            vs.some(
              v =>
                d[k] === v ||
                (v.slice(0, 1) === '>' && d[k] > v.slice(1)) ||
                (v.slice(0, 1) === '<' && d[k] < v.slice(1)),
            ),
          ),
        )
    })

    const kpiFilters = kpi => {
      const newStatus = kpi.join('|')

      if (newStatus !== route.query.status) {
        router.replace({ query: { ...route.query, status: newStatus } })
      } else {
        const { status, ...updatedQuery } = route.query
        router.replace({ query: updatedQuery })
      }
    }

    const shouldShowDrawer = () => {
      return drawerData.value !== null
    }

    const openDetails = id => {
      router.replace({ query: { ...route.query, idDQR: id } })
    }

    const closeDetails = () => {
      const { idDQR, ...updatedQuery } = route.query
      router.replace({ query: updatedQuery })
      drawerData.value = null
    }

    return {
      errorStatus,
      fixedColumns,
      columns,
      dataQualityReportResults,
      rules,
      presses,
      loaded: allLoaded,
      executionContexts,
      selectedExecutionContexts,
      selectedDomain,
      dropdownOpen,
      toggleDropdown,
      selectOption,
      removeSelected,
      filteredExecutionContexts,
      updateSelectedExecutionContexts,
      selected,
      rerunSelected,
      results,
      dataQualityReportList,
      updatedDataQualityResultIds,
      rerunningIds,
      shares,
      shouldShowDrawer,
      openDetails,
      closeDetails,
      updateDrawerData,
      drawerData,
      filtered_reports,
      kpiFilters,
      autocompletedData,
      autocompletedModel,
      autocompleteUpdateModel,
      readeableColumns,
    }
  },
  methods: {
    getNormalizedFrontEndStatus(status) {
      switch (status) {
        case 'OK':
          return 'OK'
        case 'KO':
          return 'KO'
        case 'ERROR':
          return 'KO'
        case 'running':
          return 'running'
        case 'not_executed':
          return 'not_executed'
        default:
          return 'KO'
      }
    },
    manageReports() {
      this.$router.push({ path: $root.appath + 'data-quality-reports', query: { new: true } })
    },
  },
}
</script>
<style scoped>
.multiselect-container {
  display: flex;
  align-items: flex-start;
}

.block .spr.spreadsheet-wrapper {
  max-height: 55vh;
}

.selected-options {
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
  margin-left: 10px;
}

.selected-option {
  background-color: #d3d3d3;
  border-radius: 3px;
  padding: 5px;
  display: flex;
  align-items: center;
}

.selected-option button {
  margin-left: 5px;
  border: none;
  background: none;
  cursor: pointer;
  color: red;
  font-size: 12px;
}

.dropdown {
  position: relative;
}

.dropdown button.small {
  padding: 3px 6px;
  cursor: pointer;
}

.dropdown button:disabled {
  cursor: not-allowed;
  background-color: #ccc;
}

.kpi {
  flex: 1;
}

:deep() .kpi:is(.active, :hover) {
  cursor: pointer;
  border-color: var(--highlight);
}

.kpi.active,
.kpi:hover {
  cursor: pointer;
  border-color: var(--highlight);
}

.options {
  position: absolute;
  width: 100%;
  border: 1px solid #ccc;
  background-color: #fff;
  max-height: 150px;
  overflow-y: auto;
  z-index: 10;
}

.option {
  padding: 5px;
  cursor: pointer;
}

.dqc-report-status {
  border-radius: 50%;
  width: 10px;
  height: 10px;
  display: inline-block;
}

.dqc-report-status.not_executed,
.status.not_executed {
  background-color: grey;
}

.dqc-report-status.OK,
.status.OK {
  background-color: green;
}

.dqc-report-status.KO,
.status.KO {
  background-color: red;
}

.dqc-report-status.large {
  width: 20px;
  height: 20px;
  margin-right: 10px;
}

.cell-status > div {
  cursor: pointer;
  position: relative;
  padding: 0;
  min-width: 59px;
  line-height: 28px;
  overflow: visible;
  background: inherit;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Centering icons in lang and jurisdiction cells */
.tt {
  display: flex;
  justify-content: center;
  align-items: center;
}

svg-icon {
  display: inline-block;
}

.control-bar {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 10px;
  padding-left: 15px;
}

.control-bar > select {
  max-width: 120px;
}

button.tiny {
  width: 25px;
  padding: 1px;
  font-size: 10px;
}

.row.header-check {
  border-bottom: 1px solid var(--inactive);
}

button.tiny svg-icon {
  display: flex;
  justify-content: center;
  align-items: center;
}

.spreadsheet.stripped.expand.nosort {
  display: flex;
  flex-direction: column;
}

.cell-details .resizer {
  display: none;
}

.cell-details {
  width: 60px;
}

.cell-details > div {
  cursor: pointer;
  position: relative;
  padding: 0;
  min-width: 59px;
  line-height: 28px;
  overflow: visible;
  background: none;
}

.cell-details > div > .details {
  width: 100%;
  color: var(--primary);
  text-align: center;
  text-decoration: underline;
}

.drawer {
  z-index: 1;
  position: absolute;
  top: 0px;
  right: 0px;
  background: white;
  min-height: 100vh;
  width: 800px;
  height: 200px;
  font: var(--p2);
  padding: 16px;
  flex-direction: column;
  border-radius: var(--border-radius);
  box-shadow: var(--box-shadow);
}

.column.dqc-details .row {
  flex: 1 1 30%;
  box-sizing: border-box;
  margin: 0px;
  padding: 0px;
  max-height: 20px;
  display: flex;
  align-items: center;
}

.drawer .block {
  box-shadow: unset;
}

.status {
  background: green;
  width: 16px;
  height: 16px;
  border-radius: 99px;
  margin-left: 5px;
  margin-right: 10px;
}

.status.small {
  width: 10px;
  height: 10px;
}

.drawer h2 {
  margin-top: 10px;
}

.drawer .block {
  width: 100%;
}

.drawer .block pre {
  height: 400px;
}

.header-check {
  font-weight: 800;
}

.detail-check-status {
  width: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.detail-check-rule {
  width: 200px;
  margin-left: 10px;
}

.detail-check-selector {
  width: 100px;
  margin-left: 10px;
}

.details-check-table .column {
  padding-top: 4px;
}

.detail-check-trace {
  margin-left: 60px;
}

.trace-text {
  color: red;
}

.error {
  background: red;
}

.dqc-report-status.running {
  background-color: #7fa0f4;
}

.dqc-details {
  margin-top: 16px;
  max-height: 40px;
  width: 100%;
  columns: 3;
  margin: 25px !important;
}

.dqc-details .row div:nth-child(1) {
  font-weight: 800;
}
.dqc-details .row div:nth-child(2) {
  margin-left: 4px;
}

.block.details-check-table {
  overflow-y: auto;
}

.closeButton {
  position: absolute;
  top: 10;
  right: 10px;
}

h1 .search-filters {
  display: flex;
  flex: 1;
  margin-left: 10px;
}
h1 .search-filters .autocomplete {
  min-width: 90% !important;
}

.details-hover {
  position: absolute;
  top: -75%;
  transform: translateX(40%);
  display: none;
  background-color: black;
  color: white;
  padding: 5px;
  border-radius: 5px;
  white-space: nowrap;
}

.details:hover .details-hover {
  display: block;
}
</style>
