import { reactive, ref } from 'vue'

async function defaultLogin(username, password) {
  const profile = { username, email: username, role: 'user' }
  localStorage.profile = JSON.stringify(profile)
  return profile
}
async function defaultLogout() {
  localStorage.profile = null
  location.href = '/'
}
async function defaultInit() {
  return JSON.parse(localStorage.profile || null)
}
function isAuthenticated(profile) {
  return !!profile
}
const profileRef = ref(null)
let _login, _logout
export default function useAuth() {
  const guard = (to, from, next) => {
    const profile = profileRef.value
    const authenticated = isAuthenticated(profile)
    const role = authenticated ? profile.role : 'anonymous'
    const authorized = to.meta.roles.includes(role) || (role === 'admin' && !to.path.startsWith('/login'))
    const path = to.fullPath
    if (!authenticated && !authorized) return next(`/login?redirect=${path}`)
    if (authenticated && !authorized) return next('/')
    const userRoleAuthorizedScreens = $root.$config.apps[to.meta.app]?.userRoleAuthorizedScreens || []
    if (role !== 'admin' && userRoleAuthorizedScreens?.length && !userRoleAuthorizedScreens.includes(to.meta?.name))
      return next('/' + to.meta.app + '/' + userRoleAuthorizedScreens[0])
    return next()
  }
  return {
    authPlugin(app, { login, onRequest, logout, initAuth }) {
      const global = app.config.globalProperties
      _login = (...args) =>
        (login || defaultLogin)(...args).then(profile => {
          profileRef.value = profile
          global.$router.push('/')
        })
      _logout = () =>
        (logout || defaultLogout)().then(() => {
          profileRef.value = null
          global.$router.push('/login')
        })
      // TODO not be dependant on axios being setup ?
      if (global.$axios) {
        if (onRequest) global.$axios.interceptors.request.use(onRequest)
        global.$axios.interceptors.response.use(
          res => {
            try {
              if (profileRef.value.exp < Math.floor(Date.now() / 1000)) _logout()
              // if (res.data.errors.some(error => error.extensions.code === 'FORBIDDEN')) _logout()
            } catch (e) {
              console.log('error in axios response', e)
            }
            return res
          },
          err => {
            const authenticated = isAuthenticated(profileRef.value)
            if (authenticated && err.response && err.response.status === 401) {
              _logout()
            }
          },
        )
      }
      const initAuthFn = async () => {
        profileRef.value = await (initAuth || defaultInit)()
      }
      app.provide('initAuth', initAuthFn)
      app.provide('getProfile', () => profileRef.value)
      global.$auth = reactive({
        profile: profileRef,
        login: _login,
        logout: _logout,
        initAuth: initAuthFn,
      })
    },
    guard,
    profile: profileRef,
    login: _login,
    logout: _logout,
  }
}
