<style scoped>
.tab > div {
  overflow: hidden;
  white-space: nowrap;
  max-width: 100%;
  text-overflow: ellipsis;
}
.hide {
  display: none !important;
}
.tab:hover {
  width: auto;
  min-width: 14vw;
}
.nav-top-tabs {
  max-width: 62%;
}
</style>
<template lang="pug">
.nav-top-tabs.tabs
  .tab(
    role="tab"
    v-for="tab, index in ['fund', $root.tab_benchmark?.label, 'diff'].filter(x=>!!x)"
    v-if="!$root.config.tabs && $root.tab_userflow_name && $root.$route.fullPath.split('/')?.[2]?.split('-')?.[0] === $root?.tab_userflow_name"
    :class="{ active: is_tab_active(index, tab), [tab]: true, hide: !(tab =='fund' || ($root.tab_benchmark?.id && !$root.bench_weight_nul)) }"
    @click='update_query({ metric: index !== 0 && tab })'
  )
    div 
      | {{ t[getTabTitle(tab)] || String(getTabTitle(tab)).titleize() }}
    div {{ tab }}
  
  .tab(
    role="tab"
    :class='{ active: (index === 0 && !$root.query.tab) || $root.query.tab === tab }',
    @click='update_query({ tab: index === 0 ? null : tab })',
    v-for='tab, index in $root.config.tabs',
    v-if='$root.config.tabs'
  ) {{ t[tab] || tab.titleize() }}
</template>

<script>
export default {
  computed: {
    rootWatcher() {
      // $root is a proxy and vue.js don't detect change on the proxy, so we force to check target of the proxy
      return Object.assign({}, $root)
    },
  },
  watch: {
    async rootWatcher(newRoot, oldRoot) {
      this.$forceUpdate()
    },
  },
  methods: {
    getTabTitle(tab) {
      switch (tab) {
        case 'fund':
          return $root.tab_userflow_name
        case 'benchmark':
        case 'benchmark2':
        case 'benchmark3':
          return $root.tab_benchmark?.id
        case 'diff':
          return 'compare'
      }
    },
    is_tab_active(index, tab) {
      return (
        (index === 0 && !$root.query.metric) ||
        ($root.query.metric !== 'fund' && $root.tab_benchmark?.id && $root.bench_weight_nul) ||
        $root.query.metric === tab
      )
    },
  },
}
</script>
