import { GraphQlService } from './GraphQlService'

export class DataQualityRuleService extends GraphQlService {
  async all() {
    return (
      await this.query(
        `
        query getAll {
          dataQualityRules {
            getDataQualityRules {
              id
              name
              dqcJavaScriptFunction
              createdAt
              updatedAt
              dataQualityCheckReports {
                id
                name
              }
            }
          }
        }
        `,
      )
    ).data.dataQualityRules.getDataQualityRules
  }

  async get(id) {
    return (
      await this.query(
        `
      query getAll($id: Int!) {
        dataQualityRules {
          getDataQualityRule(id: $id) {
            id
            name
            dqcJavaScriptFunction
          }
        }
      }
       `,
        {
          id: +id,
        },
      )
    ).data.dataQualityRules.getDataQualityRule
  }
  async update(id, name, dqcJavaScriptFunction) {
    return await this.query(
      `mutation CreateDataQualityRule($id: Int!, $name: String, $dqcJavaScriptFunction: String) {
  updateDataQualityRule(id: $id, name: $name,     dqcJavaScriptFunction: $dqcJavaScriptFunction) {
    id
  }
}
`,
      { id: +id, name, dqcJavaScriptFunction },
    )
  }
  async deleteDataQualityRule(id) {
    return (
      await this.query(
        `
        mutation DeleteDataQualityRule($deleteDataQualityRuleId: Int!) {
          deleteDataQualityRule(id: $deleteDataQualityRuleId)
        }
        `,
        {
          deleteDataQualityRuleId: id,
        },
      )
    ).data.deleteDataQualityRule
  }

  async createDataQualityRule(name, dqcJavaScriptFunction) {
    return (
      await this.query(
        `
        mutation CreateDataQualityRule($name: String!, $dqcJavaScriptFunction: String!) {
          createDataQualityRule(name: $name, dqcJavaScriptFunction: $dqcJavaScriptFunction) {
            name
            id
          }
        }
        `,
        {
          name,
          dqcJavaScriptFunction,
        },
      )
    ).data.createDataQualityRule
  }
}

const dataQualityRuleService = new DataQualityRuleService()
export default dataQualityRuleService
