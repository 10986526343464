import { commandrSDK, update } from '../../../features/commandr'
import { onMounted, ref } from 'vue'

export function useRuns() {
  const loaded = ref(false)
  const deleteRun = async id => {
    return await update(`data.runs.${id}`, { disabled: true })
  }

  const runs = ref({})

  const updateRuns = value => {
    runs.value = value
    loaded.value = true
  }
  onMounted(async () => {
    loaded.value = false
    commandrSDK.get(`/data/runs/`, updateRuns)
  })
  return {
    deleteRun,
    runs,
    loaded,
  }
}
