const defaultProps = {}

export const outputStories = {
  multiColumn: {
    columns: [
      { field: 'date', name: 'Date' },
      {
        field: 'benchmark',
        children: [{ field: 'carbon-footprint' }, { field: 'carbon-intensity' }],
      },
      {
        field: 'fund',
        children: [{ field: 'carbon-footprint' }, { field: 'carbon-intensity' }],
      },
    ],
    data: [
      {
        date: '2024-03',
        benchmark: {
          'carbon-footprint': '-0.1',
          'carbon-intensity': '0.2',
        },
        fund: {
          'carbon-footprint': '0.1',
          'carbon-intensity': '-0.2',
        },
      },
      {
        date: '2024-02',
        benchmark: {
          'carbon-footprint': '-0.1',
          'carbon-intensity': '0.2',
        },
        fund: {
          'carbon-footprint': '0.1',
          'carbon-intensity': '-0.2',
        },
      },
    ],
  },
  // Custom label name
  // Compact format
  'Multi-row (compact)': {
    columns: [
      { field: 'label', name: ' ' },
      {
        field: 'date',
        name: 'Date',
        children: [
          {
            field: '2024-02',
          },
          { field: '2024-03' },
        ],
      },
    ],
    data: [
      {
        _level: 0,
        label: 'benchmark',
        date: {
          '2024-02': '-0.1',
          '2024-03': '0.2',
        },
      },
      {
        _level: 1,
        label: 'carbon-footprint',
        date: {
          '2024-02': '-0.1',
          '2024-03': '-0.1',
        },
      },
      {
        _level: 1,
        label: 'carbon-intensity',
        date: {
          '2024-02': '0.2',
          '2024-03': '0.2',
        },
      },
      {
        _level: 0,
        label: 'fund',
        date: {
          '2024-02': '0.1',
          '2024-03': '-0.2',
        },
      },
      {
        _level: 1,
        label: 'carbon-footprint',
        date: {
          '2024-02': '0.1',
          '2024-03': '0.1',
        },
      },
      {
        _level: 1,
        label: 'carbon-intensity',
        date: {
          '2024-02': '-0.2',
          '2024-03': '-0.2',
        },
      },
    ],
  },
  'Multi-row (outline)': {
    columns: [
      { field: 'metric' },
      { field: 'indicator' },
      { field: 'date', children: [{ field: '2024-02' }, { field: '2024-03' }] },
    ],
    data: [
      {
        _level: 0,
        metric: 'benchmark',
        date: {
          '2024-02': '-0.1',
          '2024-03': '0.2',
        },
      },
      {
        _level: 1,
        indicator: 'carbon-footprint',
        date: {
          '2024-02': '-0.1',
          '2024-03': '-0.1',
        },
      },
      {
        _level: 1,
        indicator: 'carbon-intensity',
        date: {
          '2024-02': '0.2',
          '2024-03': '0.2',
        },
      },
      {
        _level: 0,
        metric: 'fund',
        date: {
          '2024-02': '0.1',
          '2024-03': '-0.2',
        },
      },
      {
        _level: 1,
        indicator: 'carbon-footprint',
        date: {
          '2024-02': '0.1',
          '2024-03': '0.1',
        },
      },
      {
        _level: 1,
        indicator: 'carbon-intensity',
        date: {
          '2024-02': '-0.2',
          '2024-03': '-0.2',
        },
      },
    ],
  },
  'Multi-row (tabular)': {
    columns: [
      { field: 'metric' },
      { field: 'indicator' },
      { field: 'date', children: [{ field: '2024-02' }, { field: '2024-03' }] },
    ],
    data: [
      {
        metric: 'benchmark',
        indicator: 'carbon-footprint',
        date: {
          '2024-02': '-0.1',
          '2024-03': '-0.1',
        },
      },
      {
        indicator: 'carbon-intensity',
        date: {
          '2024-02': '0.2',
          '2024-03': '0.2',
        },
      },
      {
        _level: 0,
        metric: 'Benchmark total',
        date: {
          '2024-02': '-0.1',
          '2024-03': '0.2',
        },
      },
      {
        metric: 'Fund',
        indicator: 'carbon-footprint',
        date: {
          '2024-02': '0.1',
          '2024-03': '0.1',
        },
      },
      {
        indicator: 'carbon-intensity',
        date: {
          '2024-02': '-0.2',
          '2024-03': '-0.2',
        },
      },
      {
        _level: 0,
        metric: 'Fund total',
        date: {
          '2024-02': '0.1',
          '2024-03': '-0.2',
        },
      },
    ],
  },
  // Stripe by level 0
  // Hide level 0
  lgiStripes: {
    columns: ['name', '1y', '3y', '5y', 'inception'],
    data: [
      {
        _striped: false,
        name: 'USD Class A Accumulation',
        '1y': -0.006,
        '3y': -0.05,
        '5y': -0.055,
        inception: -0.066,
      },
      {
        _striped: false,
        name: 'USD Class A Accumulation^',
        '1y': 0.012,
        '3y': -0.041,
        '5y': 0.018,
        inception: 0.008,
      },
      {
        _striped: false,
        name: 'Benchmark',
        '1y': -0.006,
        '3y': -0.05,
        '5y': -0.056,
        inception: -0.066,
      },
      {
        _striped: true,
        name: 'USD Class A Distribution',
        '1y': -0.006,
        '3y': -0.05,
        '5y': -0.056,
        inception: -0.066,
      },
      {
        _striped: true,
        name: 'USD Class A Distribution^',
        '1y': 0.012,
        '3y': -0.041,
        '5y': 0.018,
        inception: 0.008,
      },
      {
        _striped: true,
        name: 'Benchmark',
        '1y': -0.006,
        '3y': -0.05,
        '5y': -0.056,
        inception: -0.066,
      },
      {
        _striped: false,
        name: 'SGD-Hedged Class A Accumulation',
        '1y': -0.019,
        '3y': -0.056,
        '5y': -0.068,
        inception: -0.072,
      },
      {
        _striped: false,
        name: 'SGD-Hedged Class A Accumulation^',
        '1y': -0.007,
        '3y': -0.048,
        '5y': 0.007,
        inception: -0.007,
      },
      {
        _striped: false,
        name: 'Benchmark',
        '1y': -0.007,
        '3y': -0.048,
        '5y': -0.007,
        inception: -0.048,
      },
    ],
  },
  'Drilldown (outline)': {
    columns: ['country', 'sector', 'isin', 'expoBrute', 'expoNet'],
    data: [
      { _level: 0, country: 'FR', expoBrute: 0.43, expoNet: 0.24 },
      { _level: 1, sector: 'Distribution', expoBrute: 0.01, expoNet: 0.03 },
      { _level: 2, isin: 'FR000009', expoBrute: 0.01, expoNet: 0.03 },
      { _level: 1, sector: 'Industry', expoBrute: 0.42, expoNet: 0.21 },
      { _level: 2, isin: 'FR000007', expoBrute: 0.02, expoNet: 0.2 },
      { _level: 2, isin: 'FR000008', expoBrute: 0.4, expoNet: 0.01 },
      { _level: 0, country: 'US', expoBrute: 0.3, expoNet: 0.23 },
      { _level: 1, sector: 'TECH', expoBrute: 0.3, expoNet: 0.23 },
      { _level: 2, isin: 'FR000001', expoBrute: 0.1, expoNet: 0.22 },
      { _level: 2, isin: 'FR000002', expoBrute: 0.2, expoNet: 0.01 },
    ],
  },
  'Drilldown (tabular no subtotals)': {
    columns: [
      {
        field: 'label',
        children: ['country', 'sector', 'isin'],
      },
      'expoBrute',
      'expoNet',
    ],
    data: [
      { label: { country: 'FR', sector: 'Distribution', isin: 'FR000009' }, expoBrute: 0.01, expoNet: 0.03 },
      { label: { sector: 'Industry', isin: 'FR000007' }, expoBrute: 0.02, expoNet: 0.2 },
      { label: { isin: 'FR000008' }, expoBrute: 0.4, expoNet: 0.01 },
      { label: { country: 'US', sector: 'TECH', isin: 'FR000001' }, expoBrute: 0.1, expoNet: 0.22 },
      { label: { sector: 'TECH', isin: 'FR000002' }, expoBrute: 0.2, expoNet: 0.01 },
    ],
  },
}

export const defaultStory = {
  columns: ['name', 'assetClass', 'sector', 'weight'],
  data: [
    { name: 'Instrument A', sector: 'Sector 1', assetClass: 'Equity', weight: 0.05 },
    { name: 'Instrument B', sector: 'Sector 1', assetClass: 'Equity', weight: 0.042 },
    { name: 'Instrument C', sector: 'Sector 2', assetClass: 'Fixed Income', weight: 0.03 },
    { name: 'Instrument D', sector: 'Sector 2', assetClass: 'Fixed Income', weight: 0.02 },
    { name: 'Instrument E', sector: 'Sector 3', assetClass: 'Fixed Income', weight: 0.015 },
  ],
}
