import * as d3 from 'd3'
import dateService from '../services/DateService'
import performanceService from '../services/PerformanceService'
import allocationService from '../services/AllocationService'
import mappingService from '../services/MappingService'
import transactionService from '../services/TransactionService'
import { getAsof } from './asof'

const format_asof = asof => {
  if (/[0-9]{4}-[0-9]{2}-[0-9]{2}/.test(asof)) return new Date(asof).format('YYYYMMDD')
  if (/[0-9]{4}-[0-9]{2}/.test(asof)) return new Date(asof).end('month').format('YYYYMMDD')
  if (/[0-9]{4}/.test(asof) && asof.length === 4) return new Date(asof).end('year').format('YYYYMMDD')
  return new Date().format('YYYYMMDD')
}

/**
 * Extend the dataset with it dates map
 * @param data Dateset data
 * @return {*} Extended dataset
 */
const extendWithDates = data => {
  data.dates = {}
  data.forEach((v, i) => {
    if (v.date && data.dates[v.date] === undefined) data.dates[v.date] = i
  })

  return data
}
/**
 * Dataset diff helper. Compare the datasets list of two routes
 * @param to To route
 * @param from From route
 * @return {boolean} Return true if its the same datasets
 */
const datasetsDiff = (to, from) =>
  (to.meta.datasets || []).sort().join('') !== (from.meta.datasets || []).sort().join('')

const isPerformance = to => to.meta && to.meta.name === 'performance'
/**
 * Helper to know if data should be updated on route change.
 * Compare different parameters.
 * @param to To route
 * @param from From route
 * @return {boolean} Return true if data has to be updated
 */
const shouldUpdate = (to, from) =>
  datasetsDiff(to, from) ||
  to.params.userflow !== from.params.userflow ||
  (!isPerformance(to) && to.query.domain !== from.query.domain) ||
  to.query.evolution !== from.query.evolution

/**
 * Fetch a dataset according its name with the given args
 * @param name Name of the dataset
 * @param dimensions Asset dimensions
 * @param fund Fund name
 * @param share Share isin
 * @param domain Date range
 * @param structure Structure config for trans-typing
 * @param asof
 * @return {Promise<*[]|*>} Promise of the request
 */
const fetchDataset = async (name, { dimensions, fund, share, domain, structure, asof }) => {
  switch (name) {
    case 'mapping':
      return await mappingService.list({ fund, share, dimensions, domain })
    case 'performance':
      return extendWithDates(transtype(await performanceService.list({ fund, share }), structure))
    case 'allocation':
      return extendWithDates(transtype(await allocationService.list({ fund, share, domain }), structure))
    case 'transactions':
      return extendWithDates(
        transtype(await transactionService.list({ fund /* share, dimensions , domain */ }), structure),
      )
    default:
      return []
  }
}
const transtype = (data, structure) => {
  return data.map(d =>
    Object.fromEntries(Object.entries(d).map(([k, v]) => (structure[k] ? [k, structure[k]({ [k]: v })] : [k, v]))),
  )
}

export default {
  data() {
    return {
      x: null,
      dates: [],
      // is fetching data
      updating: false,
      // processing data
      processing: false,
      loadingPromise: Promise.resolve(),
      asof: null,
    }
  },
  computed: {
    // is fetching or processing data
    loading() {
      return this.updating || this.processing
    },
    xf() {
      this.processing = true
      console.time('xf process')
      if (!this.x || this.updating) {
        this.processing = false
        return {}
      }
      const xf = this.$root.config?.datasets?.__?.filter((ds, name) =>
        (this.$root.screen.datasets || []).includes(name),
      )
        .__.filter((ds, name) => !!this.x[name])
        .__.map((ds, name) => {
          return this.xfilter(this.x[name], {
            inc: (ds.inc_fn && ds.inc_fn(this)) || (v => v),
            post: ds.post_fn && ds.post_fn(this),
            filters: this.filters,
            dimensions: (ds.dimensions || this.filters.keys().filter(k => k !== 'domain')).concat(
              ds.period === 'daily' ? ['date'] : [],
            ),
          })
        })
      console.timeEnd('xf process')
      this.processing = false
      return xf
    },
  },
  watch: {
    $route(to, from) {
      // Watch the route and update data if necessary
      if (shouldUpdate(to, from)) this.loadingPromise = this.updateData()
    },
    userflow(to, from) {
      // Update data when userflow change
      if (Object.equal(to, from)) return
      this.loadingPromise = this.updateData()
    },
  },
  methods: {
    async updateData() {
      // Check if it s an userflow screen
      if (!this.$route.params || !Object.keys(this.userflow).length) return
      // Check if the screen is using datasets
      if (!this.screen?.datasets?.length) return

      console.time('update data')
      this.updating = true

      const [fund, share] = this.$route.params.userflow.split('-')
      const dimensions = (this.userflow.dimensions || [])
        .concat(this.screen?.dimensions)
        .concat(this.share?.dimensions_pdf)
        .filter()
        .unique()
      const asof = getAsof()
      // Update $root.dates with this share dates
      this.dates = await dateService.list({ fund, share, dataset: this.screen?.datasets[0] })
      // Fetch the screen required datasets
      const datasets = $root.config.datasets.keys() || []
      const data = Object.fromEntries(
        await Promise.all(
          datasets
            .filter(d => this.$route.meta.datasets.includes(d))
            .map(d =>
              d === 'contribution_no_lookthrough' || d === 'contribution' || d === 'attribution' ? 'allocation' : d,
            )
            .filter((d, i, arr) => arr.indexOf(d) === i)
            .map(async d => {
              const structure = this.$root.config.datasets[d].structure
              const data = await fetchDataset(d, {
                dimensions,
                fund,
                share,
                domain: $root.domain,
                structure,
                asof: asof ? format_asof(asof) : undefined,
              })
              return [d, data]
            }),
        ),
      )

      // If mapping dataset has been fetched, add the global mapping
      window.mapping =
        data.mapping && data.mapping.mapping
          ? data.mapping.mapping.reduce((acc, d) => {
              acc[d.id || d.isin] = d
              return acc
            }, {})
          : {}

      this.asof = window.mappingAsOf = (data.mapping && data.mapping.asof) || null

      // merge datasets with mapping
      Object.entries(data)
        .filter(([k]) => k !== 'mapping')
        .forEach(([k, ds]) => {
          data[k] = ds.map(d => ({ ...d, ...window.mapping[d.id || d.isin] }))
          data[k].dates = ds.dates
        })

      // Bind the 'other' allocations by ref
      data.contribution_no_lookthrough = data.allocation || []
      data.contribution = data.allocation || []
      data.attribution = data.allocation || []
      if (data.mapping) data.mapping = data.mapping.mapping

      this.x = Object.freeze(data)
      this.updating = false
      console.timeEnd('update data')
    },
    xfilter(data, { inc, post, filters, dimensions }) {
      // prefiltering by date
      const dates = data.dates
      const domain = filters.domain
      if (domain && dates && dates.__.keys().length) {
        const d0 = dates[dates.__.keys().__.find(k => k >= domain[0])] // same date (or next)
        const after_d1 = dates[dates.__.keys().__.find(k => k > domain[1])] // next date
        data = data.slice(d0, after_d1)
      }

      const xf = { data: [] }
      filters = filters.__.filter((v, k) => v && dimensions.includes(k))
      dimensions.__.map(dim => (xf[dim] = {}))
      data.__.forEach((line, i) => {
        if (filters.date) {
          filters.date = filters.date.map(value => value.replace('~', '|'))
        }
        dimensions.__.filter(dim => !xf[dim][line[dim] || '-']).__.map(dim => (xf[dim][line[dim] || '-'] = null))
        const filtered = filters.__.reduce((acc, fil, dim) => {
          if (fil.includes(line[dim] || '-')) return acc
          acc.push(dim)
          return acc
        }, [])
        if (filtered.length === 1) {
          const dim = filtered[0]
          xf[dim][line[dim] || '-'] = inc(xf[dim][line[dim] || '-'], line)
        }
        if (filtered.length) return
        xf.data.push(line)
        dimensions.__.map(dim => (xf[dim][line[dim] || '-'] = inc(xf[dim][line[dim] || '-'], line)))
      })

      if (post) return post(xf)
      return xf
    },
  },
}
