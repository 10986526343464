<template lang="pug">
.relative
  slot(name="legend" :legends="legends" :tooltip="tooltip")
    legends(:legends="legends")
  .relative
    slot(name="tooltip" :tooltip="tooltip")
      table-tooltip(v-if="tooltip.shown" :title='tooltip.title' :rows='tooltip.rows')
    div.chart
      dx-chart(
          :data-source="data"
          @point-hover-changed="handleTooltip"
          @point-click="handleSeriesClick"
          @series-hover-changed="handleSeriesHover"
          @customize-point="options.customizePoint"
          @drawn="handleDrawn"
        )
          dx-common-series-settings(
            :type="options.stacked ? 'stackedbar' : 'bar'" 
            :argumentField="options.labelKey" 
            hover-mode="allArgumentPoints" 
            selection-mode="allArgumentPoints"
          )
          dx-argument-axis
            dx-label(textOverflow="ellipsis" wordWrap="none")

          dx-series(
            v-for="(dataset, i) in options.datasets"
            :name="dataset.label"
            :key="dataset.key"
            :value-field="dataset.key"
            :color="dataset.backgroundColor || getColor(i)"
            hoverMode="allArgumentPoints")
          dx-value-axis
            dx-label(:customizeText="customizeValue")
          dx-crosshair(:enabled="true" :horizontalLine="false" dashStyle="dash" color="#555555")
          dx-legend(:visible="false")
          template(#markerTemplate="{ data }")
            legend-marker(:color="data.marker.fill")
</template>
<script>
import {
  DxChart,
  DxSeries,
  DxArgumentAxis,
  DxCommonSeriesSettings,
  DxExport,
  DxGrid,
  DxMargin,
  DxLegend,
  DxTitle,
  DxSubtitle,
  DxTooltip,
  DxTickInterval,
  DxCrosshair,
  DxLabel,
  DxPoint,
  DxFont,
  DxValueAxis,
} from 'devextreme-vue/chart'
import RoundedMarker from './dx-rounded-marker.vue'
import useTooltip from './composables/useTooltip'
import { toRefs } from 'vue'
import useColors from './composables/useColors'
import useSeriesHover from './composables/useSeriesHover'
import useCustomizeText from './composables/useCustomizeText'
import LegendMarker from '../legend/legend-marker.vue'
import useCustomLegends from './composables/useCustomLegends'

export default {
  components: {
    DxChart,
    DxSeries,
    DxArgumentAxis,
    DxCommonSeriesSettings,
    DxExport,
    DxGrid,
    DxMargin,
    DxLegend,
    DxTitle,
    DxSubtitle,
    DxTooltip,
    DxTickInterval,
    DxCrosshair,
    DxLabel,
    DxPoint,
    DxFont,
    DxValueAxis,
    LegendMarker,
  },
  props: {
    data: {
      type: Array,
      default: () => [],
    },
    options: {
      type: Object,
      default: () => ({}),
    },
  },
  emits: ['tooltip', 'seriesHover', 'seriesClick'],
  setup(props, { emit }) {
    const { data, options } = toRefs(props)
    const { tooltip, handleTooltip } = useTooltip(options, emit)
    const { getColor } = useColors()
    const { handleSeriesHover } = useSeriesHover(emit)
    const { customizeValue } = useCustomizeText(options)
    const { legends, handleDrawn } = useCustomLegends()
    const handleSeriesClick = ev => {
      emit('seriesClick', ev)
    }
    return {
      tooltip,
      handleTooltip,
      getColor,
      handleSeriesHover,
      handleSeriesClick,
      customizeValue,
      handleDrawn,
      legends,
    }
  },
}
</script>
<style scoped>
.chart {
  height: 100%;
}
</style>
