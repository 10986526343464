<script setup lang="ts">
import * as Plotly from 'plotly.js-dist'
import { reactive, watch } from 'vue'
const props = defineProps<{ data: Plotly.Data[]; options: {} }>()
const emit = defineEmits(['seriesHover', 'seriesClick'])
const state = reactive({
  ref: null,
  width: 0,
  height: 0,
  padding: 0,
  ratio: 0,
})
const resize = ref => {
  if (!ref) return
  state.ref = ref
  state.width = ref.clientWidth - state.padding * 2
  state.height = ref.clientHeight - state.padding * 2
  new ResizeObserver(() => {
    state.width = ref.clientWidth - state.padding * 2
    state.height = ref.clientHeight - state.padding * 2
  }).observe(ref)
}
watch([state, props], () => {
  if (!state.ref || !state.width || !state.height) return
  const minwh = Math.min(state.width, state.height)
  const width = state.ratio ? minwh * state.ratio : state.width
  const height = state.ratio ? minwh : state.height
  const palette = props.options.palette
  const colorscale = palette.map((v, i) => [i / (palette.length - 1), `rgb(${parseInt(v.slice(1, 3), 16)}, ${parseInt(v.slice(3, 5), 16)}, ${parseInt(v.slice(5, 7), 16)})`]) // prettier-ignore
  const transformer = props.options.transformer || ((data, layout, config) => [data, layout, config])
  // Create the 3D surface data
  const uniqueX = [...new Set(props.data.map(item => item[props.options.x]))]
  const uniqueY = [...new Set(props.data.map(item => item[props.options.y]))]
  const zValues = uniqueY.map(y =>
    uniqueX.map(x => {
      const point = props.data.find(item => item[props.options.x] === x && item[props.options.y] === y)
      return point ? point[props.options.z] : null
    }),
  )
  // Create the 3D surface options
  const trace = {
    x: uniqueX.map(x => '​' + x), // adding zero-width space to prevent plotly from converting to number, for exemple '-2.5 %' to -2.5
    y: uniqueY.map(y => '​' + y),
    z: zValues,
    type: 'surface',
    colorscale,
    hoverinfo: 'none',
    contours: {
      z: {
        show: true,
        usecolormap: true,
        highlightcolor: '#55dec1',
        project: { z: true },
      },
    },
  }
  if (Array.prototype._sort) {
    // RAWJS compatibility
    Array.prototype.__sort = Array.prototype.sort
    Array.prototype.sort = Array.prototype._sort
  }
  Plotly.react(
    state.ref,
    ...transformer(
      [trace],
      {
        scene: {
          // aspectratio: { x: 1, y: 1, z: 1 },
          xaxis: { title: props.options.x },
          yaxis: { title: props.options.y },
          zaxis: { title: props.options.z },
        },
        autosize: false,
        width: width,
        height: height,
        margin: { l: 0, r: 0, t: 0, b: 0 },
        colorway: palette,
        showlegend: false,
      },
      {
        displayModeBar: false,
        responsive: true,
        colorway: palette,
      },
    ),
  )
  if (Array.prototype._sort) {
    // RAWJS compatibility
    Array.prototype.sort = Array.prototype.__sort
    delete Array.prototype.__sort
  }
  state.ref.on('plotly_hover', data => emit('seriesHover', data.points[0]))
  state.ref.on('plotly_unhover', () => emit('seriesHover', null))
  // state.ref.on('plotly_click', data => emit('seriesClick', data.points[0]))
})
</script>

<template>
  <div class="nx-plotly" :ref="resize"></div>
</template>

<style>
.nx-plotly .svg-container {
  margin: auto !important;
}
</style>
