export default (num, { unit, digit, lang = 'en' }) => {
  if ((!num && num !== 0) || num.constructor !== Number) return num || ''
  digit = Math.max(0, Math.min(digit, 4))
  if (unit === 'bp') return Math.round(num * 10000) + 'bp'
  if (unit === '%')
    return (
      Intl.NumberFormat(lang.slice(0, 2), {
        notation: 'compact',
        minimumFractionDigits: digit,
        maximumFractionDigits: digit,
      }).format(num * 100) + '%'
    )
  if (['$', '€', '£', 'USD', 'EUR', 'GBP', 'CHF'].includes(unit))
    return (
      Intl.NumberFormat(lang.slice(0, 2), {
        // style: 'currency',
        // currency: unit.replace('$', 'USD').replace('€', 'EUR').replace('£', 'GBP'),
        notation: 'compact',
        minimumFractionDigits: digit,
        maximumFractionDigits: digit,
      }).format(num) + unit
    )
  return Intl.NumberFormat(lang.slice(0, 2), {
    notation: 'compact',
    minimumFractionDigits: digit,
    maximumFractionDigits: digit,
  }).format(num)
}
