import { reactive, watch, Ref } from 'vue'
import type { Store } from '../builder'
const objectEqual = (a: any, b: any): boolean => {
  if (a === b) return true
  const ta = Object.prototype.toString.call(a)
  if (ta !== Object.prototype.toString.call(b)) return false
  if (!['[object Object]', '[object Array]'].includes(ta)) return a.toString() === b.toString()
  if (Object.keys(a).length !== Object.keys(b).length) return false
  return Object.keys(a).every(k => objectEqual(a[k], b[k]))
}

export default function useStore(target: Ref<any>, onChange?: (store: Store) => void) {
  const store: Store = reactive({
    active: [],
    undo: [],
    redo: [],
    versions: [],
    undo_skip: false,
  })

  const undo = () => {
    if (store.undo.length < 2) return
    const undo_action = store.undo.at(-1)
    if (undo_action) {
      store.redo = store.redo.concat(undo_action)
    }
    store.undo = store.undo.slice(0, -1)
    store.undo_skip = true
  }
  const undoAll = () => {
    if (store.undo.length < 2) return
    store.redo = store.redo.concat(store.undo.slice(1))
    store.undo = store.undo.slice(0, 1)
    store.undo_skip = true
  }
  const redo = () => {
    if (store.redo.length < 1) return
    const redo_action = store.redo.at(-1)
    if (redo_action) {
      store.undo = store.undo.concat(redo_action)
    }
    store.redo = store.redo.slice(0, -1)
    store.undo_skip = true
  }
  const redoAll = () => {
    if (store.redo.length < 1) return
    store.undo = store.undo.concat(store.redo)
    store.redo = []
    store.undo_skip = true
  }
  watch(
    target,
    (newValue, oldValue) => {
      // TODO: use addHistory mutation from store

      if (store.undo_skip) return (store.undo_skip = false)
      store.undo = store.undo.concat(JSON.parse(JSON.stringify(newValue)))
      store.redo = []
    },
    { deep: true },
  )
  watch(
    () => [store.undo, store.redo, store.versions],
    () => {
      // Modify template when you undo /redo
      if (onChange) onChange(store)
      if (store.undo.length === 0 || objectEqual(target.value, store.undo.at(-1))) return
      target.value = JSON.parse(JSON.stringify(store.undo.at(-1)))
    },
    { deep: true },
  )

  return {
    store,
    undo,
    undoAll,
    redo,
    redoAll,
  }
}
