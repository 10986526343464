<style>
.mai-page h2 {
  color: var(--colors-primary-default);
}
.mai-page .row .column {
  width: 100%;
}
.mai-page .pdf-table .cell:not(.c1) {
  width: 18%;
}
.pdf-esg-mai-1 > h2:not(:nth-of-type(-n + 1)) {
  margin-top: 75px;
}
.pdf-esg-mai-1 .column.carbon_intensity > div:nth-of-type(2n + 1) {
  align-self: center;
}
.mai-page .theme_pocket h2 {
  justify-content: center;
}
.mai-page .theme_pocket .subtitle {
  text-align: center;
}
.mai-page .pdf-horizontalbar-icon {
  min-height: 80px;
}
</style>

<template lang="pug">
.pdf-esg-mai-1
  .subtitle.row(v-if="data.share.characteristics.benchmark_esg") {{ (t['univers_definition'] || 'univers_definition') + ': ' + t[data.share.characteristics.benchmark_esg] }}
  h2 {{ $root.t['fund'] || 'fund' }}
  .row.main_lines_pocket
    .column
      brick.no-bold.right-value(
        :subtitle="$root.t[`${data.share.characteristics.esg_coverage_table}_subtitle`] || 'subtitle_esg_coverage'"
        :data="data.tables.esg_coverage_table"
        :title="data.share.characteristics.esg_coverage_table"
      )
        .source(v-if="$root.lang.includes('be')") {{ t.source + ' ' + t[data.share.characteristics.management_company] }}
    .column.carbon_intensity(v-if="data.esg.carbon_intensity.data.length")
      brick(subtitle="subtitle_carbon_intensity" title="carbon_intensity")
      pdf-horizontalbar-icon(
        subtitle="subtitle_carbon_intensity"
        :data="data.esg.carbon_intensity.data"
        :options="{ format: '.2f', sort: d => ['fund', 'benchmark'].indexOf(d.key) }"
      )
      .sfdr-block(
        v-if="data.share.characteristics.display_carbon_intensity_bench && data.share.characteristics.benchmark_esg && ['fund', 'univers'].every(d => data.esg.carbon_intensity.data.map(d => d.key).includes(d))"
      )
        .row
          .sfdr-text {{ t.carbon_intensity_difference || 'carbon_intensity_difference' }}
          .sfdr-value {{ format('.0%')(data.esg.carbon_intensity_difference) }}
    .column(v-if="data.share.characteristics.display_esg_temperature && (typeof(data.esg.temperature_data.values.fund) === 'number' || typeof(data.esg.temperature_data.values.benchmark) === 'number')")
      brick(title="temperatures")
      pdf-thermometer(:data="data.esg.temperature_data")
      .disclaimer(v-if="data.esg.temperature_data.coverage.fund") {{ t['temperature_coverage_fund'] || 'temperature_coverage_fund' }}: {{format('.2%')(data.esg.temperature_data.coverage.fund)}}
      .disclaimer(v-if="data.esg.temperature_data.coverage.benchmark") {{ t['temperature_coverage_benchmark'] || 'temperature_coverage_benchmark' }}: {{format('.2%')(data.esg.temperature_data.coverage.benchmark)}}
    .column(v-else)

  h2 {{ t['action_pocket'] || 'action_pocket' }}
  .row.main_lines_pocket
    .column(v-if="data.esg.carbon_intensity_action_pocket.data.length")
      brick(subtitle="subtitle_carbon_intensity" title="carbon_intensity")
      pdf-horizontalbar-icon(
        :data="data.esg.carbon_intensity_action_pocket.data"
        :options="{ format: '.2f', sort: d => ['fund', 'benchmark'].indexOf(d.key) }"
      )
      brick.no-bold.right-value(
        subtitle="subtitle_scoring_climatique"
        title="scoring_climatique"
        :data="data.esg.scoring_climatique_action_pocket"
      )
        .source(v-if="$root.lang.includes('be')") {{ t.source + ' ' + t[data.share.characteristics.management_company] }}
        .disclaimer {{t['disclaimer_scoring_climatique_action_pocket'] || 'disclaimer_scoring_climatique_action_pocket'}}
    .column
    .column
      brick.no-bold.right-value(
        subtitle="subtitle_main_scores_carbon_impact"
        title="main_scores_carbon_impact"
        :data="data.esg.main_lines_table_esg_action.desc"
        :options="{ limit: 4 }"
      )
      brick.no-bold.right-value(:data="data.esg.main_lines_table_esg_action.asc" :options="{ limit: 4 }")
      .source(v-if="$root.lang.includes('be')") {{ t.source + ' ' + t[data.share.characteristics.management_company] }}

  h2 {{ t['govies_pocket'] || 'govies_pocket' }}
  .row.main_lines_pocket
    .column(v-if="data.esg.carbon_intensity_govies_pocket.data.length")
      brick(subtitle="subtitle_carbon_intensity" title="carbon_intensity")
      pdf-horizontalbar-icon(
        :data="data.esg.carbon_intensity_govies_pocket.data"
        :options="{ format: '.2f', sort: d => ['fund', 'benchmark'].indexOf(d.key) }"
      )
      brick.no-bold.right-value(
        subtitle="subtitle_scoring_climatique"
        title="scoring_climatique"
        :data="data.esg.scoring_climatique_govies_pocket"
      )
        .disclaimer {{t['disclaimer_scoring_climatique_govies_pocket'] || 'disclaimer_scoring_climatique_govies_pocket'}}
    .column.theme_pocket
      brick(
        subtitle="subtitle_theme_govies"
        title="theme_govies"
        type="hbar"
        :data="data.esg.theme_govies"
        :options="{ format: '.2%' }"
      )
      .legend_line
        .row
          .legend_sample
          span {{t['fund'] }}
        .row
          .legend_sample
          span {{t['univers'] }}
    .column
      brick.no-bold.right-value(
        subtitle="subtitle_main_scores_carbon_impact"
        title="main_scores_carbon_impact"
        :data="data.esg.main_lines_table_esg_govies.desc"
        :options="{ limit: 4 }"
      )
      brick.no-bold.right-value(:data="data.esg.main_lines_table_esg_govies.asc" :options="{ limit: 4 }")
      .source(v-if="$root.lang.includes('be')") {{ t.source + ' ' + t[data.share.characteristics.management_company] }}
</template>

<script>
export default {
  props: { data: Object },
}
</script>
