export default {
  logo: 'logo.png',
  nav: 'nav.png',
  icon: 'icon.png',
  navSmall: 'nav_small.png',
  langSelector: ['fr', 'en'],
  defaultLang: 'fr',
  auth: {
    apiLogin: import.meta.env.VITE_API_LOGIN,
    domain: import.meta.env.VITE_AUTH0_DOMAIN,
    clientId: import.meta.env.VITE_AUTH0_CLIENT_ID,
    project: import.meta.env.VITE_PROJECT,
    urls: ['https://fireauth.100m.io/'],
  },
  commandr: import.meta.env.VITE_COMMANDR,
  exporter: import.meta.env.VITE_EXPORTER,
  project: import.meta.env.VITE_PROJECT,
  db: import.meta.env.VITE_DB || 'firebase',
  firebase: {
    apiKey: import.meta.env.VITE_FIREBASE_API_KEY,
    name: import.meta.env.VITE_FIREBASE_NAME,
    project: import.meta.env.VITE_FIREBASE_PROJECT,
    root: import.meta.env.VITE_FIREBASE_ROOT || import.meta.env.VITE_FIREBASE_PROJECT,
  },
  graphqlEndpoint: import.meta.env.VITE_GRAPHQL_ENDPOINT || '/graphql',
  mode: import.meta.env.MODE,
  defaultTemplate: {
    theme: {
      prefix: 'lfr',
      font: 'MarkPro',
      page_size: 'A4',
      page_orientation: 'portrait',
      spacing: '4.5',
      text_size: '2',
      title_size: '3.25',
      primary: '#184385',
      secondary: '#f49400',
    },
    pages: [
      {
        lines: [{ component: 'header' }, { component: 'block' }, { component: 'footer' }],
      },
    ],
  },
  dqcOptions: {
    dqcNames: [
      'checkFundHasSettings',
      'checkNoNavJumps',
      'checkAllAssetInPositionAreInAssetReferential',
      'checkAllDatesConsistent',
      'checkNavInventoryCoherence',
      'checkMissingTranslations',
    ],
    optionsList: [{}, {}, {}, {}, {}, { locale: 'fr' }],
  },
}
