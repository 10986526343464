<template>
  <!-- story-controls -->
  <div class="flex items-center justify-center">
    <template v-if="type?.name === 'union' || controlOptions?.type === 'select'">
      <select v-model="select.value">
        <option
          v-for="(option, index) in (controlOptions as IStorySelect<unknown>)?.options || (type as any)?.elements"
          :key="option?.name?.replaceAll(doublequote, '')"
          :value="option?.name?.replaceAll(doublequote, '')"
        >
          {{ option?.name?.replaceAll(doublequote, '') }}
        </option>
      </select>
    </template>
    <template v-else-if="type?.name === 'string' || controlOptions?.type === 'string'">
      <textarea
        v-if="calcState[props.accessArg ?? props.prop.name]?.length > 20"
        rows="2"
        v-model="calcState[props.accessArg ?? props.prop.name]"
      />
      <input v-else type="text" v-model="calcState[props.accessArg ?? props.prop.name]" />
    </template>
    <template v-else-if="type?.name === 'number' || ['number', 'range'].includes(controlOptions?.type!)">
      <input
        v-bind="(controlOptions as IStoryNumber)?.options ?? { type: 'number' }"
        v-model.number="calcState[props.accessArg ?? props.prop.name]"
        :class="controlOptions?.type === 'range' ? '-mt-5' : ''"
      />
      <span v-if="controlOptions?.type === 'range'" class="inline-block w-4 pl-2">
        {{ calcState[props.accessArg ?? props.prop.name] }}
      </span>
    </template>
    <template v-else-if="controlOptions?.type === 'numbers'">
      <div class="flex">
        <div
          v-for="[key, subOptions] in Object.entries((controlOptions as IStoryNumbers<any>).value)"
          class="remove-spin flex shrink-0 grow-0 flex-col pr-px"
          :style="{
            width: `${100 / Object.keys((controlOptions as IStoryNumbers<any>).value)?.length}%`,
          }"
        >
          <story-controls
            :prop="{
              name: key,
              type: {
                name: 'number',
              },
            }"
            :state="subOptions as object"
            :options="controlOptions.value"
            :access-arg="props.accessArg"
          />
          <div class="truncate pb-2 text-xs font-medium">{{ key }}</div>
        </div>
      </div>
    </template>
    <template v-else-if="type?.name === 'boolean' || controlOptions?.type === 'boolean'">
      <input type="checkbox" v-model.number="calcState[props.accessArg ?? props.prop.name]" />
    </template>
    <template v-else>
      <!-- {{ props.prop.type }} -->
    </template>
  </div>
</template>

<script setup lang="ts">
import { PropDescriptor as PropDescriptorHack } from 'vue-docgen-api'
import type { IStoryNumber, IStoryNumbers, IStoryOptionsMap as IStoryOptionsMapHack, IStorySelect } from '@storytime'
import { computed, ref } from 'vue'
import { isNil } from 'lodash'

interface PropDescriptor extends PropDescriptorHack {}
interface IStoryOptionsMap extends IStoryOptionsMapHack {}

interface IProps {
  /**
   * Vue documentation object of a prop as extracted by vue-docgen
   */
  prop: PropDescriptor
  /**
   * Reactive object that allows to communicate the current value of props
   */
  state: object
  /**
   * Options that allow to modify the widget of props
   */
  options?: IStoryOptionsMap
  accessArg?: string
}

const props = withDefaults(defineProps<IProps>(), {})
const calcState = computed(() => props.state as any)

const doublequote = '"'
const type = computed(() => props.prop?.type)
const controlOptions = computed(() => props.options?.[props.prop.name])

const selectName = ref<string | null>(null)

const select = {
  get value() {
    if (controlOptions.value?.type === 'select') {
      if (isNil(selectName.value)) {
        selectName.value = (controlOptions.value as IStorySelect<unknown>).options[0].name
        calcState.value[props.accessArg ?? props.prop.name] = (
          controlOptions.value as IStorySelect<unknown>
        ).options[0].value
      }
      return selectName.value
    } else {
      return calcState.value[props.accessArg ?? props.prop.name]
    }
  },
  set value(val: string) {
    selectName.value = val
    if (controlOptions.value?.type === 'select') {
      const option = (controlOptions.value as IStorySelect<unknown>).options.find(el => el.name === val)

      calcState.value[props.accessArg ?? props.prop.name] = option?.value
    } else {
      calcState.value[props.accessArg ?? props.prop.name] = val
    }
  },
}
</script>

<style scoped lang="postcss">
input[type='range'] {
  @apply bg-transparent;
}

/* Chrome, Safari, Edge, Opera */
.remove-spin input::-webkit-outer-spin-button,
.remove-spin input::-webkit-inner-spin-button {
  display: none;
}

.remove-spin input[type='number'] {
  padding-right: 2px;
}

/* Firefox */
.remove-spin input[type='number'] {
  -moz-appearance: textfield;
  appearance: textfield;
}
</style>
