<template lang="pug">
div.normalize {{t.managers}}
  span.normal(v-if="!props.overview") :
.profile
  .managers(v-for="manager in managers" :key="manager.url")
    img(:src="manager.url", @error="handleImageError(manager)", alt="manager image")
    .firstname {{ manager.firstname }}
    .name {{ manager.lastname }}
</template>

<script setup>
import { computed, reactive } from 'vue'

const props = defineProps({
  managersData: String,
  overview: {
    type: Boolean,
    default: false,
  },
})

const managers = computed(() => {
  if (!props.managersData) {
    return {}
  }
  return props.managersData.split(',').map(name => {
    const parts = name.trim().split('_')
    const firstname = parts[0]
    const lastname = parts[1]

    return reactive({
      firstname: firstname,
      lastname: lastname,
      url: `managers/${firstname}.${lastname}.jpg`,
    })
  })
})

const handleImageError = manager => {
  manager.url = '/user_profile_avatar.png'
}
</script>

<style scoped>
.profile {
  display: flex;
}

.profile .managers {
  font-size: 8px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex: 1;
  align-items: center;
}

.profile .name {
  font-weight: 600;
  text-transform: uppercase;
}

.profile .firstname {
  padding-top: 4px;
}

.profile img {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  object-fit: cover;
  object-position: top;
}

.normalize {
  display: flex;
  align-items: center;
  transform: translateY(-8px);
  font-weight: bold;
  padding-left: 2px;
}

.normal {
  font-weight: normal;
  margin-left: 2px;
}

.overview .profile img {
  width: 100px;
  height: 100px;
}

.overview .profile .managers {
  font-size: 16px;
}

.overview .normalize {
  font-weight: bold;
  padding: 4px;
  transform: translateY(0px);
}
</style>
