<template>
  <!-- arrow -->
  <path class="link" fill="none" :d="dPath" stroke="black" :stroke-width="props.strokeWidth"></path>
</template>

<script setup lang="ts">
/**
 * Arrow component that allows to draw an arrow on screen between two points.
 * This component must be used inside an arrow-set.
 * @component
 */
import { computed } from 'vue'

interface IProps {
  /**
   * Starting position of the arrow on the X axis
   */
  startX: number
  /**
   * Starting position of the arrow on the Y axis
   */
  startY: number
  /**
   * Ending position of the arrow on the X axis
   */
  endX: number
  /**
   * Ending position of the arrow on the Y axis
   */
  endY: number
  /**
   * Line width of the arrow
   */
  strokeWidth?: number
  /**
   * The radius of the circular corner (in px)
   */
  roundSize?: number
  /**
   * The curve type used in the corner
   */
  curveType?: 'elliptical' | 'bezier'
  /**
   * Orientation of the arrow
   */
  orientation?: 'horizontal' | 'vertical'
}

const props = withDefaults(defineProps<IProps>(), {
  orientation: 'horizontal',
  strokeWidth: 2,
  roundSize: 16,
  curveType: 'elliptical',
})

const dPath = computed(() => {
  const roundSize = props.roundSize
  let angle
  if (props.orientation === 'horizontal') {
    const [leftX, leftY, rightX, rightY] =
      props.startX < props.endX
        ? [props.startX, props.startY, props.endX, props.endY]
        : [props.endX, props.endY, props.startX, props.startY]
    const middleX = (rightX - leftX) / 2
    if (leftY + roundSize * 2 < rightY && props.curveType === 'elliptical') {
      angle = `
        A${roundSize} ${roundSize} 90 0 1 ${leftX + middleX} ${leftY + roundSize}
        L${leftX + middleX} ${rightY - roundSize}
        A${roundSize} ${roundSize} 90 0 0 ${leftX + middleX + roundSize} ${rightY}
      `
    } else if (rightY + roundSize * 2 < leftY && props.curveType === 'elliptical') {
      angle = `
        A${roundSize} ${roundSize} 90 0 0 ${leftX + middleX} ${leftY - roundSize}
        L${leftX + middleX} ${rightY + roundSize}
        A${roundSize} ${roundSize} 90 0 1 ${leftX + middleX + roundSize} ${rightY}
      `
    } else {
      angle = `C${leftX + middleX + (roundSize * 2) / 3},${leftY} ${leftX + middleX - (roundSize * 2) / 3},${rightY} ${
        leftX + middleX + roundSize
      },${rightY}`
    }

    return `
        M${leftX} ${leftY}
        L${leftX + middleX - roundSize} ${leftY}
        ${angle}
        L${rightX} ${rightY}
      `
  } else {
    const [topX, topY, bottomX, bottomY] =
      props.startY < props.endY
        ? [props.startX, props.startY, props.endX, props.endY]
        : [props.endX, props.endY, props.startX, props.startY]
    const middleY = (bottomY - topY) / 2
    if (topX + roundSize * 2 < bottomX && props.curveType === 'elliptical') {
      angle = `
        A${roundSize} ${roundSize} 90 0 0 ${topX + roundSize} ${topY + middleY}
        L${bottomX - roundSize} ${topY + middleY}
        A${roundSize} ${roundSize} 90 0 1 ${bottomX} ${topY + middleY + roundSize}
      `
    } else if (bottomX + roundSize * 2 < topX && props.curveType === 'elliptical') {
      angle = `
        A${roundSize} ${roundSize} 90 0 1 ${topX - roundSize} ${topY + middleY}
        L${bottomX + roundSize} ${topY + middleY}
        A${roundSize} ${roundSize} 90 0 0 ${bottomX} ${topY + middleY + roundSize}
      `
    } else {
      angle = `C${topX},${topY + middleY + (roundSize * 2) / 3} ${bottomX},${
        topY + middleY - (roundSize * 2) / 3
      } ${bottomX},${topY + middleY + roundSize}`
    }

    return `
        M${topX} ${topY}
        L${topX} ${topY + middleY - roundSize}
        ${angle}
        L${bottomX} ${bottomY}
      `
  }
})
</script>
