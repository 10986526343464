import config from '../../../config'
import { useGraphQL } from '../../../utils/axios'

export function useTransactions() {
  async function getTransactions({ isinShare, domain, asof }) {
    const { sendQuery } = useGraphQL(config)
    const query = `#graphql
      query Transactions($fuid: FuidInput!, $domain: Domain!) {
        transactions {
          all(fuid: $fuid, domain: $domain) {
            fuid
            name
            type
            value
            variation
          }
        }
      }`
    const variables = {
      fuid: isinShare,
      domain,
    }
    const resp = await sendQuery(query, variables)
    return resp.transactions.all
  }

  return {
    getTransactions,
  }
}
