import { onMounted, ref } from 'vue'
import dataQualityRuleService from '../../../services/DataQualityRuleService'

export function useDataQualityRule() {
  const rules = ref([])
  const loaded = ref(false)

  onMounted(async () => {
    const rulesList = await dataQualityRuleService.all()
    rules.value = rulesList
    loaded.value = true
  })
  return {
    rules,
    loaded,
  }
}
