import { ref } from 'vue'
import dataReportService from '@100-m/hauru/src/services/DataReportService'

const langs = ref<string[]>([])
let isInitialized = false

async function initializeLangs() {
  const { data } = await dataReportService.run('builder-lang', {}, { preProcess: false, postProcess: false })
  langs.value = (data?.result || []).map((l: string) => l).sort()
  isInitialized = true
}

export function useLangs() {
  if (!isInitialized) {
    initializeLangs()
  }
  return {
    langs,
  }
}
