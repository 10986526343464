import config from '../../../config'

export function useAttribution() {
  const getExcessReturn = async params => {
    const resp = await axios({
      url: config.graphqlEndpoint,
      method: 'post',
      data: {
        variables: params,
        query: `query ExcessReturnQuery(
          $shareId: String!
          $domain: Domain!
        ) {
          attribution {
              excessReturn(shareId: $shareId, domain: $domain) {
                excess_return
                performance
                performance_benchmark
                fees
              }
          }
        }`,
      },
    })
    return resp.data.data.attribution.excessReturn
  }
  const getShareInfo = async params => {
    console.log('getShareInfo')
    const resp = await axios({
      url: config.graphqlEndpoint,
      method: 'post',
      data: {
        variables: params,
        query: `query ShareInfoQuery(
          $isinShare: FuidInput!
        ) {
          referential {
            share(fuid: $isinShare) {
              ${$root.config.characteristicsSchema || ''}
              axis_of_analysis:axisOfAnalysis
              fund_currency:fundCurrency
              asof
              slug
            }
          }
        }`,
      },
    })
    return resp.data.data.referential.share
  }

  const getAttribution = async params => {
    console.log('getAttribution')
    const resp = await axios({
      url: config.graphqlEndpoint,
      method: 'post',
      data: {
        variables: params,
        query: `query AttributionQuery(
           $fuid: String!
          $domain: Domain!
          $dimension: String!
        ) {

          attribution{
            attribution(shareId: $fuid, domain: $domain, dimension: $dimension)
            }
        }`,
      },
    })
    return resp.data.data.attribution.attribution
  }

  const getAttributionHistoryData = async params => {
    console.log('getAttributionHistoryData')
    const resp = await axios({
      url: config.graphqlEndpoint,
      method: 'post',
      data: {
        variables: params,
        query: `query AttributionHistoryDataQuery(
          $isinShare: String!
          $asof: Domain
          $domain: Domain!
          $dimension: String!
        ) {
            attribution {
              history(shareId: $isinShare, asof: $asof,domain: $domain, dimension: $dimension)
            }
        }`,
      },
    })
    return resp.data.data.attribution.history
  }

  const getAttributionDetails = async params => {
    console.log('getAttributionDetails')
    const resp = await axios({
      url: config.graphqlEndpoint,
      method: 'post',
      data: {
        variables: params,
        query: `query AttributionDetailsQuery(
          $isinShare: String!
          $asof: Date
          $domain: Domain!
          $dimension: String!
          $additionalDimensions: [String!]
        ) {
          businessData {
            share(shareId: $isinShare, asof: $asof) {
              attributionDetails(domain: $domain, dimension: $dimension, additionalDimensions: $additionalDimensions)
            }
          }
        }`,
      },
    })
    return resp.data.data.businessData.share.attributionDetails
  }

  const getAttributionDetailsDeep = async params => {
    console.log('getAttributionDetailsDeep')
    const resp = await axios({
      url: config.graphqlEndpoint,
      method: 'post',
      data: {
        variables: params,
        query: `query AttributionDetailsQuery(
          $isinShare: String!
          $asof: Date
          $domain: Domain!
          $dimension: String!
          $additionalDimensions: [String!]
        ) {
          businessData {
            share(shareId: $isinShare, asof: $asof) {
              attributionDetailsDeep(domain: $domain, dimension: $dimension, additionalDimensions: $additionalDimensions)
            }
          }
        }`,
      },
    })
    return resp.data.data.businessData.share.attributionDetailsDeep
  }

  return {
    getShareInfo,
    getAttribution,
    getAttributionHistoryData,
    getAttributionDetails,
    getAttributionDetailsDeep,
    getExcessReturn,
  }
}
