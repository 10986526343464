<style>
.en_hk_disclaimer {
  justify-content: center;
  font-size: large;
  white-space: pre-line;
  text-align: justify;
}
</style>

<template lang="pug">
.pdf-page.grid(v-if="$root.lang === 'en_hk'" :class="{en_hk: $root.lang === 'en_hk'}")
  pdf-header(:share="data.share" :period="data.generated_period" :domain="perf_domain")
  brick.expand.en_hk_disclaimer 
    div(v-html="t['disclaimer_en_hk']")
  pdf-footer(:share="data.share.characteristics")
</template>

<script>
export const additions = {}
import pdf from '../mixins/pdf'
export default {
  mixins: [pdf],
  props: { data: Object },
}
</script>
