import { isNil } from 'lodash'
import { reactive, readonly, ref } from 'vue'

export type IHashList<T = unknown> = ReturnType<typeof useHashList<T>>

export interface IDraggableChange<T = any> {
  added: {
    element: T
    newIndex: number
  }
  removed: {
    element: T
    oldIndex: number
  }
  moved: {
    element: T
    newIndex: number
    oldIndex: number
    afterElement?: T
  }
}

export function useHashList<T>({ allowMultiple = true, getKey = (item: T) => item as string | number | symbol } = {}) {
  const state = reactive({
    /**
     * !IMPORTANT: Be carefull to put only simple types in this list, as they are added on deep watchers that will be triggered on every change
     */
    list: ref<T[]>([]),
    map: {} as { [key: string | number | symbol]: T },
    allowMultiple,
    is,
    get,
    add,
    remove,
    toggle,
    replace,
    empty,
    set,
    move,
    change,
    sort,
    getKey,
  })

  function sort(sortfunction: (a: T, b: T) => number) {
    if (state.list.length) {
      state.list = state.list.sort(sortfunction as any)
    }
  }
  /**
   * Returns the state of the item indicated
   * @param key Key of the item whose state will be returned
   */
  function get(key: string | number | symbol): T | undefined {
    return state.map[key]
  }

  /**
   * Returns a boolean that indicates whether an item has a certain state
   * @param key Key of the item whose state will be returned
   */
  function is(key: string | number | symbol): boolean {
    return !isNil(state.map[key])
  }

  /**
   * Adds indicated item to the list
   * @param item Item to be added
   * @param index Zero-based index where the item will be added, or the end of the list if not specified
   */
  function add(item: T, index?: number) {
    const key = getKey(item)
    if (is(key)) return
    if (!allowMultiple) empty()

    // @ts-ignore
    state.list.splice(index ?? state.list.length, 0, item)
    state.map[key] = item
  }

  /**
   * Removes indicated item from the list
   * @param key Key of the item that will be removed
   */
  function remove(key: string | number | symbol) {
    if (!is(key)) return

    state.list.splice(
      state.list.findIndex(x => getKey(x as T) === key),
      1,
    )
    delete state.map[key]
  }

  /**
   * Adds indicated item to the list if the item is not already there, otherwise removes it
   * @param item Item to be toggled
   * @param force If true, the item will be added, if false, the item will be removed
   */
  function toggle(item: T, force?: boolean) {
    const key = getKey(item)

    if ((is(key) && force !== true) || force === false) remove(key)
    else add(item)
  }

  /**
   * Replaces the list with a new one
   */
  function replace(newList: T[]) {
    empty()
    newList.forEach(n => add(n))
  }

  /**
   * Removes all items from the list
   */
  function empty() {
    for (const key in state.map) {
      delete state.map[key]
    }
    state.list.length = 0
  }

  /**
   * Updates the value of the indicated item
   * @param key Key of the item whose state will be updated
   * @param item The new value of the item
   */
  function set(key: string | number | symbol, item: T) {
    const index = state.list.findIndex(i => getKey(i as T) === key)

    if (index < 0 || !is(key)) return

    // @ts-ignore
    state.list[index] = item
    state.map[key] = item
  }

  /**
   * Moves an item from one position to another
   * @param from Zero-based index of the item to be moved
   * @param to Zero-based index of the item to be moved to
   */
  function move(from: number, to: number) {
    if (from === to || from < 0 || to < 0 || from >= state.list.length || to >= state.list.length) return

    state.list.splice(to, 0, state.list.splice(from, 1)[0])
  }

  /**
   * Handles changes in the list as they occur in a draggable component
   * @param draggableChange Change object
   */
  function change(draggableChange: IDraggableChange<T>) {
    const { added, removed, moved } = draggableChange

    if (added) {
      add(added.element, added.newIndex)
    }

    if (removed) {
      remove(getKey(removed.element))
    }

    if (moved) {
      move(moved.oldIndex, moved.newIndex)
    }
  }

  return readonly(state)
}
