import { onMounted, ref } from 'vue'
import templateService from '../../../services/TemplateService'

export function useTemplates() {
  const templates = ref([])
  const loaded = ref(false)

  onMounted(async () => {
    const templatesFoundInApp = Object.entries(
      window.platform.apps.find(a => a.name === 'digital-edition')?.pages || {},
    )
      .filter(([k, v]) => v.additions?.theme)
      .reduce((acc, [k, v]) => {
        acc[k.split('/').slice(-1)] = { theme: v.additions.theme, name: k.split('/').slice(-1)[0], type: 'pdf' }
        return acc
      }, {})

    const templatesList = await templateService.listWithTemplateContext()

    const templatesFromBuilder = templatesList.reduce((acc, template) => {
      acc[template.name] = template
      return acc
    }, {})

    templates.value = {
      ...templatesFoundInApp,
      ...templatesFromBuilder,
    }

    loaded.value = true
  })

  return {
    templates,
    loaded,
  }
}
