import {
  useHashTree,
  IGridNode,
  IGridItem,
  IGridItemMetadata,
  useHashList,
  IGridTheme,
  EDirection,
  IScrollState,
} from '@hauru/common'
import { reactiveComputed } from '@vueuse/core'
import { readonly } from 'vue'
import { isBoolean } from 'lodash'

export type IGridNodes = ReturnType<typeof useNodes>

/**
 * Enum for representing offset directions in the same order as CSS
 */
export enum EOffset {
  top = 0,
  right = 1,
  bottom = 2,
  left = 3,
}

export interface IGridStateLimited {
  gridTheme: Partial<IGridTheme>
  idColumn: string
  scroll: IScrollState
}

export interface IGridNodesArgs {
  /**
   * The height of each row in px
   */
  rowHeight: number
}

interface IGridNodesMapped {
  node: IGridNode
  row: number | null

  id: number | string
  index_item: number
  index_row: number | null
}

export interface ICollapsedNodes {
  readonly id: string | number
}

export function useNodes({ rowHeight = 32 }: Partial<IGridNodesArgs> = {}) {
  const hashTree = useHashTree<IGridItem>(true)
  const collapsed = useHashList<{ id: string | number }>({
    allowMultiple: true,
    getKey: item => item.id,
  })

  let state: IGridStateLimited | undefined

  const nodes = Object.assign(hashTree, {
    mapById: {} as Record<string | number, IGridNodesMapped>,
    mapByIndexItem: {} as Record<number, IGridNodesMapped>,
    mapByIndexRow: {} as Record<number, IGridNodesMapped>,
    findNodeByTop,
    findRowByIndex,
    yieldVisibleRange,
    yieldRange,
    yieldNodes,
    calculateDimensions,
    getNextNode,
    /**
     * State of collapsed nodes
     */
    collapsed: {
      ...collapsed,
      add: (id: string | number, recalculate: boolean) => toggleCollapsed(id, true, recalculate),
      remove: (id: string | number) => toggleCollapsed(id, false),
      toggle: toggleCollapsed,
      collapseAll,
      expandAll,
      expandToLevel,
    },
    depth: 0,
    countNodes: 0,
    countItems: 0,
    countRows: 0,
    totalHeight: { value: 0 },
    calculateHeight,
    /**
     * The maximum left offset for the grid (based on the maximum depth of the tree)
     */
    maxLeftOffset: 0,
    /**
     * The maximum right offset for the grid (based on the maximum depth of the tree)
     */
    maxRightOffset: 0,
    // TODO: Move to theme
    rowHeight,
    setRowHeight: (value: number) => (nodes.rowHeight = value),
    getRowsHeight,
    getRowTop: (node: IGridNode, row: number) => node.top + nodes.getRowsHeight(row) + nodes.getNodeOffsetTop(node),
    getNodeOffset,
    /**
     * Get the top offset of an item (node or row)
     * @param node The item to get the offset for
     */
    getNodeOffsetTop: (node: IGridNode) => getNodeOffset(EOffset.top, node),
    /**
     * Get the right offset of an item (node or row)
     * @param node The item to get the offset for
     */
    getNodeOffsetRight: (node: IGridNode) => getNodeOffset(EOffset.right, node),
    /**
     * Get the left offset of an item (node or row)
     * @param node The item to get the offset for
     */
    getNodeOffsetLeft: (node: IGridNode) => getNodeOffset(EOffset.left, node),
    /**
     * Get the bottom offset of an item (node or row)
     * @param node The item to get the offset for
     */
    getNodeOffsetBottom: (node: IGridNode) => getNodeOffset(EOffset.bottom, node),
    getContainerOffset,
    /**
     * Get the top offset of the container
     * @param item Grid metadata item used to calculate the offset
     */
    getContainerOffsetTop: (item?: IGridNode | null) => getContainerOffset(EOffset.top, item),
    /**
     * Get the right offset of the container
     * @param item Grid metadata item used to calculate the offset
     */
    getContainerOffsetRight: (item?: IGridNode | null) => getContainerOffset(EOffset.right, item),
    /**
     * Get the left offset of the container
     * @param item Grid metadata item used to calculate the offset
     */
    getContainerOffsetLeft: (item?: IGridNode | null) => getContainerOffset(EOffset.left, item),
    /**
     * Get the bottom offset of the container
     * @param item Grid metadata item used to calculate the offset
     */
    getContainerOffsetBottom: (item?: IGridNode | null) => getContainerOffset(EOffset.bottom, item),
    setState: (s: IGridStateLimited) => (state = s),
    createTree,

    findSiblingVisibleRowByIndex,
    restoreCollapsed,
  })

  nodes.totalHeight = reactiveComputed(() => ({
    value: (nodes.root.height! || 0) + nodes.getContainerOffsetTop() + nodes.getContainerOffsetBottom(),
  }))

  const rowLeftOffset = (level: number, parentNode: IGridNode | null, metadataNode: IGridItemMetadata | null) => {
    const left = state?.gridTheme?.row?.rowLeftOffset ?? 0
    return typeof left === 'function' ? left({ state: state as any, level, parentNode, metadataNode }) : left
  }

  /**
   * Recursive function that creates a tree of nodes and rows from a flat array of data and calculates the position of each node on screen
   * @param parentNode - The parent node of the current item.
   * @param metadataNode - Metadata of current node or row being added to the tree.
   * @param rows - The rows associated with the current node being added to the tree.
   * @param level - The current level in the tree.
   */
  function createTree(
    parentNode: IGridNode | null,
    metadataNode: IGridItemMetadata | null,
    rows: (string | number)[] | null,
    level = 0,
  ): [number, number] {
    let totalItems = rows?.length ?? 0
    let totalRows = rows?.length ?? 0
    if (level === 0) {
      nodes.mapById = {}
      nodes.mapByIndexItem = {}
      nodes.mapByIndexRow = {}
      nodes.maxLeftOffset = 0
      nodes.maxRightOffset = 0
      nodes.depth = 0
      nodes.countNodes = 0
      nodes.countItems = 0
      nodes.countRows = 0
    }

    if (level > nodes.depth) nodes.depth = level

    const sibling = parentNode?.children?.at(-1) ?? null

    const top = sibling
      ? sibling.top + sibling.height! + (state?.gridTheme?.node?.nodeGap ?? 0)
      : (parentNode?.top ?? 0) + nodes.getContainerOffsetTop(parentNode)
    const right = (parentNode?.right ?? 0) + nodes.getContainerOffsetRight(parentNode)
    const left = (parentNode?.left ?? 0) + nodes.getContainerOffsetLeft(parentNode)

    let item: IGridItem | undefined
    if (metadataNode) {
      const is_virtual = metadataNode.is_virtual ?? false
      item = {
        type: 'node',
        id: metadataNode.id,
        metadata: metadataNode,
        is_expanded: !nodes.collapsed.is(metadataNode.id),
        is_virtual,
        top,
        right,
        left,
        inner_offset: 0, // will be updated later
        height_collapsed: state?.gridTheme?.node?.nodeHeaderHeight ?? 0,
        level,
        index_node: nodes.countNodes,
        index_item: nodes.countItems,
        index_item_first_row: nodes.countItems + (!is_virtual ? 1 : 0),
        index_first_row: nodes.countRows,
        total_items: 0, // will be updated later
        total_rows: 0, // will be updated later
      }

      if (!is_virtual) {
        nodes.countNodes += 1
        nodes.countItems += 1
      }

      if (parentNode) nodes.addChild(parentNode.id, item)
      else nodes.setRoot(item)

      const node = nodes.get(item.id)!

      let orphanRows = []
      let hasNodeChildren = false
      for (const child of metadataNode.children ?? []) {
        if (child.type === 'row') {
          orphanRows.push(child.id)
        } else if (child.type === 'node') {
          if (orphanRows.length) {
            const [childItems, childRows] = createTree(node, null, orphanRows, level + 1)
            totalItems += childItems
            totalRows += childRows
            orphanRows = []
          }
          const [childItems, childRows] = createTree(node, child, null, level + 1)
          totalItems += childItems
          totalRows += childRows
          hasNodeChildren = true
        }
      }
      if (orphanRows.length && hasNodeChildren) {
        const [childItems, childRows] = createTree(node, null, orphanRows, level + 1)
        totalItems += childItems
        totalRows += childRows
      }
      if (orphanRows.length && !hasNodeChildren) {
        node.rows = orphanRows
        totalItems += orphanRows.length
        totalRows += orphanRows.length
        nodes.countItems += orphanRows.length
        nodes.countRows += orphanRows.length
      }

      node.total_items = totalItems
      node.total_rows = totalRows
      node.height = node.height_expanded = nodes.calculateHeight(node)
      if (!node.is_virtual) {
        totalItems++
        item.inner_offset = nodes.getNodeOffset(null, item as IGridNode)
      }
      if (node?.metadata?.initialCollapsed) {
        nodes.collapsed.add(node.metadata.id, false)
      }
    }

    if (rows) {
      item = {
        type: 'node',
        id: !parentNode?.id ? -1 : parentNode.id + '_' + rows[0],
        metadata: null,
        is_virtual: true,
        is_expanded: true,
        top,
        right,
        left,
        inner_offset: 0,
        rows,
        height: nodes.getRowsHeight(rows.length),
        level,
        index_node: nodes.countNodes,
        index_item: nodes.countItems,
        index_item_first_row: nodes.countItems,
        index_first_row: nodes.countRows,
        total_items: totalItems,
        total_rows: totalRows,
      }
      nodes.countItems += rows.length
      nodes.countRows += rows.length

      if (parentNode) nodes.addChild(parentNode.id, item)
      else nodes.setRoot(item)
    }

    if (item && state !== undefined) {
      let node = nodes.get(item.id)!
      nodes.maxLeftOffset = Math.max(
        nodes.maxLeftOffset,
        left +
          (level > 0 ? nodes.getNodeOffsetLeft(node as IGridNode) : 0) +
          rowLeftOffset(level, parentNode, metadataNode),
      )
      nodes.maxRightOffset = Math.max(nodes.maxRightOffset, right + nodes.getNodeOffsetRight(node as IGridNode))

      node = nodes.get(node.id)!
      nodes.mapById[node.id] = nodes.mapByIndexItem[node.index_item] = {
        node,
        row: null,
        id: node.id,
        index_item: node.index_item,
        index_row: null,
      }
      node.rows?.forEach((row, index_row) => {
        nodes.mapById[row] =
          nodes.mapByIndexRow[node.index_first_row + index_row] =
          nodes.mapByIndexItem[node.index_item_first_row + index_row] =
            {
              node,
              row: index_row,
              id: row,
              index_item: node.index_item_first_row + index_row,
              index_row: node.index_first_row + index_row,
            }
      })
    }

    return [totalItems, totalRows]
  }

  /**
   * Calculates the total height of n rows given a certain row height and row gap.
   *
   * @param n - The number of rows
   */
  function getRowsHeight(n?: number) {
    return !n || n <= 0 ? 0 : n * nodes.rowHeight + (n - 1) * (state?.gridTheme?.node?.rowGap ?? 0)
  }

  /**
   * Retrieves the container offset in the specified direction.
   *
   * @param direction - The direction in which to get the container offset.
   * @param item - The grid item metadata, optional.
   */
  function getContainerOffset(direction: EOffset, item?: IGridNode | null) {
    const offset =
      item && !item.is_virtual ? getNodeOffset(direction, item) : state?.gridTheme?.node?.containerOffset ?? 0
    return typeof offset !== 'number' ? offset[direction] : offset
  }

  /**
   * Retrieves the node offset in the specified direction.
   *
   * @param direction - The direction in which to get the container offset.
   * @param node - The grid item metadata or grid item, optional.
   */
  function getNodeOffset(direction: null, node: IGridNode): number | [number, number, number, number]
  function getNodeOffset(direction: EOffset, node: IGridNode): number
  function getNodeOffset(direction: EOffset | null, node: IGridNode) {
    if (node.is_virtual) return 0
    const offsetFunc = state?.gridTheme?.node?.nodeOffset ?? 0
    const offset = typeof offsetFunc === 'function' ? offsetFunc(node) : offsetFunc
    const result = typeof offset !== 'number' && typeof direction === 'number' ? offset[direction] : offset
    return typeof result === 'function' ? result(node) : result
  }

  /**
   * Generator function that yields parent nodes in a grid tree structure, starting from the root.
   * If `yieldSelf` is set to true, the function also yields the initial node if it is not virtual.
   *
   * @param node - The initial node from which to start the traversal.
   * @param yieldSelf - A flag determining whether to yield the initial node or not.
   */
  function* yieldParentsFirst(node: IGridNode, yieldSelf = false): Generator<[IGridNode, number | undefined]> {
    if (node.parent) yield* yieldParentsFirst(node.parent, true)

    if (yieldSelf && !node?.is_virtual) yield [node, undefined]
  }

  /**
   * Generator function that yields rows from a node in a grid structure. The node's rows are only yielded if
   * the node is expanded. If `from` and `to` arguments are provided, only rows within that range (inclusive) are yielded.
   *
   * @param node - The node from which to yield rows.
   * @param from - The start index from which to yield rows. If not provided, yields from the first row.
   * @param to - The end index up to which to yield rows. If not provided, yields up to the last row.
   */
  function* yieldRows(node: IGridNode, from?: number, to?: number): Generator<[IGridNode, number]> {
    if (node.rows && node.is_expanded) {
      for (let r = from ?? 0; r <= (to ?? node.rows.length - 1); r++) {
        yield [node, r]
      }
    }
  }

  /**
   * Generator function that yields nodes in a grid structure, from a specified node (or the root), up to a specified node (or the last node)
   *
   * @param from - The id of the node from which to start the traversal. If not provided, the traversal starts from the root node.
   * @param to - The id of the node at which to stop the traversal. If not provided, the traversal continues until all eligible nodes have been yielded.
   * @param deep - A flag determining whether to yield child nodes.
   */
  function* yieldNodes(
    from?: number | string,
    to?: number | string,
    deep = true,
    recursive = false,
  ): Generator<IGridNode> {
    const fromNode = from ? nodes.get(from) : nodes.root

    if (!fromNode) return

    yield fromNode as IGridNode

    if (fromNode.id === to) return

    if (deep && fromNode.is_expanded) {
      for (const child of fromNode?.children ?? []) {
        yield* yieldNodes(child.id, to, deep, true)
      }
    }

    if (!recursive && fromNode.next) yield* yieldNodes(fromNode.next.id, to, deep)

    let parent = fromNode.parent

    if (recursive) return

    while (parent) {
      if (parent?.next) {
        yield* yieldNodes(parent.next.id, to, deep)
        break
      }
      parent = parent.parent
    }
  }

  /**
   * Returns the next sibling node of the given node in a grid structure. The next node is the one
   * that would be visited after the current node in a non-deep traversal of the grid.
   *
   * @param currentNode - The node from which to find the next sibling node.
   */
  function getNextNode(currentNode: IGridNode) {
    let count = 0
    let node
    for (node of nodes.yieldNodes(currentNode.id, undefined, false)) {
      if (++count === 2) break
    }
    if (count !== 2 || !node) return

    return node
  }

  /**
   * Generator function that yields a range of a grid structure. The range is determined by two
   * node identifiers (fromNode, toNode) and optionally two row indices (fromRow, toRow). The function yields all nodes
   * between and including the two specified nodes, as well as their rows. If the same node id is provided for both fromNode
   * and toNode, then the function will yield that single node (if it's not virtual) and its rows in the specified range.
   *
   * @param fromNode - The id of the node from which to start yielding nodes and rows.
   * @param fromRow - The index of the row from which to start yielding rows for the fromNode.
   * @param toNode - The id of the node at which to stop yielding nodes and rows. If not provided, the function yields up to the last node.
   * @param toRow - The index of the row at which to stop yielding rows for the toNode. If not provided, the function yields up to the last row for the toNode.
   */
  function* yieldRange(
    fromNode: number | string,
    fromRow: number | undefined,
    toNode?: number | string,
    toRow?: number | undefined,
  ): Generator<[IGridNode, number | undefined]> {
    const node = nodes.get(fromNode)

    // console.log('treeGridRangeView', fromNode, fromRow, toNode, toRow, node)

    if (!node) return

    yield* yieldParentsFirst(node)

    for (const node of yieldNodes(fromNode, toNode)) {
      if (!node?.is_virtual) yield [node, undefined]
      yield* yieldRows(node, fromNode === node.id ? fromRow : undefined, toNode === node.id ? toRow : undefined)
    }
  }

  function findNearestCollapsedParent(node: IGridNode) {
    let parent: IGridNode | null = node
    while (parent) {
      if (!parent.is_expanded) return parent
      parent = parent.parent
    }
  }

  function findSiblingVisibleRowByIndex(index: number, direction: EDirection) {
    if (direction === EDirection.left || direction === EDirection.right) return index

    const nextIndex = direction === EDirection.down ? index + 1 : index - 1
    if (nextIndex < 0 || nextIndex >= nodes.getRoot().total_rows) return undefined

    const [node] = findRowByIndex(nextIndex)

    console.log('findSiblingVisibleRowByIndex', index, nextIndex, direction, node)

    if (!node) return undefined

    const nearestCollapsedParent = findNearestCollapsedParent(node)

    if (!nearestCollapsedParent) return nextIndex
    console.log('nearestCollapsedParent', nearestCollapsedParent)
    const nearestIndex =
      direction === EDirection.down
        ? nearestCollapsedParent.index_first_row + nearestCollapsedParent.total_rows - 1
        : nearestCollapsedParent.index_first_row
    return findSiblingVisibleRowByIndex(nearestIndex, direction)
  }

  /**
   * This function is used to find a row in a grid by a given index.
   *
   * @param index - The index of the row to be found.
   * @param node - The node from which to start the search.
   * @returns A tuple where the first element is the found node or undefined and the second element is the relative index or undefined.
   */
  function findRowByIndex(index: number): [IGridNode | undefined, number | undefined] {
    const { node, row } = nodes.mapByIndexRow[index] || {}
    return [node, row ?? undefined]
  }

  /**
   * Finds the index of a row given its top coordinate.
   *
   * @param node - The node in which to find the row.
   * @param top - The top coordinate of the row in px
   */
  function findRowByTop(node: IGridNode, top: number) {
    if (node.rows) {
      const rowTop = Math.max(top - node.top - nodes.getNodeOffsetTop(node), 0)
      return Math.floor(rowTop / (nodes.rowHeight + (state?.gridTheme?.node?.rowGap ?? 0)))
    }
  }

  /**
   * Finds a node and its row index based on a given top coordinate.
   *
   * @param node - The node from which to start the search.
   * @param top - The top coordinate based on which to find the node.
   * @returns A tuple where the first element is the found node and the second is the index of a row within the node, or `undefined` if no row is found, or `null` if the found node is the virtual root.
   */
  function findNodeByTop(node: IGridNode, top: number): [IGridNode, number | undefined | null] {
    for (const child of node.children) {
      if (child.is_expanded && child.top <= top && child.top + child.height! >= top) {
        if (child.top + child.height! - nodes.getNodeOffsetBottom(child) <= top) return [child, undefined]
        else return child?.children?.length ? findNodeByTop(child, top) : [child, findRowByTop(child, top)]
      } else if (child.top > top || child.top + child.height_collapsed! >= top) return [child, undefined]
    }
    return node.id === -1 ? [node, findRowByTop(node, top)] : [node, null]
  }

  /**
   * Generator function that yields the visible range of a grid structure. The range is determined by a top
   * coordinate and an offset. The function first finds the node and its row index at the top coordinate. If the found
   * node is the virtual root, the function yields the next sibling node instead. The function then yields nodes and their
   * rows starting from the found node, up to the node and row where the current top coordinate exceeds the sum of the top
   * coordinate and the offset. The generator stops if the range is out of the grid or the root node is not defined.
   *
   * @param top - The top coordinate from which to start yielding nodes and rows.
   * @param offset - The offset from the top coordinate up to which to yield nodes and rows.
   * @yields A tuple where the first element is a grid node and the second is a row index or undefined if the yielded element is a node, or null if the node is the virtual root.
   */
  function* yieldVisibleRange(top: number, offset: number): Generator<[IGridNode, number | undefined | null]> {
    if (top + offset < 0 || top > nodes.root.top + nodes.root.height! || !nodes.root?.id) return

    let [topNode, topRow] = findNodeByTop(nodes.root as IGridNode, top)
    // console.log('topNode', topNode, topRow)

    if (topRow === null && !topNode.rows?.length) {
      // yield [topNode, topRow]
      const nextNode = nodes.getNextNode(topNode)
      // console.log('nextNode return', nextNode)
      if (!nextNode) return
      topNode = nextNode
      topRow = undefined
      // console.log('topNodeNext', topNode, topRow)
    }

    for (const [node, row] of nodes.yieldRange(topNode.id, topRow ?? undefined)) {
      if (nodes.getRowTop(node, row ? row + 1 : 0) > top + offset) return
      yield [node, row]
    }
  }

  /**
   * Calculates the height of a given grid node in px. If the node is not expanded, it returns the node's collapsed height.
   *
   * @param node - The node for which to calculate the height.
   */
  function calculateHeight(node: IGridNode) {
    if (!node.is_expanded) return node.height_collapsed

    let height = node.is_virtual ? 0 : nodes.getNodeOffsetTop(node) + nodes.getNodeOffsetBottom(node)
    height += node.rows?.length
      ? nodes.getRowsHeight(node.rows?.length)
      : ((node?.children?.length ?? 0) - 1) * (state?.gridTheme?.node?.nodeGap ?? 0)

    for (const child of node?.children ?? []) {
      height += child?.height ?? 0
    }
    return height
  }

  /**
   * Recursively calculates the top coordinates and heights of a given node and its children in a grid.
   *
   * @param node - The node for which to calculate the top coordinate and the height. If no node is passed, the function uses the root node.
   */
  function calculateDimensions(node = nodes.getRoot() as IGridNode) {
    node.top = node.prev
      ? node.prev.top + node.prev.height! + (state?.gridTheme?.node?.nodeGap ?? 0)
      : (node.parent?.top ?? 0) + nodes.getContainerOffsetTop(node.parent)

    for (const child of node?.children ?? []) {
      calculateDimensions(child)
    }

    node.height = calculateHeight(node)
  }

  /**
   * Adds indicated item to the collapsed list if the item is not already there, otherwise removes it
   *
   * @param id The id of the item to collapse or expand
   * @param force If true, the item will be collapsed, if false, the item will be epxanded
   */
  function toggleCollapsed(id: number | string, force?: boolean, recalculate = true) {
    const item = nodes.get(id)

    if (!item) {
      collapsed.toggle({ id }, force)
    } else {
      item.is_expanded = isBoolean(force) ? !force : !item.is_expanded
      item.height = item.is_expanded ? item.height_expanded : item.height_collapsed
      collapsed.toggle({ id }, force)
    }

    if (recalculate) calculateDimensions()
  }

  /**
   * Collapse all nodes in the tree up to the indicated depth
   *
   * @param depth Depth to collapse to, collapses all nodes if not specified
   */
  function collapseAll(depth?: number, items = nodes.root.children, level = 0) {
    state?.scroll.setTop(0)
    if (depth === undefined) {
      for (let i = 0; i < nodes.depth; i++) {
        collapseAll(i)
      }
      return
    }

    for (const item of items ?? []) {
      if (level === depth && !item.is_virtual) toggleCollapsed(item.id, true, false)
      if (level < depth) {
        collapseAll(depth, item.children, level + 1)
      }
    }
    if (level === 0) calculateDimensions()
  }

  /**
   * Expand all nodes in the tree up to the indicated depth
   *
   * @param depth Depth to expand to
   */
  function expandAll(depth = 0, items = nodes.root.children, level = 0) {
    for (const item of items ?? []) {
      toggleCollapsed(item.id, false, false)
      if (level < depth) {
        expandAll(depth, item.children, level + 1)
      }
    }
    if (level === 0) calculateDimensions()
  }

  /**
   * Expands all nodes up to a certain depth level in a grid and then collapses nodes at that level.
   *
   * @param depth - The depth up to which to expand the nodes and at which to collapse the nodes. It is zero-based, so 0 refers to the root level.
   */
  function expandToLevel(depth: number) {
    console.log('depth', depth)
    expandAll(depth)
    collapseAll(depth)
  }

  function restoreCollapsed(storedCollapsedList: ICollapsedNodes[], items = nodes.collapsed.list) {
    const storedSet = new Set(storedCollapsedList.map(item => item.id))
    const itemsSet = new Set(items.map(item => item.id))
    const uniqueItemsSet = new Set([...storedCollapsedList.map(item => item.id), ...items.map(item => item.id)])

    for (const item of uniqueItemsSet) {
      const isItemInStoredList = storedSet.has(item)
      const isItemInCurrentList = itemsSet.has(item)
      if (!isItemInStoredList) {
        toggleCollapsed(item, false, true)
      } else if (!isItemInCurrentList) {
        toggleCollapsed(item, true, true)
      }
    }
  }

  return readonly(nodes)
}
