<template>
  <lfr-table :data="data" :options="options" />
</template>

<style>
.lfr-table-monthly-perfs td:last-child {
  color: rgb(var(--primary));
}
.lfr-table-monthly-perfs table {
  table-layout: auto;
}
.lfr-table-monthly-perfs td {
  padding-left: 0;
  padding-right: 0;
}
</style>

<script setup>
const { data, options } = defineProps(['data', 'options'])
</script>

<script>
export default {
  api: {
    unit: {
      label: 'Unit',
      default: () => '%',
    },
    digit: {
      label: 'Decimals',
      default: () => 2,
      attrs: {
        type: 'number',
      },
    },
    limit: {
      label: 'Limit',
      attrs: {
        type: 'number',
      },
    },
    highlightFirstLine: {
      label: 'Highlight first line',
      default: () => true,
      attrs: {
        type: 'checkbox',
        class: 'none',
      },
    },
    highlightFirstColumn: {
      label: 'Highlight first column',
      default: () => true,
      attrs: {
        type: 'checkbox',
        class: 'none',
      },
    },
  },
}
</script>
