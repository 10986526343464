import config from '../../../config'

export function useManagementComments() {
  const getManagementComment = async variables => {
    const resp = await axios({
      url: config.graphqlEndpoint,
      method: 'post',
      data: {
        variables,
        query: `query ManagementComment($fundId: String!, $date: Date!, $period: String!, $lang: String!) {
          rawData {
            managementComment(fundId: $fundId, date: $date, period: $period, lang: $lang) {
              id
              period
              date
              fund_id
              lang
              value
            }
          }
        }`,
      },
    })
    return resp.data.data.rawData.managementComment
  }

  const updateManagementComment = async variables => {
    const resp = await axios({
      url: config.graphqlEndpoint,
      method: 'post',
      data: {
        variables,
        query: `mutation UpdateManagementCommentValueById($id: Int!, $value: String!) {
          updateManagementCommentValueById(id: $id, value: $value) {
            id
            period
            date
            fund_id
            lang
            value
          }
        }`,
      },
    })
    return resp.data.data.updateManagementCommentValueById
  }

  const createManagementComment = async variables => {
    const resp = await axios({
      url: config.graphqlEndpoint,
      method: 'post',
      data: {
        variables,
        query: `mutation CreateManagementComment($date: Date!, $lang: String!, $value: String!, $fundId: String!, $period: String!) {
          createManagementComment(date: $date, lang: $lang, value: $value, fundId: $fundId, period: $period) {
            id
            period
            date
            fund_id
            lang
            value
          }
        }`,
      },
    })
    return resp.data.data.createManagementComment
  }
  return {
    getManagementComment,
    updateManagementComment,
    createManagementComment,
  }
}
