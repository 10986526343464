<template>
  <div qa-list-item class="flex cursor-pointer select-none" :class="selectItemTheme?.idle">
    <!-- ^^ nx-list-item -->

    <slot></slot>
  </div>
</template>

<script setup lang="ts">
import { useTheme } from '@hauru/common'
interface IProps {
  /**
   * Allows to manually bypass the theme set as default, among the themes provided by the theme config
   */
  theme?: string
  /**
   * The type of the select list item among the types defined in the theme config
   */
  type?: string
}

const props = withDefaults(defineProps<IProps>(), {})

const themeConfig = useTheme()

const selectItemTheme = themeConfig.computedThemeType('listItem', props)
</script>
