import { onMounted, onUnmounted, ref } from 'vue'
import { commandrSDK } from '../../../features/commandr'

export function useCommands() {
  const commands = ref([])

  const onUpdate = snap => {
    commands.value = snap
  }

  onMounted(() => commandrSDK.on('/data/commands', 'value', onUpdate))
  onUnmounted(() => commandrSDK.off('/data/commands', 'value'))

  return {
    commands,
  }
}
