import config from '../../../config'
import { useGraphQL } from '../../../utils/axios'

export function useDetails() {
  const getDetails = async ({ fundId, isinShare, benchmarkId, date, dimensions, domain, asof, compareDate }) => {
    const { sendQuery } = useGraphQL(config)
    const query = `#graphql
    query Details($shareIds: FuidInput!, $date: Domain!, $compareDate: Domain!, $dimensions: [String], $asof: Date, $domain: Domain!) {
      inventory {
        allocation(fuid: $shareIds, domain: $date, dimensions: $dimensions, asof: $asof) {
          date
          name
          fuid
          weight
          valuation
          quantity
          price
          axis
        }
        allocationStart: allocation(fuid: $shareIds, domain: $compareDate, dimensions: $dimensions, asof: $asof) {
          fuid
          priceStart: price
        }
        contribution(fuid: $shareIds, dimensions: $dimensions, domain: $domain, asof: $asof) {
          fuid
          axis
          contribution
          pnlOrFeesPerFund
          pnlOrFeesPerShare
          fuid
          name
          startDate
          endDate
        }
      }
    }
    `
    const shareIds = benchmarkId
      ? [
          {
            fuid: isinShare,
            name: 'fund',
          },
          {
            fuid: benchmarkId,
            name: 'benchmark',
          },
        ]
      : [
          {
            fuid: isinShare,
            name: 'fund',
          },
        ]
    const variables = {
      shareIds,
      date,
      asof,
      compareDate,
      dimensions,
      domain,
    }
    const resp = await sendQuery(query, variables)
    // Do maps instead of doing .find(fuid) to limit iteartion over arrays
    const contributionMap = new Map()
    resp.inventory.contribution.forEach(row => {
      contributionMap.set(row.fuid, row)
    })
    const allocationStartMap = new Map()
    resp.inventory.allocationStart.forEach(row => {
      allocationStartMap.set(row.fuid, row)
    })
    const valueKeys = [
      'valuation',
      'weight',
      'contribution',
      'quantity',
      'price',
      'pnlOrFeesPerFund',
      'pnlOrFeesPerShare',
    ]
    const alloc = resp.inventory.allocation.map(row => {
      const newRow = {
        ...row,
        ...contributionMap.get(row.fuid),
        ...allocationStartMap.get(row.fuid),
      }
      valueKeys.forEach(key => {
        if (!newRow[key]) {
          newRow[`${key}_fund`] = 0
          newRow[`${key}_benchmark`] = 0
          newRow[`${key}_diff`] = 0
          return
        }
        newRow[`${key}_fund`] = newRow[key].fund
        newRow[`${key}_benchmark`] = newRow[key].benchmark
        newRow[`${key}_diff`] = newRow[key].fund - newRow[key].benchmark
      })
      // newRow.priceEnd / newRow.priceStart - 1
      newRow.price_change = newRow?.priceStart?.fund ? newRow.price.fund / newRow.priceStart.fund - 1 : '-'
      newRow.quantity = row.quantity.fund
      return newRow
    })
    return alloc
  }
  const getDetailsData = async params => {
    console.log('getDetailsData')
    const domain = params.domain.split('|')
    // let resp = await axios({
    //   url: config.graphqlEndpoint,
    //   method: 'post',
    //   data: {
    //     variables: { ...params, domainEnd: `${domain[1]}|${domain[1]}`, domainEvol: params.domain },
    //     query: `query DetailsQuery(
    //       $isinShare: String!
    //       $asof: Date
    //       $domainEnd: Domain!
    //       $domainEvol: Domain!
    //     ) {
    //       businessData {
    //         share(shareId: $isinShare, asof: $asof) {
    //           asof
    //           axis_of_analysis
    //           slug
    //           benchmark {
    //             id
    //             label
    //           }
    //           allocEnd: allocation(domain: $domainEnd) {
    //             date
    //             asof
    //             fuid
    //             name
    //             axis
    //             weight_fund
    //             weight_benchmark
    //             weight_diff_benchmark
    //             weight_active_share_benchmark
    //             quantity
    //             price
    //           }
    //           allocEvol: allocation(domain: $domainEvol) {
    //             fuid
    //             price_diff: price
    //             axis
    //           }
    //           contribution(domain: $domainEvol) {
    //             fuid
    //             name
    //             contribution_fund
    //             contribution_benchmark
    //             pnlOrFeesPerFund_fund
    //             pnlOrFeesPerFund_benchmark
    //             contribution_diff_benchmark
    //             axis
    //           }
    //         }
    //       }
    //     }`,
    //   },
    // })
    // const newShare = resp.data.data.businessData.share
    // const alloc = newShare.allocEvol.reduce((acc, a) => {
    //   acc[a.fuid] = {
    //     ...a,
    //     ...newShare.allocEnd.find(el => el.fuid === a.fuid),
    //     ...newShare.contribution.find(el => el.fuid === a.fuid),
    //   }
    //   // the evol query returns price_diff which is the diffrence between starting price and ending price
    //   // we calculate price_change which is the percentage of the evolution
    //   acc[a.fuid].price_change = acc[a.fuid].price_diff / (acc[a.fuid].price - acc[a.fuid].price_diff)
    //   return acc
    // }, {})
    // newShare.allocation = Object.values(alloc)
    // delete newShare.allocEnd
    // delete newShare.allocEvol
    // delete newShare.contribution
    // //! should i keep ? could be useful ?
    // return { ...resp.data.data.businessData, share: newShare }
  }
  const getTimeSeries = async ({ fuid, domain, isinShare, benchmarkId }) => {
    const { sendQuery } = useGraphQL(config)
    const query = `#graphql
    query Contribution($instrumentFuid: FuidInput!, $domain: Domain!, $ids: FuidInput!) {
      inventory {
        instrumentHistory(instrumentFuid: $instrumentFuid, domain: $domain, fuid: $ids) {
          valuation
          price
          contribution
          pnlOrFeesPerFund
          weight
          fuid
          name
          quantity
          date
        }
      }
    }`
    const ids = benchmarkId
      ? [
          {
            fuid: isinShare,
            name: 'fund',
          },
          {
            fuid: benchmarkId,
            name: 'benchmark',
          },
        ]
      : [
          {
            fuid: isinShare,
            name: 'fund',
          },
        ]
    const variables = {
      ids,
      instrumentFuid: fuid,
      domain,
    }
    const resp = await sendQuery(query, variables)
    const valueKeys = ['valuation', 'weight', 'contribution', 'pnlOrFeesPerFund', 'quantity']
    return resp.inventory.instrumentHistory.map(d => {
      valueKeys.forEach(key => {
        d[`${key}_fund`] = d[key].fund
        d[`${key}_benchmark`] = d[key].benchmark
        d[`${key}_diff`] = d[key].fund - d[key].benchmark
      })
      d.price = d?.price?.fund
      return d
    })

    // console.log('getTimeSeries')
    // let resp = await axios({
    //   url: config.graphqlEndpoint,
    //   method: 'post',
    //   data: {
    //     variables: params,
    //     query: `query TimeSeriesQuery(
    //       $domain: Domain!
    //       $isinShare: String!
    //       $asof: Date
    //       $fuid: String!
    //     ) {
    //       businessData {
    //         share(shareId: $isinShare, asof: $asof) {
    //           timeSeries {
    //             priceSeries(domain: $domain, fuid: $fuid) {
    //               date
    //               price
    //               quantity
    //             }
    //             weightSeries(domain: $domain, fuid: $fuid) {
    //               date
    //               weight_fund
    //               weight_benchmark
    //             }
    //             contributionSeries(domain: $domain, fuid: $fuid) {
    //               date
    //               contribution_fund
    //               contribution_benchmark
    //               pnlOrFeesPerFund_fund
    //               pnlOrFeesPerFund_benchmark
    //             }
    //           }
    //         }
    //       }
    //     }
    //     `,
    //   },
    // })
    // return resp.data.data.businessData.share.timeSeries
  }
  return {
    getDetailsData,
    getTimeSeries,
    getDetails,
  }
}
