<template>
  <!-- nx-scroll-content -->
  <div
    qa-scroll-content
    :style="{ height: props.state.containerHeight + 'px', width: props.state.containerWidth + 'px' }"
    ref="container"
  >
    <!-- vertical scrollbar -->
    <div
      qa-vertical-scrollbar
      v-if="props.state.containerHeight < props.state.contentHeight && props.state.showScrollbars"
      class="pointer-events-auto absolute right-0 top-0 w-[10px] rounded-full bg-black/[.28] shadow-[inset_0_0_0_1px_rgba(255,255,255,0.85)] hover:bg-black/40"
      :style="{ height: scrollbarHeight + 'px', transform: `translateY(${scrollbarTopPosition}px)` }"
      @pointerdown="startScrollbarDragY"
    ></div>
    <!-- horizontal scrollbar -->
    <div
      qa-horizontal-scrollbar
      v-if="props.state.containerWidth < props.state.contentWidth && props.state.showScrollbars"
      class="pointer-events-auto absolute bottom-0 left-0 h-[10px] rounded-full bg-black/[.28] shadow-[inset_0_0_0_1px_rgba(255,255,255,0.85)] hover:bg-black/40"
      :style="{ width: scrollbarWidth + 'px', transform: `translateX(${scrollbarLeftPosition}px)` }"
      @pointerdown="startScrollbarDragX"
    ></div>
  </div>
</template>

<script setup lang="ts">
/**
 * @component Adds horizontal and vertical scrollbars to a container element
 */
/* eslint-disable vue/no-mutating-props */
/* eslint-disable vue/no-setup-props-destructure */
import { IMoveEvent, IScrollState, useMove } from '@hauru/common'
import { computed, reactive, ref } from 'vue'

interface IProps {
  /**
   * The current scroll state of the container element
   */
  state: IScrollState
}

const props = withDefaults(defineProps<IProps>(), {})

const emit = defineEmits<{
  (e: 'scroll-start'): void
  (e: 'scroll-end'): void
}>()

const container = ref<HTMLElement | null>(null)

const scrollbarHeight = computed(() => {
  return Math.max(
    (props.state.containerHeight / props.state.contentHeight) * props.state.containerHeight,
    props.state.minScrollbarSize,
  )
})

const scrollbarWidth = computed(() => {
  return Math.max(
    (props.state.containerWidth / props.state.contentWidth) * props.state.containerWidth,
    props.state.minScrollbarSize,
  )
})

const showScrollbarY = computed(() => {
  return props.state.containerHeight < props.state.contentHeight
})

const showScrollbarX = computed(() => {
  return props.state.containerWidth < props.state.contentWidth
})

const scrollPadding = computed(() => {
  return showScrollbarX.value && showScrollbarY ? 10 : 0
})

const initial = reactive({
  top: 0,
  left: 0,
})

const scrollbarTopPosition = computed(() => {
  return (
    (props.state.containerHeight - scrollbarHeight.value - scrollPadding.value) *
    (props.state.top / (props.state.contentHeight - props.state.containerHeight))
  )
})

const scrollbarLeftPosition = computed(() => {
  return (
    (props.state.containerWidth - scrollbarWidth.value) *
    (props.state.left / (props.state.contentWidth - props.state.containerWidth))
  )
})

const moveY = useMove({
  onMove: updateScrollbarY,
  onMoveEnd: () => {
    emit('scroll-end')
  },
})

/**
 * Update the vertical scrollbar position when it's being dragged
 * @param deltaX The horizontal offset
 * @param deltaY The vertical offset
 */
function updateScrollbarY({ deltaY }: IMoveEvent) {
  const scrollbarTop = initial.top + deltaY
  props.state.setTop(
    (props.state.contentHeight - props.state.containerHeight) *
      (scrollbarTop / (props.state.containerHeight - scrollbarHeight.value - scrollPadding.value)),
  )
}

/**
 * Start dragging the vertical scrollbar when a pointerdown event is triggered.
 * @param e - The pointer event
 */
function startScrollbarDragY(e: PointerEvent) {
  emit('scroll-start')
  initial.top = scrollbarTopPosition.value
  moveY.moveStart(e)
}

const moveX = useMove({
  onMove: updateScrollbarX,
  onMoveEnd: () => {
    emit('scroll-end')
  },
})

/**
 * Update the horizontal scrollbar position when it's being dragged
 * @param deltaX The horizontal offset
 */
function updateScrollbarX({ deltaX }: IMoveEvent) {
  const scrollbarLeft = initial.left + deltaX
  props.state.setLeft(
    (props.state.contentWidth - props.state.containerWidth) *
      (scrollbarLeft / (props.state.containerWidth - scrollbarWidth.value)),
  )
}

/**
 * Start dragging the horizontal scrollbar when a pointerdown event is triggered.
 * @param e - The pointer event
 */
function startScrollbarDragX(e: PointerEvent) {
  emit('scroll-start')
  initial.left = scrollbarLeftPosition.value
  moveX.moveStart(e)
}
</script>
