<template>
  <!-- :class="{ '!table-auto': _props._options.auto }" -->
  <builder-block v-bind="{ data, options, context }">
    <div class="characteristics-container flex flex-col">
      <template v-for="key in options.values">
        <slot
          name="row"
          :k="key"
          :label="translate(key)"
          :value="data[key]"
          :formattedValue="formatValue(key, data[key])"
        >
          <slot name="ms_stars" v-if="key === 'ms_stars'" :k="key" :label="translate(key)" :value="data[key]">
            <div class="characteristics-row flex flex-row gap-2" :key="key">
              <span class="characteristics-label font-bold">{{ translate(key) }}</span>
              <span class="characteristics-value">{{ `${data[key]} ${translate('stars')}` }}</span>
            </div>
          </slot>
          <slot :name="key" :k="key" :label="translate(key)" :value="data[key]" v-else>
            <div class="characteristics-row flex flex-row gap-2" :key="key">
              <span class="characteristics-label">{{ translate(key) }}</span>
              <span class="characteristics-value" v-html="formatValue(key, data[key])"></span>
            </div>
          </slot>
        </slot>
      </template>
    </div>
  </builder-block>
</template>
<script setup lang="ts">
import { computed } from 'vue'
import { characteristics as api } from '../composables/builderOptions'
import { characteristics as story } from './stories'
import { formatFactory } from '../lib/format'
import useTranslations from '../composables/translations'

const props = defineProps(['data', 'options', 'context'])
// @ts-ignore props should be better defined
const { translate } = useTranslations(props)

const currencyKeys = ['aumShare', 'aumFund', 'nav']
const formatCurrency = computed(() =>
  formatFactory({ unit: props.data.fundCurrency || 'EUR', digit: 2, lang: props.context.variables.lang }),
)
function formatText(value: string) {
  return translate.value(value)?.replaceAll('\n', '<br>')
}
const formatValue = computed(() => (key: string, value: any) => {
  if (currencyKeys.includes(key)) {
    return formatCurrency.value(value)
  }
  if (typeof value === 'string') {
    return formatText(value)
  }
  if (Array.isArray(value)) {
    return value.map(formatText).join('<br>')
  }
  return value
})
</script>
<script lang="ts">
export default {
  api,
  story,
  styles: {
    'characteristics-container': `.characteristics-label::after {
  content: ':';
  margin-left: 2px;
}`,
    'characteristics-row': '',
    'characteristics-label': 'font-weight: bold;',
    'characteristics-value': '',
  },
}
</script>
<style scoped></style>
