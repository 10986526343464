import { onMounted, onUnmounted, ref } from 'vue'
import dataReportService from '../../../services/DataReportService'

export function useFiles(options = { limit: 100 }) {
  const files = ref([])
  const loaded = ref(false)
  let t = null
  onMounted(async () => {
    files.value = (
      await dataReportService.run('drFiles', options, { preProcess: false, postProcess: false })
    ).data.result.businessData.files
    loaded.value = true
    t = setInterval(refresh, 30000)
  })

  onUnmounted(() => {
    clearInterval(t)
  })

  const refresh = async () => {
    files.value = (
      await dataReportService.run('drFiles', options, { preProcess: false, postProcess: false })
    ).data.result.businessData.files
  }

  return {
    files,
    loaded,
  }
}
