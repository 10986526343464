const defaultContext = {
  translations: {
    name: 'Name',
  },
  variables: {
    lang: 'en',
  },
  node: {},
}

export const bar = {
  props: {
    options: {
      x: 'key',
      y: 'value',
      horizontal: true,
      unit: '%',
      digit: 1,
    },
    data: [
      { key: 'Category 1', value: 0.5, category: 'Fund' },
      { key: 'Category 2', value: 0.3, category: 'Fund' },
      { key: 'Category 3', value: 0.2, category: 'Fund' },
      { key: 'Category 1', value: 0.42, category: 'Benchmark' },
      { key: 'Category 2', value: 0.01, category: 'Benchmark' },
      { key: 'Category 3', value: 0.24, category: 'Benchmark' },
      // { key: 'Category 4', value: 0.5, category: 'Fund' },
      // { key: 'Category 5', value: 0.3, category: 'Fund' },
      // { key: 'Category 6', value: 0.2, category: 'Fund' },
      // { key: 'Category 4', value: 0.42, category: 'Benchmark' },
      // { key: 'Category 5', value: 0.01, category: 'Benchmark' },
      // { key: 'Category 6', value: 0.24, category: 'Benchmark' },
      // { key: 'Category 7', value: 0.5, category: 'Fund' },
      // { key: 'Category 8', value: 0.3, category: 'Fund' },
      // { key: 'Category 9', value: 0.2, category: 'Fund' },
      // { key: 'Category 7', value: 0.42, category: 'Benchmark' },
      // { key: 'Category 8', value: 0.01, category: 'Benchmark' },
      // { key: 'Category 9', value: 0.24, category: 'Benchmark' },
    ],
    context: defaultContext,
  },
}

export const pie = {
  props: {
    options: {
      x: 'key',
      y: 'value',
      unit: '%',
      digit: 2,
      legend: true,
    },
    data: [
      { key: 'Category 1', value: 0.5 },
      { key: 'Category 2', value: 0.3 },
      { key: 'Category 3', value: 0.2 },
      { key: 'Category 4', value: 0.5 },
      { key: 'Category 5', value: 0.3 },
      { key: 'Category 6', value: 0.2 },
    ],
    context: defaultContext,
  },
}

function makePerfArray(category: string) {
  let date = new Date('2021-01-01')
  let nav = 100
  let trend = 0 // Initialize trend
  let step = 0 // Step counter

  return Array.from({ length: 100 }, (_, i) => {
    date = new Date(date.setDate(date.getDate() + 1))

    // Adjust trend based on current step
    if (step === 0) {
      trend += 0.2 + (Math.random() - 0.5) * 0.2 // Slightly up
      if (i % 33 === 0) step++ // Move to next step after 33 iterations
    } else if (step === 1) {
      trend += 0.5 + (Math.random() - 0.2) * 2 // A lot up
      if (i % 33 === 0) step++ // Move to next step after 33 iterations
    } else {
      trend += (Math.random() - 0.5) * 2 - 0.5 // A lot down
    }

    nav += trend / 100 + (Math.random() - 0.5) * 0.5 // Add trend and noise
    return {
      date,
      value: nav,
      category,
    }
  }).slice(0, -1)
}
export const line = {
  props: {
    options: {
      x: 'date',
      y: 'value',
      category: 'category',
      digit: 0,
      legend: true,
    },
    data: makePerfArray('Fund').concat(makePerfArray('Benchmark')),
    context: defaultContext,
  },
}

export const characteristics = {
  props: {
    options: {
      values: ['Fund Name', 'Fund Manager', 'Category', 'Benchmark', 'Fund Inception'],
    },
    data: {
      'Fund Name': 'Fund XYZ',
      'Fund Manager': 'Manager ABC',
      Category: 'Bonds',
      Benchmark: '50% Composite Index and 50% MSCI Europe',
      'Fund Inception': '02 February 2016',
    },
    context: defaultContext,
  },
}

export const table = {
  props: {
    options: {},
    context: defaultContext,
    data: [
      { name: 'Instrument A', sector: 'Sector 1', assetClass: 'Equity', weight: 0.05 },
      { name: 'Instrument B', sector: 'Sector 1', assetClass: 'Equity', weight: 0.042 },
      { name: 'Instrument C', sector: 'Sector 2', assetClass: 'Fixed Income', weight: 0.03 },
      { name: 'Instrument D', sector: 'Sector 2', assetClass: 'Fixed Income', weight: 0.02 },
      { name: 'Instrument E', sector: 'Sector 3', assetClass: 'Fixed Income', weight: 0.015 },
    ],
  },
}
