<script setup lang="ts">
import Chart, { dxChartOptions } from 'devextreme/viz/chart'
import PieChart from 'devextreme/viz/pie_chart'
import PolarChart from 'devextreme/viz/polar_chart'
import { reactive, watch } from 'vue'
const props = defineProps<{ data: []; options: {} }>()
const emit = defineEmits(['seriesHover', 'seriesClick'])
const state = reactive({
  ref: null,
  // width: 0,
  // height: 0,
  // padding: 0,
  // ratio: 0,
})
const resize = ref => {
  if (!ref) return
  state.ref = ref
  // state.width = ref.clientWidth - state.padding * 2
  // state.height = ref.clientHeight - state.padding * 2
  // new ResizeObserver(() => {
  //   state.width = ref.clientWidth - state.padding * 2
  //   state.height = ref.clientHeight - state.padding * 2
  // }).observe(ref)
}
watch([state, props], () => {
  if (!state.ref) return
  // if (!state.ref || !state.width || !state.height) return
  // const minwh = Math.min(state.width, state.height)
  // const width = state.ratio ? minwh * state.ratio : state.width
  // const height = state.ratio ? minwh : state.height
  // Overview: https://js.devexpress.com/jQuery/Documentation/Guide/UI_Components/Chart/Overview/
  // Intractive API: https://js.devexpress.com/jQuery/Documentation/ApiReference/UI_Components/dxChart/
  // Demos: https://js.devexpress.com/jQuery/Demos/WidgetsGallery/Demo/Charts/Overview/MaterialBlueLight/
  // To ease the option override, we use the JS/Jquery version and not the Vue version
  let DxChart = Chart
  if (props.options.viz === 'pie') DxChart = PieChart
  if (props.options.viz === 'radar') DxChart = PolarChart
  let type = props.options.viz
    .replace('pie', 'doughnut')
    .replace('dot', props.options.z ? 'bubble' : 'scatter')
    .replace('box', 'candlestick')
    .replace('radar', 'line')
  if (props.options.stack && ['bar', 'area'].includes(props.options.viz)) type = 'stacked' + type
  if (props.options.fullstack && ['bar', 'area'].includes(props.options.viz)) type = 'full' + type
  let data = props.data
  let series = [{ name: props.options.x }]
  if (props.options.category && data[0].group) {
    const categories = data
      .flatMap(v => v.group)
      .map(props.options.category)
      .unique()
      .filter()
      .sort()
    data = data.map(v => {
      const group = v[props.options.x] === 'Other' ? v.group.flatMap(v => v.group) : v.group
      return {
        ...v,
        _total: v[props.options.y],
        ...Object.fromEntries(
          categories.map(cat => {
            const grp = group.filter(v => v[props.options.category] === cat)
            if (!grp.length) return [cat, undefined]
            return [cat, props.options.aggregateFn(grp)]
          }),
        ),
      }
    })
    series = categories.map(category => ({
      name: category,
      valueField: category,
    }))
  }
  if (props.options.viz === 'pie') {
    series = [
      {
        argumentField: props.options.x,
        valueField: props.options.y,
        label: { customizeText: arg => `${arg.argumentText} (${arg.percentText})` },
      },
    ]
  }
  if (props.options.viz === 'box') {
    series = [
      {
        argumentField: props.options.x ?? 'date',
        openValueField: props.options.y ?? 'open',
        closeValueField: props.options.z ?? 'close',
        lowValueField: 'low',
        highValueField: 'high',
      },
    ]
  }
  const dxOptions: dxChartOptions = {
    animation: {
      enabled: false,
      duration: 500,
    },
    legend: {
      visible: false,
    },
    palette: props.options.palette,
    rotated: props.options.horizontal,
    argumentAxis: {
      inverted: props.options.horizontal,
      label: {
        // customizeText: ({ valueText }) => (valueText.length > 25 ? valueText.substring(0, 24) + '…' : valueText),
        customizeText: ({ value, valueText }) =>
          props.options.formatX.default ? valueText : props.options.formatX(value),
        overlappingBehavior: 'none',
      },
    },
    valueAxis: {
      minValueMargin: 0.01,
      maxValueMargin: 0.01,
      label: {
        format: props.options.formatY,
      },
    },
    commonSeriesSettings: {
      argumentField: props.options.x,
      valueField: props.options.y,
      sizeField: props.options.z,
      type: type,
      label: {
        visible: props.options.label ?? true,
        format: props.options.formatLabel,
        font: {
          color: '#123456',
        },
        backgroundColor: 'rgb(255, 255, 255, 0.8)',
        connector: {
          visible: props.options.viz === 'pie',
        },
      },
      point: {
        visible: props.options.label ?? true,
        ...(['line', 'area'].includes(props.options.viz) && data.length > 20 && { visible: false }),
        ...(['line', 'area'].includes(props.options.viz) && { color: '#123456' }),
        size: 6,
        hoverStyle: {
          size: 3,
          border: {
            width: 3,
            ...(['line', 'area'].includes(props.options.viz) && { color: '#123456' }),
          },
        },
      },
      hoverStyle: {
        hatching: {
          direction: 'none',
        },
      },
      selectionStyle: {
        hatching: {
          direction: 'none',
        },
        border: {
          visible: true,
          width: 3,
          color: '#123456',
        },
        highlight: false,
      },
      candlestick: {
        color: props.options.palette[0],
        reduction: {
          color: props.options.palette[1],
        },
      },
    },
    dataSource: data,
    series,
    useSpiderWeb: true, // only for radar
    minBubbleSize: 0.04, // only for bubble
    maxBubbleSize: 0.12, // only for bubble
    startAngle: 90, // only for pie
    innerRadius: 0.6, // only for pie
    resolveLabelOverlapping: 'shift', // only for pie
    // centerTemplate: (chart, container) => container.innerHTML += `<circle cx="100" cy="100" r="${chart.getInnerRadius() - 6}" fill="rgb(249 250 251)" />`,
    crosshair: {
      enabled: ['line', 'area', 'dot'].includes(props.options.viz),
      ...(props.options.viz !== 'dot' && { horizontalLine: false }), // true or undefined is not working
      dashStyle: 'dash',
      color: '#555555',
    },
    tooltip: {
      enabled: props.options.tooltip ?? true,
      opacity: 0,
      font: {
        opacity: 0,
      },
    },
    pointSelectionMode: 'multiple',
    seriesSelectionMode: 'multiple',
    onTooltipShown: e => {
      emit('seriesHover', {
        [props.options.x]: e.target.argument,
        [props.options.y]: e.target.originalValue,
        ...(props.options.z && { [props.options.z]: e.target.size }),
        ...(props.options.category && { [props.options.category]: e.target.series.name }),
        target: e.target,
      })
    },
    onTooltipHidden: e => emit('seriesHover', null),
    onDrawn: e => {
      //* Resize when overflow
      const containerEl = e.element?.parentNode?.parentNode
      if (!containerEl) return
      const diffH = containerEl.scrollHeight - containerEl.clientHeight
      const diffW = containerEl.scrollWidth - containerEl.clientWidth
      if (diffH) e.element.style.height = e.element.clientHeight - diffH + 'px'
      else e.element.style.height = null
      if (diffW) e.element.style.width = e.element.clientWidth - diffW + 'px'
      else e.element.style.width = null

      if (props.options.viz === 'pie') return
      if (props.options.viz === 'line') return

      //* Point Label Overlap
      const labelTexts = $$('.dxc-labels-group text', e.element)
      if (props.options.horizontal) {
        const labelSize = +$('.dxc-series rect').getAttribute('height') - 4
        const currentSize = e.component.option('commonSeriesSettings.label.font.size') || 12
        if (labelSize !== currentSize)
          e.component.option('commonSeriesSettings.label.font.size', labelSize < 12 ? labelSize : undefined)
      }
      if (detectOverlap(labelTexts)) $('.dxc-labels-group', e.element).remove()

      //* Axis Label Overlap
      if (e.component.redraw === false) return (e.component.redraw = true)
      e.component.option('argumentAxis.label.visible', props.options.label)
      const axisTexts = $$('.dxc-arg-elements text', e.element)
      for (let axisSize = 12; detectOverlap(axisTexts) && axisSize-- > 8; )
        axisTexts.forEach(el => (el.style.fontSize = axisSize + 'px'))
      const remainingOverlap = detectOverlap(axisTexts)
      if (remainingOverlap && remainingOverlap < 15 && !props.options.horizontal)
        axisTexts.forEach(el => {
          const rotate = `${el.getAttribute('transform')} rotate(-10 ${el.getAttribute('x')} ${el.getAttribute('y')})`
          el.setAttribute('transform', rotate)
        })
      if (remainingOverlap && remainingOverlap >= 15) {
        e.component.redraw = false
        e.component.option('argumentAxis.label.visible', false)
      }

      function elementOverlap(el1: Element, el2: Element) {
        const rect1 = el1.getBoundingClientRect()
        const rect2 = el2.getBoundingClientRect()
        const overlap = !(
          rect1.right < rect2.left ||
          rect1.left > rect2.right ||
          rect1.bottom < rect2.top ||
          rect1.top > rect2.bottom
        )
        if (!overlap) return 0
        const overlapX = Math.max(0, Math.min(rect1.right, rect2.right) - Math.max(rect1.left, rect2.left))
        const overlapY = Math.max(0, Math.min(rect1.bottom, rect2.bottom) - Math.max(rect1.top, rect2.top))
        const overlapArea = overlapX * overlapY
        const totalArea = rect1.width * rect1.height + rect2.width * rect2.height - overlapArea
        return (overlapArea / totalArea) * 100 // Calculate overlap percentage
      }
      function detectOverlap(elements: Element[], max = false) {
        let maxOverlapPercentage = 0
        for (let i = 0; i < elements.length; i++) {
          for (let j = i + 1; j < elements.length; j++) {
            maxOverlapPercentage = Math.max(maxOverlapPercentage, elementOverlap(elements[i], elements[j]))
          }
        }
        return maxOverlapPercentage
      }
    },
  }
  // Bubble Chart
  if (props.options.category && !data[0].group) {
    delete dxOptions.series
    dxOptions.seriesTemplate = { nameField: props.options.category }
  }
  const transformer = props.options.transformer ?? (v => v)
  const dxChart = new DxChart(state.ref, transformer(dxOptions))
  //* Selection
  if (props.options.selection)
    props.options.selection.forEach(selection => {
      const x = selection[props.options.x]
      const category = selection[props.options.category]
      if (category && x)
        return dxChart.series
          .find(s => s.name === category)
          ._points.find(p => p.argument === x)
          .select()
      if (category) return dxChart.series.find(s => s.name === category).select()
      if (x) return dxChart.series.forEach(s => s._points.find(p => p.argument === x).select())
    })
  //* Click on Axis or Legend
  let over = null
  dxChart.element().onmouseover = e => {
    const className = ['dxc-arg-elements', 'dxc-legend'].find(k => e.path.find(v => v.classList?.contains(k)))
    if (!className) return
    over = true
    const textEl = e.target.tagName !== 'text' ? e.target.parentNode.parentNode.querySelector('text') : e.target
    const tspanEls = $$('tspan', textEl)
    const value = tspanEls.length ? tspanEls.map(el => el.innerHTML).join(' ') : textEl.innerHTML
    const serie = dxChart.series.find(s => s.name === value)
    if (serie) return emit('seriesHover', { [props.options.category]: value })
    return emit('seriesHover', { [props.options.x]: value })
  }
  dxChart.element().onmouseout = e => over && emit('seriesHover', (over = null))
  dxChart.element().onclick = e => emit('seriesClick')
})
</script>

<template>
  <div class="nx-devextreme" :ref="resize"></div>
</template>
