<template lang="pug" v-if="loaded">
.row
  h1 {{ t['dqc_create_rule'] || 'dqc_create_rule'}}
.row
  subtitle(style="font-style: italic; margin-top: -10px;") {{ t['create_dqc_rule_subtitle'] || 'create_dqc_rule_subtitle'}}
.block.expand(v-if="loading === false")
  .expand
    .row
      .rule
        label {{ t['rule_name'] || 'rule_name'}}
        input(v-model="ruleName" type="text")
    .column
      label {{ t['data_quality_function'] || 'data_quality_function'}}
      code-editor(:code="ruleFunction" max-height="400px" @update="handleChanges")
  .row
    .action
      button.primary(@click="saveRule(ruleName, ruleFunction)" :disabled="!hasUnsavedChanges") {{ t['save'] || 'save'}}
      button.primary(@click="cancel") {{ t['cancel'] || 'cancel'}}
</template>

<script>
import { ref, onMounted } from 'vue'
import { useDataQualityRule } from '../composables/useDataQualityRule'
import dataQualityRuleService from '../../../services/DataQualityRuleService'

export default {
  data() {
    return {
      loading: true,
      ruleName: '',
      ruleFunction: '',
      hasUnsavedChanges: false,
      isNew: false,
      id: 0,
    }
  },
  async mounted() {
    await this.init()
  },
  setup() {
    const { rules, loaded } = useDataQualityRule()
    return {
      rules,
      loaded,
    }
  },
  methods: {
    async init() {
      this.isNew = !this.$route.query.id
      this.loading = true
      this.id = this.$route.query.id
      if (this.$route.query.id) {
        const q = await dataQualityRuleService.get(this.$route.query.id)
        this.ruleName = q.name
        this.ruleFunction = q.dqcJavaScriptFunction
      } else {
        this.ruleName = ''
        this.ruleFunction = '(e) => {\n return { trace: "ok", status: "OK" };\n }'
      }
      this.hasUnsavedChanges = false
      this.loading = false
    },
    handleChanges(e) {
      this.ruleFunction = e
      this.hasUnsavedChanges = true
    },
    async saveRule(ruleName, ruleFunction) {
      if (!this.ruleName || !this.ruleFunction) {
        $root.toast({
          description: $root.t['error_invalid_rule'] || 'error_invalid_rule',
          type: 'error',
          timeout: 5000,
        })
        return
      }
      if (this.isNew) {
        try {
          await dataQualityRuleService.createDataQualityRule(ruleName, ruleFunction)
          this.$router.push($root.appath + 'data-quality-rules')
        } catch (e) {
          $root.toast({ description: e.message, type: 'error', timeout: 5000 })
        } finally {
          this.hasUnsavedChanges = false
        }
      } else {
        try {
          await dataQualityRuleService.update(this.id, ruleName, ruleFunction)
          $root.toast({ description: $root.t.saved || 'Saved', type: 'success', timeout: 5000 })
        } catch (e) {
          $root.toast({ description: e.message, type: 'error', timeout: 5000 })
        } finally {
          this.hasUnsavedChanges = false
        }
      }
    },
    cancel() {
      this.$router.push($root.appath + 'data-quality-rules')
    },
  },
}
</script>

<style scoped>
button {
  margin-right: 16px;
}

.code {
  font-family: monospace;
  min-width: 100%;
  min-height: 50vh;
}

textarea {
  font-family: COURIER NEW;
}

.editor .editor-left .query {
  flex: 2;
}

.rule input {
  width: 400px;
}

.rule {
  padding: 8px 0 8px 0;
}
</style>
