import { reactive, readonly } from 'vue'

export interface IMoveEvent {
  event: PointerEvent
  deltaX: number
  deltaY: number
}

interface IArgs {
  box: 'page' | 'client' | 'screen'
  onMove: (event: IMoveEvent) => void
  onMoveStart: (event: IMoveEvent) => void
  onMoveEnd: (event: Omit<IMoveEvent, 'event'>) => void
  setTargetElement: undefined | ((event: PointerEvent) => HTMLElement | null)
  applyOffset: boolean
}

export function useMove({
  box = 'page',
  onMove = () => {},
  onMoveStart = () => {},
  onMoveEnd = () => {},
  applyOffset = false,
  setTargetElement,
}: Partial<IArgs> = {}) {
  const selectorX = box === 'page' ? 'pageX' : box === 'client' ? 'clientX' : 'screenX'
  const selectorY = box === 'page' ? 'pageY' : box === 'client' ? 'clientY' : 'screenY'

  const state = reactive({
    isMoving: false,
    moveStartX: 0,
    moveStartY: 0,
    deltaX: 0,
    deltaY: 0,
    moveStart,
  })

  /**
   * Handles the event of the user clicking on a move hanler element.
   *
   * @param event The mouse or finger event that triggered the move.
   */
  function moveStart(event: PointerEvent, captureTarget = true) {
    if (captureTarget) {
      const target = setTargetElement ? setTargetElement(event) : (event.target as HTMLElement)
      target?.setPointerCapture(event.pointerId)
    }
    state.isMoving = true
    state.moveStartX = event[selectorX] - (applyOffset ? event.offsetX : 0)
    state.moveStartY = event[selectorY] - (applyOffset ? event.offsetY : 0)
    bindEvents()
    onMoveStart({ event, deltaX: state.moveStartX, deltaY: state.moveStartX })
  }

  /**
   * Binds event listeners to the document to handle mouse and finger events.
   */
  function bindEvents() {
    // Passive: false to prevent scrolling while touch dragging.
    document.addEventListener('pointermove', onPointerMove, { passive: false })
    document.addEventListener('pointerup', onPointerUp)
  }

  /**
   * Removes event listeners from the document that were previously bound to handle mouse and touch events.
   */
  function unbindEvents() {
    document.removeEventListener('pointermove', onPointerMove)
    document.removeEventListener('pointerup', onPointerUp)
  }

  /**
   * Handles the event of the user moving their mouse or finger while clicking on a move handler element.
   *
   * @param event The event triggered by the moving mouse or finger.
   */
  function onPointerMove(event: PointerEvent) {
    // console.log('move', event, event.offsetX, event.offsetY)
    if (!state.isMoving) return

    // Prevent scrolling while touch dragging (only works with an active event, eg. passive: false).
    event.preventDefault()

    state.deltaX = event[selectorX] - state.moveStartX
    state.deltaY = event[selectorY] - state.moveStartY
    onMove({ event, deltaX: state.deltaX, deltaY: state.deltaY })
  }

  /**
   * Handles the event of the user releasing the mouse or finger after clicking on a pane resizer.
   */
  function onPointerUp() {
    state.isMoving = false
    unbindEvents()
    onMoveEnd({ deltaX: state.deltaX, deltaY: state.deltaY })
  }

  return readonly(state)
}
