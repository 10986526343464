import config from '../../../config'
import { useGraphQL } from '../../../utils/axios'

function flattenCompareResponse(data, valueKeys = ['performance']) {
  return data.map(d => {
    valueKeys.forEach(key =>
      Object.entries(d[key]).forEach(([name, value]) => {
        d[name] = value
      }),
    )
    return d
  })
}
export function usePerformance() {
  const getPerformanceData = async params => {
    const { sendQuery } = useGraphQL(config)
    // const { getDataReport } = useDataReport(config)
    const { isinShare, domain, benchmarkId, riskFreeBenchmarkId } = params
    const ids = benchmarkId
      ? [
          {
            name: 'fund',
            fuid: isinShare,
          },
          { name: 'benchmark', fuid: benchmarkId },
        ]
      : [{ name: 'fund', fuid: isinShare }]
    const query = `#graphql
    query Performance(
      $domain: Domain!
      $ids: FuidInput!
      $shareId: FuidInput!
      $benchmarkId: FuidInput!
      $riskFreeBenchmarkId: FuidInput!
      $options: PerformanceHistoryOptions
    ) {
      domainHistorical: performance {
        history(fuid: $ids, domain: $domain, options: $options) {
          date
          performance
        }
      }
      yearlyPerformances: performance {
        history(fuid: $ids, domain: "inception", groupBy: year) {
           date
           performance
        }
      }
      monthlyPerformances: performance {
        history(fuid: $ids, domain: "inception", groupBy: month) {
           date
           performance
        }
      }
      performance {
        fund: value(fuid: $shareId, domain: $domain)
        benchmark: value(fuid: $benchmarkId, domain: $domain)
      }
      volatility: analytics {
        fund: volatility(fuid: $shareId, domain: $domain)
        ${benchmarkId ? 'benchmark: volatility(fuid: $benchmarkId, domain: $domain)' : ''}
      }
      sharpeRatio: analytics {
        fund: sharpeRatio(
          fuid: $shareId
          riskFreeBenchmarkId: $riskFreeBenchmarkId
          domain: $domain
        )
        benchmark: sharpeRatio(
          fuid: $benchmarkId
          riskFreeBenchmarkId: $riskFreeBenchmarkId
          domain: $domain
        )
      }
      ${
        benchmarkId
          ? `otherAnalytics: analytics {
        trackingError(fuid: $shareId, benchmarkId: $benchmarkId, domain: $domain)
        informationRatio(fuid: $shareId, benchmarkId: $benchmarkId, domain: $domain)
      }`
          : ''
      }
      drawdownDetails: analytics {
        fund: drawdownDetails(fuid: $shareId, domain: $domain) {
          drawdown
          begin
          begin_nav
          end
          end_nav
          recovery
          duration
        }
        benchmark: drawdownDetails(fuid: $benchmarkId, domain: $domain) {
          drawdown
          begin
          begin_nav
          end
          end_nav
          recovery
          duration
        }
      }
    }
    `
    const variables = {
      shareId: isinShare,
      domain,
      benchmarkId: benchmarkId ? benchmarkId : '',
      riskFreeBenchmarkId,
      ids,
      options: {
        mode: 'cumulative',
      },
    }
    // const resp = await getDataReport('performance', variables)
    const resp = await sendQuery(query, variables)
    const kpis = {
      performance: resp.performance,
      volatility: resp.volatility,
      sharpeRatio: resp.sharpeRatio,
      drawdownDetails: resp.drawdownDetails,
      trackingError: resp.otherAnalytics?.trackingError,
      informationRatio: resp.otherAnalytics?.informationRatio,
    }
    return {
      kpis,
      domainHistorical: flattenCompareResponse(resp.domainHistorical.history, ['performance']),
      yearlyPerformances: flattenCompareResponse(resp.yearlyPerformances.history, ['performance']),
      monthlyPerformances: flattenCompareResponse(resp.monthlyPerformances.history, ['performance']),
    }
  }
  return {
    getPerformanceData,
  }
}
