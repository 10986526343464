import { onMounted, ref } from 'vue'
import dataReportService from '../../../services/DataReportService'

export function useShares(expired = false, standby = false, extra_share_characteristics = []) {
  const shares = ref([])
  const fund_context = ref({})
  const loaded = ref(false)

  onMounted(async () => {
    shares.value = (
      await dataReportService.run(
        'drShares',
        { expired, standby, extra_share_characteristics },
        { preProcess: false, postProcess: false },
      )
    ).data?.result?.businessData?.shares
    fund_context.value = makeFundContext(shares.value)
    loaded.value = true
  })

  return {
    shares,
    fund_context,
    loaded,
  }
}

function makeFundContext(shares) {
  return (
    shares?.reduce((acc, { share_letter, emailContext, ...s }) => {
      acc[s.share_id] = {
        ...s,
        ...s.characteristics,
        ...emailContext,
        share: s.characteristics?.share_letter,
      }
      return acc
    }, {}) || {}
  )
}
