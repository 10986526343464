import { onMounted, ref } from 'vue'
import dataReportService from '../../../services/DataReportService'

export function useFunds() {
  const funds = ref([])
  const loaded = ref(false)
  onMounted(async () => {
    funds.value = (
      await dataReportService.run('drFunds', {}, { preProcess: false, postProcess: false })
    ).data.result.businessData.funds
    loaded.value = true
  })

  return {
    funds,
    loaded,
  }
}
