<script setup>
import { ref, defineProps } from 'vue'

const props = defineProps({
  textHeader: {
    type: String,
    required: true,
  },
  textBody: {
    type: Array,
    required: true,
  },
})

const collapse = ref(false)

const updateCollapse = () => {
  collapse.value = !collapse.value
}
</script>

<template>
  <div class="expand block">
    <h2 @click="updateCollapse">
      <svg-icon name="nx-chevron" :class="{ icon: true, rotate: !collapse }" />
      {{ textHeader }}
    </h2>
    <template v-if="!collapse">
      <p v-for="text in textBody">{{ text }}</p>
    </template>
  </div>
</template>

<style scoped>
.block {
  margin-bottom: 10px;
  border: 3px solid #1b689c;
}
h2 {
  color: #1b689c;
}
h2 .icon {
  margin-right: 3px;
  fill: #1b689c;
  max-width: 19px;
}

.rotate {
  transform: rotate(90deg);
}

p {
  color: #2593de;
  margin-left: 20px;
}
</style>
