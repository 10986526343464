<script setup>
import { ref, onMounted, computed, watch, onUnmounted } from 'vue'
import { useAllocation } from '../../composables/useAllocation.js'
import { useAutocomplete } from '../../composables/useAutocomplete.js'
import { useXfilter } from '../../composables/useXfilter.js'
import { useDates } from '../../composables/useDates'

const { getAllocationData } = useAllocation()
const { initAutocomplete, autocompleteAvailableData, activeAutocompleteFilters, onFiltersUpdateAutoComplete } =
  useAutocomplete()
const { initXfilter, filteredDataset, datasetByDimensions, axis, axisFormat, axisMetric } = useXfilter()
const { getDatesQuery } = useDates()
const loaded = ref(false)
const availableDimensions = ref()
const asof = ref()
const fund_asset_class = ref()

const leave = (el, done) => {
  el.style.opacity = 0
  setTimeout(() => done(), 500)
}

const loadData = async () => {
  loaded.value = false
  const domain = $root.$route.query.domain || $root.domain.join('|')
  let params = {
    isinShare: $root.$route.fullPath.split('/')[2].split('-')[1],
    domain: $root.query.evolution ? domain : domain.split('|').last() + '|' + domain.split('|').last(),
    asof: $root.domain[1],
    lang: $root.$route.query.lang || $root.lang,
  }
  if (!$root.dates || $root.dates.isin_share !== params.isinShare || $root.dates.query !== 'allocation') {
    $root.dates = await getDatesQuery(params, 'allocation')
    $root.dates.isin_share = params.isinShare
    $root.dates.query = 'allocation'
    $root.refresh = $root.refresh + 1
    params.domain = $root.domain.join('|')
    params.asof = $root.domain[1]
  }
  const data = await getAllocationData(params)
  fund_asset_class.value = data.share.characteristics?.fund_asset_class || ''
  $root.tab_benchmark = data.share.benchmark
  $root.tab_userflow_name = data.share.slug
  $root.nav_data.path = data.share.characteristics?.share_letter
  $root.refresh = $root.refresh + 1
  asof.value = data.share.asof //! here
  availableDimensions.value = data.share.allocation[0].axis.keys()
  initAutocomplete(data.share.allocation, availableDimensions.value)
  initXfilter(data.share.allocation, availableDimensions.value)
  $root.bench_weight_nul = filteredDataset.value.map(d => d.weight_benchmark)?.sum() === 0
  if ($root.query.metric !== 'fund' && $root.tab_benchmark?.id && $root.bench_weight_nul) {
    update_query({ metric: '' })
  }
  // if (filteredDataset.map(d => d.weight_benchmark).sum() === 0) {
  //   $('main').classList.add('no_benchmark_data')
  // }
  loaded.value = true
}

onMounted(loadData)
watch(() => $root.query.domain, loadData)
watch(() => $root.query.evolution, loadData)
onUnmounted(() => ($root.bench_weight_nul = false))

const axis_list = computed(() => {
  if (fund_asset_class.value === 'fund_asset_class_actions') return ['exposure', 'contrib_deltaaction']
  if (
    fund_asset_class.value === 'fund_asset_class_obligations' ||
    fund_asset_class.value === 'fund_asset_class_monetaire'
  )
    return ['exposure', 'contrib_sensi']
  if (
    fund_asset_class.value === 'fund_asset_class_diversifies' ||
    fund_asset_class.value === 'fund_asset_class_convertibles'
  )
    return ['exposure', 'contrib_sensi', 'contrib_deltaaction']
  return ['exposure', 'contrib_sensi', 'contrib_deltaaction']
})

const kpiAllocation = computed(() => {
  return [
    {
      title: axis.value + '_fund',
      value: filteredDataset.value.map(d => d[axis.value + '_fund']).sum(),
    },
    {
      title: axis.value + '_benchmark',
      value: filteredDataset.value.map(d => d[axis.value + '_benchmark']).sum(),
    },
  ]
})
const kpiActiveShare = computed(() => {
  return [
    {
      title: 'active_share',
      value: filteredDataset.value.map(d => d.weight_active_share).sum(),
    },
  ]
})
const kpiDiff = computed(() => {
  return [
    {
      title: 'diff',
      value:
        filteredDataset.value.map(d => d[axis.value + '_fund']).sum() -
        filteredDataset.value.map(d => d[axis.value + '_benchmark']).sum(),
    },
  ]
})
const mainLines = computed(() => {
  // Not equal to factsheet main lines => based on current filter
  const main_lines = filteredDataset.value
    .filter(v => v[axisMetric.value])
    .sort(v => -v[axisMetric.value])
    .slice(0, 20)
    .map(v => ({
      title: v.name || v.id,
      value: format(axisFormat.value)(v[axisMetric.value]),
    }))
  return [{ title: 'main_lines' }].concat(main_lines)
})
const allocation_axis = computed(() => {
  return $root.query && $root.query.evolution ? 'allocation_evolution' : 'allocation'
})
const metadataAllocation = dim => {
  const format = axisFormat.value
  const max = 6 // Warning Old code (display at leat 80% removed)
  const dimension = dim // REQUIRE for inactive bar in plot-bar.vue
  const sort = $root.config.sorting(dim)
  const suppressEmptyBars = true
  return { format, max, dimension, sort, suppressEmptyBars }
}
const update_filter = (dimension, event) => {
  const value = event.x === 'other' ? event.label : event.x
  // Unselect case:
  if ($root.query?.[dimension] && $root.query?.[dimension].split('|').includes(value)) {
    const filters = $root.query?.[dimension].split('|').filter(d => d !== value)
    return update_query({ [dimension]: filters.join('|') })
  }
  // Add criteria on existing dimension
  if ($root.query?.[dimension]) {
    const filters = $root.query?.[dimension].split('|').concat(value)
    return update_query({ [dimension]: filters.join('|') })
  }
  // new filter
  update_query({ [dimension]: value })
}
</script>
<script>
export const additions = { calendar: 'daily', icon: 'ic_pie_chart' }
</script>
<template lang="pug">
transition(@leave='leave')
  loader(v-if="!loaded")
template(v-if="loaded")
  .screen-title
    .row
      h1 {{ t['allocation' + ($root.$route.query.evolution ? '_evolution' : '')] }}
      .row.right
        autocomplete(:data="autocompleteAvailableData" :options="{ placeholder: t.search }" :modelValue="activeAutocompleteFilters" @update:modelValue="onFiltersUpdateAutoComplete")
    .button-bar
      .evolution
        router-link(:class="{ on: !$route.query.evolution }" :to="{ query: Object.assign({}, $route.query, { evolution: null }).filter(d => d) }") {{ t[$root.screen.path] || $root.screen.path }}
        router-link(:class="{ on: $route.query.evolution }" :to="{ query: Object.assign({}, $route.query, { evolution: 'on' }) }") {{ t.evolution }}
      .axis
        router-link(:class="{ on: !$root.query.axis }" :to="{ query: Object.assign({}, $root.query, { axis: null }).filter(d => d) }") {{ t.weight }}
        router-link(:class="[{ on: $root.query.axis === axis }, axis]" :to="{ query: Object.assign({}, $root.query, { axis: axis }) }" v-for="axis in axis_list" ) {{ t[axis] }}
  .row.stretch
    kpi(:data="kpiAllocation" v-if="$root.query.metric !== 'diff'" :class="$root.bench_weight_nul ? 'no_benchmark_data' : '' ")
    kpi(:data="kpiActiveShare" v-if="$root.query.metric === 'diff' && axis === 'weight'" :class="$root.bench_weight_nul ? 'no_benchmark_data' : '' ")
    kpi(:data="kpiDiff" v-if="$root.query.metric === 'diff' && axis !=='weight'" :class="$root.bench_weight_nul ? 'no_benchmark_data' : '' ")
    kpi(:data="mainLines")
  .block.column.expand(v-if="availableDimensions")
    .row.expand(v-for="row in availableDimensions.chunk(2)")
      block.allow-fullscreen(v-for="dimension in row" type="plot-bar" :data="datasetByDimensions(dimension)" :title="allocation_axis + ',by,' + dimension" :metadata="metadataAllocation(dimension)" @plotclick="update_filter(dimension, $event)")
  .asof(v-if="asof")
    p Mapping asof: {{asof}}
</template>
<style>
.on {
  opacity: 1;
}
.page > .asof {
  margin-top: 0;
  margin-bottom: 0;
}
.asof p {
  font-size: 9px;
  text-align: right;
}
.row.right {
  flex: 1;
}
.screen-allocation .block-plot-bar {
  min-width: 370px;
}
.button-bar {
  min-width: 624px;
}
/* .screen-allocation.no_benchmark_data .tab, */
.screen-allocation.no_benchmark_data .kpi .weight_benchmark,
.screen-allocation .kpi.no_benchmark_data .title:nth-of-type(2),
.screen-allocation .kpi.no_benchmark_data .value:nth-of-type(2) {
  display: none;
}
</style>
