import { onMounted, ref, watch } from 'vue'
import Color from '@kurkle/color'
import ISOCountries from 'i18n-iso-countries'
import { topojson } from 'chartjs-chart-geo'
// map numeric code to iso country code
const countriesMap = ISOCountries.getNumericCodes()

/**
 * Create datasets and watch for updates
 * @param data Chart data
 * @param options Chart options
 * @param chartOptions Extend dataset with chartOptions
 * @param multicolor Use several colors per dataset
 * @param transparentize Make background color transparent
 * @return {{datasets: ToRef<*[]>, labels: ToRef<*[]>}}
 */
export default function (
  data,
  options,
  datasetOptions = {},
  { multicolor, transparentize } = { multicolor: false, borderColor: true, transparentize: false },
) {
  const datasets = ref([])
  const labels = ref([])

  let countries = []

  const style = getComputedStyle(document.body)
  const genColors = (i, transparentize = false) => {
    const color = i =>
      new Color(style.getPropertyValue(`--cat${i + 1}`).trim()).alpha(transparentize ? 0.2 : 1).rgbString()

    if (!multicolor) return color(i)
    return Array(12)
      .fill('')
      .map((_, i) => color(i))
  }
  const fetchCountries = async () => {
    const data = await (await fetch('https://unpkg.com/world-atlas/countries-110m.json')).json()
    return topojson.feature(data, data.objects.countries).features
  }
  const updateDatasets = () => {
    const labelKey = options.value.labelKey || 'date'
    labels.value = countries.map(c => ISOCountries[c.id])
    datasets.value = options.value.datasets?.map((d, i) => {
      const dataset = {
        ...datasetOptions,
        ...d,
        /**
         * Features contains the country shape, so, we need to add all the countries if we want a full worldmap
         */
        data: countries.map(c => ({
          feature: c,
          code: countriesMap[c.id],
          value: (data.value.find(o => o[labelKey] === countriesMap[c.id]) || {})[d.key] || 0,
        })),
      }
      delete dataset.key
      return dataset
    })
  }
  onMounted(async () => {
    countries = await fetchCountries()
    updateDatasets()
    watch(data, updateDatasets, { deep: true })
    watch(options, updateDatasets)
  })

  return {
    datasets,
    labels,
  }
}
