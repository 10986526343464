<script setup lang="ts">
import { reactive, computed } from 'vue'
const props = defineProps(['data', 'options'])
const emit = defineEmits(['seriesHover', 'seriesClick'])
const state = reactive({
  hover: undefined,
  select: undefined,
})
const slices = computed(() => {
  const groups = props.data.reduce((acc, v) => {
    if (!acc[v[props.options.category]]) acc[v[props.options.category]] = []
    acc[v[props.options.category]].push(v)
    return acc
  }, {})
  const values = Object.entries(groups).map(([category, values], i) => {
    return { value: values.reduce((acc, v) => acc + v[props.options.y], 0), category, class: `category-${i + 1}` }
  })
  const total = values.reduce((acc, v) => acc + v.value, 0)
  const slices = values.map((slice, i, arr) => {
    const prev = i === 0 ? 0 : arr[i - 1].arc.endAngle
    const next = prev + (2 * Math.PI * slice.value) / total
    slice.arc = { innerRadius: 61, outerRadius: 90, startAngle: prev + (2 * Math.PI) / 360, endAngle: next }
    return slice
  })
  slices.forEach(v => (v.arc = d3.arc()(v.arc)))
  return slices
})
const slices2 = computed(() => {
  const categories = [...new Set(props.data.map(v => v[props.options.category]))]
  const values = props.data.map(v => {
    return {
      value: v[props.options.y],
      key: v[props.options.x],
      level: 1,
      category: v[props.options.category],
      class: `category-${categories.indexOf(v[props.options.category]) + 1}`,
    }
  })
  const total = props.data.reduce((acc, v) => acc + v.value, 0)
  const slices = values.map((slice, i, arr) => {
    const prev = i === 0 ? 0 : arr[i - 1].arc.endAngle
    const next = prev + (2 * Math.PI * slice.value) / total
    if (state.select === slice.category) {
      slice.arc = { innerRadius: 91, outerRadius: 100, startAngle: prev + (2 * Math.PI) / 360, endAngle: next }
      return slice
    }
    slice.arc = { innerRadius: 91, outerRadius: 94, startAngle: prev + (2 * Math.PI) / 360, endAngle: next }
    return slice
  })
  slices.forEach(v => (v.arc = d3.arc()(v.arc)))
  return slices
})
const hover = slice => {
  state.hover = slice?.category
  emit('seriesHover', slice)
}
const click = slice => {
  state.select = state.select === slice?.category ? undefined : slice?.category
  emit('seriesClick', slice)
}
</script>

<template>
  <svg class="nx-sunburst" viewBox="-100 -100 200 200">
    <path
      :d="slice.arc"
      :class="slice.class"
      :style="{ opacity: state.select && state.select !== slice.category ? 0.5 : 1 }"
      @mouseenter="hover(slice)"
      @mouseleave="hover(null)"
      @click="click(slice)"
      v-for="slice in slices"
    ></path>
    <path
      :d="slice.arc"
      :class="slice.class"
      :style="{ opacity: state.select && state.select !== slice.category ? 0.3 : 0.6 }"
      @mouseenter="hover(slice)"
      @mouseleave="hover(null)"
      @click="click(slice)"
      v-for="slice in slices2"
    ></path>
    <!-- <circle r="63" style="fill: none;stroke: white;stroke-width: 1px;"></circle> -->
    <circle r="60" style="fill: white" @click="click(null)"></circle>
  </svg>
</template>

<style>
.nx-sunburst path.category-1 {
  fill: #ffb931;
}
.nx-sunburst path.category-2 {
  fill: #f55a3a;
}
.nx-sunburst path.category-3 {
  fill: #cc1c3a;
}
.nx-sunburst path.category-4 {
  fill: #169889;
}
.nx-sunburst path.category-5 {
  fill: #28ae71;
}
.nx-sunburst path.category-6 {
  fill: #a1de66;
}
.nx-sunburst path.category-7 {
  fill: #7eeb89;
}
.nx-sunburst path.category-8 {
  fill: #55dec1;
}
.nx-sunburst path.category-9 {
  fill: #92e5f7;
}
.nx-sunburst path.category-10 {
  fill: #7fa0f4;
}
.nx-sunburst path,
.nx-sunburst circle {
  cursor: pointer;
}
</style>
