import { commandrSDK } from '../../../features/commandr'
import { onMounted, onUnmounted, ref } from 'vue'

const config = ref({})
export function useConfig() {
  const loaded = ref(false)
  const update = snap => {
    config.value = snap || {}
    loaded.value = true
  }
  const define_scope_fn = report => {
    try {
      if (config.value.define_scope) {
        const define_scope = eval(config.value.define_scope)
        return define_scope(report)
      }
    } catch (e) {
      console.log(e)
      return 'default'
    }
  }
  onMounted(() => {
    loaded.value = false
    commandrSDK.on('/config', 'value', update)
  })
  onUnmounted(() => commandrSDK.off('/config', 'value'))
  return { config, define_scope_fn, loaded }
}
