import type { TemplateLayout } from '../builder'
function parseNode(node: any) {
  if (node.lines) {
    return {
      type: 'column',
      nodes: node.lines.map(parseNode),
    }
  }
  if (node.columns) {
    return {
      type: 'row',
      nodes: node.columns.map(parseNode),
    }
  }
  return {
    type: 'block',
    component: node.component,
    data: node.data,
    options: node.options,
    ...node,
  }
}

export function migrateLayout(layout: TemplateLayout, stylesheets: string[]) {
  if (!layout.theme) {
    layout.theme = {
      stylesheet: stylesheets[0],
    }
  } else if (!layout.theme?.stylesheet) {
    layout.theme.stylesheet = stylesheets[0]
  }
  if (layout?.theme?.loadedStylesheets) {
    delete layout.theme.loadedStylesheets
  }
  if (!layout.overflowPages) {
    layout.overflowPages = {}
  }
  if (!layout?.theme.pageMargin) {
    layout.theme.pageMargin = window?.config?.defaultTemplate?.theme?.pageMargin || 8
  }
  if (!layout.pages) return layout
  const nodes = layout.pages.map((node: any) => {
    return parseNode(node)
    // return page.map((node: any) => {
    // })
  })
  delete layout.pages
  return {
    ...layout,
    nodes,
  }
}
