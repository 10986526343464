import { ResolvedBuilderOptions } from '../composables/builderOptions'
export function formatFactory({ unit, digit, lang = 'en' }: Partial<ResolvedBuilderOptions>) {
  if (!unit && !digit) return (num: number) => num
  return (num: number) => {
    if ((!num && num !== 0) || typeof num !== 'number') return num ? '' + num : ''
    digit = Math.max(0, Math.min(digit || 0, 4))
    if (unit === 'bp') return Math.round(num * 10000) + 'bp'
    if (unit === '%')
      return (
        Intl.NumberFormat(lang.slice(0, 2), {
          notation: 'compact',
          minimumFractionDigits: digit,
          maximumFractionDigits: digit,
        }).format(num * 100) + '%'
      )
    if (['$', '€', '£', 'USD', 'EUR', 'GBP', 'CHF'].includes(unit)) {
      return Intl.NumberFormat(lang.slice(0, 2), {
        style: 'currency',
        currency: unit.replace('$', 'USD').replace('€', 'EUR').replace('£', 'GBP'),
        notation: 'compact',
        minimumFractionDigits: digit,
        maximumFractionDigits: digit,
      }).format(num)
    }
    return Intl.NumberFormat(lang.slice(0, 2), {
      notation: 'compact',
      minimumFractionDigits: digit,
      maximumFractionDigits: digit,
    }).format(num)
  }
}

export function dateFormatFactory(data: any[], { lang = 'en' }: ResolvedBuilderOptions) {
  return (date: Date) => {
    return date.toLocaleDateString(lang.slice(0, 2))
  }
}
