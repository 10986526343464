<template>
  <story-template :story="story" class="h-80 flex-col bg-white" :max-examples="3">
    <div class="builder-table-story h-full overflow-scroll">
      <builder-table
        :data="story.props.data"
        :options="story.props.options"
        :context="story.props.context"
      ></builder-table>
    </div>
  </story-template>
</template>
<script setup lang="ts">
import { createStory, controls } from '@storytime'
import { defaultStory, pivotStories } from './builder-table-pivot.story'

const story = createStory('builder-table')
story.setProps(defaultStory).createDefaultExample('Default')
Object.entries(pivotStories).forEach(([name, example]) => {
  story.createExample(name).setExampleProps(example)
})

// Controls
function onControlChange(key: string) {
  return (v: any) => (story.props.options[key] = v)
}
story.createState('Controls', {
  showTotal: controls.boolean(false).onChange(onControlChange('showTotal')),
  showSubtotals: controls.boolean(true).onChange(onControlChange('showSubtotals')),
  display: controls.select({ compact: 'compact', outline: 'outline' }).onChange(onControlChange('display')),
  // sort: controls.boolean(false).onChange(v => (story.props.options.sort = v)),
  // limit: controls.number(15, 5, 15, 1).onChange(v => (story.props.options.limit = v)),
  // other: controls.boolean(false).onChange(v => (story.props.options.other = v)),
})
// story.createExample('Base').setExampleProps(table.props)
</script>
<style></style>
