<template>
  <builder-block :data="data" :options="options" :context="context">
    <div class="text-content flex flex-col">
      <!-- <div>{{ isOverflow }} {{ context.isLast }} {{ context.path }}</div> -->
      <div v-if="options.text" v-html="formatText(translate(options.text))"></div>
      <template v-for="key in options.values">
        <slot :name="key" :k="key" :value="translate(data[key])" :formattedValue="formatText(translate(data[key]))">
          <div
            class="text-justify leading-tight tracking-normal"
            :class="options?.class"
            v-html="formatText(translate(data[key]))"
            :key="key"
          ></div>
        </slot>
      </template>
    </div>
  </builder-block>
</template>

<script setup lang="ts">
// import { marked } from "marked"
import { text as api } from '../composables/builderOptions'
const { data, options, context } = defineProps(['data', 'options', 'context'])

function translate(value: string) {
  return context.translations[value] || value
}
function formatText(value: string | number) {
  if (typeof value !== 'string') return value
  return value.replaceAll('\n', '<br>')
}
</script>
<script lang="ts">
export default {
  api,
  styles: {
    '.text-content': '',
  },
  story: {
    props: {
      options: {
        text: `This is a HTML bloc of text, with a <strong>bold</strong> word and a <em>italic</em> word.<br>
        <span>This line is wrapped by the span tag.</span>
        `,
      },
      context: {
        translations: {},
      },
    },
  },
}
</script>
