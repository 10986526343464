<script>
import { isTrue } from '@100-m/hauru/src/utils/characteristics'

export const additions = { calendar: 'period', icon: 'ic_dashboard' }
import { useDates } from '../../composables/useDates'
import { processTable } from '../../composables/useProcessTable'
const { getDatesQuery } = useDates()

export default {
  data() {
    return {
      displayed_plot: 'performance_historical',
      loaded: false,
      default_domain: '0001-01-01|' + new Date().toISOString().split('T')[0],
      params: {
        isinShare: this.$route.fullPath.split('/')[2].split('-')[1],
        domain: $root.domain.join('|') || this.$route.query.domain,
        lang: this.$route.query.lang || $root.lang,
      },
      data: {},
    }
  },
  computed: {
    overviewPlot() {
      const title = 'de_' + this.displayed_plot
      let options = { format: v => format('.2%')(v * 0.01) }
      let data = []
      if (this.displayed_plot === 'performance_historical') {
        // options = { format: '.2%' }
        // Get the first benchmark in the list, if it's empty, use 'benchmark'
        const benchmark_multiple = this.data.share.characteristics?.benchmark_multiple?.split(',')[0]
        data = this.data.share.analytics.domain_historical
          .filter(d => d.date >= $root.domain[0] && d.date <= $root.domain[1])
          .reduce((acc, a) => {
            let doc = {}
            if (a.fund) doc.fund = a.fund - 100
            if (a.benchmark && (!benchmark_multiple || benchmark_multiple === 'benchmark'))
              doc.benchmark = a.benchmark - 100
            if (a.benchmark2 && benchmark_multiple === 'benchmark2') doc.benchmark2 = a.benchmark2 - 100
            if (a.benchmark3 && benchmark_multiple === 'benchmark3') doc.benchmark3 = a.benchmark3 - 100
            acc[a.date] = doc
            return acc
          }, {})
      }
      if (this.displayed_plot === 'real_nav') {
        options = {
          format: ',' + (this.data.share.characteristics.share_currency || '€'),
        }
        data = this.data.share.analytics.historical_real_nav
          .filter(d => d.date >= $root.domain[0] && d.date <= $root.domain[1])
          .reduce((acc, a) => {
            acc[a.date] = { fund: a.real_nav }
            return acc
          }, {})
      }
      if (this.displayed_plot === 'aum_fund_currency') {
        options = {
          format: 'M' + this.data.share.characteristics.fund_currency,
        }
        data = this.data.share.analytics.historical_aum
          .filter(d => d.date >= $root.domain[0] && d.date <= $root.domain[1])
          .reduce((acc, a) => {
            acc[a.date] = { fund: a.aum }
            return acc
          }, {})
      }
      return { title, data, options }
    },
    rootWatcher() {
      return this.$route.query.domain || $root.query.domain
    },
    tables() {
      const charTab = this.data.tables.find(t => t.name === this.data.share.characteristics.characteristics)?.table
      const custTab = this.data.tables.find(
        t => t.name === this.data.share.characteristics.customer_informations,
      )?.table
      return {
        characteristics: processTable(charTab, this.data),
        customer_informations: processTable(custTab, this.data),
      }
    },
    performanceKpis() {
      const kpis = [
        {
          title: this.performance_type_title,
          value: this.data.share.analytics.domain_performance.fund,
        },
      ]
      if (this.data.share.benchmark.label) {
        kpis.push({
          title: 'performance_benchmark',
          value: this.data.share.analytics.domain_performance[this.data.share.benchmark.label],
        })
      }
      return kpis
    },
    isFictiveMandate() {
      return isTrue(this.data.share.characteristics.mandat_fictif)
    },
    performance_type_title() {
      if (
        isTrue(this.data.share.characteristics?.mandat_is_perf_net) ||
        this.data.share.characteristics?.mandat_is_perf_net === ''
      )
        return 'performance_net'
      return 'performance_brut'
    },
  },
  watch: {
    async rootWatcher(newRoot, oldRoot) {
      if (newRoot?.toString() === oldRoot?.toString()) return
      this.params.domain = newRoot?.join('|')
      if (this.$route.query.domain === undefined) {
        this.params.domain = this.default_domain
      } else {
        this.params.domain = this.$route.query.domain
      }
      await this.getOverviewData()
    },
  },
  async created() {
    await this.getOverviewData()
  },
  methods: {
    leave(el, done) {
      el.style.opacity = 0
      setTimeout(() => done(), 500)
    },
    togglePlot(name) {
      this.displayed_plot = name
    },
    async getOverviewData() {
      this.loaded = false
      if (!$root.dates || $root.dates.isin_share !== this.params.isinShare || $root.dates.query !== 'performance') {
        $root.dates = await getDatesQuery(this.params, 'performance')
        $root.dates.isin_share = this.params.isinShare
        $root.dates.query = 'performance'
        $root.refresh = $root.refresh + 1
        this.params.domain = $root.domain.join('|')
      }
      console.log('getOverviewData')
      const resp = await axios({
        url: '/dr',
        method: 'post',
        data: {
          variables: this.params,
          query: `query OverviewQuery($isinShare: String!, $domain: Domain) {
            share(isin_share: $isinShare) {
              benchmark {
                label
              }
              analytics {
                domain_performance(domain: $domain) {
                  fund
                  benchmark
                  benchmark2
                  benchmark3
                }
                domain_historical(domain: $domain) {
                  date
                  fund
                  benchmark
                  benchmark2
                  benchmark3
                }
                historical_real_nav {
                  date
                  real_nav
                }
                historical_aum {
                  date
                  aum
                }
                net_asset_value(domain: $domain)
                aum_fund_share_currency(domain: $domain)
              }
              characteristics
              position_count
              transactions(domain: $domain) {
                sale {
                  name
                  var
                }
                cutback {
                  var
                  name
                }
                purchase {
                  var
                  name
                }
                reinforcement {
                  var
                  name
                }
              }
            }
            tables {
              name
              table
            }
          }`,
        },
      })
      this.data = resp.data.data
      $root.nav_data.path = this.data.share.characteristics?.share_letter
      this.loaded = true
    },
    displayed_plot_title(data) {
      let title = ''
      switch (this.displayed_plot) {
        case 'real_nav':
          title = 'nav'
          break
        case 'aum_fund_currency':
          title = 'aum'
          break
        case 'performance_historical':
          title = this.performance_type_title
          break
      }
      return title
    },
  },
}
</script>
<template lang="pug">
transition(@leave='leave')
  loader(v-if="!this.loaded")
template(v-if="this.loaded")
  h1
    .name {{ t[data.share.characteristics.fund_name] || data.share.characteristics.fund_name && data.share.characteristics.fund_name.titleize() || ''}}
    .share(v-if="data.share.characteristics.share_letter") {{ t.share }} {{ data.share.characteristics.share_letter }}
    .row
      router-link.lines(:to="{ path: 'details', query: $route.query }") {{ data.share.position_count }} {{ t.lines }}
      .isin {{ data.share.characteristics.share_id }}
  .row.stretch
    kpi(
      :data="performanceKpis"
      @click="togglePlot('performance_historical')" 
      :class="[{active: this.displayed_plot==='performance_historical'}]"
      aria-label="Performance KPI list"
    )
    kpi(
      :data="[{ title: 'nav', value: format(',' + (data.share.characteristics.share_currency || '€'))(parseFloat(data.share.analytics.net_asset_value)) }, { title: t.asof + ' ' + new Date($root.domain[1]).format('day, mon, year', $root.lang) }]"
      @click="togglePlot('real_nav')" 
      :class="[{active: this.displayed_plot==='real_nav'}]"
      v-if="!isFictiveMandate"
      aria-label="NAV KPI"
    )
    kpi(
      :data="[{ title: 'aum', value: format('M' + data.share.characteristics.fund_currency)(parseFloat(data.share.analytics.aum_fund_share_currency)) }, { title: t.asof + ' ' + new Date($root.domain[1]).format('day, mon, year', $root.lang) }]"
      @click="togglePlot('aum_fund_currency')" 
      :class="[{active: this.displayed_plot==='aum_fund_currency'}]"
      v-if="!isFictiveMandate"
      aria-label="AUM KPI"
    )
  .row
    block(
      :title="displayed_plot_title(data)"
      type="plot-line"
      :data="overviewPlot.data"
      :metadata="{format: overviewPlot.options.format}"
      @rebind="$event.yAxis.formatter(x => format(overviewPlot.options.format)(x))"
      :enableActions="false"
      id="overview-plot"
    )
  .row
    block(title="management_orientation")
      div {{ t[data.share.characteristics.orientation] }}
  .row
    block(title="characteristics")
      pdf-table(:data="tables.characteristics" :metadata="{noHeader: true, contentEditable: false}")
    block.arrows.expand.mgt_bloc(title="commercial_informations")
      pdf-table(v-if="data.share.characteristics.managers_picture"
        :data="tables.customer_informations.filter(subArray => subArray[0] !== 'managers')"
        :metadata="{noHeader: true, contentEditable: false }")
      pdf-table(v-else :data="tables.customer_informations" :metadata="{noHeader: true, contentEditable: false }")
      manager_image.overview(v-if="data.share.characteristics.managers_picture" :managersData="data.share.characteristics.managers_picture" :overview="true")
  .row.stretch
    .column
      block(title="purchase_position")
        kpi(:data="data.share.transactions.purchase.map(v => [v.name, v.var]).map(v => ({title: v[0], value: format('+.2%')(v[1])}))" )
      block(title="reinforcement_position")
        kpi(:data="data.share.transactions.reinforcement.map(v => [v.name, v.var]).map(v => ({title: v[0], value: format('+.2%')(v[1])}))" )
    .column
      block(title="sale_position")
        kpi(:data="data.share.transactions.sale.map(v => [v.name, v.var]).map(v => ({title: v[0], value: format('+.2%')(v[1])}))" )
      block(title="cutback_position")
        kpi(:data="data.share.transactions.cutback.map(v => [v.name, v.var]).map(v => ({title: v[0], value: format('+.2%')(v[1])}))" )
</template>
<style scoped>
.kpi:hover,
.active {
  cursor: pointer;
  border-color: var(--highlight);
}
.management_orientation {
  text-align: justify;
}
.block-characteristics,
.block-commercial_informations,
.block-Positions {
  min-width: 370px;
}
</style>
