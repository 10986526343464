<template>
  <div v-if="loading">{{ t.loading }}...</div>
  <div v-else-if="!dataReport">{{ t['dr_setting'].error.fetching }}</div>
  <div v-else>
    <h1>{{ dataReport.name }}</h1>
    <div class="subtitle">{{ t['dr_setting'].subtitle }}</div>
    <div class="expand block">
      <div class="form column expand">
        <div
          v-for="(variable, index) in variableDefinitions"
          :key="index"
          :class="{ inError: variable.error }"
          class="section expand"
        >
          <h3>{{ variable.name }}</h3>
          <div class="row">
            <div class="select">
              <label>
                {{ t['dr_setting'].variableScope.label }}
              </label>
              <tooltip-helper
                :key="`${variable.name}-scope-helper`"
                :textHeader="t['dr_setting'].helper.scope.header"
                :textBody="t['dr_setting'].helper.scope.content"
              />
              <select v-model="variableDefinitions[index].scope" @change="handleChanges">
                <option :value="undefined" disabled>{{ t['dr_setting'].variableScope.placeholder }}</option>
                <option v-for="scope in scopes" :value="scope">{{ t['dr_setting'].variableScope.value[scope] }}</option>
              </select>
            </div>
            <div
              v-if="variableDefinitions[index].scope && variableDefinitions[index].scope !== 'defaultVariable'"
              class="select"
            >
              <div>
                <label>{{ t['dr_setting'].variableType.label }}</label>
                <tooltip-helper
                  :key="`${variable.name}-type-helper`"
                  :textHeader="t['dr_setting'].helper.type.header"
                  :textBody="t['dr_setting'].helper.type.content"
                />
                <select v-model="variableDefinitions[index].variableType" @change="handleChanges">
                  <option :value="undefined" disabled>{{ t['dr_setting'].variableType.placeholder }}</option>
                  <optgroup
                    v-for="[key, types] in Object.entries(
                      getTypesByGroup(selectedScopes(variableDefinitions[index].scope)),
                    )"
                    :label="t['dr_setting'].variableType.group[key]"
                  >
                    <option v-for="type in types" :value="type">
                      {{ type }}
                    </option>
                  </optgroup>
                </select>
              </div>
              <div
                v-if="
                  ['shareInfo', 'shareInfoAxis', 'parsedFromDomain'].includes(variableDefinitions[index].variableType)
                "
                class="sub_select"
              >
                <label>{{ t['dr_setting'].variableInfo.label }}</label>
                <input
                  type="text"
                  :placeholder="getInfoPlaceholder(variableDefinitions[index].variableType)"
                  v-model="variableDefinitions[index].argument"
                  @change="handleChanges"
                />
              </div>
            </div>
            <div v-else-if="variableDefinitions[index].scope === 'defaultVariable'" class="select">
              <label>{{ t['dr_setting'].variableDefault.label }}</label>
              <input :value="getDefaultVariableValue(variable.name)" disabled />
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <button class="mr2 primary" :disabled="!activeChanges" @click="save">{{ t.save }}</button>
        <button class="mr2" @click="$router.push($root.appath + 'data-reports')">{{ t.cancel }}</button>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import dataReportService from '@100-m/hauru/src/services/DataReportService'

const scopes = ['defaultVariable', 'partial', 'runParameter']
const partialTypes = ['fundId', 'shareId', 'langList']
const runParameterTypes = [...partialTypes, 'domainFreeText', 'parsedFromDomain', 'shareInfo', 'shareInfoAxis']

const loading = ref(true)
const id = ref(null)
const dataReport = ref(null)
const activeChanges = ref(false)
const variableDefinitions = ref([])

const route = useRoute()
const router = useRouter()

const init = async () => {
  loading.value = true
  id.value = parseInt(route.query.id)
  dataReport.value = await getReport(id.value)
  variableDefinitions.value =
    Object.keys(dataReport.value?.variables).reduce((acc, key) => {
      const variableDefinition = dataReport.value.settingVariableParameters?.find(v => v.name === key) || { name: key }
      acc.push(variableDefinition)
      return acc
    }, []) || []
  loading.value = false
}

const getReport = async id => {
  return await dataReportService.getById(id)
}

const getInfoPlaceholder = variableType => {
  switch (variableType) {
    case 'shareInfo':
      return $root.t['dr_setting'].variableInfo.placeholder
    case 'shareInfoAxis':
      return $root.t['dr_setting'].variableInfoAxis.placeholder
    case 'parsedFromDomain':
      return $root.t['dr_setting'].variableDomain.placeholder
    default:
      return ''
  }
}

const getTypesByGroup = types => {
  const inputTypes = types.filter(t => ['fundId', 'shareId', 'langList', 'domainFreeText'].includes(t))
  const computedTypes = types.filter(t => ['shareInfo', 'shareInfoAxis', 'parsedFromDomain'].includes(t))
  return {
    inputTypes,
    computedTypes,
  }
}

const save = async () => {
  activeChanges.value = false
  variableDefinitions.value.forEach(v => {
    delete v.error
  })

  const hasFundId = variableDefinitions.value.some(v => v.variableType === 'fundId')
  const hasUniqFundId = variableDefinitions.value.filter(v => v.variableType === 'fundId').length === 1
  const hasShareId = variableDefinitions.value.some(v => v.variableType === 'shareId')
  const hasUniqShareId = variableDefinitions.value.filter(v => v.variableType === 'shareId').length === 1
  const hasShareInfo = variableDefinitions.value.some(v => ['shareInfo', 'shareInfoAxis'].includes(v.variableType))
  const hasDomainFreeText = variableDefinitions.value.some(v => v.variableType === 'domainFreeText')
  const hasUniqDomainFreeText = variableDefinitions.value.filter(v => v.variableType === 'domainFreeText').length === 1
  const hasParsedFromDomain = variableDefinitions.value.some(v => v.variableType === 'parsedFromDomain')
  const allHaveBeenDefined = variableDefinitions.value.every(v => v.scope)

  let errorDescriptions = []
  if (true === hasFundId && !hasUniqFundId) {
    errorDescriptions.push('uniqFundId')
    variableDefinitions.value.forEach(v => {
      if (v.variableType === 'fundId') v.error = true
    })
  }
  if (true === hasShareId && !hasUniqShareId) {
    errorDescriptions.push('uniqShareId')
    variableDefinitions.value.forEach(v => {
      if (v.variableType === 'shareId') v.error = true
    })
  }
  if (true === hasShareId && !hasFundId) {
    errorDescriptions.push('shareIdNeedFundId')
  }
  if (true === hasShareInfo && !hasShareId) {
    errorDescriptions.push('shareInfoNeedShareId')
  }
  if (true === hasDomainFreeText && !hasUniqDomainFreeText) {
    errorDescriptions.push('uniqDomainFreeText')
    variableDefinitions.value.forEach(v => {
      if (v.variableType === 'domainFreeText') v.error = true
    })
  }
  if (true === hasParsedFromDomain && !hasDomainFreeText) {
    errorDescriptions.push('parsedFromDomainNeedDomain')
  }
  if (
    true === (hasShareInfo || hasParsedFromDomain) &&
    variableDefinitions.value
      .filter(v => ['shareInfo', 'shareInfoAxis', 'parsedFromDomain'].includes(v.variableType))
      .some(v => !v.argument)
  ) {
    errorDescriptions.push('missingArgument')
    variableDefinitions.value.forEach(v => {
      if (['shareInfo', 'shareInfoAxis', 'parsedFromDomain'].includes(v.variableType) && !v.argument) v.error = true
    })
  }
  if (!allHaveBeenDefined) {
    errorDescriptions.push('missingScope')
    variableDefinitions.value.forEach(v => {
      if (!v.scope || (['partial', 'runParameter'].includes(v.variableType) && !v.variableType)) v.error = true
    })
  }

  if (errorDescriptions.length) {
    errorDescriptions.map(translateErrorKey =>
      $root.toast({
        description: $root.t['dr_setting'].error[translateErrorKey],
        type: 'error',
        timeout: 5000,
      }),
    )
    return
  }

  await dataReportService.updateVariableParameters(id.value, variableDefinitions.value)
  router.push($root.appath + 'data-reports')
}

const handleChanges = () => {
  activeChanges.value = true
}

const selectedScopes = scope => {
  return scope === 'partial' ? partialTypes : runParameterTypes
}

const getDefaultVariableValue = variableName => {
  return dataReport.value.variables[variableName]
}

onMounted(() => {
  init()
})
</script>
<style scoped>
.mr2 {
  margin-right: 8px;
}
.form {
  display: flex;
}

.form .row {
  display: flex;
  flex-direction: row;
  flex: 1;
  position: relative;
  margin-top: 10px;
  margin-left: 5px;
}

.form .section {
  display: flex;
  flex: auto;
  flex-direction: column;
  margin: 6px 0;
  position: relative;
}

.form .row .select {
  margin-left: 10px;
}

.form .row .sub_select {
  margin-top: 5px;
}

.form .row .select select {
  margin-top: 3px;
}

.subtitle {
  font-style: italic;
  margin-bottom: 5px;
}

.inError {
  border: 2px solid red;
  border-radius: 10px;
  padding: 3px;
}

.tooltip-content :deep(.select) {
  min-width: 200%;
  white-space: normal;
}
</style>
