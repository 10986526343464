import config from '../../../config'
import { useGraphQL } from '../../../utils/axios'

export function useShare() {
  const getShare = async ({ isinShare }) => {
    const { sendQuery } = useGraphQL(config)
    // NOTE: have to put the useDataReport inside the query, because config.graphqlEndpoint is undefined at the start, only gets injected later
    // const { getDataReport } = useDataReport(config)
    const query = `#graphql
      query shareInfo(
        $shareId: FuidInput!
      ) {
        referential {
          share(fuid: $shareId) {
            riskFreeBenchmarkId
            benchmarkId
            benchmarkName
            fundName
            fundId
            axisOfAnalysis
            asof
            shareCurrency
            ${$root.config.characteristicsSchema || ''}
          }
        }
      }`
    const variables = {
      shareId: isinShare,
    }
    const resp = await sendQuery(query, variables)
    // const resp = await getDataReport('shareInfo', variables)
    return resp.referential.share
  }
  return {
    getShare,
  }
}
