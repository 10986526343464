<template lang="pug">
transition(@leave='leave')
  loader(v-if="isLoading")
.row
  h1 {{ t['create_dqc_report'] || 'create_dqc_report' }}
.row
  subtitle(style="font-style: italic; margin-top: -10px;") {{ t['create_dqc_report_subtitle'] || 'create_dqc_report_subtitle' }}
.block.expand(key="form")
  .expand
    .data-report
      label {{ t['dqc_report_name'] || 'dqc_report_name' }}
      input(v-model="reportName" maxlength="50" placeholder="Enter report Name" type="text" style="width:100%")
    .data-report
      label {{ t['data_report'] || 'data_report' }}
        .report-container
          select(v-model="selectedDataReport" style="width: 70%; margin-right: 10px;")
            option(v-for="report in availableDataReports" :value="report.name" :key="report.id") {{ report.name }}
          .warning(v-if="(!selectorKeys.length > 0) && selectedDataReport" style="display: flex; align-items: center; flex: 1;")
            svg-icon(name="pt-icon-issue" style="margin-right: 5px;")
            .div(style="color:red;") {{ t['no_variables_in_dataReport'] || 'no_variables_in_dataReport' }}
    .row(style="padding:4px")
      .ruleSelection(v-if="selectedRules.length > 0")
        label {{ t['rule'] || 'rule' }}
      .selector(v-if="selectedRules.length > 0")
        label {{ t['selector'] || 'selector' }}
      .tag(v-if="selectedRules.length > 0")
        label {{ t['tag'] || 'tag' }}
    .container-scrolled(style="padding:4px")
      div(v-for="element in selectedRules" :key="element.id")
        .row
          .ruleSelection
            select(v-model="element.ruleId" style="width:100%")
              option(v-for="rule in rules" :value="rule.id" :key="rule.id") {{ rule.name }}
          .selector
            select(v-model="element.selector" style="width:100%")
              option(v-for="selector in selectorKeys" :value="selector" :key="selector") {{ selector }}
          .tag
            input(v-model="element.tag" maxlength="50" placeholder="Enter tag" type="text" style="width:100%")
          .delete
            div(@click="deleteRule(element)" style="max-width:100%") X
    .row(style="padding:4px")
      button.primary(class="hover:underline" @click="addRule") {{ t['add_rule'] || 'add_rule' }}
  .row(style="padding:4px")
    .action
      button.primary(@click="saveReport") {{ t['save'] || 'save' }}
      button.primary(@click="cancel") {{ t['cancel'] || 'cancel' }}
</template>

<script setup>
import { ref, reactive, onMounted, watch } from 'vue'
import { useRouter, useRoute } from 'vue-router'
import { useDataQualityRule } from '../composables/useDataQualityRule'
import dataReportService from '@100-m/hauru/src/services/DataReportService'
import dataQualityReportService from '../../../services/DataQualityReportService'

const router = useRouter()
const route = useRoute()
const { rules } = useDataQualityRule()

const isLoading = ref(true)
const hasUnsavedChanges = ref(false)
const isNewReport = ref(false)
const reportId = ref(0)
const reportName = ref('')
const selectedRules = ref([])
const selectedDataReport = ref('')
const availableDataReports = ref([])
const dataReportResults = reactive({})
const selectorKeys = ref([])

const extractKeys = (data, parentKey = '') => {
  let keys = []
  if (typeof data === 'object' && data !== null && !Array.isArray(data)) {
    for (const key in data) {
      if (data.hasOwnProperty(key)) {
        const fullKey = parentKey ? `${parentKey}.${key}` : key
        keys.push(fullKey)
        keys = keys.concat(extractKeys(data[key], fullKey))
      }
    }
  } else if (Array.isArray(data) && parentKey) {
    keys.push(parentKey)
  }
  return keys
}

const initialize = async () => {
  isNewReport.value = !route.query.id
  isLoading.value = true
  reportId.value = route.query.id
  if (route.query.id) {
    const dqReport = await dataQualityReportService.get(route.query.id)
    reportName.value = dqReport.name
    selectedRules.value = dqReport.rules?.map(e => ({ selector: e.path, ruleId: e.rule?.id, tag: e.tag }))
    selectedDataReport.value = dqReport.dataReport?.name
    if (dqReport.dataReport) {
      await initializeSelectorKeys(dqReport.dataReport.id)
    }
  } else {
    resetForm()
  }
  hasUnsavedChanges.value = false
  availableDataReports.value = (await dataReportService.list()).filter(dr => typeof dr.id === 'number')
  isLoading.value = false
}

const resetForm = () => {
  reportName.value = ''
  selectedRules.value = []
  selectedDataReport.value = ''
}

const saveReport = async () => {
  if (!reportName.value || !validateRules(selectedRules.value) || !selectedDataReport.value) {
    $root.toast({ description: $root.t.dqc_error_name, type: 'error', timeout: 5000 })
    return
  }
  try {
    if (isNewReport.value) {
      await dataQualityReportService.create(
        reportName.value,
        selectedRules.value.map(e => ({ path: e.selector, ruleId: e.ruleId, tag: e.tag })).filter(e => e.ruleId),
        selectedDataReport.value,
      )
    } else {
      await dataQualityReportService.update(
        reportId.value,
        reportName.value,
        selectedRules.value.map(e => ({ path: e.selector, ruleId: e.ruleId, tag: e.tag })),
        selectedDataReport.value,
      )
      $root.toast({ description: $root.t.saved || 'Saved', type: 'success', timeout: 5000 })
    }
    router.push($root.appath + 'data-quality-reports')
  } catch (e) {
    $root.toast({ description: e.message, type: 'error', timeout: 5000 })
  } finally {
    hasUnsavedChanges.value = false
  }
}

const addRule = () => {
  selectedRules.value.push({
    id: selectedRules.value.length + 1,
    ruleId: null,
    selector: '',
    tag: '',
  })
}

const deleteRule = rule => {
  const index = selectedRules.value.findIndex(r => r.id === rule.id)
  selectedRules.value.splice(index, 1)
}

const cancel = () => {
  router.push($root.appath + 'data-quality-reports')
}

const initializeSelectorKeys = async dataReportId => {
  isLoading.value = true // Set loading to true before fetching data
  dataReportResults.value = await dataReportService.run(dataReportId, {}, { preProcess: false, postProcess: true })
  refreshSelectorKeys()
  isLoading.value = false // Set loading to false after data is fetched
}

const refreshSelectorKeys = () => {
  selectorKeys.value = Array.from(new Set([...extractKeys(dataReportResults.value?.data)]))
}

// Ajout du watch pour selectedDataReport
let isUserReverting = false

watch(selectedDataReport, async (newDataReport, oldDataReport) => {
  if (isUserReverting) {
    isUserReverting = false
    return
  }

  if (newDataReport !== oldDataReport) {
    if (selectedRules.value.length > 0 && oldDataReport) {
      if (window.confirm($root.t.erase_rules)) {
        selectedRules.value = []
      } else {
        isUserReverting = true
        selectedDataReport.value = oldDataReport
        return // Exit early if the user cancels the confirmation
      }
    }

    if (newDataReport) {
      const selectedReport = availableDataReports.value.find(report => report.name === newDataReport)
      if (selectedReport) {
        await initializeSelectorKeys(selectedReport.id)
      }
    } else {
      // Reset keys and results if newDataReport is null or empty
      selectorKeys.value = []
      dataReportResults.value = {}
    }
  }
})

onMounted(initialize)

const validateRules = rules => {
  return rules.every(rule => rule.ruleId && rule.selector)
}
</script>

<style scoped>
button {
  margin-right: 16px;
}

.ruleSelection,
.selector,
.tag {
  margin-bottom: 5px;
  margin-right: 5px;
}

.action {
  margin-right: 5px;
}

.data-report {
  padding: 4px;
}

.delete {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  text-align: center;
  color: black;
}

.delete:hover > * {
  color: var(--primary) !important;
}

.container-scrolled {
  overflow-y: auto;
  max-height: 42vh;
}

.ruleSelection {
  width: 20%;
}
.selector {
  width: 400px;
}

.tag {
  width: 20%;
}

.report-container {
  display: flex;
  align-items: center;
  width: 100%;
}
</style>
