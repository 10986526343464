import { gql, GraphQLClient } from 'graphql-request'
import { createClient } from 'graphql-ws'
import AbstractDatabase from '../AbstractDatabase.js'
import { absWsUrl, urlJoin } from '../../utils.js'
export default class HasuraBrowser extends AbstractDatabase {
  #webSocketClient
  #unSubscriptionsMap = new Map()
  #hasuraCredentials = { url: '', password: '', dataSourceName: '' }
  #getToken = () => null
  #getRole = () => null

  constructor(config) {
    super()
    this.initDB(config)
  }

  // generic public methods
  initDB({
    hasuraEndpoint,
    hasuraDataSourceName,
    getToken = () => window.$root && window.$root.getProfile().idToken,
    getRole = () => window.$root && window.$root.getProfile().role
  }) {
    if (!hasuraEndpoint || !hasuraDataSourceName) {
      throw new Error('hasuraEndpoint or postgresqlName are null')
    }
    this.#getToken = getToken
    this.#getRole = getRole
    this.#hasuraCredentials = {
      url: hasuraEndpoint,
      dataSourceName: hasuraDataSourceName
    }

    const websocketParams = {
      connectionParams: () => ({
        headers: {
          Authorization: `Bearer ${this.#getToken()}`,
          'x-hasura-role': this.#getRole()
        }
      })
    }
    websocketParams.url = absWsUrl(hasuraEndpoint)

    this.#webSocketClient = createClient({
      ...websocketParams
    })
    this.setDbRef(
      new GraphQLClient(hasuraEndpoint + '/v1/graphql', {
        headers: () => ({
          Authorization: `Bearer ${this.#getToken()}`,
          'x-hasura-role': this.#getRole()
        })
      })
    )
    return true
  }

  async get(path, callback, specificQuery, specificVariable) {
    let query = gql`
      query data($path: String!) {
        data(where: { path: { _ilike: $path } }) {
          path
          value
        }
      }
    `

    let postProcess
    let postProcessFilter

    let variable = {}
    if (specificQuery) {
      query = specificQuery
    }
    if (specificVariable) {
      variable = specificVariable
    } else {
      const formattedPath = this.formatPathAndValue(this.formatPath(path))
      postProcess = formattedPath.postProcess
      postProcessFilter = formattedPath.formattedValue

      variable.path = `%${formattedPath.formattedPath}%`
    }

    const value = await this.gqlRequest(query, variable)

    if (callback) {
      return callback(this.formatValue(value, postProcess, postProcessFilter))
    }
    return this.formatValue(value, postProcess, postProcessFilter)
  }

  async update(path, value) {
    const res = await this.gqlRequest(
      `
      mutation Mutation($path: String!, $value: JSON!) {
        update(path: $path, value: $value)
      }
    `,
      { path, value }
    )
    return res.update
  }

  set(path, value) {
    console.log('set ', { path, value })
    return this.update(path, value)
  }

  on(path, event, callback, specificQuery, specificVariable) {
    const { formattedPath, postProcess, formattedValue } =
      this.formatPathAndValue(this.formatPath(path))

    const query = `
      subscription data {
        data(where: { path: { _ilike: "%${formattedPath}%" } }) {
          path
          value
        }
      }
    `
    const unSubscription = this.#webSocketClient.subscribe(
      {
        query: specificQuery || query,
        variables: specificVariable || null
      },
      {
        next: (data) => {
          const formattedData = this.formatValue(
            data.data,
            postProcess,
            formattedValue
          )
          callback(formattedData)
        },
        error: (err) => {
          console.error(`Subscription error ${formattedPath}_${event}:`, err)
        },
        complete: () => {}
      }
    )

    this.#unSubscriptionsMap.set(`${formattedPath}_${event}`, unSubscription)

    return unSubscription
  }

  off(path, event) {
    const { formattedPath } = this.formatPathAndValue(path)
    const unSubscription = this.#unSubscriptionsMap.get(
      `${formattedPath}_${event}`
    )
    if (unSubscription) {
      unSubscription()
      this.#unSubscriptionsMap.delete(`${formattedPath}_${event}`)
    }
  }

  // specific public methods
  async incrementAndGetRunId() {
    const res = await this.gqlRequest(`
      mutation Mutation {
        incrementAndGetRunId
      }
    `)

    return res.incrementAndGetRunId
  }

  syncRunsForYear(year, event, callback) {
    const { path, query, variable } = this.presetRunsForYear(year)
    return this.on(
      path,
      `${year}_${event}`,
      callback,
      query.replace('query', 'subscription'),
      variable
    )
  }

  async getPartialRunsPerYear(year, limit, callback) {
    const { path, query, variable } = this.presetRunsForYear(year, limit)
    return this.get(path, callback, query, variable)
  }

  unSyncRunsForYear(year, event) {
    return this.off(
      year === 'all' ? 'data.runs' : `data.runs`,
      `${year}_${event}`
    )
  }

  // private specific methods
  async gqlRequest(query, variable) {
    return this.getDbRef().request(query, variable)
  }

  presetRunsForYear(year, limit) {
    const path = '/data/runs'
    if (year === 'all') {
      return { path }
    }

    const query = gql`
      query data($year: jsonb) {
        data(
          where: {
              value: { _contains: $year }, path: { _ilike: "data.runs%" } 
          },
          ${limit ? `limit: ${limit},` : ''}
            order_by: {value: desc}
        ) {
          path
          value
        }
      }
    `
    const variable = { year: { year } }

    return { path, query, variable }
  }

  formatValue({ data }, postProcess, formattedValue) {
    const value = data.map((e) => e.value)
    if (postProcess === 'getOne') {
      return value[0]
    }

    if (postProcess === 'deepGet') {
      const deepGet = (obj, pathObj) => {
        return Object.keys(pathObj).reduce((acc, key) => {
          if (acc === null || typeof acc !== 'object') {
            return undefined
          }
          return deepGet(acc[key], pathObj[key])
        }, obj)
      }

      return deepGet(value[0], JSON.parse(formattedValue))
    }

    let sortedValue = value

    if (
      sortedValue[0] &&
      (sortedValue[0].id !== undefined || sortedValue[0].id !== null)
    ) {
      sortedValue = sortedValue.sort((a, b) => a.id - b.id)
    }

    return sortedValue.reduce((acc, curr, index) => {
      acc = { ...acc, [index]: curr }
      return acc
    }, {})
  }

  formatPath(path) {
    let formattedPath
    if (typeof path === 'string') {
      formattedPath = path.split('/').filter((e) => e)
    } else if (Array.isArray(path)) {
      formattedPath = path.filter((e) => e)
    } else {
      throw new TypeError('Invalid path type, must be string or array')
    }

    if (formattedPath[0] === '.') {
      formattedPath.shift()
    }

    return formattedPath.join('.')
  }

  formatPathAndValue(path, value = '') {
    const iterateValues = (expected) => {
      if (parts.length > expected) {
        const formattedValue = {}
        parts
          .slice(expected)
          .reduce(
            (acc, part, index, slicedParts) =>
              (acc[part] =
                index === slicedParts.length - 1 ? value : acc[part] || {}),
            formattedValue
          )
        const formattedPath = parts.slice(0, expected).join('.')
        return {
          formattedPath,
          formattedValue: JSON.stringify(formattedValue || null).replace(
            /'/g,
            "''"
          ),
          postProcess: 'deepGet'
        }
      }

      if (parts.length === expected) {
        return {
          formattedPath: path,
          formattedValue: value
            ? JSON.stringify(value).replace(/'/g, "''")
            : null,
          postProcess: 'getOne'
        }
      }

      if (parts.length < expected && value) {
        let values = value

        if (typeof values === 'object') {
          values = Object.values(values)
        }

        if (
          (Array.isArray(values) && values[0].id !== null) ||
          values[0].id !== undefined
        ) {
          values = values.sort((a, b) => a.id - b.id)
        }

        return values.map((v, k) =>
          formatPathAndValue(parts.concat(v.id || k).join('.'), v)
        )
      }

      if (parts.length < expected && !value) {
        return {
          formattedPath: path,
          formattedValue: null,
          postProcess: null
        }
      }
    }
    const parts = path.split('.').filter(Boolean)
    switch (true) {
      case parts[0] === 'data' &&
        [
          'documents',
          'portfolios',
          'presses',
          'runs',
          'templates',
          'workflows',
          'impressions',
          'commands',
          'waiting',
          'users'
        ].includes(parts[1]):
        return iterateValues(3)
      case ['data', 'secrets', 'config'].includes(parts[0]):
        return iterateValues(2)
      default:
        return iterateValues(1)
    }
  }
}
