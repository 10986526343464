/**
 * Merge function
 * @param args List of object to merge
 * @return {*}
 */
export const merge = (...args) =>
  args.reduce(
    (acc, o) => (
      Object.keys(o || {}).forEach(
        k => (acc[k] = typeof o[k] === 'object' && !Array.isArray(o[k]) ? merge(acc[k] || {}, o[k]) : o[k]),
      ),
      acc
    ),
    {},
  )
