<style>
.templates .cell-format {
  flex: unset !important;
}
</style>

<style scoped>
.ml-2 {
  margin-left: 8px;
}
</style>

<template lang="pug">
transition(@leave='leave')
  loader(v-if="isLoading")
.row
  h1(style="margin-right: 0") Templates
  badge(:count="templates.length")
subtitle(style="font-style: italic; margin-top: -5px;") {{ t['client-edition-templates-subtitle'] }}
.block.expand
  spreadsheet.stripped.expand.nosort(:data="templates" :options="{ columns: ['format', 'name', 'Data Report', 'Data Quality Report','Last Update', 'Usage', ,'actions'], editable: false }")
    template(v-slot:cell-format="{ column, line }")
      .tt(style="display: flex; justify-content: center;" :tt="line.format?.toUpperCase()")
        ui-asset.file_icon(:name="'icon_' + (line.type?.toLowerCase() || 'pdf')")
    template(v-slot:cell-data-quality-report="{ column, line }")
        div {{ line.dataQualityReportName }}
    template(v-slot:cell-name="{ column, line }")
      div {{ line.name }}
    template(v-slot:cell-last-update="{ column, line }")
      div {{ line.createdAt }}
    template(v-slot:cell-usage="{ column, line }")
      div {{ line.usage }}
    template(v-slot:cell-data-report="{ column, line }")
      div {{ line.dataReportName }}
    template(v-slot:cell-actions="{ column, line }")
      .row
        button.ghost(tt="View" @click="edit(line)" v-if="line.readOnly")
          svg-icon(name="pt-icon-eye-open")
        button.ghost(tt="Edit" @click="edit(line)" v-else)
          svg-icon(name="pt-icon-edit")
        button.ghost(v-if="line.type !=='xlsx'" :tt="t.duplicate" @click="duplicate(line)")
          svg-icon(name="ic_file_copy")
        button.ghost(v-else tt="Download" @click="downloadExcel(line)")
          svg-icon(name="pt-icon-cloud-download")
        button.ghost(tt="Delete" @click="confirmRemoveId=line" v-if="line.usage === 0" :disabled="line.readOnly")
          svg-icon(name="pt-icon-trash")
  .row
    button.primary(@click="createPdf") {{ t.create_pdf_template }}
    button.primary.ml-2(v-if="excelTemplatesFeature === true" @click="$router.push({ path: $root.appath + 'excel-template' })") {{ t.create_excel_template }}

  popup(:show="confirmRemoveId")
      template(v-slot:header)
        | {{t["confirmation_remove"]}}
      template(v-slot:content)
        | {{t["text_remove"]}}
      template(v-slot:action)
        button.secondary-action(@click="confirmRemoveId = null") {{t['confirmation_btn_close']}}
        button.main-action(@click="remove(confirmRemoveId)") {{t["confirmation_btn_delete"]}}
</template>

<script>
import Popup from '@100-m/hauru/src/components/ui/popup.vue'
export default {
  icon: 'ic_picture_as_pdf',
}
</script>

<script setup>
import { ref, onMounted } from 'vue'
import templateService from '@100-m/hauru/src/services/TemplateService'
import dataReportService from '@100-m/hauru/src/services/DataReportService'
import { commandrSDK } from '@100-m/hauru/src/features/commandr'
import { merge } from '@100-m/hauru/src/utils/merge'
import excelTemplateService from '@100-m/hauru/src/services/ExcelTemplateService'
import dataQualityReportService from '@100-m/hauru/src/services/DataQualityReportService'
import { downloadResponseAsFile } from '@100-m/hauru/src/services/RestService'
import UiAsset from '@100-m/hauru/src/components/ui/ui-asset.vue'

const isLoading = ref(true)
const templates = ref([])
const confirmRemoveId = ref(null)
const excelTemplatesFeature = ref(config.excelTemplatesFeature)

const getTemplatesWithUsage = async () => {
  isLoading.value = true
  const dqReportList = await dataQualityReportService.all()
  const partialTemplates = await templateService.listWithTemplateContext()
  const pressesTemplateNames = (await commandrSDK.get('/data/presses'))
    ?.v()
    .filter(press => !press.disabled)
    .map(presse => presse.template)
  const dataReportList = await dataReportService.list()
  const templatesInformation = partialTemplates.reduce((acc, partialTemplate) => {
    const nbPresses = pressesTemplateNames?.filter(
      pressTemplateName => pressTemplateName === partialTemplate.name,
    ).length
    const dataReportName =
      dataReportList.find(dataReport => dataReport.id === partialTemplate.dataReportId)?.name || '-'
    const dataQualityReportName =
      dqReportList.find(dqr => dqr.id === partialTemplate.dataQualityCheckReportId)?.name || '-'
    acc.push({ ...partialTemplate, usage: nbPresses, dataReportName, dataQualityReportName })
    return acc
  }, [])
  isLoading.value = false
  return await templatesInformation
}

const createPdf = async () => {
  const newName = prompt('Enter template name', new Date().format('YYYY-MM-DD hh:mm'))
  if (templates.value.find(v => v === newName)) return alert('Template already exists')
  const defaultTemplate = {
    theme: {
      prefix: 'grk',
      page_size: 'A4',
      page_orientation: 'portrait',
      spacing: 3,
      pageMargin: 2,
    },
    nodes: [
      {
        type: 'column',
        nodes: [
          { component: 'header' },
          { options: { title: 'block' }, component: 'block' },
          { options: { title: 'block' }, component: 'block' },
          { component: 'footer' },
        ],
      },
    ],
  }
  const newTemplate = merge(defaultTemplate, config.defaultTemplate)
  await templateService.create(newName, newTemplate, 'pdf')
  edit({ name: newName })
}

const edit = templateInfo => {
  if (templateInfo.type === 'xlsx') {
    $root.$router.push({ path: $root.appath + 'excel-template', query: { name: templateInfo.name } })
  } else {
    $root.$router.push(`/builder/?templateName=${templateInfo.name}`)
  }
}

const duplicate = async templateInfo => {
  const template = await templateService.get(templateInfo.name)
  const newName = prompt(
    'Enter template name',
    `${name.replace(/ \d\d\d\d-\d\d-\d\d \d\d:\d\d/, '')} ${new Date().format('YYYY-MM-DD hh:mm')}`,
  )
  if (newName && newName.length) {
    await templateService.create(newName, template.layout, 'pdf', template.dataReportId)
    edit({ name: newName })
  } else {
    $root.toast({ description: $root.t.missing_mandatory_fields, type: 'error', timeout: 5000 })
  }
}

const remove = async templateInfo => {
  if (templateInfo.readOnly) return
  confirmRemoveId.value = null
  await templateService.removeAll(templateInfo.name)
  templates.value = await getTemplatesWithUsage()
}

const downloadExcel = async templateInfo => {
  // console.log('downloadExcel', templateInfo)
  const response = await excelTemplateService.download(templateInfo.id)
  downloadResponseAsFile(response, 'application/vnd.ms-excel', `${templateInfo.name}-${templateInfo.id}.xlsx`)
}

onMounted(async () => {
  templates.value = await getTemplatesWithUsage()
})
</script>
