<template>
  <!-- arrow-set-story -->
  <div>
    <story-template :story="story" class="h-[300px]">
      <div class="w-full" :ref="arrowsReactiveRef.updateEl">
        <nx-arrow-set>
          <nx-arrow
            class="stroke-[#1b9e77]"
            :start-x="story.props.startX"
            :start-y="story.props.startY"
            :end-x="story.props.endX"
            :end-y="story.props.endY"
            :stroke-width="story.props.strokeWidth"
            :round-size="story.props.roundSize"
            :orientation="story.props.orientation as any"
            :curve-type="story.props.curveType as any"
          ></nx-arrow>
          <nx-arrow-position-selector
            v-model:x="story.props.startX"
            v-model:y="story.props.startY"
            :ref-el="arrowsReactiveRef.value"
          ></nx-arrow-position-selector>
          <nx-arrow-position-selector
            v-model:x="story.props.endX"
            v-model:y="story.props.endY"
            :ref-el="arrowsReactiveRef.value"
          ></nx-arrow-position-selector>
        </nx-arrow-set>
      </div>
    </story-template>

    <div class="h-36"></div>
  </div>
</template>

<script setup lang="ts">
import { watchEffect } from 'vue'
import { elementReactiveRef, INxArrowProps } from '@hauru/common'
import { createStory, controls } from '@storytime'

const arrowsReactiveRef = elementReactiveRef()

const story = createStory<INxArrowProps>('nx-arrow')
story.setProps({
  startX: 20,
  startY: 20,
  endX: 800,
  endY: 300,
  orientation: 'horizontal',
  strokeWidth: controls.range(2, 1, 10, 1),
  roundSize: controls.range(16, 2, 64, 2),
  curveType: 'elliptical',
})

watchEffect(() => {
  if (!arrowsReactiveRef.value || !arrowsReactiveRef.width) return

  story.props.endX = arrowsReactiveRef.width - 20
  story.props.endY = arrowsReactiveRef.height - 20
})
</script>
