export const lower = str => str.toLowerCase()
export const upper = str => str.toUpperCase()
export const capitalize = str => str.replace(/./, c => c.toUpperCase())
export const words = (str, sep = /[-_,.\s]/) =>
  str
    .normalize('NFKD')
    .replace(RegExp('[^A-z0-9' + sep.source.slice(1, -1) + ']', 'g'), '')
    .replace(/([a-z])([A-Z\d])/g, '$1 $2')
    .split(sep)
    .filter(Boolean)
export const titleize = str =>
  String.words(str)
    .map(v => v.toLowerCase())
    .map(v => v.replace(/./, c => c.toUpperCase()))
    .join(' ')

export const format = (str, ...args) => {
  if (!args.length) args = ['title']
  if (['title', 'pascal', 'camel', 'dash', 'list', 'kebab', 'underscore', 'snake'].includes(args[0])) {
    let words = String.words(str).map(v => v.toLowerCase())
    let sep = ' '
    if (args[0] === 'camel') return str.format('pascal').replace(/./, c => c.toLowerCase())
    if (['title', 'pascal'].includes(args[0])) words = words.map(v => v.replace(/./, c => c.toUpperCase()))
    if (['pascal'].includes(args[0])) sep = ''
    if (['dash', 'list', 'kebab'].includes(args[0])) sep = '-'
    if (['underscore', 'snake'].includes(args[0])) sep = '_'
    return words.join(sep)
  }
  let i = 0
  let fn = m => (args[m] == null ? '' : String(args[m]))
  if (typeof args[0] === 'object') fn = m => args[0][m]
  if (typeof args[0] === 'function') fn = args.shift()
  return str.replace(/\{[^}]*\}/g, m => fn(m.length === 2 ? i : m.slice(1, -1), i++) || '')
}
