import {
  componentsFromGlobRecord,
  directivesFromGlobRecord,
  pagesFromGlobRecord,
  translationFromGlobRecord,
} from './utils/platformBuilder'

/**
 * Base app with sync components
 */
export default {
  name: 'base',
  pages: pagesFromGlobRecord(import.meta.globEager('./pages/**/*.vue'), './pages'),
  components: componentsFromGlobRecord(import.meta.globEager('./components/**/*.vue')),
  directives: directivesFromGlobRecord(import.meta.globEager('./directives/**/*.js')),
  translations: translationFromGlobRecord(import.meta.globEager('./translations/**/*.yml')),
}
