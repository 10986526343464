<template lang="pug">
.lang
  label(v-if="!hideLabel") {{ t.lang }}
  select#lang.dark(:value="$root.lang" @change="$root.lang = $event.target.value")
    option(v-for="lang in $root.langs" :key="lang" :value="lang") {{ lang.toUpperCase() }}
</template>

<script>
export default {
  props: {
    hideLabel: {
      type: Boolean,
      default: false,
    },
  },
}
</script>
