import config from '@100-m/hauru/src/config'
import { titleize_normal } from '../composables/useProcessTable'

export default {
  computed: {
    displayDelta() {
      return this.data.share.characteristics.display_delta
    },
    displaySensi() {
      return this.data.share.characteristics.display_sensitaux
    },
    external_assets() {
      if (!this.data || !this.data.share.external_assets) return []
      const external_assets = this.data.share.external_assets.split(',')
      if (external_assets.length === 0) return []
      return external_assets.map(
        d => `${d}_${this.data.share.characteristics.fund_id}_${this.perf_domain[1].slice(0, 7)}.jpg`,
      )
    },
    perf_domain() {
      if (this.data.share.analytics.domain_historical.length)
        return [
          this.data.share.analytics.domain_historical[0].date,
          this.data.share.analytics.domain_historical[this.data.share.analytics.domain_historical.length - 1].date,
        ]
    },
    main_lines_formated() {
      const dimensions = this.data.share.characteristics.dimensions_main_lines
        .split(',')
        .map(d => d.trim())
        .filter(d => !!d)
      return this.data.share.allocation_analytics.lines.reduce((acc, line) => {
        acc[line.type] = [
          [line.header, ...dimensions, 'weight'],
          ...line.allocationsDesc.map(el => [
            this.titleize_normal(el.name),
            ...dimensions.map(d => this.convertToLink(el.axis[d])),
            el[line.metric],
          ]),
        ]
        return acc
      }, {})
    },
    total_lines_top10_weight() {
      return this.data.share.allocation_analytics.lines
        .reduce((acc, a) => acc.concat(a.allocationsDesc), [])
        .map(a => a.weight_fund)
        .sort((a, b) => b - a)
        .slice(0, 10)
        .reduce((acc, a) => acc + a, 0)
    },
    issuers_top10_formated() {
      const dimensions = this.data.share.characteristics.dimensions_main_lines
        .split(',')
        .map(d => d.trim())
        .filter(d => !!d)
      return this.data.share.allocation_analytics.issuers_top10.map(issuer => {
        const axis = Object.fromEntries(dimensions.map(d => [d, this.convertToLink(issuer.axis[d])]))
        return {
          name: this.titleize_normal($root.t[issuer.axis.emetteur] || issuer.axis.emetteur),
          ...axis,
          weight: issuer.weight_fund,
        }
      })
    },
    histo_alloc_data() {
      const settings = this.data.share.characteristics.display_histo_alloc.split('.')
      if (settings.length < 2) {
        console.log('wrong setting histo_alloc')
        return {}
      }
      const metric = settings[1] || 'weight_fund'
      const color_pallet = [
        'var(--primary)',
        'var(--secondary)',
        'var(--third)',
        'var(--fourth)',
        'var(--fifth)',
        'var(--sixth)',
        'var(--seventh)',
        'var(--eighth)',
      ]

      const fund_id = this.data.share.characteristics.fund_id
      const histo_alloc_sort = (fund_id => {
        if (fund_id === 31875)
          return d =>
            [
              'lfam_sub_debt_coco_s',
              'lfam_sub_debt_assurance',
              'lfam_sub_debt_hybrid_corporate',
              'lfam_sub_debt_tier_1',
              'lfam_sub_debt_tier_2',
              'lfam_sub_debt_autres_subordonnees',
              'lfam_sub_debt_dirr___non_subordonnees',
              'NA',
            ].indexOf(d)
        if (fund_id === 287 || fund_id === 40162)
          return d =>
            [
              'classe_d_actif__pct_expo_actions',
              'classe_d_actif__pct_expo_obligations',
              'classe_d_actif__pct_expo_monetaires',
              'classe_d_actif__pct_expo_liquidites',
              'NA',
            ].indexOf(d)
        if (fund_id === 211475)
          return d =>
            [
              'classe_d_actif__pct_expo_actions',
              'classe_d_actif__pct_expo_obligations',
              'classe_d_actif__pct_expo_diversifie',
              'classe_d_actif__pct_expo_monetaires',
              'classe_d_actif__pct_expo_liquidites',
              'NA',
            ].indexOf(d)
        if (fund_id === 297299)
          return d =>
            [
              'classe_d_actif_mai_dirr___actions_dividend_yield',
              'classe_d_actif_mai_dirr___reits',
              'classe_d_actif_mai_strategie_credit_mai',
              'classe_d_actif_mai_dette_emergente',
              'classe_d_actif_mai_dirr___monetaire_mai',
              'classe_d_actif_mai_dirr___derives__hors_changes_',
              'classe_d_actif_mai_dirr___change__avec_derives_',
              '',
            ].indexOf(d)
        return d => d
      })(fund_id)

      const histo_alloc = this.data.share.allocation_history
        .filter()
        .sort(d => histo_alloc_sort(d.dimension_value))
        .map((group, i) => {
          return group.entries
            .map(d => {
              return {
                x: d.date,
                y: d[metric],
                name: group.dimension_value || 'NA',
                color: color_pallet[i] || 'grey',
              }
            })
            .sort('x')
        })
      return { histo_alloc }
    },
    histo_alloc_options() {
      return {
        axis_format: { y: '.1%' },
        xAxis_type: 'Time',
        xAxis_config: 'time_year',
        plots: {
          histo_alloc: {
            plot_type: 'StackedArea',
            attr: {
              fill: d => d.color,
              opacity: 0.75,
            },
          },
        },
      }
    },
    historical_delta_action() {
      if (!this.data.share.characteristics.display_delta) return {}
      const data = this.data.share.analytics.weekly_historical.reduce((acc, v) => {
        if (
          this.data.share.characteristics.display_benchmark_comp &&
          this.data.share.characteristics.display_benchmark_delta
        ) {
          acc[v.date] = {
            [this.data.share.fund_or_mandat]: +v.delta_action,
            benchmark: +v[`${this.data.share.benchmark?.label}_delta_action`],
          }
        } else {
          acc[v.date] = { [this.data.share.fund_or_mandat]: +v.delta_action }
        }
        return acc
      }, {})
      const options = {
        format: Math.abs(Math.max(data.v()) - Math.min(data.v())) > 0.01 ? '.0%' : '.2%',
      }
      const days_number = Math.round(
        (new Date(data.keys().last()).getTime() - new Date(data.keys().first()).getTime()) / (1000 * 3600 * 24),
      )
      if (days_number < 40 && days_number > 22) {
        options.format_xAxis = {
          interval: 'day',
          step: Math.round(days_number / 10),
          formatter: "d=>d.format('DD/MM')",
        }
      }
      if (days_number < 22) {
        options.format_xAxis = {
          interval: 'day',
          step: 5,
          formatter: "d=>d.format('DD/MM')",
        }
      }
      return {
        data,
        options,
      }
    },
    historical_sensitaux() {
      if (!this.data.share.characteristics.display_sensitaux) return {}
      const data = this.data.share.analytics.weekly_historical.reduce((acc, v) => {
        if (
          this.data.share.characteristics.display_benchmark_comp &&
          this.data.share.characteristics.display_benchmark_sensitaux
        ) {
          acc[v.date] = {
            [this.data.share.fund_or_mandat]: +v.sensitaux,
            benchmark: +v[`${this.data.share.benchmark?.label}_sensitaux`],
          }
        } else {
          acc[v.date] = { [this.data.share.fund_or_mandat]: +v.sensitaux }
        }
        return acc
      }, {})
      const options = { format: '.1f' }
      const days_number = Math.round(
        (new Date(data.keys().last()).getTime() - new Date(data.keys().first()).getTime()) / (1000 * 3600 * 24),
      )
      if (days_number < 40 && days_number > 22) {
        options.format_xAxis = {
          interval: 'day',
          step: Math.round(days_number / 10),
          formatter: "d=>d.format('DD/MM')",
        }
      }
      if (days_number < 22) {
        options.format_xAxis = {
          interval: 'day',
          step: 5,
          formatter: "d=>d.format('DD/MM')",
        }
      }
      return {
        data,
        options,
      }
    },
    movements_filter() {
      return this.data.share.characteristics.movements_filter
    },
  },
  methods: {
    parseAxis(format_xAxis) {
      const res = format_xAxis
      format_xAxis.formatter && (res.formatter = eval(format_xAxis.formatter))
      return res
    },
    download_asset(asset_name) {
      const urlParams = new URLSearchParams(window.location.search)
      const token = urlParams.get('token') || localStorage.getItem('idToken')
      return `https://fireauth.100m.io/download?project=${config.project}&auth0_token=${token}&path=/raw/external_assets/output/${asset_name}`
    },
    parse_date_param(date) {
      if (date.match('[0-9]{4}/[0-9]{2}/[0-9]{2}'))
        return date.match('[0-9]{4}/[0-9]{2}/[0-9]{2}')[0].replaceAll('/', '-')
      if (date.match('[0-9]{4}-[0-9]{2}-[0-9]{2}')) return date.match('[0-9]{4}-[0-9]{2}-[0-9]{2}')[0]
      return new Date(this.perf_domain[1]).minus(date).format()
    },
    format_transactions(type, data) {
      const metricColumn = this.movements_filter === 'filter_derivative' ? 'weight' : '%actif'
      const v = data.length
        ? data.map(el => [this.titleize_normal(el.name), el.var])
        : [['no_' + type.replace('sale', 'sales'), ' ']]
      return [[type + '_position', metricColumn], ...v].filter(d => d[1] !== 0)
    },
    convertToLink(text) {
      if (!text) return text
      if (text === 'NA') return ''

      const translatedValue = $root.t.text || text

      const isURL =
        /\b(?:http|https):\/\/[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)+(?:\/[a-zA-Z0-9-._~:?#[\]@!$&'()*+,;=]*)?\b/gm
      if (isURL.test(translatedValue)) {
        const link = $root.t.Link || 'Link'
        const href = translatedValue
        return `<a href='${href}' style="color: #2a70d1; text-decoration: underline;">${link}</a>`
      }
      return text
    },
    titleize_normal,
  },
}
