import { useElementSize, useScroll, UseScrollReturn } from '@vueuse/core'
import { reactive, Ref, toRef } from 'vue'

/**
 * A reactive ref for html elements
 */
export interface ElementReactiveRef {
  /**
   * HTML element saved as a reactive variable
   */
  value?: HTMLElement
  /**
   * Returns a synced ref of the reactive element
   */
  getRef: () => Ref<HTMLElement | undefined>
  /**
   * Update the HTMLElement
   * @param el The element to be saved
   */
  updateEl: (el: any) => void
  /**
   * Reactive width of element
   */
  width: number
  /**
   * Reactive height of element
   */
  height: number
  /**
   * Current scroll state of element
   */
  scroll: UseScrollReturn
}

/**
 * Returns a reactive object that safely preserves a synced copy of an HTMLElement ref inside it
 * @param originalRef The HTMLElement ref that is going to be preserved
 */
export function elementReactiveRef(): ElementReactiveRef {
  let ref: Ref<HTMLElement | undefined>

  const store = reactive({
    value: undefined as HTMLElement | undefined,
    getRef,
    updateEl,
  })

  function getRef(): Ref<HTMLElement | undefined> {
    //@ts-ignore
    return ref !== undefined ? ref : (ref = toRef(store, 'value'))
  }

  function updateEl(el: HTMLElement | undefined) {
    store.value = el ?? undefined
  }
  const size = useElementSize(store.getRef())

  Object.assign(store, {
    width: size.width,
    height: size.height,
    scroll: useScroll(store.getRef()),
  })

  return store as ElementReactiveRef
}
