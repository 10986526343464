import { initCommandrSDK, initCommandrSDKFileSystem } from '../../commandrSDK'
export let commandrSDK = null
export let commandrSDKFileSystem = null

export function initCommandr() {
  // Init file system first to prevent firebase from being initialized twice (in google mode)
  commandrSDKFileSystem = initCommandrSDKFileSystem({
    mode: import.meta.env.VITE_COMMANDR_FILESYSTEM_MODE,
    endpoint: import.meta.env.VITE_COMMANDR_DOCKER_ENDPOINT,
    token: import.meta.env.VITE_COMMANDR_DOCKER_TOKEN,
    awsRegion: import.meta.env.VITE_COMMANDR_AWS_REGION,
    awsBucketName: import.meta.env.VITE_COMMANDR_AWS_BUCKET_NAME,
    awsCredentials: {
      accessKeyId: import.meta.env.VITE_COMMANDR_AWS_ACCESS_KEY_ID,
      secretAccessKey: import.meta.env.VITE_COMMANDR_AWS_SECRET_ACCESS_KEY,
    },
    awsAssumeRole: {
      RoleArn: import.meta.env.VITE_COMMANDR_AWS_ARN_USER_ROLE,
      RoleSessionName: `front-${import.meta.env.VITE_PROJECT}-${Math.floor(Math.random() * 10000)}`,
    },
    googleConfig: {
      root: import.meta.env.VITE_FIREBASE_ROOT,
      name: import.meta.env.VITE_FIREBASE_NAME,
      project: import.meta.env.VITE_PROJECT,
      apiKey: import.meta.env.VITE_FIREBASE_API_KEY,
    },
  })

  commandrSDK = initCommandrSDK({
    mode: import.meta.env.VITE_COMMANDR_SDK_MODE,
    root: import.meta.env.VITE_FIREBASE_ROOT,
    name: import.meta.env.VITE_FIREBASE_NAME,
    project: import.meta.env.VITE_PROJECT,
    apiKey: import.meta.env.VITE_FIREBASE_API_KEY,
    hasuraEndpoint: import.meta.env.VITE_COMMANDR_HASURA_GRAPHQL_ENDPOINT,
    hasuraAdminSecret: import.meta.env.VITE_COMMANDR_HASURA_GRAPHQL_ADMIN_SECRET, // todo delete if we stop using PostgresAcrossHasura (hasura mode)
    hasuraDataSourceName: import.meta.env.VITE_COMMANDR_HASURA_DATASOURCE_NAME,
    env: import.meta.env.VITE_COMMANDR_FILESYSTEM_MODE,
  })

  return true
}

export async function get(path) {
  return commandrSDK.get(path)
}
export function set(path, value) {
  return commandrSDK.set(path, value)
}
export function update(path, value) {
  return commandrSDK.update(path, value)
}
