import { getCode } from '@hauru/common'
import {
  ILinkedState,
  IMapKeysToControl,
  IMapKeysToTypeOrControl,
  IMapKeysToTypeOrString,
  IMapPropertiesToControl,
  isStorySelect,
  IStoryButton,
  IStoryState,
  IWritable,
} from '@storytime'
import { cloneDeep } from 'lodash'
import { reactive, Ref } from 'vue'

const actionsKey$ = Symbol('actions')
export { actionsKey$ }

type Reactive<T> = Ref<T>

export function createStory<T>(componentName: string) {
  const state: IStoryState<T> = reactive({
    name: componentName,
    props: {} as Reactive<IWritable<T>>,
    options: {} as Reactive<IMapKeysToControl<T>>,
    states: {},
    actions: [],
    examples: [],
    exampleIndex: 0,
    example: '',
    onSet: [],
    css: '',
    code: getCode(),
    setProps,
    createState,
    addActions,
    setExample,
    onExampleSet,
    createExample,
    createDefaultExample,
    setExampleState,
    setExampleProps,
  })

  function extractProps(props: IMapKeysToTypeOrControl<T>) {
    const result = {} as IWritable<T>
    for (const key in props) {
      if (props[key] instanceof Object && 'type' in (props[key] as Object)) {
        state.options[key] = props[key] as IMapPropertiesToControl<T>
        //@ts-ignore
        result[key] = props[key].value
      } else {
        //@ts-ignore
        result[key] = props[key]
      }
    }
    return result
  }

  function setProps(props: IMapKeysToTypeOrControl<T>) {
    //@ts-ignore
    state.props = Array.isArray(props) ? props.map(curProps => extractProps(curProps)) : extractProps(props)
    return state
  }

  function createState(title: string, linkState: ILinkedState) {
    state.states[title] = linkState
    return state
  }

  function addActions(actions: IStoryButton[]) {
    const lastStateLinked = Object.values(state.states).at(-1)
    if (lastStateLinked) {
      //@ts-ignore
      lastStateLinked[actionsKey$] = actions
    } else {
      state.actions.push(...actions)
    }
    return state
  }

  function createDefaultExample(name: string) {
    if (state.examples.length === 0) state.example = name

    state.examples.push({
      name,
      props: cloneDeep(state.props),
      states: Object.keys(state.states).reduce(function (resultStates, stateName) {
        resultStates[stateName] = Object.keys(state.states[stateName]).reduce(function (resultState, stateKey) {
          // console.log(
          //   'state.states[stateName][stateKey]',
          //   stateName,
          //   stateKey,
          //   state.states[stateName][stateKey].getValue(),
          // )
          resultState[stateKey] = cloneDeep(state.states[stateName][stateKey].getValue())
          return resultState
        }, {} as any)
        return resultStates
      }, {} as any),
      onSet: [...state.onSet],
    })
    return state
  }

  function createExample(name: string) {
    if (state.examples.length === 0) createDefaultExample('Default')

    createDefaultExample(name)
    return state
  }

  function setExampleProps(props: Partial<IMapKeysToTypeOrString<T>>) {
    if (state.examples.length > 0) {
      const lastExample = state.examples.at(-1)!
      for (const key in props) {
        const option = state.options[key]
        if (option && isStorySelect(option)) {
          const selectOption = option.options.find(option => option.name === props[key])
          if (selectOption) {
            lastExample.props[key] = selectOption.value
          } else {
            lastExample.props[key] = props[key]! as any
          }
        } else {
          lastExample.props[key] = props[key]! as any
        }
      }
    }
    return state
  }

  function setExampleState(name: string, setState: { [key: string]: any }) {
    const lastExample = state.examples.at(-1)!
    Object.assign(lastExample.states[name], setState)
    return state
  }

  function setExample(name: string, index?: number) {
    if (!state.examples.length) return state

    // Reset values to default
    if (name !== state.examples[0].name) setExample(state.examples[0].name)

    state.example = name
    if (index) state.exampleIndex = index
    else state.exampleIndex = state.examples.findIndex(example => example.name === name)

    const example = state.examples.find(example => example.name === name)
    if (!example) return state

    const exampleCopy = cloneDeep(example)
    Object.assign(state.props, exampleCopy.props)
    for (const stateName in exampleCopy.states) {
      for (const stateKey in exampleCopy.states[stateName]) {
        state.states[stateName][stateKey].setValue(exampleCopy.states[stateName][stateKey])
      }
    }
    example.onSet.forEach(fun => fun())
    return state
  }

  function onExampleSet(fun: () => void) {
    if (!state.examples.length) state.onSet.push(fun)
    else state.examples.at(-1)!.onSet.push(fun)
    return state
  }

  return state
}
