const fn = (el, { value }) => {
  try {
    el.innerHTML = insane(value)
  } catch (e) {
    el.innerText = value
  }
}
export default {
  mounted: fn,
  update: fn,
}
