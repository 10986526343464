<template>
  <lfr-block :data="data" :options="options">
    <div class="mx-auto w-[210px] py-2">
      <pdf-risk-scale :risk-scale="data" />
    </div>
  </lfr-block>
</template>

<script setup>
const { data, options } = defineProps(['data', 'options'])
$root.t.risk_lower = 'Risque le plus faible'
$root.t.risk_higher = 'Risque le plus élevé'
$root.t.performance_lower = ''
$root.t.performance_higher = ''
</script>

<style>
.risk-scale .num {
  border-radius: 50%;
  margin-bottom: 4px;
}
.lfr-risk-scale .num.active {
  background: rgb(var(--primary));
}
.lfr-risk-scale .row.between {
  display: flex;
  justify-content: space-between;
}
</style>
