<template>
  <div class="flex flex-row gap-2" v-if="style">
    <label class="w-40">{{ style.name }}</label>
    <textarea
      class="w-full rounded !border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-emerald-200"
      v-model="style.css"
      @input="updateHeight"
      ref="textarea"
    ></textarea>
    <!-- <div>{{ isValidCss(style.css) }}</div> -->
  </div>
</template>
<script setup lang="ts">
import { ref, onMounted } from 'vue'
import type { TemplateStyle } from '../../builder'

const textarea = ref<HTMLTextAreaElement | null>(null)
const style = defineModel<TemplateStyle>()
function updateHeight() {
  if (!textarea.value) return
  textarea.value.style.height = 'auto'
  textarea.value.style.height = `${textarea.value.scrollHeight}px`
}
onMounted(() => {
  updateHeight()
})
// Experimental, doesn't work in 100% of cases
// const isValidCss = (css: string) => {
//   const reg =
//     /((?:\s*)([\w#.@*,:\-.:>+~[\]"=(),*\s]+)\s*{(?:[\s]*)((?:[A-Za-z\- \s]+[:]\s*['"0-9\w .,\/\()\-!#%]+;?)*)*\s*}(?:\s*))/im
//   return reg.test(`.test { ${css} }`)
// }
</script>
