<style>
.pdf-horizontalbar-icon {
  --size: 3;
  min-height: 110px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}
.pdf-horizontalbar-icon .column {
  margin: unset !important;
  max-width: unset !important;
  min-width: unset !important;
}
.pdf-horizontalbar-icon .column .row {
  align-items: center;
  height: calc(var(--size) * 1em);
  max-height: calc(var(--size) * 1em);
}
.pdf-horizontalbar-icon img {
  max-width: 18px;
  margin: 1px;
}
.pdf-horizontalbar-icon .rect {
  position: relative;
  width: var(--value);
  height: calc(var(--size) * 1em);
  background: var(--primary);
  display: flex;
  align-items: center;
}
.pdf-horizontalbar-icon .left {
  height: calc(var(--size) * 1em);
  align-items: center;
  text-align: end;
  justify-content: flex-end !important;
}
.pdf-horizontalbar-icon .right {
  height: calc(var(--size) * 1em);
  align-items: center;
  text-align: start;
  justify-content: flex-start !important;
}
.pdf-horizontalbar-icon .primary.rect {
  background: var(--primary);
}
.pdf-horizontalbar-icon .secondary.rect {
  background: var(--secondary);
}
.pdf-horizontalbar-icon .third.rect {
  background: var(--inactive);
}
.pdf-horizontalbar-icon .negative {
  border-radius: 2px 0px 0px 2px;
}
.pdf-horizontalbar-icon .positive {
  border-radius: 0px 2px 2px 0px;
}
.pdf-horizontalbar-icon .row .value {
  padding: 0px 2px;
}
</style>

<template lang="pug">
.pdf-horizontalbar-icon
  .row(v-for="item in data" :style="{'margin-bottom': '1px'}")
    .row.left(:style="{width: valueLeft(+item.fund) + '%', padding: '2px'}")
      .label(v-if="item.fund > 0") {{ $root.t[item.label] || item.label }}
      img(v-if="item.fund < 0" :src="img_icon" v-for="(img_icon, index) in item.icon")
      .value(v-if="item.fund < 0") {{ format(default_format)(+Math.abs(+item.fund)) }}
    .rect(:style="{'--value': value(+item.fund) + '%'}" :class="[item.color, item.fund > 0 ? 'positive' : 'negative']")
    .row.right(:style="{width: valueRight(+item.fund) + '%', padding: '2px'}")
      .label(v-if="item.fund < 0") {{ $root.t[item.label] || item.label }}
      .value(v-if="item.fund > 0") {{ format(default_format)(+Math.abs(+item.fund)) }}
      img(v-if="item.fund > 0" :src="img_icon" v-for="(img_icon, index) in item.icon")
</template>

<script>
export const additions = {}
export default {
  props: { data: Array, options: Object },
  data() {
    return {
      max: this.data.map(d => d.fund).max(),
      default_format: (this.options && this.options.formats) || '.0f',
      min: this.data.map(d => d.fund).min(),
      default_left_width: this.data.map(d => d.label.length).max() > 20 ? 35 : 25, //long label case
      default_rect_width: this.data.map(d => d.label.length).max() > 20 ? 35 : 50, //long label case
    }
  },
  methods: {
    valueLeft(value) {
      if (value < 0 || this.min > 0) {
        return this.default_left_width
      }
      return (Math.abs(this.min) / (Math.abs(this.min) + this.max)) * this.default_rect_width + this.default_left_width
    },
    valueRight(value) {
      if (value < 0) {
        return (
          100 -
          ((Math.abs(value) / (Math.abs(this.min) + this.max)) * this.default_rect_width + this.default_left_width)
        )
      }
      if (this.min > 0) {
        return 100 - ((Math.abs(value) / this.max) * this.default_rect_width + this.default_left_width)
      }
      return (
        100 -
        ((Math.abs(value) / (Math.abs(this.min) + this.max)) * this.default_rect_width +
          (Math.abs(this.min) / (Math.abs(this.min) + this.max)) * this.default_rect_width +
          this.default_left_width)
      )
    },
    value(value) {
      if (this.min < 0) {
        return (Math.abs(value) / (Math.abs(this.min) + this.max)) * this.default_rect_width
      }
      return (Math.abs(value) / this.max) * this.default_rect_width
    },
  },
}
</script>
