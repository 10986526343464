<style>
.pdf .carbon_intensity .bar-chart .rect {
  --size: 3;
}
.pdf .carbon_intensity .bar-chart .rect.benchmark {
  background: var(--secondary) !important;
}
.pdf .carbon_intensity .bar-chart .row {
  padding: 8px 0px;
}

.pdf-esg-carbon-2 .sfdr-block {
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.pdf-esg-carbon-2 .sfdr-block .row {
  width: 200px;
  background: var(--background);
  height: 40px;
  border-radius: 8px;
  padding: 8px;
}
.sfdr-block {
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.sfdr-block .row {
  width: 200px;
  background: var(--background);
  height: 40px;
  border-radius: 8px;
  padding: 8px;
}
.sfdr-block .row .sfdr-text {
  display: flex;
  flex: 2;
  align-items: center;
  text-align: center;
  font-weight: 600;
}
.sfdr-block .row .sfdr-value {
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  font-size: 12px;
}
.pdf-esg-carbon-2 .main_green_bonds .c3 > div {
  justify-content: center !important;
  display: flex;
}
.pdf-esg-carbon-2 .main_green_bonds .c4 > div {
  justify-content: center !important;
  display: flex;
}
.pdf-esg-carbon-2 .main_green_bonds .c1 {
  min-width: 100px;
}
.pdf .block-data_impact {
  min-width: 100%;
  height: 300px;
}
.table_footer {
  font-weight: bold;
}
.carbon_footprint_by_sector {
  margin-top: 0px !important;
  margin-bottom: 0px !important;
}
.pdf-esg-carbon-2 .subtitle.row {
  margin-left: 8px;
}
.pdf-esg-carbon-2 .row .column {
  flex-grow: 1;
}
.pdf-esg-carbon-2 .main_green_bonds .content {
  margin-bottom: 8px;
}
.pdf-esg-carbon-2 .repart_greenfin .disclaimer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  font-weight: 600;
}
.pdf-esg-carbon-2 .repart_greenfin .pie path {
  fill: #327007;
}
.pdf-esg-carbon-2 .repart_greenfin .legend .color {
  background: #327007;
}
</style>

<template lang="pug">
.pdf-esg-carbon-2
  .subtitle.row(v-if="data.share.characteristics.benchmark_esg") {{(t['univers_definition'] || 'univers_definition') + ': ' + t[data.share.characteristics.benchmark_esg] }}
  .column(v-if="!data.share.characteristics.hide_data_impact")
    brick(:title="t['data_impact'] || 'data_impact'" :subtitle="t['data_impact_subtitle'] || 'data_impact_subtitle'")
    pdf-pictogram(:data="data.esg.esg_data_impact")
    div.source(v-if="$root.lang.includes('be')") {{ t.source + ' ' + t[data.share.characteristics.management_company] }}
  .row(v-if="data.share.characteristics.esg_impact_table")
    .colum
      impact-esg-table(:characteristicsData="data.share.characteristics" :mapping="window.mapping")
      div.source(v-if="$root.lang.includes('be')") {{ t.source + ' ' + t[data.share.characteristics.management_company] }}
  .column
    brick.risk_disclaimer(title="definitions_esg" v-if="(data.share.characteristics.definition_esg_list || []).length > 0")
      div(v-html="t[definition] || definition" v-for="definition in data.share.characteristics.definition_esg_list.split(',')")
</template>

<script>
export const additions = {}
export default {
  props: { data: Object },
}
</script>
