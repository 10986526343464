import { reactive, readonly } from 'vue'

interface IArgs {
  /**
   * The current vertical scroll position
   */
  top: number
  /**
   * The current horizontal scroll position
   */
  left: number
  /**
   * The height of a row in px
   */
  rowHeight: number
  /**
   * The width of a column in px
   */
  columnWidth: number
  /**
   * The horizontal scroll speed multiplier.
   */
  scrollSpeedHorizontal: number
  /**
   * The vertical scroll speed multiplier.
   */
  scrollSpeedVertical: number
  /**
   * The minimum size of the scrollbar in px.
   */
  minScrollbarSize: number
  /**
   * Boolean indicating if scrollbars should be shown
   */
  showScrollbars: boolean
}

export type IScrollState = ReturnType<typeof useScrollState> & IArgs

export function useScrollState({
  top = 0,
  left = 0,
  rowHeight = 20,
  columnWidth = 100,
  scrollSpeedHorizontal = 1,
  scrollSpeedVertical = 0.3,
  minScrollbarSize = 25,
  showScrollbars = true,
}: Partial<IArgs> = {}) {
  const scroll = reactive({
    top,
    setTop: (top: number) => {
      if (typeof top !== 'number' || Number.isNaN(top)) throw new Error('Invalid top value')
      const scrollTop = Math.min(Math.max(0, scroll.contentHeight - scroll.containerHeight), Math.max(0, top))
      if (scrollTop !== scroll.top) scroll.top = scrollTop
    },
    left,
    setLeft: (left: number) => {
      if (typeof left !== 'number' || Number.isNaN(left)) throw new Error('Invalid left value')
      const scrollLeft = Math.min(Math.max(0, scroll.contentWidth - scroll.containerWidth), Math.max(0, left))
      if (scrollLeft !== scroll.left) scroll.left = scrollLeft
    },
    throttled: {
      top: 0,
      left: 0,
      update: () => {
        if (scroll.left !== scroll.throttled.left) scroll.throttled.left = scroll.left
        if (scroll.top !== scroll.throttled.top) scroll.throttled.top = scroll.top
      },
    },
    offsetTop: (offset: number) => scroll.setTop(scroll.top + offset),
    offsetLeft: (offset: number) => scroll.setLeft(scroll.left + offset),
    rowHeight,
    setRowHeight: (rowHeight: number) => (scroll.rowHeight = rowHeight),
    columnWidth,
    setColumnWidth: (columnWidth: number) => (scroll.columnWidth = columnWidth),
    /**
     * The height of the container element in px
     */
    containerHeight: 0,
    setContainerHeight: (containerHeight: number) => (scroll.containerHeight = containerHeight),
    /**
     * The width of the container element in px
     */
    containerWidth: 0,
    setContainerWidth: (containerWidth: number) => (scroll.containerWidth = containerWidth),
    /**
     * The height of the content being scrolled in px
     */
    contentHeight: 0,
    setContentHeight: (contentHeight: number) => (scroll.contentHeight = contentHeight),
    /**
     * The width of the content being scrolled in px
     */
    contentWidth: 0,
    setContentWidth: (contentWidth: number) => (scroll.contentWidth = contentWidth),
    /**
     * The horizontal scroll speed multiplier.
     */
    scrollSpeedHorizontal,
    /**
     * The vertical scroll speed multiplier.
     */
    scrollSpeedVertical,
    horizontalOffset: undefined as number | undefined,
    setHorizontalOffset: (horizontalOffset: number) => (scroll.horizontalOffset = horizontalOffset),
    verticalOffset: undefined as number | undefined,
    setVerticalOffset: (verticalOffset: number) => (scroll.verticalOffset = verticalOffset),

    minScrollbarSize,
    showScrollbars,
  })

  return readonly(scroll)
}
