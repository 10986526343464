<style>
.toast-list {
  z-index: 11000;
  position: fixed;
  right: 20px;
  bottom: 20px;
  min-width: 420px;
  width: 40vw;
}
.toast-list:not(:hover) .toast:nth-last-child(n + 4) {
  display: none;
}
.toast-list:not(:hover) .toast:nth-last-child(3) {
  transform: translate(0, calc(200% - 20px)) scale(0.9);
}
.toast-list:not(:hover) .toast:nth-last-child(2) {
  transform: translate(0, calc(100% - 10px)) scale(0.95);
}
.toast-list:not(:hover) .toast {
  transform: translate(0, 0);
}
.toast {
  margin: 10px;
  color: var(--colors-text);
  background: white;
  border-radius: 4px;
  box-shadow: var(--box-shadow);
}
.toast span {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 15px;
  border-radius: 4px;
}
.toast .progress-bar {
  height: 4px;
  background: var(--colors-primary-default);
  border-radius: 0 0 4px 4px;
}
.toast.error .progress-bar {
  background: var(--colors-negative);
}
.toast.warning .progress-bar {
  background: var(--colors-highlight);
}
.toast.success .progress-bar {
  background: var(--colors-positive);
}
</style>

<template lang="pug">
.toast-list(@mouseover="messages.__.map(m => m.pause && m.pause())" @mouseout="messages.__.map(m => m.resume && m.resume())")
  .toast(:class="message.type" v-for="message, i in messages" :key="i")
    span.toast-description(v-if="(message.description || t.unknown_error).includes('<html>')" v-html="t[message.description] || message.description || t.unknown_error")
    span.toast-description(v-else) {{ t[message.description] || message.description || t.unknown_error }}
    .progress-bar(:style='{ opacity: .5 + .5 * message.progress / 100 }')
</template>

<script>
export default {
  props: ['messages'],
}
</script>
