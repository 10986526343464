// 3D Surface = Tenor / Strike Level / Volatility
export const surfaceData = [
  { id: 0, 'Strike level': '-2.5 %', Tenor: '1 y', Volatility: 24.3007, Strike: 5.0195 },
  { id: 11, 'Strike level': '-2.5 %', Tenor: '18 m', Volatility: 21.7497, Strike: 4.8095 },
  { id: 22, 'Strike level': '-2.5 %', Tenor: '2 y', Volatility: 21.6022, Strike: 4.9089 },
  { id: 33, 'Strike level': '-2.5 %', Tenor: '3 y', Volatility: 19.7602, Strike: 4.9202 },
  { id: 44, 'Strike level': '-2.5 %', Tenor: '4 y', Volatility: 18.618, Strike: 4.9542 },
  { id: 55, 'Strike level': '-2.5 %', Tenor: '5 y', Volatility: 17.7024, Strike: 4.9503 },
  { id: 66, 'Strike level': '-2.5 %', Tenor: '6 y', Volatility: 17.0356, Strike: 4.9182 },
  { id: 77, 'Strike level': '-2.5 %', Tenor: '7 y', Volatility: 16.4198, Strike: 4.8807 },
  { id: 88, 'Strike level': '-2.5 %', Tenor: '8 y', Volatility: 15.9695, Strike: 4.8545 },
  { id: 99, 'Strike level': '-2.5 %', Tenor: '9 y', Volatility: 15.701, Strike: 4.8299 },
  { id: 110, 'Strike level': '-2.5 %', Tenor: '10 y', Volatility: 15.5468, Strike: 4.8057 },
  { id: 121, 'Strike level': '-2.5 %', Tenor: '12 y', Volatility: 15.3422, Strike: 4.756 },
  { id: 132, 'Strike level': '-2.5 %', Tenor: '15 y', Volatility: 15.061, Strike: 4.6443 },
  { id: 143, 'Strike level': '-2.5 %', Tenor: '20 y', Volatility: 14.6485, Strike: 4.3832 },
  { id: 154, 'Strike level': '-2.5 %', Tenor: '25 y', Volatility: 14.4328, Strike: 4.2339 },
  { id: 165, 'Strike level': '-2.5 %', Tenor: '30 y', Volatility: 14.3027, Strike: 4.1402 },
  { id: 176, 'Strike level': '-2.5 %', Tenor: '35Y', Volatility: 14.2154, Strike: 4.0777 },
  { id: 187, 'Strike level': '-2.5 %', Tenor: '40Y', Volatility: 14.1524, Strike: 4.0344 },
  { id: 198, 'Strike level': '-2.5 %', Tenor: '45Y', Volatility: 14.1054, Strike: 4.0036 },
  { id: 209, 'Strike level': '-2.5 %', Tenor: '50Y', Volatility: 14.0692, Strike: 3.9813 },
  { id: 220, 'Strike level': '-2.5 %', Tenor: '55Y', Volatility: 14.041, Strike: 3.9649 },
  { id: 231, 'Strike level': '-2.5 %', Tenor: '60Y', Volatility: 14.0189, Strike: 3.9528 },
  { id: 1, 'Strike level': '-2 %', Tenor: '1 y', Volatility: 21.8508, Strike: 5.5195 },
  { id: 12, 'Strike level': '-2 %', Tenor: '18 m', Volatility: 19.3619, Strike: 5.3095 },
  { id: 23, 'Strike level': '-2 %', Tenor: '2 y', Volatility: 19.2197, Strike: 5.4089 },
  { id: 34, 'Strike level': '-2 %', Tenor: '3 y', Volatility: 17.511, Strike: 5.4202 },
  { id: 45, 'Strike level': '-2 %', Tenor: '4 y', Volatility: 16.552, Strike: 5.4542 },
  { id: 56, 'Strike level': '-2 %', Tenor: '5 y', Volatility: 15.8311, Strike: 5.4503 },
  { id: 67, 'Strike level': '-2 %', Tenor: '6 y', Volatility: 15.3373, Strike: 5.4182 },
  { id: 78, 'Strike level': '-2 %', Tenor: '7 y', Volatility: 14.8452, Strike: 5.3807 },
  { id: 89, 'Strike level': '-2 %', Tenor: '8 y', Volatility: 14.4876, Strike: 5.3545 },
  { id: 100, 'Strike level': '-2 %', Tenor: '9 y', Volatility: 14.3002, Strike: 5.3299 },
  { id: 111, 'Strike level': '-2 %', Tenor: '10 y', Volatility: 14.2161, Strike: 5.3057 },
  { id: 122, 'Strike level': '-2 %', Tenor: '12 y', Volatility: 14.123, Strike: 5.256 },
  { id: 133, 'Strike level': '-2 %', Tenor: '15 y', Volatility: 13.9683, Strike: 5.1443 },
  { id: 144, 'Strike level': '-2 %', Tenor: '20 y', Volatility: 13.7164, Strike: 4.8832 },
  { id: 155, 'Strike level': '-2 %', Tenor: '25 y', Volatility: 13.568, Strike: 4.7339 },
  { id: 166, 'Strike level': '-2 %', Tenor: '30 y', Volatility: 13.4761, Strike: 4.6402 },
  { id: 177, 'Strike level': '-2 %', Tenor: '35Y', Volatility: 13.4144, Strike: 4.5777 },
  { id: 188, 'Strike level': '-2 %', Tenor: '40Y', Volatility: 13.3702, Strike: 4.5344 },
  { id: 199, 'Strike level': '-2 %', Tenor: '45Y', Volatility: 13.3374, Strike: 4.5036 },
  { id: 210, 'Strike level': '-2 %', Tenor: '50Y', Volatility: 13.3124, Strike: 4.4813 },
  { id: 221, 'Strike level': '-2 %', Tenor: '55Y', Volatility: 13.293, Strike: 4.4649 },
  { id: 232, 'Strike level': '-2 %', Tenor: '60Y', Volatility: 13.2779, Strike: 4.4528 },
  { id: 2, 'Strike level': '-1.5 %', Tenor: '1 y', Volatility: 18.8788, Strike: 6.0195 },
  { id: 13, 'Strike level': '-1.5 %', Tenor: '18 m', Volatility: 16.4973, Strike: 5.8095 },
  { id: 24, 'Strike level': '-1.5 %', Tenor: '2 y', Volatility: 16.3763, Strike: 5.9089 },
  { id: 35, 'Strike level': '-1.5 %', Tenor: '3 y', Volatility: 15.1936, Strike: 5.9202 },
  { id: 46, 'Strike level': '-1.5 %', Tenor: '4 y', Volatility: 14.5751, Strike: 5.9542 },
  { id: 57, 'Strike level': '-1.5 %', Tenor: '5 y', Volatility: 14.1071, Strike: 5.9503 },
  { id: 68, 'Strike level': '-1.5 %', Tenor: '6 y', Volatility: 13.8041, Strike: 5.9182 },
  { id: 79, 'Strike level': '-1.5 %', Tenor: '7 y', Volatility: 13.4464, Strike: 5.8807 },
  { id: 90, 'Strike level': '-1.5 %', Tenor: '8 y', Volatility: 13.1846, Strike: 5.8545 },
  { id: 101, 'Strike level': '-1.5 %', Tenor: '9 y', Volatility: 13.0732, Strike: 5.8299 },
  { id: 112, 'Strike level': '-1.5 %', Tenor: '10 y', Volatility: 13.051, Strike: 5.8057 },
  { id: 123, 'Strike level': '-1.5 %', Tenor: '12 y', Volatility: 13.0544, Strike: 5.756 },
  { id: 134, 'Strike level': '-1.5 %', Tenor: '15 y', Volatility: 13.0129, Strike: 5.6443 },
  { id: 145, 'Strike level': '-1.5 %', Tenor: '20 y', Volatility: 12.9187, Strike: 5.3832 },
  { id: 156, 'Strike level': '-1.5 %', Tenor: '25 y', Volatility: 12.8332, Strike: 5.2339 },
  { id: 167, 'Strike level': '-1.5 %', Tenor: '30 y', Volatility: 12.7751, Strike: 5.1402 },
  { id: 178, 'Strike level': '-1.5 %', Tenor: '35Y', Volatility: 12.7349, Strike: 5.0777 },
  { id: 189, 'Strike level': '-1.5 %', Tenor: '40Y', Volatility: 12.7061, Strike: 5.0344 },
  { id: 200, 'Strike level': '-1.5 %', Tenor: '45Y', Volatility: 12.6847, Strike: 5.0036 },
  { id: 211, 'Strike level': '-1.5 %', Tenor: '50Y', Volatility: 12.6685, Strike: 4.9813 },
  { id: 222, 'Strike level': '-1.5 %', Tenor: '55Y', Volatility: 12.656, Strike: 4.9649 },
  { id: 233, 'Strike level': '-1.5 %', Tenor: '60Y', Volatility: 12.6462, Strike: 4.9528 },
  { id: 3, 'Strike level': '-1 %', Tenor: '1 y', Volatility: 15.2606, Strike: 6.5195 },
  { id: 14, 'Strike level': '-1 %', Tenor: '18 m', Volatility: 13.6193, Strike: 6.3095 },
  { id: 25, 'Strike level': '-1 %', Tenor: '2 y', Volatility: 13.5488, Strike: 6.4089 },
  { id: 36, 'Strike level': '-1 %', Tenor: '3 y', Volatility: 13.0771, Strike: 6.4202 },
  { id: 47, 'Strike level': '-1 %', Tenor: '4 y', Volatility: 12.8142, Strike: 6.4542 },
  { id: 58, 'Strike level': '-1 %', Tenor: '5 y', Volatility: 12.5797, Strike: 6.4503 },
  { id: 69, 'Strike level': '-1 %', Tenor: '6 y', Volatility: 12.4455, Strike: 6.4182 },
  { id: 80, 'Strike level': '-1 %', Tenor: '7 y', Volatility: 12.212, Strike: 6.3807 },
  { id: 91, 'Strike level': '-1 %', Tenor: '8 y', Volatility: 12.0381, Strike: 6.3545 },
  { id: 102, 'Strike level': '-1 %', Tenor: '9 y', Volatility: 11.9934, Strike: 6.3299 },
  { id: 113, 'Strike level': '-1 %', Tenor: '10 y', Volatility: 12.0239, Strike: 6.3057 },
  { id: 124, 'Strike level': '-1 %', Tenor: '12 y', Volatility: 12.1098, Strike: 6.256 },
  { id: 135, 'Strike level': '-1 %', Tenor: '15 y', Volatility: 12.1711, Strike: 6.1443 },
  { id: 146, 'Strike level': '-1 %', Tenor: '20 y', Volatility: 12.2322, Strike: 5.8832 },
  { id: 157, 'Strike level': '-1 %', Tenor: '25 y', Volatility: 12.2109, Strike: 5.7339 },
  { id: 168, 'Strike level': '-1 %', Tenor: '30 y', Volatility: 12.1849, Strike: 5.6402 },
  { id: 179, 'Strike level': '-1 %', Tenor: '35Y', Volatility: 12.1638, Strike: 5.5777 },
  { id: 190, 'Strike level': '-1 %', Tenor: '40Y', Volatility: 12.1478, Strike: 5.5344 },
  { id: 201, 'Strike level': '-1 %', Tenor: '45Y', Volatility: 12.1355, Strike: 5.5036 },
  { id: 212, 'Strike level': '-1 %', Tenor: '50Y', Volatility: 12.126, Strike: 5.4813 },
  { id: 223, 'Strike level': '-1 %', Tenor: '55Y', Volatility: 12.1187, Strike: 5.4649 },
  { id: 234, 'Strike level': '-1 %', Tenor: '60Y', Volatility: 12.1129, Strike: 5.4528 },
  { id: 4, 'Strike level': '-0.5 %', Tenor: '1 y', Volatility: 11.9549, Strike: 7.0195 },
  { id: 15, 'Strike level': '-0.5 %', Tenor: '18 m', Volatility: 11.2782, Strike: 6.8095 },
  { id: 26, 'Strike level': '-0.5 %', Tenor: '2 y', Volatility: 11.2507, Strike: 6.9089 },
  { id: 37, 'Strike level': '-0.5 %', Tenor: '3 y', Volatility: 11.3084, Strike: 6.9202 },
  { id: 48, 'Strike level': '-0.5 %', Tenor: '4 y', Volatility: 11.3205, Strike: 6.9542 },
  { id: 59, 'Strike level': '-0.5 %', Tenor: '5 y', Volatility: 11.2699, Strike: 6.9503 },
  { id: 70, 'Strike level': '-0.5 %', Tenor: '6 y', Volatility: 11.2729, Strike: 6.9182 },
  { id: 81, 'Strike level': '-0.5 %', Tenor: '7 y', Volatility: 11.147, Strike: 6.8807 },
  { id: 92, 'Strike level': '-0.5 %', Tenor: '8 y', Volatility: 11.0493, Strike: 6.8545 },
  { id: 103, 'Strike level': '-0.5 %', Tenor: '9 y', Volatility: 11.0612, Strike: 6.8299 },
  { id: 114, 'Strike level': '-0.5 %', Tenor: '10 y', Volatility: 11.1354, Strike: 6.8057 },
  { id: 125, 'Strike level': '-0.5 %', Tenor: '12 y', Volatility: 11.2899, Strike: 6.756 },
  { id: 136, 'Strike level': '-0.5 %', Tenor: '15 y', Volatility: 11.4383, Strike: 6.6443 },
  { id: 147, 'Strike level': '-0.5 %', Tenor: '20 y', Volatility: 11.6165, Strike: 6.3832 },
  { id: 158, 'Strike level': '-0.5 %', Tenor: '25 y', Volatility: 11.6719, Strike: 6.2339 },
  { id: 169, 'Strike level': '-0.5 %', Tenor: '30 y', Volatility: 11.6842, Strike: 6.1402 },
  { id: 180, 'Strike level': '-0.5 %', Tenor: '35Y', Volatility: 11.6839, Strike: 6.0777 },
  { id: 191, 'Strike level': '-0.5 %', Tenor: '40Y', Volatility: 11.68, Strike: 6.0344 },
  { id: 202, 'Strike level': '-0.5 %', Tenor: '45Y', Volatility: 11.6759, Strike: 6.0036 },
  { id: 213, 'Strike level': '-0.5 %', Tenor: '50Y', Volatility: 11.6722, Strike: 5.9813 },
  { id: 224, 'Strike level': '-0.5 %', Tenor: '55Y', Volatility: 11.6691, Strike: 5.9649 },
  { id: 235, 'Strike level': '-0.5 %', Tenor: '60Y', Volatility: 11.6665, Strike: 5.9528 },
  { id: 5, 'Strike level': 'ATM', Tenor: '1 y', Volatility: 9.7149, Strike: 7.5195 },
  { id: 16, 'Strike level': 'ATM', Tenor: '18 m', Volatility: 9.7236, Strike: 7.3095 },
  { id: 27, 'Strike level': 'ATM', Tenor: '2 y', Volatility: 9.7236, Strike: 7.4089 },
  { id: 38, 'Strike level': 'ATM', Tenor: '3 y', Volatility: 10.1092, Strike: 7.4202 },
  { id: 49, 'Strike level': 'ATM', Tenor: '4 y', Volatility: 10.3128, Strike: 7.4542 },
  { id: 60, 'Strike level': 'ATM', Tenor: '5 y', Volatility: 10.3712, Strike: 7.4503 },
  { id: 71, 'Strike level': 'ATM', Tenor: '6 y', Volatility: 10.4451, Strike: 7.4182 },
  { id: 82, 'Strike level': 'ATM', Tenor: '7 y', Volatility: 10.3767, Strike: 7.3807 },
  { id: 93, 'Strike level': 'ATM', Tenor: '8 y', Volatility: 10.3258, Strike: 7.3545 },
  { id: 104, 'Strike level': 'ATM', Tenor: '9 y', Volatility: 10.3703, Strike: 7.3299 },
  { id: 115, 'Strike level': 'ATM', Tenor: '10 y', Volatility: 10.469, Strike: 7.3057 },
  { id: 126, 'Strike level': 'ATM', Tenor: '12 y', Volatility: 10.6609, Strike: 7.256 },
  { id: 137, 'Strike level': 'ATM', Tenor: '15 y', Volatility: 10.8451, Strike: 7.1443 },
  { id: 148, 'Strike level': 'ATM', Tenor: '20 y', Volatility: 11.0799, Strike: 6.8832 },
  { id: 159, 'Strike level': 'ATM', Tenor: '25 y', Volatility: 11.1749, Strike: 6.7339 },
  { id: 170, 'Strike level': 'ATM', Tenor: '30 y', Volatility: 11.2224, Strike: 6.6402 },
  { id: 181, 'Strike level': 'ATM', Tenor: '35Y', Volatility: 11.2487, Strike: 6.5777 },
  { id: 192, 'Strike level': 'ATM', Tenor: '40Y', Volatility: 11.2622, Strike: 6.5344 },
  { id: 203, 'Strike level': 'ATM', Tenor: '45Y', Volatility: 11.2692, Strike: 6.5036 },
  { id: 214, 'Strike level': 'ATM', Tenor: '50Y', Volatility: 11.2734, Strike: 6.4813 },
  { id: 225, 'Strike level': 'ATM', Tenor: '55Y', Volatility: 11.2756, Strike: 6.4649 },
  { id: 236, 'Strike level': 'ATM', Tenor: '60Y', Volatility: 11.2771, Strike: 6.4528 },
  { id: 6, 'Strike level': '0.5 %', Tenor: '1 y', Volatility: 10.0245, Strike: 8.0195 },
  { id: 17, 'Strike level': '0.5 %', Tenor: '18 m', Volatility: 9.6265, Strike: 7.8095 },
  { id: 28, 'Strike level': '0.5 %', Tenor: '2 y', Volatility: 9.6158, Strike: 7.9089 },
  { id: 39, 'Strike level': '0.5 %', Tenor: '3 y', Volatility: 9.8954, Strike: 7.9202 },
  { id: 50, 'Strike level': '0.5 %', Tenor: '4 y', Volatility: 10.0781, Strike: 7.9542 },
  { id: 61, 'Strike level': '0.5 %', Tenor: '5 y', Volatility: 10.1013, Strike: 7.9503 },
  { id: 72, 'Strike level': '0.5 %', Tenor: '6 y', Volatility: 10.1434, Strike: 7.9182 },
  { id: 83, 'Strike level': '0.5 %', Tenor: '7 y', Volatility: 10.0545, Strike: 7.8807 },
  { id: 94, 'Strike level': '0.5 %', Tenor: '8 y', Volatility: 9.9908, Strike: 7.8545 },
  { id: 105, 'Strike level': '0.5 %', Tenor: '9 y', Volatility: 10.0287, Strike: 7.8299 },
  { id: 116, 'Strike level': '0.5 %', Tenor: '10 y', Volatility: 10.1235, Strike: 7.8057 },
  { id: 127, 'Strike level': '0.5 %', Tenor: '12 y', Volatility: 10.3091, Strike: 7.756 },
  { id: 138, 'Strike level': '0.5 %', Tenor: '15 y', Volatility: 10.4774, Strike: 7.6443 },
  { id: 149, 'Strike level': '0.5 %', Tenor: '20 y', Volatility: 10.692, Strike: 7.3832 },
  { id: 160, 'Strike level': '0.5 %', Tenor: '25 y', Volatility: 10.7872, Strike: 7.2339 },
  { id: 171, 'Strike level': '0.5 %', Tenor: '30 y', Volatility: 10.8412, Strike: 7.1402 },
  { id: 182, 'Strike level': '0.5 %', Tenor: '35Y', Volatility: 10.8742, Strike: 7.0777 },
  { id: 193, 'Strike level': '0.5 %', Tenor: '40Y', Volatility: 10.8974, Strike: 7.0344 },
  { id: 204, 'Strike level': '0.5 %', Tenor: '45Y', Volatility: 10.913, Strike: 7.0036 },
  { id: 215, 'Strike level': '0.5 %', Tenor: '50Y', Volatility: 10.923, Strike: 6.9813 },
  { id: 226, 'Strike level': '0.5 %', Tenor: '55Y', Volatility: 10.9293, Strike: 6.9649 },
  { id: 237, 'Strike level': '0.5 %', Tenor: '60Y', Volatility: 10.9334, Strike: 6.9528 },
  { id: 7, 'Strike level': '1 %', Tenor: '1 y', Volatility: 11.9861, Strike: 8.5195 },
  { id: 18, 'Strike level': '1 %', Tenor: '18 m', Volatility: 10.837, Strike: 8.3095 },
  { id: 29, 'Strike level': '1 %', Tenor: '2 y', Volatility: 10.7984, Strike: 8.4089 },
  { id: 40, 'Strike level': '1 %', Tenor: '3 y', Volatility: 10.6092, Strike: 8.4202 },
  { id: 51, 'Strike level': '1 %', Tenor: '4 y', Volatility: 10.5293, Strike: 8.4542 },
  { id: 62, 'Strike level': '1 %', Tenor: '5 y', Volatility: 10.4197, Strike: 8.4503 },
  { id: 73, 'Strike level': '1 %', Tenor: '6 y', Volatility: 10.3829, Strike: 8.4182 },
  { id: 84, 'Strike level': '1 %', Tenor: '7 y', Volatility: 10.2374, Strike: 8.3807 },
  { id: 95, 'Strike level': '1 %', Tenor: '8 y', Volatility: 10.1266, Strike: 8.3545 },
  { id: 106, 'Strike level': '1 %', Tenor: '9 y', Volatility: 10.1264, Strike: 8.3299 },
  { id: 117, 'Strike level': '1 %', Tenor: '10 y', Volatility: 10.1899, Strike: 8.3057 },
  { id: 128, 'Strike level': '1 %', Tenor: '12 y', Volatility: 10.3239, Strike: 8.256 },
  { id: 139, 'Strike level': '1 %', Tenor: '15 y', Volatility: 10.4193, Strike: 8.1443 },
  { id: 150, 'Strike level': '1 %', Tenor: '20 y', Volatility: 10.512, Strike: 7.8832 },
  { id: 161, 'Strike level': '1 %', Tenor: '25 y', Volatility: 10.5753, Strike: 7.7339 },
  { id: 172, 'Strike level': '1 %', Tenor: '30 y', Volatility: 10.6157, Strike: 7.6402 },
  { id: 183, 'Strike level': '1 %', Tenor: '35Y', Volatility: 10.6406, Strike: 7.5777 },
  { id: 194, 'Strike level': '1 %', Tenor: '40Y', Volatility: 10.6578, Strike: 7.5344 },
  { id: 205, 'Strike level': '1 %', Tenor: '45Y', Volatility: 10.6702, Strike: 7.5036 },
  { id: 216, 'Strike level': '1 %', Tenor: '50Y', Volatility: 10.6799, Strike: 7.4813 },
  { id: 227, 'Strike level': '1 %', Tenor: '55Y', Volatility: 10.6868, Strike: 7.4649 },
  { id: 238, 'Strike level': '1 %', Tenor: '60Y', Volatility: 10.6918, Strike: 7.4528 },
  { id: 8, 'Strike level': '1.5 %', Tenor: '1 y', Volatility: 14.0659, Strike: 9.0195 },
  { id: 19, 'Strike level': '1.5 %', Tenor: '18 m', Volatility: 12.3809, Strike: 8.8095 },
  { id: 30, 'Strike level': '1.5 %', Tenor: '2 y', Volatility: 12.3173, Strike: 8.9089 },
  { id: 41, 'Strike level': '1.5 %', Tenor: '3 y', Volatility: 11.5944, Strike: 8.9202 },
  { id: 52, 'Strike level': '1.5 %', Tenor: '4 y', Volatility: 11.2257, Strike: 8.9542 },
  { id: 63, 'Strike level': '1.5 %', Tenor: '5 y', Volatility: 10.9775, Strike: 8.9503 },
  { id: 74, 'Strike level': '1.5 %', Tenor: '6 y', Volatility: 10.8614, Strike: 8.9182 },
  { id: 85, 'Strike level': '1.5 %', Tenor: '7 y', Volatility: 10.6603, Strike: 8.8807 },
  { id: 96, 'Strike level': '1.5 %', Tenor: '8 y', Volatility: 10.4992, Strike: 8.8545 },
  { id: 107, 'Strike level': '1.5 %', Tenor: '9 y', Volatility: 10.458, Strike: 8.8299 },
  { id: 118, 'Strike level': '1.5 %', Tenor: '10 y', Volatility: 10.4883, Strike: 8.8057 },
  { id: 129, 'Strike level': '1.5 %', Tenor: '12 y', Volatility: 10.5697, Strike: 8.756 },
  { id: 140, 'Strike level': '1.5 %', Tenor: '15 y', Volatility: 10.6096, Strike: 8.6443 },
  { id: 151, 'Strike level': '1.5 %', Tenor: '20 y', Volatility: 10.6127, Strike: 8.3832 },
  { id: 162, 'Strike level': '1.5 %', Tenor: '25 y', Volatility: 10.6109, Strike: 8.2339 },
  { id: 173, 'Strike level': '1.5 %', Tenor: '30 y', Volatility: 10.607, Strike: 8.1402 },
  { id: 184, 'Strike level': '1.5 %', Tenor: '35Y', Volatility: 10.6026, Strike: 8.0777 },
  { id: 195, 'Strike level': '1.5 %', Tenor: '40Y', Volatility: 10.5992, Strike: 8.0344 },
  { id: 206, 'Strike level': '1.5 %', Tenor: '45Y', Volatility: 10.5967, Strike: 8.0036 },
  { id: 217, 'Strike level': '1.5 %', Tenor: '50Y', Volatility: 10.5948, Strike: 7.9813 },
  { id: 228, 'Strike level': '1.5 %', Tenor: '55Y', Volatility: 10.5936, Strike: 7.9649 },
  { id: 239, 'Strike level': '1.5 %', Tenor: '60Y', Volatility: 10.5937, Strike: 7.9528 },
  { id: 9, 'Strike level': '2 %', Tenor: '1 y', Volatility: 15.7501, Strike: 9.5195 },
  { id: 20, 'Strike level': '2 %', Tenor: '18 m', Volatility: 13.8824, Strike: 9.3095 },
  { id: 31, 'Strike level': '2 %', Tenor: '2 y', Volatility: 13.8072, Strike: 9.4089 },
  { id: 42, 'Strike level': '2 %', Tenor: '3 y', Volatility: 12.6184, Strike: 9.4202 },
  { id: 53, 'Strike level': '2 %', Tenor: '4 y', Volatility: 11.9826, Strike: 9.4542 },
  { id: 64, 'Strike level': '2 %', Tenor: '5 y', Volatility: 11.6076, Strike: 9.4503 },
  { id: 75, 'Strike level': '2 %', Tenor: '6 y', Volatility: 11.4188, Strike: 9.4182 },
  { id: 86, 'Strike level': '2 %', Tenor: '7 y', Volatility: 11.1671, Strike: 9.3807 },
  { id: 97, 'Strike level': '2 %', Tenor: '8 y', Volatility: 10.9602, Strike: 9.3545 },
  { id: 108, 'Strike level': '2 %', Tenor: '9 y', Volatility: 10.8799, Strike: 9.3299 },
  { id: 119, 'Strike level': '2 %', Tenor: '10 y', Volatility: 10.8772, Strike: 9.3057 },
  { id: 130, 'Strike level': '2 %', Tenor: '12 y', Volatility: 10.9074, Strike: 9.256 },
  { id: 141, 'Strike level': '2 %', Tenor: '15 y', Volatility: 10.9018, Strike: 9.1443 },
  { id: 152, 'Strike level': '2 %', Tenor: '20 y', Volatility: 10.8563, Strike: 8.8832 },
  { id: 163, 'Strike level': '2 %', Tenor: '25 y', Volatility: 10.8111, Strike: 8.7339 },
  { id: 174, 'Strike level': '2 %', Tenor: '30 y', Volatility: 10.7732, Strike: 8.6402 },
  { id: 185, 'Strike level': '2 %', Tenor: '35Y', Volatility: 10.7425, Strike: 8.5777 },
  { id: 196, 'Strike level': '2 %', Tenor: '40Y', Volatility: 10.7187, Strike: 8.5344 },
  { id: 207, 'Strike level': '2 %', Tenor: '45Y', Volatility: 10.7001, Strike: 8.5036 },
  { id: 218, 'Strike level': '2 %', Tenor: '50Y', Volatility: 10.6858, Strike: 8.4813 },
  { id: 229, 'Strike level': '2 %', Tenor: '55Y', Volatility: 10.6748, Strike: 8.4649 },
  { id: 240, 'Strike level': '2 %', Tenor: '60Y', Volatility: 10.6663, Strike: 8.4528 },
  { id: 10, 'Strike level': '2.5 %', Tenor: '1 y', Volatility: 17.1341, Strike: 0.0195 },
  { id: 21, 'Strike level': '2.5 %', Tenor: '18 m', Volatility: 15.1546, Strike: 9.8095 },
  { id: 32, 'Strike level': '2.5 %', Tenor: '2 y', Volatility: 15.0764, Strike: 9.9089 },
  { id: 43, 'Strike level': '2.5 %', Tenor: '3 y', Volatility: 13.5969, Strike: 9.9202 },
  { id: 54, 'Strike level': '2.5 %', Tenor: '4 y', Volatility: 12.741, Strike: 9.9542 },
  { id: 65, 'Strike level': '2.5 %', Tenor: '5 y', Volatility: 12.2532, Strike: 9.9503 },
  { id: 76, 'Strike level': '2.5 %', Tenor: '6 y', Volatility: 11.9956, Strike: 9.9182 },
  { id: 87, 'Strike level': '2.5 %', Tenor: '7 y', Volatility: 11.6959, Strike: 9.8807 },
  { id: 98, 'Strike level': '2.5 %', Tenor: '8 y', Volatility: 11.4447, Strike: 9.8545 },
  { id: 109, 'Strike level': '2.5 %', Tenor: '9 y', Volatility: 11.3271, Strike: 9.8299 },
  { id: 120, 'Strike level': '2.5 %', Tenor: '10 y', Volatility: 11.2936, Strike: 9.8057 },
  { id: 131, 'Strike level': '2.5 %', Tenor: '12 y', Volatility: 11.2754, Strike: 9.756 },
  { id: 142, 'Strike level': '2.5 %', Tenor: '15 y', Volatility: 11.2299, Strike: 9.6443 },
  { id: 153, 'Strike level': '2.5 %', Tenor: '20 y', Volatility: 11.1533, Strike: 9.3832 },
  { id: 164, 'Strike level': '2.5 %', Tenor: '25 y', Volatility: 11.0766, Strike: 9.2339 },
  { id: 175, 'Strike level': '2.5 %', Tenor: '30 y', Volatility: 11.0103, Strike: 9.1402 },
  { id: 186, 'Strike level': '2.5 %', Tenor: '35Y', Volatility: 10.9564, Strike: 9.0777 },
  { id: 197, 'Strike level': '2.5 %', Tenor: '40Y', Volatility: 10.9138, Strike: 9.0344 },
  { id: 208, 'Strike level': '2.5 %', Tenor: '45Y', Volatility: 10.8803, Strike: 9.0036 },
  { id: 219, 'Strike level': '2.5 %', Tenor: '50Y', Volatility: 10.8539, Strike: 8.9813 },
  { id: 230, 'Strike level': '2.5 %', Tenor: '55Y', Volatility: 10.8332, Strike: 8.9649 },
  { id: 241, 'Strike level': '2.5 %', Tenor: '60Y', Volatility: 10.8169, Strike: 8.9528 },
]

export default {
  Surface: {
    data: surfaceData,
    options: {
      viz: 'surface',
      x: 'Tenor',
      y: 'Strike level',
      z: 'Volatility',
    },
  },
}
