<template>
  <div class="editor" :style="{ height: maxHeight }">
    <!-- add a true hidden <pre> tag containing the raw code that can serve technical purposes (like E2E testing): -->
    <pre style="display: none">{{ editorCode }}</pre>

    <!-- ooooh scary editor for devMode = true -->
    <CodemirrorEditor v-if="$root.devMode" v-model:value="editorCode" :options="cmOptions" @change="changeCode" />

    <!-- aaaaw cute editor for devMode = false -->
    <textarea
      v-else
      class="pre-like"
      :class="{ readonly }"
      :readonly="readonly"
      @input="$emit('update', $event.target.value)"
      >{{ editorCode }}</textarea
    >
  </div>
</template>

<script setup>
import CodemirrorEditor from 'codemirror-editor-vue3'
import 'codemirror/mode/javascript/javascript.js'
import 'codemirror/mode/css/css.js'
import 'codemirror/mode/htmlembedded/htmlembedded.js'
// TODO find a grammar mode for GraphQL!
import 'codemirror/theme/dracula.css'
import { watch, ref } from 'vue'

const emit = defineEmits(['update'])

const props = defineProps({
  code: {
    type: String,
    required: true,
  },
  language: {
    type: String,
    default: 'javascript',
  },
  maxHeight: {
    type: String,
    default: '40px',
  },
  readonly: {
    type: Boolean,
  },
})

const cmOptions = ref({
  mode: 'text/javascript',
  indentUnit: 2,
  theme: 'dracula',
  height: '100%',
  lineNumbers: true,
  lineWrapping: true,
  readOnly: props.readonly,
})

const changeCode = newCode => {
  emit('update', newCode)
}

const editorCode = ref(props.code || '')

watch(
  () => props.code,
  newCode => {
    editorCode.value = newCode
  },
  () => props.readonly,
  newReadonly => {
    cmOptions.value.readOnly = newReadonly
  },
  () => props.language,
  newLanguage => {
    cmOptions.value.mode = `text/${newLanguage}`
  },
)
</script>

<style scoped>
.editor {
  overflow-y: auto;
}

.pre-like {
  resize: none;
  white-space: pre-wrap;
  background-color: transparent;
  border: 1px solid #dedede;
  font-family: Arial, Helvetica, sans-serif;
  cursor: text;
  height: calc(100% - 10px);
  padding: 8px;
  width: 100%;
  max-width: 100%;
}

.readonly {
  cursor: default;
}

.codemirror-container {
  height: calc(100% - 10px) !important;
}
</style>
