/**
 * Generate chartjs chartOptions according options and chartOptions
 * @param options
 * @param tooltip
 * @param legends legends ref
 * @return {{chartOptions: {plugins: {legend: {align: string, labels: {usePointStyle: boolean}}, tooltip: *}, elements: {line: {tension: number}, point: {pointStyle: string, radius: number}}, scales: {x: {grid: {display: boolean}, time: {unit: string}, type: string}, y: {ticks: {callback: function(*=, *, *): (*|undefined)}}}, interaction: {intersect: boolean, axis: string}}}}
 */
export default function (options, { tooltip, legends } = {}) {
  const callbackY = options.value.formatY ? value => options.value.formatY(value) : undefined
  const callbackX = options.value.formatX ? value => options.value.formatX(value) : undefined

  const datalabels =
    options.value.datalabels === true
      ? {
          align: 'end',
          anchor: 'end',
          formatter: options.value.labelFormatter,
        }
      : undefined

  const chartOptions = {
    indexAxis: 'y',
    plugins: {
      datalabels,
      tooltip: !options.value.hideExternalTooltip && tooltip,
      legend: {
        display: false,
      },
      customLegend: {
        legends: !options.value.hideCustomLegend && legends,
      },
    },
    elements: {
      line: {
        tension: 0,
      },
      bar: {},
      point: {
        pointStyle: 'point',
        radius: 0,
      },
    },
    /**
     * Find nearest values for x (used for tooltip data)
     */
    interaction: {
      intersect: false,
      axis: 'y',
    },
    scales: {
      /**
       * Scale x by month
       */
      x: {
        ticks: {
          callback: callbackX,
          font: {
            size: options.value.textSize,
          },
        },
      },
      y: {
        ticks: {
          ...(callbackY ? { callbackY } : {}),
          font: {
            size: options.value.textSize,
          },
        },
        grid: { display: false },
      },
    },
    animation: options.value.animation,
  }
  return {
    chartOptions,
  }
}
