<template>
  <div class="tabs">
    <div class="tab-buttons">
      <div
        v-for="tab in tabs"
        :key="tab.name"
        @click="setActiveTab(tab.name)"
        :class="{ active: activeTab === tab.name, block: true }"
        role="tab"
      >
        {{ tab.title }}
      </div>
    </div>
    <div class="tab-content">
      <slot :name="activeTab"></slot>
    </div>
  </div>
</template>

<script setup lang="ts">
import { defineProps, ref, watch, defineEmits } from 'vue'

const props = defineProps({
  tabs: {
    type: Array as () => { name: string; title: string }[],
    required: true,
  },
  activeTab: {
    type: String,
    required: false,
  },
})

const emit = defineEmits(['send-active-tab'])

const activeTab = ref(props.activeTab || props.tabs[0].name)

watch(
  () => props.activeTab,
  newActiveTab => {
    if (newActiveTab) {
      activeTab.value = newActiveTab
    }
  },
)

const setActiveTab = (tabName: string) => {
  emit('send-active-tab', tabName)
}
</script>

<style scoped>
.tabs {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.tab-buttons {
  position: relative;
  display: flex;
}

.tab-buttons div {
  border-bottom: 1px solid var(--colors-gray1);
  width: 20%;
  margin-right: 5px !important;
  cursor: pointer;
  text-align: center;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
  max-height: 40px;
  height: 40px;
  padding: 0px;
  align-content: center;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  display: inline-block;
  color: var(--colors-inactive);
  z-index: 1;
  box-shadow: none !important;
}

.tab-buttons div:first-child {
  margin-left: 5px;
}

.tab-buttons div.active {
  border-bottom: 1px solid var(--colors-primary-default);
  color: var(--color-text);
}

.tab-content {
  display: flex;
  flex-direction: column;
  flex: 1;
}
</style>
