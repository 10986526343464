import { commandrSDK } from '../../../features/commandr'
import { onMounted, onUnmounted, ref } from 'vue'

export function useNxPack() {
  const loaded = ref(false)
  const nxpack = ref({})
  const update = snap => {
    nxpack.value = snap || {}
    loaded.value = true
  }
  onMounted(() => {
    loaded.value = false
    commandrSDK.on('/nxpack', 'value', update)
  })
  onUnmounted(() => commandrSDK.off('/nxpack', 'value'))
  return { nxpack, loaded }
}
