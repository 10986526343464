<template>
  <dr-variable-input
    class="flex w-40 flex-col items-center text-sm"
    v-for="param in inputs"
    :variableDefinition="param"
    :inputData="inputData?.[param.name]"
    :missingDependencies="missingDependencies[param.name]"
    :value="variables[param.name]"
    :key="param.name"
    @update:value="value => updateVariable(param, value)"
  >
    <template #label="{ variableDefinition }">
      <label class="w-full text-xs font-semibold text-[#00d9b8]">{{ variableDefinition.name }}</label>
    </template>
  </dr-variable-input>
  <tooltip-helper class="my-auto">
    <template #icon="{ updateDisplay }">
      <span @mouseover="updateDisplay" @mouseleave="updateDisplay" class="text-sm">Hover to see computed values</span>
    </template>
    <div v-for="{ name, value } in computedValues" :key="name">
      <div>{{ name }}: {{ value }}</div>
    </div>
  </tooltip-helper>
  <!-- <div>{{ variables }}</div> -->
</template>
<script setup lang="ts">
import { computed } from 'vue'
import { VariableDefinition, variableTypes } from '@100-m/hauru/src/components/form/drVariables/lib'

interface IProps {
  inputs: VariableDefinition[]
  missingDependencies: Record<string, any>
  inputData: Record<string, any>
  variables: Record<string, any>
  updateVariable: (param: VariableDefinition, value: any) => void
}
const props = defineProps<IProps>()
const computedValues = computed(() => {
  return props.inputs
    .filter(param => {
      const varType = variableTypes[param.variableType]
      return varType.inputType === 'readonly'
    })
    .map(param => {
      return {
        name: param.name,
        value: props.missingDependencies[param.name]
          ? `Needs ${props.missingDependencies[param.name].join(', ')} to be set.`
          : props.variables[param.name],
      }
    })
})
</script>
<style>
.dr-variable-input select,
.dr-variable-input input {
  @apply flex w-full truncate rounded border-transparent bg-slate-50 p-0 pl-1 pr-6 [font-size:inherit];
}
.dr-variable-input .missing-dependencies {
  @apply h-7 text-xs;
}
.tooltip-helper .tooltip-content {
  @apply rounded border border-slate-100 shadow;
}
</style>
