<style>
.pdf-esg-mai-2 > .column:not(:nth-of-type(-n + 1)) {
  margin-top: 30px;
}
</style>

<template lang="pug">
.pdf-esg-mai-2
  .subtitle.row(v-if="data.share.characteristics.benchmark_esg") {{ (t['univers_definition'] || 'univers_definition') + ': ' + t[data.share.characteristics.benchmark_esg] }}
  h2 {{ t['credit_pocket'] || 'credit_pocket' }}
  .row.main_lines_pocket
    .column(v-if="data.esg.carbon_intensity_credit_pocket.data.length")
      brick(subtitle="subtitle_carbon_intensity" title="carbon_intensity")
      pdf-horizontalbar-icon(
        :data="data.esg.carbon_intensity_credit_pocket.data"
        :options="{ format: '.2f', sort: d => ['fund', 'benchmark'].indexOf(d.key) }"
      )
      brick.no-bold.right-value(
        subtitle="subtitle_scoring_climatique"
        title="scoring_climatique"
        :data="data.esg.scoring_climatique_credit_pocket"
      )
        .disclaimer {{t['disclaimer_scoring_climatique_credit_pocket'] || 'disclaimer_scoring_climatique_credit_pocket'}}
    .column.theme_pocket
      brick(
        subtitle="subtitle_theme_credit"
        title="theme_credit"
        type="hbar"
        :data="data.esg.theme_credit"
        :options="{ format: '.2%' }"
      )
      .legend_line
        .row
          .legend_sample
          span {{t['fund'] }}
        .row
          .legend_sample
          span {{t['benchmark3'] }}
    .column
      brick.no-bold.right-value(
        subtitle="subtitle_main_scores_carbon_impact"
        title="main_scores_carbon_impact"
        :data="data.esg.main_lines_table_esg_credit.desc"
        :options="{ limit: 4 }"
      )
      brick.no-bold.right-value(
        :data="data.esg.main_lines_table_esg_credit.asc"
        :options="{ limit: 4 }"
      )
      .source(v-if="$root.lang.includes('be')") {{ t.source + ' ' + t[data.share.characteristics.management_company] }}
  .column(v-if="!data.share.characteristics.hide_data_impact")
    brick(subtitle="data_impact_subtitle" :title="t['data_impact'] || 'data_impact'")
    pdf-pictogram(:data="data.esg.esg_data_impact")
    .source(v-if="$root.lang.includes('be')") {{ t.source + ' ' + t[data.share.characteristics.management_company] }}
  .column
    brick.risk_disclaimer(title="definitions_esg" v-if="(data.share.characteristics.definition_esg_list || []).length > 0")
      div(v-for="definition in data.share.characteristics.definition_esg_list.split(',')" v-html="t[definition] || definition")
</template>

<script>
export default {
  props: { data: Object },
}
</script>
