<style>
.pdf .header-upper > * {
  color: var(--colors-primary-default);
}
.pdf .block.block-pdf-header {
  font-size: 10px;
  font-weight: 500;
  margin: 0 8px;
}
.pdf header .header-upper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 20px;
  margin-top: -24px;
  font-weight: 600;
}
.pdf header .header-upper .row :nth-child(2)::before {
  content: '-';
  margin: 0 4px;
}
.pdf header .header-inner {
  display: flex;
  min-width: calc(100% + 2 * var(--margin) + 16px);
  margin: 0 calc(-1 * var(--margin) - 8px);
  border-radius: 0;
}
.pdf .moniwan header .header-inner {
  padding: 0;
}
.pdf .moniwan header .header-inner {
  background: var(--hollywood);
  color: var(--navy);
}
.pdf header .hidden_logo {
  opacity: 0;
  width: 0px;
}
.pdf header .lang {
  width: 18px;
  height: 18px;
}
.pdf header .row.center.between {
  width: 100%;
}
.pdf header .company {
  height: 24px;
  margin-left: -2px;
  margin-right: 10px;
}
.pdf .pdf-page:nth-child(n + 2) header .company {
  display: none;
}
.pdf header .fund,
.pdf header .fund_other_page {
  font-size: 32px;
  font-weight: 600;
  line-height: 1.1;
  margin-bottom: 2px;
}
.pdf .moniwan header .fund {
  font-family: Roboto-Black !important;
  font-weight: 900;
}
.pdf header .fund.xxl,
.pdf header .fund_other_page.xxl,
.pdf-page:nth-child(n + 2) header .fund,
.pdf-page:nth-child(n + 2) header .fund_other_page {
  font-size: 18px;
}
.pdf header .fund.xl,
.pdf header .fund_other_page.xl,
.pdf-page:nth-child(n + 2) header .fund,
.pdf-page:nth-child(n + 2) header .fund_other_page {
  font-size: 22px;
}
.pdf header .fund.l,
.pdf header .fund_other_page.l,
.pdf-page:nth-child(n + 2) header .fund,
.pdf-page:nth-child(n + 2) .fund_other_page {
  font-size: 25px;
}
.pdf-page:nth-child(n + 2):not(.en_hk) header .fund.xl,
.pdf-page:nth-child(n + 2):not(.en_hk) header .fund_other_page.xl,
.pdf-page.en_hk:first-child header .fund.xl,
.pdf-page.en_hk:first-child header .fund_other_page.xl,
.pdf-page.en_hk:nth-child(n + 3) header .fund.xl,
.pdf-page.en_hk:nth-child(n + 3) header .fund_other_page.xl {
  font-size: 18px;
}
.pdf-page:nth-child(n + 2):not(.en_hk) header .fund.xxl,
.pdf-page:nth-child(n + 2):not(.en_hk) header .fund_other_page.xxl,
.pdf-page.en_hk:first-child header .fund.xxl,
.pdf-page.en_hk:first-child header .fund_other_page.xxl,
.pdf-page.en_hk:nth-child(n + 3) header .fund.xxl,
.pdf-page.en_hk:nth-child(n + 3) header .fund_other_page.xxl {
  font-size: 18px;
}
.pdf-page:nth-child(n + 2):not(.en_hk) header .column,
.pdf-page.en_hk:first-child header .column,
.pdf-page.en_hk:nth-child(n + 3) header .column {
  flex-direction: row;
  align-items: center;
}
.pdf-page:nth-child(n + 2):not(.en_hk) header .type,
.pdf-page.en_hk:first-child header .type,
.pdf-page.en_hk:nth-child(n + 3) header .type {
  display: none;
}
.pdf-page:nth-child(n + 2):not(.en_hk) header .row.center.between:first-child,
.pdf-page.en_hk:first-child header .row.center.between:first-child,
.pdf-page.en_hk:nth-child(n + 3) header .row.center.between:first-child {
  max-width: fit-content;
}
.pdf-page:nth-child(n + 2):not(.en_hk) header .row.center.between:nth-child(2),
.pdf-page.en_hk:first-child header .row.center.between:nth-child(2),
.pdf-page.en_hk:nth-child(n + 3) header .row.center.between:nth-child(2) {
  width: unset;
  flex: 1;
}
.pdf-page:nth-child(n + 2):not(.en_hk) header .header-lower,
.pdf-page.en_hk:first-child header .header-lower,
.pdf-page.en_hk:nth-child(n + 3) header .header-lower {
  display: none;
}

.pdf header .header-lower {
  display: flex;
  align-items: center;
  height: 20px;
}
.pdf header .header-lower .title {
  font-weight: 600;
}
.pdf header .header-lower svg {
  height: 10px;
}
.pdf .pdf-page:not(.en_hk):first-child header .logos,
.pdf .pdf-page.en_hk:nth-child(2) header .logos {
  align-items: center;
  justify-content: space-evenly;
  flex-wrap: wrap;
}
.pdf .pdf-page:not(.en_hk):first-child header .logos .label,
.pdf .pdf-page.en_hk::nth-child(2) header .logos .label {
  max-width: 120px;
  max-height: 45px;
}
.pdf .pdf-page:not(.en_hk):first-child header .logos .label img,
.pdf .pdf-page.en_hk:nth-child(2) header .logos .label img {
  max-width: 120px;
  max-height: 45px;
}
.pdf .pdf-page:nth-child(n + 2):not(.en_hk) .logos,
.pdf .pdf-page.en_hk:first-child .logos,
.pdf .pdf-page.en_hk:nth-child(n + 3) .logos {
  display: none;
}

.pdf-page:nth-child(n + 2):not(.en_hk) header .fund,
.pdf-page.en_hk:first-child header .fund,
.pdf-page.en_hk:nth-child(n + 3) header .fund {
  display: none;
}
.pdf-page:nth-child(1):not(.en_hk) .fund_other_page,
.pdf-page:nth-child(2).en_hk .fund_other_page {
  display: none;
}
.pdf-page:not(.en_hk):first-child .column:nth-child(2),
.pdf-page.en_hk:nth-child(2) .column:nth-child(2) {
  margin-left: 5px;
  min-width: 530px;
}
.pdf header .fund.l,
.pdf header .fund_other_page.l,
.pdf-page:nth-child(n + 2) header .fund,
.pdf-page:nth-child(n + 2) header .fund_other_page {
  font-size: 25px;
}
.fund_subtitle {
  font-size: 10px;
  font-weight: lighter;
}
.pdf .moniwan header .logo-container {
  width: 12%;
  padding-left: 20px;
}
.pdf .moniwan.page1 header .logo-container {
  width: 16%;
}
.pdf .moniwan header .title-container {
  width: 60%;
  padding: 0 8px;
  display: flex;
}
.pdf .moniwan header .separator {
  width: 0;
  height: 0;
  border-left: 16px solid transparent;
  border-bottom: 54px solid var(--sand);
}
.pdf .moniwan.page1 header .separator {
  border-left: 16px solid transparent;
  border-bottom: 84px solid var(--sand);
}
.pdf .moniwan header .logo-right-container {
  width: 26%;
  background: var(--sand);
  display: flex;
}
.pdf .moniwan header .logo-right {
  height: 40px;
  align-self: center;
  padding-left: 12px;
}
</style>

<template lang="pug">
header.block.block-pdf-header(:class="{[share.characteristics.theme]: true}")
  .header-upper
    .row
      .isin(v-if="share.fund_or_mandat === 'fund'") {{ share.share_id }}
    .row
      .period {{ t[period] || period }}
      .date {{ new Intl.DateTimeFormat($root.lang.slice(0,2), {day: 'numeric', month: 'long', year: 'numeric'}).format(new Date(domain[1])) }}
  .header-inner.moniwan(v-if="share.fund_name.includes('Moniwan')")
    .logo-container
      img.logo(:src="'logoMoniwan.png'")
    .title-container
      .row.center.between
        .fund(:class="[({ xxl: 45, xl: 25, l: 15 }).find(v => share.fund_name.length > v) ]") {{ share.fund_name + ' ' + share.share_letter }}
          .fund_subtitle(v-if="$root.lang.includes('be')") {{t.fund_managed_by + ' ' + t[share.characteristics.management_company] }}
        .fund_other_page(:class="[({ xl: 25, l: 15 }).find(v => share.fund_name.length > v) ]") {{ share.fund_name + ' ' + share.share_letter }}
    .separator
    .logo-right-container
      img.logo-right(:src="'logoMoniwan-RVB.png'")
  .header-inner(v-else)
    .column.center.left.expand
      .row.center.between
        //.company(v-if="share.pdf_company_title") {{ share.pdf_company_title.titleize() }}
        //img.company(:src="'/dist/lafrancaise/' + share.pdf_company_title.replace(/_/g, '-') + '.png'" v-if="share.pdf_company_title")
        //.type {{ t[share.characteristics.share_category] || share.characteristics.share_category }}
      .row.center.between
        .fund(:class="[({ xxl: 45, xl: 25, l: 15 }).find(v => share.fund_name.length > v) ]") {{ share.fund_name + ' ' + share.share_letter }}
          .fund_subtitle(v-if="$root.lang.includes('be')") {{t.fund_managed_by + ' ' + t[share.characteristics.management_company] }}
        .fund_other_page(:class="[({ xl: 25, l: 15 }).find(v => share.fund_name.length > v) ]") {{ share.fund_name + ' ' + share.share_letter }}
    .logos.row(v-if="(share.labels || []).length > 0")
      .label(v-for="img in share.labels")
        img(:src="`${img.split('.')[0]}_${$root.lang.slice(0,2).toLowerCase()}.png`" @error="$event.target.src = img")
  .header-lower(v-if="share.characteristics.country_registration")
    .title {{ (t['country_registration'] || 'country_registration') + ':' }}
    .row.center(v-for="flag in share.characteristics.country_registration.split('-')" )
      svg-icon(:name="['flag', flag.lower().trim()].join('-')")
      div {{ flag }}
</template>

<script>
export const additions = {}
export default {
  props: {
    share: {
      type: Object,
      required: true,
    },
    period: {
      type: String,
      required: true,
    },
    domain: {
      type: Array,
      required: true,
    },
  },
}
</script>
