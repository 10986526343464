import { ref, watch } from 'vue'
import Color from '@kurkle/color'
import { genColors } from '../../utils'
/**
 * Create datasets and watch for updates
 * @param data Chart data
 * @param options Chart options
 * @param chartOptions Extend dataset with chartOptions
 * @param multicolor Use several colors per dataset
 * @param transparentize Make all series background color transparent
 * @return {{datasets: ToRef<*[]>, labels: ToRef<*[]>}}
 */
export default function (
  data,
  options,
  chartOptions = {},
  { multicolor, transparentize } = { multicolor: false, borderColor: true, transparentize: false },
) {
  const datasets = ref([])
  const labels = ref([])

  const updateDatasets = () => {
    const labelKey = options.value.labelKey || 'date'
    labels.value = data.value.map(d => d[labelKey])

    datasets.value = options.value.datasets?.map((d, i) => {
      const dataset = {
        backgroundColor: genColors(i, multicolor, transparentize),
        borderColor: genColors(i, multicolor),
        ...chartOptions,
        ...d,
        data: data.value.map(o => o[d.key]),
      }
      // console.log('dataset', dataset)
      delete dataset.key
      return dataset
    })

    // console.log('datasets', datasets.value)
  }
  updateDatasets()
  watch(data, updateDatasets, { deep: true })
  watch(options, updateDatasets)
  return {
    datasets,
    labels,
  }
}
