import Color from '@kurkle/color'

/**
 * Generate a graph color according a color number. It use the --catX css variables.
 * @param i Color number (1-12)
 * @param multicolor If true, return the 12 color array
 * @param transparentize  If true apply an aplha on the colors
 * @return {string|string[]} rgba string or rgba string array
 */
export function genColors(i, multicolor = false, transparentize = false) {
  const style = getComputedStyle(document.body)

  const color = i =>
    new Color((style.getPropertyValue(`--cat${i + 1}`) || '#000000').trim()).alpha(transparentize ? 0.2 : 1).rgbString()

  if (!multicolor) return color(i)
  return Array(12)
    .fill('')
    .map((_, i) => color(i))
}

/**
 * Return all cat colors
 * @param transparentize
 * @return {string[]}
 */
export function allColors(transparentize = false) {
  const style = getComputedStyle(document.body)
  const color = i =>
    new Color(style.getPropertyValue(`--cat${i + 1}`).trim() || '#000000').alpha(transparentize ? 0.2 : 1).rgbString()

  return Array(12)
    .fill('')
    .map((_, i) => color(i))
}
