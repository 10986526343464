export type IMapKeysToTypeOrString<T> = {
  [Property in keyof T]: T[Property] | string
}

export type IMapKeysToTypeOrControl<T> = {
  [Property in keyof T]?: T[Property] | IMapPropertiesToControl<T[Property]>
}

export type IMapKeysToControl<T> = {
  [Property in keyof T]: IMapPropertiesToControl<T> | undefined
}

export type IMapPropertiesToControl<T> = T extends number
  ? IStoryNumber | IStoryRange | IStorySelect<T>
  : T extends string
    ? IStoryString | IStoryText | IStorySelect<T>
    : T extends boolean
      ? IStoryBoolean | IStorySelect<T>
      : IStorySelect<T>

export type IWritable<T> = {
  -readonly [Key in keyof T]: T[Key]
}

export interface ILinkedState {
  [key: string]: IStoryControlBase
}

export interface IStoryOptionsMap {
  [key: string]: IStoryControlBase
}

export interface IStoryBase<T> {
  /**
   * Component Name
   */
  name: string
  /**
   * Contains current value of props
   */
  props: IWritable<T>
  states: { [key: string]: ILinkedState }
  onSet: (() => void)[]
}

export interface IStory<T> extends IStoryBase<T> {
  /**
   * Prop options
   */
  options: IMapKeysToControl<T>
  /**
   * Contains css code
   */
  css: string
  /**
   * Contains html template
   */
  code: string
  actions: IStoryButton[]
  examples: IStoryBase<T>[]
  example: string
  exampleIndex: number
}

export interface IStoryState<T> extends IStory<T> {
  /**
   * Sets props (values and options)
   * @param props
   */
  setProps: (props: IMapKeysToTypeOrControl<T>) => IStoryState<T>
  /**
   * Links state parameters that will be shown in the doc
   */
  createState: (title: string, state: ILinkedState) => IStoryState<T>
  addActions: (actions: IStoryButton[]) => IStoryState<T>
  setExample: (name: string, index?: number) => IStoryState<T>
  onExampleSet: (fun: () => void) => IStoryState<T>
  createExample: (name: string) => IStoryState<T>
  createDefaultExample: (name: string) => IStoryState<T>
  setExampleState: (name: string, setState: { [key: string]: any }) => IStoryState<T>
  setExampleProps: (props: Partial<IMapKeysToTypeOrString<T>>) => IStoryState<T>
}

export interface IStoryControlBase<
  T extends 'string' | 'number' | 'numbers' | 'boolean' | 'range' | 'select' | 'text' = any,
  V = any,
  SerializedV = V,
> {
  type: T
  value: V
  description?: string
  isRequired: boolean
  getter?: () => V
  setters: ((value: V) => void)[]
  onChange: (fun: (value: V) => void) => IStoryControlBase<T, V>
  it: (description: string) => IStoryControlBase<T, V>
  required: () => IStoryControlBase<T, V>
  optional: () => IStoryControlBase<T, V>
  getValue: () => SerializedV
  setValue: (value: SerializedV) => void
}

export interface IStoryNumber extends IStoryControlBase<'number', number> {
  options: {
    type: 'number'
    min?: number
    max?: number
    step?: number
  }
}

export interface IStoryRange extends IStoryControlBase<'range', number> {
  options: {
    type: 'range'
    min?: number
    max?: number
    step?: number
  }
}

export interface IStoryNumbers<T extends { [key: string]: IStoryNumber }>
  extends IStoryControlBase<'numbers', T, { [key: string]: number }> {}

export interface IStorySelect<T> extends IStoryControlBase<'select', T, T | string> {
  type: 'select'
  options: {
    name: string
    value: T
  }[]
}

export interface IStoryString extends IStoryControlBase<'string', string> {}
export interface IStoryText extends IStoryControlBase<'text', string> {}
export interface IStoryBoolean extends IStoryControlBase<'boolean', boolean> {}

/**
 * User Defined Type Guards
 */
export function isStoryNumber(control: IStoryControlBase): control is IStoryNumber {
  return control.type === 'number'
}

export function isStoryRange(control: IStoryControlBase): control is IStoryRange {
  return control.type === 'range'
}

export function isStoryNumbers(control: IStoryControlBase): control is IStoryNumbers<any> {
  return control.type === 'numbers'
}

export function isStorySelect(control: IStoryControlBase): control is IStorySelect<any> {
  return control.type === 'select'
}

export function isStoryString(control: IStoryControlBase): control is IStoryString {
  return control.type === 'string'
}

export function isStoryText(control: IStoryControlBase): control is IStoryText {
  return control.type === 'text'
}

export function isStoryBoolean(control: IStoryControlBase): control is IStoryBoolean {
  return control.type === 'boolean'
}

export interface IStoryButton {
  label: string
  onClick: () => void
}
