export function useDates() {
  const getDatesQuery = async (params, query = 'performance') => {
    const queries = {
      performance: `
        query PerformanceDatesQuery($isinShare: String!) {
          share(isin_share: $isinShare) {
            performanceDates
          }
        }
      `,
      allocation: `
        query AllocationDatesQuery($isinShare: String!) {
          share(isin_share: $isinShare) {
            allocationDates
          }
        }
      `,
    }

    console.log('getDatesQuery')
    const resp = await axios({
      url: '/dr',
      method: 'post',
      data: {
        variables: params,
        query: queries[query],
      },
    })
    const data = resp.data.data
    if (query === 'performance') return data.share.performanceDates
    return data.share.allocationDates
  }
  return {
    getDatesQuery,
  }
}
