<template>
  <!-- nx-dropdown-more -->
  <div>
    <nx-autocomplete :options="autocompleteOptions" :data="props.data" @change="onChange">
      <template #trigger>
        <div
          class="dropdown-trigger-container inline-flex cursor-pointer items-center border border-solid border-transparent py-0.5"
        >
          <div class="i-lucide/more-vertical"></div>
        </div>
      </template>
    </nx-autocomplete>
  </div>
</template>

<script setup lang="ts">
interface IProps {
  /**
   * Dropdown data tree (will be forwarded to component nx-autocomplete.vue)
   */
  data: Object
}
const emit = defineEmits<{
  (e: 'click', value: String | number): void
}>()

const props = withDefaults(defineProps<IProps>(), {})
const autocompleteOptions = {
  placeholder: 'autocomplete',
}

const onChange = (e: Array<String | number>) => emit('click', e.pop() as any)
</script>

<style scoped></style>
