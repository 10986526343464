export function processCell(cell, share) {
  if (cell === 'pdf.computed.gross_rate_sensitivity')
    return format('.2f')(share.allocation_analytics.gross_rate_sensitivity)
  if (cell === 'pdf.computed.derivative_exposure') return format('.2f')(share.allocation_analytics.derivative_exposure)
  if (
    /^(performance|performance_annualized|volatility|tracking_error|information_ratio|sharpe_ratio|max_drawdown_value|beta|alpha|var_X|var_X_Y_days)./.test(
      cell,
    )
  ) {
    const analytics = share.analytics[cell.replaceAll('.', '_')]
    const value = analytics?.fund || analytics?.benchmark || analytics?.benchmark2 || analytics?.benchmark3 || analytics
    return value === null ? '-' : format(cell.split('.')[0])(value)
  }
  if (/=>/.test(cell)) {
    cell = cell.replaceAll('$root.share', 'share.characteristics')
    cell = cell.replaceAll('$root.userflow', 'share.characteristics')
    cell = cell.replaceAll('$root.db.config.translation[$root.lang]', '$root.t')
    cell = cell.replaceAll(
      "$root.x.performance.filter(d => d.date < $root.domain[1]).maxgain_details('fund')",
      'share.analytics.maxgain_details_inception',
    )
    cell = cell.replaceAll(
      "$root.x.performance.filter(d => d.date < $root.domain[1]).drawdown_details('fund')",
      'share.analytics.drawdown_details_inception',
    )
    cell = cell.replaceAll(
      "$root.x.performance.filter(d => d.date > new Date($root.domain[1]).minus('5 year').format() && d.date < $root.domain[1]).maxgain_details('fund')",
      'share.analytics.maxgain_details_5y',
    )
    cell = cell.replaceAll(
      "$root.x.performance.filter(d => d.date > new Date($root.domain[1]).minus('5 year').format() && d.date < $root.domain[1]).drawdown_details('fund')",
      'share.analytics.drawdown_details_5y',
    )

    // Performance indicators - performance_indicators_left_diversifie_classique
    cell = cell.replaceAll('$root.x.mapping.filter(d => d.id === $root.benchmark)[0]', 'mapping[share.benchmark.id]')
    // Performance indicators - performance_indicators_treso
    cell = cell.replaceAll(
      "$root.x.performance.filter(d=> d.date <= $root.domain[1] && d.date >= new Date($root.domain[1]).minus('1 year').format()).volatility(metric='fund', day=4)",
      'share.analytics.volatility_1y_fund',
    )
    cell = cell.replaceAll(
      "$root.x.performance.filter(d=> d.date <= $root.domain[1] && d.date >= new Date($root.domain[1]).minus('3 year').format()).volatility(metric='fund', day=4)",
      'share.analytics.volatility_3y_fund',
    )
    cell = cell.replaceAll(
      "$root.x.performance.filter(d=> d.date <= $root.domain[1] && d.date >= new Date($root.domain[1]).minus('5 year').format()).volatility(metric='fund', day=4)",
      'share.analytics.volatility_5y_fund',
    )
    cell = cell.replaceAll(
      "$root.x.performance.filter(d=> d.date <= $root.domain[1] && d.date >= new Date($root.domain[1]).minus('1 year').format()).sharpe_ratio(day=4)",
      'share.analytics.sharpe_ratio_1y',
    )
    cell = cell.replaceAll(
      "$root.x.performance.filter(d=> d.date <= $root.domain[1] && d.date >= new Date($root.domain[1]).minus('3 year').format()).sharpe_ratio(day=4)",
      'share.analytics.sharpe_ratio_3y',
    )
    cell = cell.replaceAll(
      "$root.x.performance.filter(d=> d.date <= $root.domain[1] && d.date >= new Date($root.domain[1]).minus('5 year').format()).sharpe_ratio(day=4)",
      'share.analytics.sharpe_ratio_5y',
    )

    // Risk indicators - performance_table_second_page_right
    cell = cell.replaceAll(
      "$root.xf.allocation.data.filter(d => d.date === $root.domain[1]).filter(d => ['classe_d_actif__pct_expo_obligations', 'classe_d_actif__pct_expo_convertibles'].includes((mapping[d.id] || {}).classe_d_actif__pct_expo)).v().length",
      'share.allocation_analytics.assets_count',
    )
    cell = cell.replaceAll(
      "$root.xf.allocation.data.filter(d => d.date === $root.domain[1] && mapping[d.id].emetteur && d.weight_fund).filter(d => ['classe_d_actif__pct_expo_obligations', 'classe_d_actif__pct_expo_convertibles'].includes((mapping[d.id] || {}).classe_d_actif__pct_expo)).map(d => (mapping[d.id] || {}).emetteur).unique().length",
      "share.allocation_analytics.lines.map('allocationsDesc').flat().group('axis.emetteur').keys().filter(v => v !== 'NA').length",
    )
    cell = cell.replaceAll(
      "$root.xf.allocation.data.filter(d => d.date === $root.domain[1]).filter(d => mapping[d.id].callable === 'callable_true').sum('valuation') / $root.xf.allocation.data.filter(d => d.date === $root.domain[1]).sum('valuation')",
      'share.allocation_analytics.callable_rate',
    )
    cell = cell.replaceAll(
      "$root.xf.allocation.data.filter(d => d.date === $root.domain[1]).filter(d => mapping[d.id].dettes_ig_hy_sub === 'dettes_ig_hy_sub_dettes_subordonnees').sum('valuation') / $root.xf.allocation.data.filter(d => d.date === $root.domain[1]).sum('valuation')",
      'share.allocation_analytics.sub_debt_exposure',
    )
    cell = cell.replaceAll(
      "$root.xf.allocation.data.filter(d => d.date === $root.domain[1]).filter(d => mapping[d.id].dettes_ig_hy_sub === 'dettes_ig_hy_sub_dettes_subordonnees').sum('weight_benchmark')",
      'share.allocation_analytics.sub_debt_benchmark_exposure',
    )
    cell = cell.replaceAll(
      "$root.xf.allocation.data.filter(d => d.date === $root.domain[1]).filter(d => mapping[d.id].devise != 'EUR').sum('valuation') / $root.xf.allocation.data.filter(d => d.date === $root.domain[1]).sum('valuation')",
      'share.allocation_analytics.net_currency_exposure',
    )
    cell = cell.replaceAll(
      "$root.xf.allocation.data.filter(d => d.date === $root.domain[1]).filter(d => mapping[d.id].type === 'type_cds').sum('exposure_fund')",
      'share.allocation_analytics.cds_exposition',
    )
    cell = cell.replaceAll(
      '$root.x.mapping.filter(d => d.id == share.characteristics.comparison_index)[0].delta_action',
      'mapping[share.benchmark.id].dimensions.delta_action',
    )
    cell = cell.replaceAll(
      '$root.x.mapping.filter(d => d.id == share.characteristics.comparison_index)[0].sensibilite_taux',
      'mapping[share.benchmark.id].dimensions.sensibilite_taux',
    )

    return eval(cell)()
  }
  return cell
}

export function processRow(row, share) {
  if (!Array.isArray(row)) {
    return [row, share[row] || share.characteristics[row]]
  }
  if (row[1] === '') {
    const value = share[row[0]] || share.characteristics[row[0]]
    return [row[0], format(row[0])(value)]
  }
  return row.map(cell => {
    try {
      return processCell(cell, share)
    } catch (e) {
      window.errors = window.errors || []
      window.errors.push(['cell', cell, e])
      // console.error('cell', cell, e)
      return ''
    }
  })
}

export function processTable(table, data) {
  const { share } = data
  return cleanTable(table.map(row => processRow(row, share)))
}

export function findTable(table, data) {
  const { tables, share } = data
  return tables.find(t => t.name === share.characteristics[table])?.table
}

export function cleanTable(table) {
  const header_cols = table[0].length - table[0].reverse().findIndex()
  table = table.map(r => r.slice(0, header_cols))
  const empty_cols = table
    .map(r => r.reverse().findIndex())
    .map(v => (v === -1 ? 0 : v))
    .min()
  return empty_cols ? table.map(r => r.slice(0, -empty_cols)) : table
}

export function titleize_normal(str) {
  return ('' + str)
    .lower()
    .split(' ')
    .map(d => [d.charAt(0).toUpperCase(), d.slice(1)].join(''))
    .join(' ')
}

export function contextualizationTable(tables, defaultValue, key, lang) {
  const contextedKey = `${defaultValue || key}_${lang.toUpperCase()}`
  return tables[contextedKey] ? contextedKey : defaultValue
}
