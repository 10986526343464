<style>
:root {
  --thermo-stroke-color: #7d7d7d;
  --termo-text-color: black;
  --thermo-bar-color: #b0b0b0;
  --thermo-bar-stroke-width: 0.3;
}
svg {
  width: 100%;
  height: 100%;
  display: block;
  opacity: 1;
}
.termo_stroke {
  fill: none;
  stroke: var(--thermo-stroke-color);
  stroke-miterlimit: 1;
}
.thermoLabels text {
  font-weight: 600;
  fill: var(--termo-text-color);
  font-size: var(--font-size);
  font-family: Apercu;
}
.thermoBars {
  fill: none;
  stroke: var(--thermo-bar-color);
  stroke-width: var(--thermo-bar-stroke-width);
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-miterlimit: 10;
  stroke-dasharray: 2;
}
.thermoTick {
  fill: none;
  stroke: none;
  stroke-width: 0.75;
}
#thermoLabels text:nth-child(2) {
  transform: matrix(1, 0, 0, 1, 90.7389, 14.4589) !important;
}
#thermoLabels text:nth-child(3) {
  transform: matrix(1, 0, 0, 1, 146.5494, 14.4589) !important;
}
circle#fund,
text#fund {
  fill: var(--colors-primary-default);
}
circle#univers,
text#univers {
  fill: var(--colors-secondary);
}
</style>

<template lang="pug">
.thermometer_container
  svg(version='1.1' id='Layer_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 240 140' style='enable-background:new 0 0 420.7 283.5;' xml:space='preserve')
    defs
      linearGradient(id="thermoGradient" x1="0%" y1="0%" x2="0%" y2="100%")
        stop(offset="0%" stop-color="red" stop-opacity="1")
        stop(offset="50%" stop-color="orange" stop-opacity="1")
        stop(offset="100%" stop-color="green" stop-opacity="1")
    g#thermoContainer
      g#metrics
        g#topMetric(v-if="y_values.top")
          path.termo_stroke(:d="`M43,${y_top}h8`")
          text(:transform="`matrix(1 0 0 1 8 ${y_top+2})`")
            | {{ format('.2f')(y_values.top) }} °C
        
        g#midtopMetric(v-if="y_values.mid_top")
          path.termo_stroke(:d="`M43,${y_mid_top}h8`")
          text(:transform="`matrix(1 0 0 1 8 ${y_mid_top+2})`")
            | {{ format('.2f')(y_values.mid_top) }} °C
        
        g#midMetric(v-if="y_values.mid")
          path.termo_stroke(:d="`M43,${y_mid}h8`")
          text(:transform="`matrix(1 0 0 1 8 ${y_mid+2})`")
            | {{ format('.2f')(y_values.mid) }} °C
        
        g#midbotMetric(v-if="y_values.mid_bot")
          path.termo_stroke(:d="`M43,${y_mid_bot}h8`")
          text(:transform="`matrix(1 0 0 1 8 ${y_mid_bot+2})`")
            | {{ format('.2f')(y_values.mid_bot) }} °C
        
        g#botMetric(v-if="y_values.bot")
          path.termo_stroke(:d="`M43,${y_bot}h8`")
          text(:transform="`matrix(1 0 0 1 8 ${y_bot+2})`")
            | {{ format('.2f')(y_values.bot) }} °C
        g#thermo
          path#thermoBody(d='M62.3,121V42.9c0-2.8-2-5.2-4.5-5.2s-4.5,2.3-4.5,5.2V121c0,0.4-1.6,3.7-1.6,5.3 c0.1,3,2.7,5.5,5.8,5.6c3.5,0.1,6.3-2.5,6.3-5.8C63.9,124.6,62.3,121.4,62.3,121z' style='fill:none;stroke:#DDDDDD;stroke-width:0.75')
          path#thermoGradiant(d='M59.2,121.8V42.6h-2.9v79.2c-1.5,0.6-2.5,2.1-2.5,3.9c0,2.3,1.8,4.2,4,4.2s4-1.9,4-4.2 C61.8,123.9,60.7,122.4,59.2,121.8z' style='fill:url(#thermoGradient)')
    line#topBar.thermoBars(x1='67' :y1='y_top' x2='189' :y2='y_top' v-if="y_values.top")
    line#midtopBar.thermoBars(x1='67' :y1='y_mid_top' x2='189' :y2='y_mid_top' v-if="y_values.mid_top")
    line#midBar.thermoBars(x1='67' :y1='y_mid' x2='189' :y2='y_mid' v-if="y_values.mid")
    line#midbotBar.thermoBars(x1='67' :y1='y_mid_bot' x2='189' :y2='y_mid_bot' v-if="y_values.mid_bot")
    line#botBar.thermoBars(x1='67' :y1='y_bot' x2='189' :y2='y_bot' v-if="y_values.bot")

    circle(v-if="y_values.fund" cx="105" :cy="y_values.fund" r="2.5" id="fund")
    circle(v-if="y_values.bench" cx="162" :cy="y_values.bench" r="2.5" id="univers")

    g#thermoLabels
      text( v-for="(item, id) in data.header" transform="matrix(1 0 0 1 8 14)" :id="data.header[id]")
          | {{ t[item] || item }}
</template>

<script>
export const additions = { props: ['data'] }
export default {
  props: {
    data: {
      type: Object,
    },
  },
  data() {
    return {
      y_top: 50,
      y_mid_top: 78,
      y_mid: 85,
      y_mid_bot: 92,
      y_bot: 120,
    }
  },
  computed: {
    y_values() {
      const fundArray = Object.values(this.data.values)
      const min = Math.min(...fundArray)
      const max = Math.max(...fundArray)
      const moy = (max - min) / 2 + min
      const res = {
        top: moy != max ? max : Math.ceil(moy * 10) / 10 != moy ? Math.ceil(moy * 10) / 10 : moy + 0.1,
        mid: moy,
        bot: moy != min ? min : Math.floor(moy * 10) / 10 != moy ? Math.floor(moy * 10) / 10 : moy - 0.1,
      }
      //* only one value: fund or bench
      if (!this.data.values.hasOwnProperty('fund')) return { fund: null, bench: this.y_mid, ...res }
      if (!this.data.values.hasOwnProperty('benchmark')) return { fund: this.y_mid, bench: null, ...res }
      //* fund === bench
      if (this.data.values.fund === this.data.values.benchmark) return { fund: this.y_mid, bench: this.y_mid, ...res }
      //* diff<0.01
      if (max - min <= 0.02) {
        if (this.data.values.fund > this.data.values.benchmark)
          return {
            fund: this.y_mid_top,
            bench: this.y_mid_bot,
            mid_top: max,
            mid_bot: min,
            top: Math.ceil(max) != max ? Math.ceil(max * 10) / 10 : max + 0.1,
            bot: Math.floor(min) != min ? Math.floor(min * 10) / 10 : min - 0.1,
          }
        if (this.data.values.fund < this.data.values.benchmark)
          return {
            fund: this.y_mid_bot,
            bench: this.y_mid_top,
            mid_top: max,
            mid_bot: min,
            top: Math.ceil(max) != max ? Math.ceil(max * 10) / 10 : max + 0.1,
            bot: Math.floor(min) != min ? Math.floor(min * 10) / 10 : min - 0.1,
          }
      }
      //* usual case
      if (this.data.values.fund > this.data.values.benchmark) return { fund: this.y_top, bench: this.y_bot, ...res }
      if (this.data.values.fund < this.data.values.benchmark) return { fund: this.y_bot, bench: this.y_top, ...res }
    },
  },
}
</script>
