import { GraphQlService } from './GraphQlService'

/**
 * Performances service
 */
export class PerformanceService extends GraphQlService {
  /**
   * List performances of a share
   * @param fund Fund name
   * @param share Share name
   * @param domain Date range
   * @return {Promise<*>} Array of Performance
   */
  async list({ fund, share, domain }) {
    if (!fund || !share) throw new Error('Missing parameters')

    return (
      await this.query(
        `
        query AllocationQuery($fund: String!, $share: String!, $domain: [String]) {
          allocations(fund: $fund share: $share, domain: $domain)
        }
    `,
        { fund, share, domain },
      )
    ).data.allocations
  }
}
const performanceService = new PerformanceService()
export default performanceService
