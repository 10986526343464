<style>
:root {
  --picto-border-radius: 4px;
  --item-margin: 8px;
  --item-padding: 8px;
  --picto-text-size: 12px;
  --picto-text-paragraph-size: 10px;
  --picto-text-paragraph-weight: 400;
  --picto-font-weight: bold;
  --icon-width: 35px;
  --icon-height: 30px;
  --picto-bg-secondary: #c0e3ee;
}
.pictoContainer {
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: center;
  min-height: 300px;
}
.picto_box {
  display: flex;
  flex-direction: column;
  flex: 1;
}
.image_container {
  width: 40px;
  height: 40px;
  background-size: contain !important;
  background-repeat: no-repeat !important;
  background-position: 0 0 !important;
}
.picto_article {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  flex: 1;
  padding: var(--item-padding);
  margin-right: var(--item-margin);
  margin-bottom: var(--item-margin);
  border-radius: var(--picto-border-radius);
}
.picto_article__title {
  font-size: var(--picto-text-size);
  font-weight: var(--picto-font-weight);
}
.picto_article__value {
  font-size: var(--picto-text-size);
  font-weight: var(--picto-font-weight);
}
.picto_content {
  font-size: var(--picto-text-paragraph-size);
  font-weight: var(--picto-text-paragraph-weight);
}

.pictoContainer .svg-icon {
  height: var(--icon-height) !important;
  width: var(--icon-width) !important;
  margin: 0px !important;
}

.fr_carbone,
.fr_car,
.fr_home,
.fr_bag {
  background-color: var(--colors-primary-default);
  color: var(--picto-bg-secondary);
}

.fr_wind,
.fr_wind_turbine {
  background: var(--picto-bg-secondary);
  color: var(--colors-primary-default);
}

.fr_tree {
  background: #eaecf1;
  color: var(--colors-primary-default);
}
</style>

<template lang="pug">
.pictoContainer
  .picto_box
    .article.picto_article(v-for="item in data.column1" :key="item.id" :class="`${item.icon}`")
      .image_container
        svg-icon(:name="`${item.icon}`")
      span.picto_article__title {{ item.title }}
      span.picto_article__value {{ item.value }}{{item.unit}}
      p.picto_content {{ item.p1 }}
      p.picto_content {{ item.p2 }}
  .picto_box
    .article.picto_article(v-for="item in data.column2" :key="item.id" :class="`${item.icon}`")
      .image_container
        svg-icon(:name="`${item.icon}`")
      span.picto_article__title {{ item.title }}
      span.picto_article__value {{ item.value }}{{item.unit}}
      p.picto_content {{ item.p1 }}
      p.picto_content {{ item.p2 }}
  .picto_box
    .article.picto_article(v-for="item in data.column3" :key="item.id" :class="`${item.icon}`")
      .image_container
        svg-icon(:name="`${item.icon}`")
      span.picto_article__title {{ item.title }}
      span.picto_article__value {{ item.value }}{{item.unit}}
      p.picto_content {{ item.p1 }}
      p.picto_content {{ item.p2 }}
</template>

<script>
export const additions = {}
export default {
  props: { data: Object },
}
</script>
