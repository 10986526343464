<style>
.pdf-esg-cigg-1 .row .column {
  width: 100%;
}
.pdf-esg-cigg-1 h2 {
  color: var(--colors-primary-default);
}
.pdf-esg-cigg-1 .legend {
  display: none;
}
.circle {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  padding-top: 16px;
  /* vertical-align: middle; */
  font-size: 8px;
  color: black;
  background-color: rgb(146, 208, 80);
  text-align: center;
  overflow-wrap: normal;
  align-self: center;
}
.pdf-esg-cigg-1 .pdf-table .cell:not(.c1) {
  width: 18%;
}
.pdf-esg-cigg-1 h6.total_impact_bonds {
  align-self: center;
}
.pdf-esg-cigg-1 .main_lines_pocket.impact_bonds > .column {
  gap: 15px;
}
.pdf-esg-cigg-1 .main_lines_pocket {
  gap: 20px;
}
.pdf-esg-cigg-1 > .brick:not(:first-of-type) {
  margin-top: 40px;
}
</style>

<template lang="pug">
.pdf-esg-cigg-1
  brick(
    :title="$root.t['fund'] || 'fund'"
    :subtitle="data.share.characteristics.benchmark_esg && (t['univers_definition'] || 'univers_definition') + ': ' + t[data.share.characteristics.benchmark_esg]"
  )
  .row.main_lines_pocket.center-value
    .column
      brick.no-bold.center-value(
        subtitle="subtitle_esg_coverage"
        :data="data.tables.esg_coverage_table"
        :title="data.share.characteristics.esg_coverage_table"
      )
        .source(v-if="$root.lang.includes('be')") {{ t.source + ' ' + t[data.share.characteristics.management_company] }}
    .column(v-if="data.esg.carbon_intensity.data.length")
      brick(subtitle="subtitle_carbon_intensity" title="carbon_intensity")
      pdf-horizontalbar-icon(
        subtitle="subtitle_carbon_intensity"
        :data="data.esg.carbon_intensity.data"
        :options="{ format: '.2f', sort: d => ['fund', 'benchmark'].indexOf(d.key) }"
      )
      .sfdr-block(
        v-if="(data.share.characteristics.display_carbon_intensity_bench) && data.share.characteristics.benchmark_esg && ['fund', 'univers'].every(d => data.esg.carbon_intensity.data.map(d => d.key).includes(d))"
      )
        .row
          .sfdr-text {{ t.carbon_intensity_difference || 'carbon_intensity_difference' }}
          .sfdr-value {{ format('.0%')(data.esg.carbon_intensity_difference) }}
    .column
  

  brick(
    :title="$root.t['etats_assimiles'] || 'etats_assimiles'"
    :subtitle="data.share.characteristics.benchmark_esg && (t['univers_definition_etats_assimiles'] || 'univers_definition_etats_assimiles') + ': ' + t[data.share.characteristics.benchmark_poche_obligations]"
  )
  .row.main_lines_pocket.center-value
    .column
      brick.no-bold(subtitle="subtitle_main_scores_carbon_impact" title="main_scores_carbon_impact" :data="data.esg.main_lines_table_carbon_govies.desc" :options="{ limit: 4 }")
      brick.no-bold(:data="data.esg.main_lines_table_carbon_govies.asc" :options="{ limit: 4 }")
      .source(v-if="$root.lang.includes('be')") {{ t.source + ' ' + t[data.share.characteristics.management_company] }}
    .column(v-if="data.esg.carbon_intensity_govies_pocket.data.length")
      brick(subtitle="subtitle_carbon_intensity" title="carbon_intensity_govies")
      pdf-horizontalbar-icon(
        :data="data.esg.carbon_intensity_govies_pocket.data"
        :options="{ format: '.2f', sort: d => ['fund', 'benchmark'].indexOf(d.key) }"
      )
      brick.no-bold.center-value(
        subtitle="subtitle_scoring_climatique"
        title="scoring_climatique_govies"
        :data="data.esg.scoring_climatique_govies_pocket"
      )
    .column.theme_pocket
      brick(
        subtitle="subtitle_theme_govies"
        title="theme_govies"
        type="hbar"
        :data="data.esg.theme_govies"
        :options="{ format: '.2%' }"
      )
      .legend_line
        .row
          .legend_sample
          span {{t['fund'] }}
        .row
          .legend_sample
          span {{t['univers'] }}
  
  brick(
    :title="$root.t['impact_bonds'] || 'impact_bonds'"
    :subtitle="data.share.characteristics.benchmark_esg && (t['univers_definition_impact_bonds'] || 'univers_definition_impact_bonds') + ': ' + t[data.share.characteristics.benchmark_poche_credit]"
  )
  .row.main_lines_pocket.impact_bonds
    .column.theme_pocket
      brick(subtitle="subtitle_impact_bonds" title="impact_bonds" type="hbar" :data="data.esg.impact_bonds_breackdown" :options="{ format: '.2%' }")
      h6(class="total_impact_bonds") {{t['total_impact_bonds'] || 'total_impact_bonds'}} : {{format('.2%')(data.esg.impact_bonds_breackdown.v().sum('fund'))}}
      .circle Green Bonds 100%
    .column(v-if="data.esg.carbon_intensity_credit_pocket.data.length")
      brick(subtitle="subtitle_carbon_intensity" title="carbon_intensity")
      pdf-horizontalbar-icon(
        :data="data.esg.carbon_intensity_credit_pocket.data"
        :options="{ format: '.2f', sort: d => ['fund', 'benchmark'].indexOf(d.key) }"
      )
      brick.no-bold.center-value(
        subtitle="subtitle_scoring_climatique"
        title="scoring_climatique"
        :data="data.esg.scoring_climatique_credit_pocket"
      )
    .column.theme_pocket
      brick(
        subtitle="subtitle_theme_credit"
        title="theme_credit"
        type="hbar"
        :data="data.esg.theme_credit"
        :options="{ format: '.2%' }"
      )
      .legend_line(v-if="!(data.share.characteristics.esg_thematik_carbon_hide_benchmark)")
        .row
          .legend_sample
          span {{t['fund'] }}
        .row
          .legend_sample
          span {{t['univers'] }}
  </template>

<script>
export default {
  props: { data: Object },
}
</script>
