import { GraphQlService } from './GraphQlService'

/**
 * Mappings service
 */
export class MappingService extends GraphQlService {
  /**
   * List performances of a share
   * @param fund Fund name
   * @param share Share name
   * @param dimensions Mapping dimensions
   * @param domain Date range
   * @return {Promise<*>} Array of Mapping
   */
  async list({ fund, share, dimensions = [], domain }) {
    if (!fund || !share) throw new Error('Missing parameters')

    return (
      await this.query(
        `
        query MappingQuery($fund: String!, $share: String!, $dimensions: [String] $domain: [String]) {
          mapping(fund: $fund share: $share, dimensions: $dimensions, domain: $domain) {
            asof
            mapping
          }
        }
    `,
        { fund, share, domain, dimensions },
      )
    ).data.mapping
  }
}
const mappingService = new MappingService()
export default mappingService
