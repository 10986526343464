import { useAxios } from '../utils/axios'

export function fetchAsof(asof) {
  const axios = useAxios()
  return axios
    .get(`https://fireauth.100m.io/app/?path=asof/userflows-${format_asof(asof)}.json`)
    .then(r => (r.data.__proto__ === Object.prototype ? { userflows: r.data } : {}))
    .catch(e => e)
}

export function getAsof() {
  return new URLSearchParams(location.search).get('asof')
}
