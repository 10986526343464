export function getHeaderData(context: any, headerDataPath = 'reporting.header') {
  const headerData = headerDataPath.split('.').reduce((acc: any, key: string) => acc?.[key], context.data)
  if (!headerData) {
    throw new Error(`Could not find headerData in data report at path: ${headerDataPath}`)
  }
  return headerData
}
// TODO: get the logo images from stylesheet
export function getLogoImg(context: any) {
  const logoNames = ['logo-header', 'logo']
  const findAssetByName = (names, assets) => {
    if (!assets) return null
    return names.reduce((acc, name) => {
      if (acc) return acc
      return assets.find(el => el.name.includes(name))
    }, null)
  }
  return findAssetByName(logoNames, context.layout.theme.assets)?.src || window.config.logo
}
