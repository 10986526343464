<script>
export const additions = {}
export default {
  computed: {
    autocompleteData() {
      if (!$root.screen.datasets || !$root.xf[$root.screen.datasets[0]]) return []
      return $root.xf[$root.screen.datasets[0]]
        .filter((v, k) => !['data', 'date'].includes(k))
        .map(v => v.map((v, k) => k))
    },
    filters() {
      return $root.filters
        .filter((v, k) => k !== 'domain')
        .reduce((acc, v, k) => (v && v.map(v => acc.push([k, v].join('.'))), acc), [])
    },
  },
  methods: {
    onFiltersUpdate($event) {
      this.$router.push({
        query: {
          ...$root.query.filter((v, k) => !$root.filters.keys().toggle('domain').includes(k)),
          ...$event
            .map(v => v.split('.'))
            .group('0')
            .map(g => g.map('1').join('|')),
        },
      })
    },
  },
}
</script>

<template lang="pug">
.screen-title
  h1 {{ t[$root.screen.path + ($route.name === 'allocation' && $route.query.evolution ? '_evolution' : '')] }}
    autocomplete.right(:data="autocompleteData" :options="{ placeholder: t.search }" :modelValue="filters" @update:modelValue="onFiltersUpdate")
  slot
</template>

<style scoped></style>
