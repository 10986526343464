<style scoped></style>

<template>
  <block>
    <h2>Docker status</h2>
    <spreadsheet
      v-if="allServices.docker"
      class="stripped expand nosort"
      :data="allServices.docker"
      :options="{ columns: ['Name', 'RunningFor', 'Status'], editable: false }"
    ></spreadsheet>
    <div v-else>No available information</div>
  </block>

  <block>
    <h2>Service status</h2>
    <div v-if="allServices.services">Coming soon</div>
    <div v-else>No available information</div>
  </block>

  <block>
    <h2>Data source web services</h2>
    <block>
      <h3>Response time</h3>
      <spreadsheet
        v-if="dataSourceWebServices.responseTimes"
        class="stripped expand nosort"
        :data="dataSourceWebServices.responseTimes"
        :options="{ columns: ['endpoint', 'durationMs', 'itemCount'], editable: false }"
      ></spreadsheet>
      <div v-else>No available information</div>
    </block>
    <block>
      <h3>Response missing fields</h3>
      <spreadsheet
        v-if="dataSourceWebServices.missingFields"
        class="stripped expand nosort"
        :data="dataSourceWebServices.missingFields"
        :options="{ columns: ['endpoint', 'missingFields'], editable: false }"
      ></spreadsheet>
      <div v-else>No available information</div>
    </block>
  </block>

  <block>
    <h2>Data source missing column definitions</h2>
    <spreadsheet
      class="stripped expand nosort"
      :data="missingColumnDefinitions"
      :options="{ columns: ['table', 'missingColumns'], editable: false }"
    ></spreadsheet>
  </block>

  <block>
    <h2>Impress Database migrations</h2>
    <spreadsheet
      class="stripped expand nosort"
      :data="dbMigrations"
      :options="{
        columns: ['migration_name', 'started_at', 'finished_at', 'rolled_back_at', 'applied_steps_count'],
        editable: false,
      }"
    ></spreadsheet>
  </block>

  <block>
    <h2>Impress Database structure</h2>
    <block v-for="[schema, tableInfo] in Object.entries(dbStructure)">
      <h3>Schema: {{ schema }}</h3>
      <block v-for="[table, columns] in Object.entries(tableInfo)">
        <div>Table: {{ table }}</div>
        <spreadsheet
          class="stripped expand nosort"
          :data="columns"
          :options="{ columns: ['column_name', 'data_type', 'is_nullable'], editable: false }"
        ></spreadsheet>
      </block>
    </block>
  </block>
</template>

<script setup>
import { onMounted, ref } from 'vue'
import statusService from '@100-m/hauru/src/services/StatusService'
import technicalAuditService from '@100-m/hauru/src/services/TechnicalAuditService'

const dbStructure = ref({})
const dbMigrations = ref([])
const allServices = ref({})

const missingColumnDefinitions = ref([])
const dataSourceWebServices = ref({})

onMounted(async () => {
  dbStructure.value = (await statusService.getDbStructure()) || {}
  dbMigrations.value = (await statusService.getDbMigrations()) || []
  missingColumnDefinitions.value.push({
    table: 'ids_inventory',
    missingColumns: (await technicalAuditService.getMissingColumnDefinitions('ids_inventory')).join(', ') || 'none',
  })
  dataSourceWebServices.value = await technicalAuditService.checkDataSourceWebServices()
  dataSourceWebServices.value.missingFields = dataSourceWebServices.value.missingFields.map(item => ({
    endpoint: item.endpoint,
    missingFields: item.missingFields ? item.missingFields.join(', ') || 'none' : 'N/A',
  }))
  allServices.value = (await statusService.getAllServices()) || {}
})
</script>
