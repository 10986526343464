<style scoped>
.search [type='search'] {
  background: white no-repeat left 10px center / 20px var(--search);
}

.search input {
  min-width: 400px;
  max-width: unset;
  background: none;
  box-shadow: none;
  flex: 1;
}
</style>

<template lang="pug">
.search
  input(:value='modelValue' type="search" @input='handleInput' @change='handleChange')
</template>

<script>
/**
 * Search input component
 */
export default {
  props: {
    /**
     * String v-model
     */
    modelValue: String,
  },
  emits: ['update:modelValue'],
  methods: {
    handleInput(ev) {
      this.$emit('update:modelValue', ev.target.value)
    },
  },
}
</script>
