import { computed, inject, onMounted, watch } from 'vue'
import { onBeforeRouteLeave } from 'vue-router'

export function useProgress(loads = []) {
  const setProgress = inject('setProgress')
  const progress = computed(() =>
    loads
      .map(l => l.value)
      .map(b => (b ? 1 / loads.length : 0))
      .reduce((a, b) => a + b, 0),
  )

  onMounted(() => setProgress(0))
  watch(progress, () => setProgress(progress.value))

  // reset progress on leave
  onBeforeRouteLeave(() => setProgress(0))
  return { progress }
}
