<style>
.investment-period .num {
  display: flex;
  place-items: center;
  place-content: center;
  flex: 1;
  background: #c0e3ee;
  font-size: 7px !important;
  font-weight: 600;
  line-height: 2;
  border: 1px solid rgb(0, 0, 0, 0.1);
  margin: -1px;
}
.investment-period .num.active,
.investment-period .num.active_only {
  background: var(--primary);
  border-color: var(--primary);
  color: white;
}
.moniwan .investment-period .num {
  background: var(--light-hollywood);
  border: 1px solid var(--forest);
}
.moniwan .investment-period .num.active,
.moniwan .investment-period .num.active_only {
  background: var(--forest);
  border-color: var(--forest);
}
.investment-period .num.active:before {
  content: '>';
}
.investment-period .row > :first-child {
  font-weight: 600;
}
/* .investment-period.row { margin-right: 0px;} */
.investment-period h2 {
  color: var(--colors-primary-default);
  font-size: 13px;
}
.investment-period .row > :nth-child(2) {
  padding: 0 0 0 2px !important;
}
.centered-div {
  font-weight: normal !important;
}
</style>

<template lang="pug">
.investment-period
  .row(v-if="isDate")
    div {{ t.at_maturity || 'at_maturity' + ':' }}
    div {{ horizon }}
  .row(v-else-if="isDays")
    div.centered-div {{ t['greater_than_7_days'] || 'greater_than_7_days'}}
  .row(v-else-if="isTwoFiveYears")
    .num(:class="{ active_only: i.includes('>') || i.includes('<') }" v-for="i in scale") {{  i + ' ' + unit(i) }}
  .row(v-else)
    .num(:class="{ active: i === horizon || horizon.includes(i) }" v-for="i in scale") {{  i + ' ' + unit(i) }}
</template>

<script>
export const additions = {}
export default {
  props: {
    horizon: {
      type: String,
      required: true,
    },
  },
  methods: {
    unit(index) {
      const units = this.horizon.includes('mois') ? [this.t['m'], this.t['ms']] : [this.t['y'], this.t['ys']]
      if (index === 1) return units[0]
      return units[1]
    },
  },
  computed: {
    isDate() {
      return this.horizon.match('[0-9]{2}/[0-9]{2}/[0-9]{4}')
    },
    isTwoFiveYears() {
      return this.horizon === '> 2 et <5 ans'
    },
    isDays() {
      return this.horizon === '> 7 jours'
    },
    scale() {
      // Specific lfra !
      if (['> 2 et <5 ans'].includes(this.horizon)) return ['1', '> 2', '3', '4', '< 5']
      if (['> 1 an', '> 2 ans', '> 3 ans', '> 4 ans', '> 5 ans'].includes(this.horizon)) return [1, 2, 3, 4, 5]
      if (['> 1 mois', '> 2 mois', '> 3 mois', '> 4 mois', '> 5 mois'].includes(this.horizon)) return [1, 2, 3, 4, 5]
      if (['> 6 ans', '> 7 ans', '> 8 ans', '> 9 ans', '> 10 ans', '>10 ans'].includes(this.horizon))
        return [6, 7, 8, 9, 10]
      if (['> 1 mois', '> 3 mois', '> 6 mois', '> 9 mois', '> 10 mois', '> 12 mois'].includes(this.horizon))
        return [1, 3, 6, 9, 12]
      return [1, 2, 3, 4, 5]
    },
  },
}
</script>
