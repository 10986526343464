import { reactive, readonly, Ref, shallowReactive } from 'vue'

export interface IBaseHashTreeNode {
  id: string | number
}

export interface IHashTreeNodeInternal<T> extends IBaseHashTreeNode {
  children: (IHashTreeNodeInternal<T> & T)[]

  parent: (IHashTreeNodeInternal<T> & T) | null
  next: (IHashTreeNodeInternal<T> & T) | null
  prev: (IHashTreeNodeInternal<T> & T) | null
}

export type IHashTreeNode<T> = IHashTreeNodeInternal<T> & T

export type IHashTree<T extends IBaseHashTreeNode> = ReturnType<typeof useHashTree<T>>

// Hack used to stop annoying UnrapRef error
type Reactive<T> = Ref<T>

export function useHashTree<T extends IBaseHashTreeNode>(extend = false) {
  const state = reactive({
    root: shallowReactive({}) as Reactive<IHashTreeNode<T>>,
    map: {} as { [key: string | number | symbol]: IHashTreeNode<T> },
    is,
    get,
    addChild,
    remove: () => {},
    setRoot,
    set,
    getRoot: () => state.root,
    printable,
  })

  /**
   * Returns the state of the item indicated
   * @param key Key of the item whose state will be returned
   */
  function get(key: string | number | symbol): IHashTreeNode<T> | undefined {
    return state.map[key]
  }

  /**
   * Returns a boolean that indicates whether an item has a certain state
   * @param key Key of the item whose state will be returned
   */
  function is(key: string | number | symbol): boolean {
    return !!state.map[key]
  }

  /**
   * Adds indicated item to the tree
   * @param parentKey Key of the parent item
   * @param child Item to be added
   * @param index Zero-based index where the item will be added, or the end of the list if not specified
   */
  function addChild(parentKey: string | number | symbol, child: T, index?: number) {
    const parent = get(parentKey)

    if (!parent) return
    if (index === undefined) index = parent.children.length

    const key = child.id

    const childNode: IHashTreeNode<T> = {
      ...child,
      parent,
      children: [],
      next: null,
      prev: null,
    }

    parent.children.splice(index, 0, childNode)

    // if it's not the last child
    if (index < parent.children.length - 1) {
      childNode.next = parent.children[index + 1]
      parent.children[index + 1].prev = childNode
    }

    // if it's not the first child
    if (index > 0) {
      childNode.prev = parent.children[index - 1]
      parent.children[index - 1].next = childNode
    }

    state.map[key] = childNode
  }

  /**
   * Removes all items from the list
   */
  function setRoot(root: T) {
    state.root = {
      ...root,
      parent: null,
      children: [],
      next: null,
      prev: null,
    }
    state.map = {
      [root.id]: state.root,
    }
  }

  /**
   * Updates the value of the indicated item
   * @param key Key of the item whose state will be updated
   * @param item The new value of the item
   */
  function set(key: string | number | symbol, item: T) {
    if (!is(key)) return

    state.map[key] = {
      ...state.map[key],
      item,
    }
  }

  function printable(node: IHashTreeNode<T> = state.root): IHashTreeNode<T> {
    const result: IHashTreeNode<T> = { ...node, children: [], parent: null, next: null, prev: null }
    for (const child of node.children) {
      result.children.push(printable(child))
    }
    return result
  }

  return extend ? state : readonly(state)
}
