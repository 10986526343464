<template>
  <!-- arrow-head-triangle-story -->
  <div>
    <story-template :story="story" class="h-[150px]">
      <div class="w-full" :ref="arrowsReactiveRef.updateEl">
        <nx-arrow-set>
          <nx-arrow-head-triangle
            class="stroke-[#1b9e77]"
            :class="[story.props.isFilled ? 'fill-[#1b9e77]' : '']"
            :point-x="story.props.pointX"
            :point-y="story.props.pointY"
            :base="story.props.base"
            :altitude="story.props.altitude"
            :stroke-width="story.props.strokeWidth"
            :rotation="story.props.rotation"
            :is-filled="story.props.isFilled"
            :small-altitude="story.props.smallAltitude"
          />
        </nx-arrow-set>
      </div>
    </story-template>
  </div>
</template>

<script setup lang="ts">
import { watchEffect } from 'vue'
import { elementReactiveRef, INxArrowHeadTriangleProps } from '@hauru/common'
import { createStory, controls } from '@storytime'

const arrowsReactiveRef = elementReactiveRef()

const story = createStory<INxArrowHeadTriangleProps>('nx-arrow-head-triangle')
story.setProps({
  pointX: 20,
  pointY: 20,
  base: 20,
  altitude: 10,
  strokeWidth: controls.range(2, 0, 10, 1),
  rotation: controls.range(90, 0, 360, 1),
  isFilled: false,
  smallAltitude: 0,
})

watchEffect(() => {
  if (!arrowsReactiveRef.value || !arrowsReactiveRef.width) return

  story.props.pointX = arrowsReactiveRef.width / 2
  story.props.pointY = arrowsReactiveRef.height / 2
})
</script>
