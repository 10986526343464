let options
let containerEl

export default {
  created(el, binding) {
    containerEl = el
    options = binding.value
  },
  mounted() {
    handleScroll()
    window.addEventListener('resize', handleScroll)
  },
  unmounted() {
    window.removeEventListener('resize', handleScroll)
  },
}

function handleScroll() {
  let containerWidth = parseInt(window.getComputedStyle(containerEl).width, 10)
  containerEl.classList.remove(...['container-desktop', 'container-tablet', 'container-mobile'])
  if (containerWidth > 900) {
    return containerEl.classList.add('container-desktop')
  }
  if (containerWidth > 600) {
    return containerEl.classList.add('container-tablet')
  }
  return containerEl.classList.add('container-mobile')
}
