<script setup lang="ts">
import impressExamples from './mocks/impress'
import visageExamples from './mocks/visage'
// import observableExamples from './mocks/observable'
import { createStory, controls } from '@storytime'

const story = createStory('nx-chart')
// Impress Charts
Object.entries(impressExamples).forEach(([name, example], i) => {
  if (i === 0) return story.setProps(example).createDefaultExample(name)
  return story.createExample(name).setExampleProps(example)
})
// Visage Charts
Object.entries(visageExamples).forEach(([name, example], i) => {
  return story.createExample(name).setExampleProps(example)
})
// Observable Charts
// Object.entries(observableExamples).forEach(([name, example], i) => {
//   return story.createExample(name).setExampleProps(example)
// })
// story.setExample('Surface')

// Controls
story.createState('Controls', {
  lib: controls
    .select({ dx: 'dx', cjs: 'cjs', plotly: 'plotly', plot: 'plot', nx: 'nx' })
    .onChange(v => (story.props.options.lib = v)),
  sort: controls.boolean(false).onChange(v => (story.props.options.sort = v)),
  limit: controls.number(15, 5, 15, 1).onChange(v => (story.props.options.limit = v)),
  other: controls.boolean(false).onChange(v => (story.props.options.other = v)),
})

const selectPlot = $event => {
  if (!$event) return
  const { selection = [], x, category } = story.props.options
  const filter = {}
  if ($event[x]) filter[x] = $event[x]
  if ($event[category]) filter[category] = $event[category]
  const filters = selection.filter(v => !Object.equal(v, filter))
  if (filters.length === selection.length) story.props.options.selection = filters.concat(filter)
  else story.props.options.selection = filters
}
</script>

<template>
  <story-template :story="story" class="h-80 flex-col" :max-examples="5">
    <nx-chart :data="story.props.data" :options="story.props.options" @series-click="selectPlot"></nx-chart>
  </story-template>
</template>
