import { RestService } from './RestService'

export class ExcelTemplateService extends RestService {
  constructor() {
    super()
    this.baseRoute = 'excel/'
  }

  async create(templateName, dataReportId, dataReportVariables) {
    return await this.post(
      `${this.baseRoute}create`,
      { templateName, dataReportId, dataReportVariables },
      { responseType: 'blob' },
    )
  }

  async download(templateId) {
    return await this.post(`${this.baseRoute}download`, { templateId }, { responseType: 'blob' })
  }

  async update(templateId, dataReportId, dataReportVariables) {
    return await this.post(
      `${this.baseRoute}update`,
      { templateId, dataReportId, dataReportVariables },
      { responseType: 'blob' },
    )
  }

  async upload(templateId, file) {
    // NOTE: file is expected as a base64 string
    return await this.post(`${this.baseRoute}upload`, { templateId, file }) // This one does not respond with a file
  }

  async report(templateId, dataReportId, dataReportVariables, reportFilename) {
    return await this.post(
      `${this.baseRoute}report`,
      { templateId, dataReportId, dataReportVariables, reportFilename },
      { responseType: 'blob' },
    )
  }
}

const excelTemplateService = new ExcelTemplateService()
export default excelTemplateService
