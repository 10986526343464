<template>
  <fieldset class="my-3 flex items-center text-sm">
    <datalist :id="datalistId">
      <option></option>
      <option v-for="(v, k) in context.translations" :value="`translation.${k}`" :key="k">
        {{ truncString(v, 50) }}
      </option>
    </datalist>
    <slot name="label">
      <label class="w-32 shrink-0" v-if="label">{{ label }}</label>
    </slot>
    <input
      type="text"
      class="w-48 rounded bg-neutral-600 p-1 [font-size:inherit] focus:outline-none focus-visible:ring-1"
      :list="datalistId"
      v-model="value"
    />
  </fieldset>
</template>
<script setup lang="ts">
import { getCurrentInstance } from 'vue'
defineProps(['context', 'label'])
const value = defineModel()
const instance = getCurrentInstance()
const datalistId = 'translations' + instance?.uid
function truncString(str: string, length: number) {
  if (!str) return ''
  return str.length > length ? str.slice(0, length) + '...' : str
}
</script>
