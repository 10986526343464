<script setup lang="ts">
import { ref, defineProps } from 'vue'
interface TooltipProps {
  textHeader?: string
  textBody?: string[]
}
defineProps<TooltipProps>()

const display = ref(false)

const updateDisplay = () => {
  display.value = !display.value
}
</script>

<template>
  <div class="tooltip-helper tooltip-container">
    <slot name="icon" :updateDisplay="updateDisplay">
      <span class="icon" @mouseover="updateDisplay" @mouseleave="updateDisplay">i</span>
    </slot>
    <div v-if="display" class="tooltip-content">
      <slot :textHeader="textHeader" :textBody="textBody">
        <div class="tooltip-header">
          <h2>{{ textHeader }}</h2>
        </div>
        <div class="tooltip-body">
          <p v-for="(text, index) in textBody" :key="index">{{ text }}</p>
        </div>
      </slot>
    </div>
  </div>
</template>

<style scoped>
span.icon {
  color: #1b689c;
  border-radius: 16px;
  border: 2px solid #1b689c;
  padding-right: 8px;
  padding-left: 8px;
  margin: 4px;
  font-weight: bold;
}

h2 {
  color: #1b689c;
}

p {
  color: #2593de;
  margin-left: 20px;
}

.tooltip-container {
  position: relative;
  display: inline-block;
  cursor: pointer;
}

.tooltip-content {
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1000;
  background-color: #fff;
  border: 1px solid #ccc;
  padding: 10px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  white-space: nowrap;
}

.tooltip-header {
  font-weight: bold;
}

.tooltip-body p {
  margin: 0;
}
</style>
