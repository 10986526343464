<template>
  <div class="flex flex-row" v-if="stylesheet">
    <div class="flex flex-1 flex-col gap-4">
      <builderui-style-input
        v-model="stylesheet.styles[style]"
        v-for="style in filteredStyleList"
        :key="style"
      ></builderui-style-input>
    </div>
    <div class="theme-all flex-[1.5] px-8">
      <component
        class="mx-auto max-w-[420px]"
        :class="'builder-component-' + componentName"
        :is="componentTag"
        v-bind="componentProps"
      >
        <div v-if="component.story.slot" v-html="component.story.slot"></div>
      </component>
    </div>
  </div>
</template>
<script setup lang="ts">
import { computed } from 'vue'
import { Stylesheet, BuilderComponent } from '../../builder'

interface IProps {
  componentName: string
  componentTag: string
  component: BuilderComponent
  search?: string
}
const props = defineProps<IProps>()
const stylesheet = defineModel<Stylesheet>()
const componentProps = computed(() => {
  // return props.component?.story?.props
  console.log('updaing props ?', stylesheet?.value?.defaultPalette)
  return {
    ...props.component?.story?.props,
    context: {
      ...props.component.story.props.context,
      stylesheet: stylesheet.value,
      node: {},
      // For some reason i have to declare this here and not in the stories.ts
      t:
        props.component?.story?.props?.context?.translations &&
        new Proxy(props.component.story.props.context.translations, {
          get(target: any, prop: string) {
            return target[prop] || prop
          },
        }),
    },
  }
})

const styleList = computed(() => {
  if (!props.component.styles) return
  if (Array.isArray(props.component.styles)) {
    props.component.styles.forEach(style => {
      if (!stylesheet?.value?.styles?.[style]) {
        stylesheet.value.styles[style] = {
          name: style.titleize(),
          css: '',
        }
      }
    })
    return props.component.styles
  }
  const _styleList: string[] = []
  Object.entries(props.component.styles).forEach(([style, styleData]) => {
    if (!stylesheet?.value?.styles?.[style]) {
      if (typeof styleData === 'string') {
        stylesheet.value.styles[style] = {
          name: style.titleize(),
          css: styleData,
        }
      } else {
        stylesheet.value.styles[style] = {
          name: styleData.name || style.titleize(),
          css: styleData.css,
        }
      }
    }
    _styleList.push(style)
  })
  return _styleList
})

const filteredStyleList = computed(() => {
  if (!props.search) return styleList.value
  const regex = new RegExp(props.search.toLowerCase(), 'i')
  if (regex.test(props.componentName)) return styleList.value
  if (props.componentName === 'block' && regex.test('global styles')) return styleList.value
  return styleList.value && styleList.value.filter(style => regex.test(style) || regex.test(style.titleize()))
})
// watch(
//   styleList,
//   () => {
//     // if (props.component.styles && stylesheet.value) {
//     //   props.component.styles.forEach(style => {
//     //     if (!stylesheet.value.styles[style]) {
//     //       stylesheet.value.styles[style] = {
//     //         name: style.titleize(),
//     //         css: '',
//     //       }
//     //       // props.component.styles[style]
//     //     }
//     //   })
//     // }
//   },
//   { immediate: true },
// )
</script>
