<template>
  <lfr-block :data="data" :options="options">
    <table
      :class="{ '!table-auto': options.auto }"
      class="bottom-0 h-full w-full table-fixed rounded-lg leading-[11.2px]"
      v-if="data.constructor === Array"
    >
      <tr class="font-bold" v-if="data[0].constructor === Object">
        <td v-for="(value, key, index) in data[0]">
          <div v-if="index">{{ options.data.translation[key] || key }}</div>
        </td>
      </tr>
      <tr
        :class="'even:bg-[#eef8fb]' + (options.highlightFirstLine ? ' first:font-bold' : '')"
        v-for="line in data.slice(0, actualLimit || Infinity)"
      >
        <td
          :class="
            'first:w- overflow-hidden whitespace-nowrap px-2 py-1' +
            (options.highlightFirstColumn ? ' first:font-bold' : '')
          "
          v-for="cell in line"
        >
          {{ format(options.data.translation[cell] || cell, options) }}
        </td>
      </tr>
    </table>
  </lfr-block>
</template>

<style scoped>
td:not(:first-child) {
  text-align: center;
}
td {
  font-variant-numeric: tabular-nums;
}
</style>

<script setup>
import format from '../../format.js'
import { computed } from 'vue'
const { data, options } = defineProps(['data', 'options'])
const actualLimit = computed(() => {
  // add +1 to limit for header line
  return data[0].constructor === Array ? options.limit + 1 : options.limit
})
</script>

<script>
export default {
  api: {
    unit: {
      label: 'Unit',
      default: () => '%',
    },
    digit: {
      label: 'Decimals',
      default: () => 2,
      attrs: {
        type: 'number',
      },
    },
    limit: {
      label: 'Limit',
      attrs: {
        type: 'number',
      },
    },
    highlightFirstLine: {
      label: 'Highlight first line',
      default: () => true,
      attrs: {
        type: 'checkbox',
        class: 'none',
      },
    },
    highlightFirstColumn: {
      label: 'Highlight first column',
      default: () => true,
      attrs: {
        type: 'checkbox',
        class: 'none',
      },
    },
  },
}
</script>
