export default class AbstractDatabase {
  #dbRef
  _accessFrom

  constructor() {
    if (new.target === AbstractDatabase) {
      throw new TypeError('This class cannot be instantiated directly!')
    }
  }

  // public methods
  initDB({ root, name, project, apiKey }) {
    throw new TypeError('initDB method must be implemented by the subclasses!')
  }

  async get(path, callback) {
    throw new TypeError('get method must be implemented by the subclasses!')
  }

  update(path, value) {
    throw new TypeError('update method must be implemented by the subclasses!')
  }

  set(path, value) {
    throw new TypeError('set method must be implemented by the subclasses!')
  }

  from(path, options) {
    throw new TypeError('from method must be implemented by the subclasses!')
  }

  on(event, callback) {
    throw new TypeError('on method must be implemented by the subclasses!')
  }

  off(event, callback) {
    throw new TypeError('off method must be implemented by the subclasses!')
  }

  async transaction(t) {
    throw new TypeError(
      'transaction method must be implemented by the subclasses!'
    )
  }

  // private methods
  setDbRef(value) {
    this.#dbRef = value
  }

  getDbRef() {
    return this.#dbRef
  }
}
