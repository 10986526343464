import { IThemeClass, IThemeConfig, ITypeThemeBase } from '@hauru/common'

export interface IListItemTheme extends ITypeThemeBase {
  idle: IThemeClass
}

export const listItemTheme: IThemeConfig = {
  themes: {
    $default: {},
    nx: {
      listItem: {
        basic: {
          idle: 'hover:bg-gray-50 active:bg-gray-100',
        },
        grid: {
          idle: 'h-8 text-gray-800 flex gap-2 items-center px-4 hover:bg-gray-50',
        },
      },
    },
  },
}
