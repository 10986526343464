import { createRouter, createWebHashHistory, createWebHistory } from 'vue-router'
import App from '../App.vue'
import { createApp, defineAsyncComponent, defineComponent } from 'vue'
import axiosPlugin from './axios'
import useAuth from '../features/auth'
import FloatingVue from 'floating-vue'
import vuePlugin from '../utils/vue.js'
import { setTranslation } from '../features/translation'
import '../utils/raw-addon.js'
import '../components/layout/tooltip'
import { merge } from './merge'
import { initCommandr } from '../features/commandr'
import platformConfig from '../config'
/**
 * Builder for the platform
 */
export class PlatformBuilder {
  constructor() {
    this.apps = []
    this.auth = null
    this.translations = []
    this.mode = 'history'
    this.cssvar = undefined

    this.app = App

    this.defaultRoutes = [
      { path: '/logout', component: () => $root.$auth.logout(), meta: { roles: ['user', 'admin'] } },
      { path: '/:catchAll(.*)', redirect: '/', meta: { access: null } },
    ]
  }

  /**
   * Set the base App.vue component
   * @param app Vue component
   * @return {PlatformBuilder}
   */
  setBaseApp(app) {
    this.app = app
    return this
  }

  /**
   * Set the cssvars
   * @param css
   * @return {PlatformBuilder}
   */
  setCssVar(css) {
    this.cssvar = css
    return this
  }

  /**
   * Add an app to the platform
   * @param app An app module
   * @param override An app module with overridden settings
   * @return {PlatformBuilder}
   */
  addApp(app, override = {}) {
    this.apps.push(merge(app, override))
    return this
  }

  /**
   * Set the auth mechanism
   * @param auth Auth implementation
   * @return {PlatformBuilder}
   */
  setAuth(auth = null) {
    this.auth = auth
    return this
  }

  /**
   * Add a translation
   * @param translation
   * @return {PlatformBuilder}
   */
  addTranslation(translation) {
    this.translations.push(translation)
    return this
  }

  /**
   * Initialize firebase
   * @param config Firebase config
   * @return {PlatformBuilder}
   */
  initFirebase() {
    initCommandr()
    return this
  }

  /**
   * Get the app routes
   * @param app
   * @return {{path: string, component: () => {[p: string]: any}, meta: {[p: string]: *}}[]}
   */
  getRoutes(app) {
    const pages = app.pages || {}
    const prefix = app.routePrefix || ''
    const appName = app.name || null
    return Object.entries(pages).map(([path, component]) => {
      const name = path.split('/').pop()
      const { datasets, icon = '', theme = false, roles = ['user'] } = component.default

      if (!Array.isArray(roles)) throw new Error('Roles attribute from page components must be an  Array<String>.')

      if (component?.additions?.props) {
        console.warn(
          'Additions export is deprecated. Props in component additions should go component itself',
          component,
        )
      }

      if (app.globalMixin) {
        console.warn('App is a using a globalMixin. This feature is deprecated.')
        component.default.mixins = (component.default.mixins || []).concat([app.globalMixin])
      }

      const routePath = makePath(path, prefix)
      const { default: comp, ...exports } = component
      return {
        path: routePath,
        component: comp,
        meta: {
          app: appName,
          name,
          access,
          datasets,
          icon,
          theme,
          roles,
          ...exports,
          ...exports.additions,
        },
      }
    })
  }

  /**
   * Set the router mode
   * @param mode ['history']{'history', 'hash'} router mode
   * @return {PlatformBuilder}
   */
  setRouterMode(mode = 'history') {
    if (!['history', 'hash'].includes(mode)) throw new Error('Router mode not implemented. Use history or hash.')
    this.mode = mode
    return this
  }

  /**
   * Get all the routes
   * @param apps
   * @return {unknown[]}
   */
  getAllRoutes(apps) {
    const appsRoutes = apps.map(a => this.getRoutes(a))

    const routes = [this.defaultRoutes]
      .concat(appsRoutes)
      .reduce((a, b) => a.concat(b), [])
      // Unique
      .reduce((acc, b) => {
        acc[b.path] = b
        return acc
      }, {})

    return Object.values(routes)
  }

  /**
   * Create the router
   * @param routes
   * @return {Router}
   */
  createRouter(routes) {
    const history = this.mode === 'history' ? createWebHistory() : createWebHashHistory()
    const router = createRouter({
      strict: true,
      history,
      routes: [...routes],
      linkActiveClass: 'active',
      linkExactActiveClass: 'exact',
    })
    router.beforeEach(to => {
      if (to.meta.app) router.app = to.meta.app
      return true
    })
    return router
  }

  /**
   * Get the app configs
   * @return {{}}
   */
  getConfig() {
    return this.apps
      .map(a => {
        const config = { ...a.config, datasets: a.config?.datasets ? evalDatasets(a.config.datasets) : undefined }
        return { [a.name]: { ...a, config } }
      })
      .reduce((a, b) => ({ ...a, ...b }), {})
  }

  /**
   * Process the translations
   */
  processTranslations() {
    this.apps
      .filter(a => a.name)
      .forEach(a => {
        setTranslation(a.translations || {}, a.name)
      })
    this.apps
      .filter(a => (a.name === 'base' || !a.name) && a.translations && Object.keys(a.translations).length > 0)
      .forEach(a => setTranslation(a.translations, 'default'))
  }

  build() {
    this.processTranslations()
    const mergedComponents = merge(...[...this.apps.map(a => a.components || {})])
    const mergedDirectives = merge(...[...this.apps.map(a => a.directives || {})])

    const config = this.getConfig()
    const routes = this.getAllRoutes(this.apps)
    const router = this.createRouter(routes)

    const app = createApp(this.app)
    app.use(vuePlugin, { config })
    app.mixin({
      computed: {
        t() {
          // return 'a'
          // return this.$root.t.__.map(trad =>trad?.split('|')[0]) => WTF ?
          return this.$root.t
        },
      },
    })
    app.use(axiosPlugin)
    const { guard, authPlugin } = useAuth()
    app.use(authPlugin, this.auth || {})
    Object.entries(mergedComponents).map(([name, component]) => app.component(name, component))
    Object.entries(mergedDirectives).map(([name, directive]) => app.directive(name, directive))
    router.beforeEach(guard)
    app.use(router)
    app.use(FloatingVue)

    app.config.globalProperties.$cssvar = this.cssvar
    app.config.globalProperties.$config = { apps: config }
    app.config.devtools = platformConfig.mode !== 'production'
    app.config.unwrapInjectedRef = true
    app.provide('apps', config)
    app.provide('mergedComponents', mergedComponents)
    return app
  }
}

/**
 * Parse pages from glob record and map them to routes
 * @param record Glob record
 * @param base Base path of the records to remove
 * @return {{[path: string]: Component}}
 */
export function pagesFromGlobRecord(record, base = '') {
  return Object.fromEntries(
    Object.entries(record).map(([k, v]) => [k.replace(base, '').replace('.vue', '').replace('.md', ''), v]),
  )
}

/**
 * Parse components from glob records and map them to a component map
 * @param record Glob record
 * @return {{[name: string]: Component}}
 */
export function componentsFromGlobRecord(record) {
  return Object.fromEntries(
    Object.entries(record).map(([k, v]) => {
      return [
        k.split('/').slice(-1).join().replace('.vue', '').replace('.', '-'),
        typeof v === 'function' ? defineAsyncComponent(v) : defineComponent(v.default),
      ]
    }),
  )
}

/**
 * Parse directives from glob records and map them to a component map
 * @param record
 * @return {{[p: string]: ComponentPublicInstanceConstructor<any> & ComponentOptionsBase<Readonly<ExtractPropTypes<unknown>> & EmitsToProps<Record<string, any>>, {[p: string]: any}, {}, ComputedOptions, MethodOptions, ComponentOptionsMixin, ComponentOptionsMixin, Record<string, any>, string, ExtractDefaultPropTypes<unknown>> & VNodeProps & AllowedComponentProps & ComponentCustomProps}}
 */
export function directivesFromGlobRecord(record) {
  return Object.fromEntries(
    Object.entries(record).map(([k, v]) => [
      k.split('/').slice(-1).join().replace('.js', '').replace('v-', ''),
      defineComponent(v.default),
    ]),
  )
}
/**
 * Eval the datasets config functions (from index.yml)
 * @param datasets Datasets config
 * @return {*} Same config with functions evaluated
 */
function evalDatasets(datasets) {
  return Object.fromEntries(
    Object.entries(datasets).map(([name, ds]) => {
      const fns = ['inc_fn', 'url_fn', 'post_fn'].reduce((acc, v) => {
        if (!ds[v]) return acc
        acc[v] = _evalIfString(ds[v])
        return acc
      }, {})
      const struct = ds.structure || {}
      const structure = Object.fromEntries(
        Object.entries(struct).map(([field, type]) => {
          if (type === 'number' || type === 'float' || type === 'int') return [field, d => +d[field] || 0]
          if (type === 'string' || type === 'date') return [field, d => d[field]]
          return [field, _evalIfString(type)]
        }),
      )
      return [name, { ...ds, ...fns, structure }]
    }),
  )
}

/**
 * Return evaluated fn if fn is a string
 * @param fn
 * @return {any}
 * @private
 */
function _evalIfString(fn) {
  // eslint-disable-next-line no-eval
  if (typeof fn === 'string') return eval(fn)
  return fn
}

/**
 * Return a translation object from a glob entries. Files should looks to 'xxx-en.yml'
 * @param record
 * @return {{}}
 */
export function translationFromGlobRecord(record) {
  return Object.entries(record)
    .map(([k, v]) => {
      const lang = k.slice(-6, -4)
      return [lang, v.default]
    })
    .reduce((acc, [lang, value]) => {
      acc[lang] = { ...acc[lang], ...value }
      return acc
    }, {})
}

/**
 * Make the real route path.
 * Replace _ by :, /slash by / and a the prefix
 * @param path Page component path
 * @param prefix Route prefix
 * @return {string} Vue router route path
 */
export function makePath(path, prefix = '') {
  const p = path
    .split('/')
    .map(a => (a.startsWith('_') ? `:${a.slice(1)}` : a))
    .join('/')
    .replace('/slash', '/')
    .replace('/index', '')
  return `${prefix}${p}`
}
