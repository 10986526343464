<style>
.pdf-esg-lfp .row .column {
  width: 100%;
}
.pdf-esg-lfp .block:not(.main_table_esg) .pdf-table .cell:not(.c1) {
  width: 18%;
}
.pdf-esg-lfp .block.main_table_esg .pdf-table .cell:not(.c1) {
  width: 13%;
}
.pdf-esg-lfp .block-repartition_sfdr h2 {
  justify-content: center;
}
.pdf-esg-lfp .column.carbon_intensity {
  align-items: center;
}
.pdf-esg-lfp .pdf-horizontalbar-icon {
  margin-left: 50px;
  width: 100%;
}
.pdf-esg-lfp > .row:not(:nth-of-type(-n + 2)) {
  margin-top: 70px;
}
</style>

<template lang="pug">
.pdf-esg-lfp
  .subtitle.row(v-if="data.share.characteristics.benchmark_esg") {{ (t['univers_definition'] || 'univers_definition') + ': ' + t[data.share.characteristics.benchmark_esg] }}
  .row
    .column
      brick.no-bold.center-value(
        subtitle="subtitle_esg_coverage"
        :data="data.tables.esg_coverage_table"
        :title="data.share.characteristics.esg_coverage_table"
      )
        .source(v-if="$root.lang.includes('be')") {{ t.source + ' ' + t[data.share.characteristics.management_company] }}
    .column.carbon_intensity(v-if="data.esg.carbon_intensity.data.length")
      brick(subtitle="subtitle_carbon_intensity" title="carbon_intensity")
      pdf-horizontalbar-icon(
        subtitle="subtitle_carbon_intensity"
        :data="data.esg.carbon_intensity.data"
        :options="{ format: '.2f', sort: d => ['fund', 'benchmark'].indexOf(d.key) }"
      )
      .sfdr-block(v-if="data.share.characteristics.display_carbon_intensity_bench && data.share.characteristics.benchmark_esg && ['fund', 'univers'].every(d => data.esg.carbon_intensity.data.map(d => d.key).includes(d))")
        .row
          .sfdr-text {{ t.carbon_intensity_difference || 'carbon_intensity_difference' }}
          .sfdr-value {{ format('.0%')(data.esg.carbon_intensity_difference) }}
    .column
      brick.no-bold(:data="data.esg.sfdr_breackdown" title="repartition_sfdr" :type="'pie'")
        .source(v-if="$root.lang.includes('be')") {{ t.source + ' ' + t[data.share.characteristics.management_company] }}
  .row
    .column
      brick.no-bold.main_table_esg(subtitle="subtitle_main_lines_esg" title="top5_esg" :data="data.esg.main_lines_table_esg_cropped.desc" :options="{ limit: 6 }")
        .source(v-if="$root.lang.includes('be')") {{ t.source + ' ' + t[data.share.characteristics.management_company] }}
    .column
      brick.no-bold.main_table_esg(subtitle="subtitle_main_lines_esg" title="worst5_esg" :data="data.esg.main_lines_table_esg_cropped.asc" :options="{ limit: 6 }")
        .source(v-if="$root.lang.includes('be')") {{ t.source + ' ' + t[data.share.characteristics.management_company] }}
  .row
    brick.risk_disclaimer(title="definitions_esg" v-if="(data.share.characteristics.definition_esg_list || []).length > 0")
      div(v-for="definition in data.share.characteristics.definition_esg_list.split(',')" v-html="t[definition] || definition")
</template>

<script>
export default {
  props: { data: Object },
}
</script>
