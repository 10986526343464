import { IThemeClass, IThemeConfig, ITypeThemeBase } from '@hauru/common'

export interface ISwitchTheme extends ITypeThemeBase {
  slider?: IThemeClass
}

export const switchTheme: IThemeConfig = {
  themes: {
    $default: {},
    nx: {
      switch: {
        basic: {
          slider: '',
        },
      },
    },
  },
}
