import { reactive } from 'vue'
import {
  IStoryBoolean,
  IStoryButton,
  IStoryControlBase,
  IStoryNumber,
  IStoryNumbers,
  IStoryRange,
  IStorySelect,
} from '@storytime'

function useControl<T extends IStoryControlBase>(init: T): T {
  let value = init.value

  const control: IStoryControlBase = reactive({
    ...init,
    get value() {
      if (control.getter) {
        return control.getter()
      }
      return value
    },
    set value(v) {
      value = v
      control.setters.forEach(setter => setter(v))
    },
    setters: [],
    isRequired: true,
    it,
    optional,
    required,
    onChange,
    getValue,
    setValue,
  })
  if (typeof init.value === 'function') control.getter = init.value

  function it(description: string) {
    control.description = description
    return control
  }

  function optional() {
    control.isRequired = false
    return control
  }

  function required() {
    control.isRequired = true
    return control
  }

  function onChange(fun: (value: typeof control.value) => void) {
    control.setters.push(fun)
    return control
  }

  function getValue() {
    return control.value
  }

  function setValue(value: typeof control.value) {
    control.value = value
  }

  return control as T
}

export function number(value: number | (() => number), min?: number, max?: number, step?: number): IStoryNumber {
  const control = useControl({
    type: 'number',
    value,
    options: {
      type: 'number',
    },
  } as IStoryNumber)
  if (min || min === 0) control.options.min = min
  if (max || max === 0) control.options.max = max
  if (step || step === 0) control.options.step = step
  return control
}

export function numbers<T extends { [key: string]: IStoryNumber }>(value: T): IStoryNumbers<T> {
  const control = useControl({
    type: 'numbers',
    value,
  } as unknown as IStoryNumbers<T>)
  Object.assign(control, {
    getValue: () => {
      const result: { [key: string]: number } = {}
      Object.keys(control.value).forEach(key => {
        result[key] = control.value[key].getValue()
      })
      // console.log('getValue', result)
      return result
    },
    setValue: (value: { [key: string]: number }) => {
      // console.log('setValue', value)
      Object.keys(control.value).forEach((key, index) => {
        control.value[key].setValue(value[key])
      })
    },
    onChange(fun: (value: T) => void) {
      control.setters.push(fun)
      for (const key in control.value) {
        control.value[key].onChange(() => fun(control.value))
      }
      return control as IStoryControlBase
    },
  })
  return control
}

export function range(value: number | (() => number), min?: number, max?: number, step?: number): IStoryRange {
  const control = useControl({
    type: 'range',
    value,
    options: {
      type: 'range',
    },
  } as IStoryRange)
  if (min || min === 0) control.options.min = min
  if (max || max === 0) control.options.max = max
  if (step || step === 0) control.options.step = step
  return control
}

export function boolean(value: boolean | (() => boolean)) {
  const control = useControl({
    type: 'boolean',
    value,
  } as IStoryBoolean)
  return control
}

export function select<T>(options: { [key: string]: T }): IStorySelect<T> {
  // reduce object to array of { name, value }
  const optionsArray = Object.keys(options).map(name => ({
    name,
    value: options[name],
  }))
  const control = useControl({
    type: 'select',
    value: optionsArray[0].value,
    options: optionsArray,
  } as IStorySelect<T>)
  Object.assign(control, {
    setValue: (value: T | string) => {
      if (typeof value === 'string') {
        control.value = options?.[value] ?? (value as T)
      } else {
        control.value = value
      }
    },
  })
  return control
}

export function button(label: string, onClick: () => void): IStoryButton {
  return {
    label,
    onClick,
  }
}

const controls = {
  boolean,
  number,
  numbers,
  range,
  select,
  button,
}

export { controls }
