export function mapRun(d) {
  if (!d || d.disabled) return
  const report = d.press
  if (!report) return
  if (!d.context) d.context = {}
  // Firebase has weird behaviors sometimes
  const logs = Array.isArray(d.logs) ? d.logs : d.logs.v()
  const workflow = d.workflow
  const workflow_name = workflow.name
  const sid = workflow.id + '|' + d.id
  const log = logs && logs.last()
  const status = log.status
  const last_action_id = log.action_id
  const last_step = workflow.actions[last_action_id]
  const step = last_action_id ? last_step.name : 'N/A'
  const link = makeLink(sid)
  const isParallel = !!last_step.parallel
  const isValidation = ['user_input', 'user_validation'].includes(last_step.type) && log.status === 'running'
  const lang = d.context.language.slice(0, 2).lower()
  const jurisdiction = d.context.language.slice(-2).lower()
  const rLogs = logs.reverse().filter()
  const lastNotRunning = logs.find(d => d && d.status !== 'running')

  const tasks = workflow.actions
    .v()
    .filter()
    .map(({ name, id }) => {
      const log = rLogs.find(l => l.action_id === id)
      return {
        name,
        id,
        status: log?.status,
        skipped: log?.skipped && log.status !== 'rerun',
      }
    })
  let timeline = 'running_task'
  if (new Date(d.context.run_time) > new Date()) timeline = 'future_task'
  if (log.status === 'success' && log.action_id === workflow.actions.v().last().id) timeline = 'past_task'
  if (timeline === 'future_task') return
  return {
    ...d,
    ...d.context,
    report,
    sid,
    workflow_name,
    status,
    last_step,
    log,
    step,
    timeline,
    scope: 'default',
    assignee: log.action_id ? workflow.actions[log.action_id].user : 'N/A',
    start_date: log.start,
    last_update_date: lastNotRunning?.end || lastNotRunning?.start || lastNotRunning?.killed,

    // adds
    link,
    isParallel,
    isValidation,
    tasks,
    lang,
    jurisdiction,
  }
}

export function makeLink(sid) {
  const [id, rid] = sid.split('|')
  return {
    path: 'workflow',
    query: { ...$root.query, id, rid },
  }
}
