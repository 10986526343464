<template>
  <!-- nx-arrow-head-circle-story -->
  <div>
    <story-template :story="story" class="h-[150px]">
      <div class="w-full" :ref="arrowsReactiveRef.updateEl">
        <nx-arrow-set>
          <nx-arrow-head-circle
            class="fill-white stroke-[#1b9e77]"
            :center-x="story.props.centerX"
            :center-y="story.props.centerY"
            :radius="story.props.radius"
            :stroke-width="story.props.strokeWidth"
          />
        </nx-arrow-set>
      </div>
    </story-template>
  </div>
</template>

<script setup lang="ts">
import { watch } from 'vue'
import { elementReactiveRef, INxArrowHeadCircleProps } from '@hauru/common'
import { createStory, controls } from '@storytime'

const arrowsReactiveRef = elementReactiveRef()

const story = createStory<INxArrowHeadCircleProps>('nx-arrow-head-circle')
story.setProps({
  centerX: 20,
  centerY: 20,
  radius: 8,
  strokeWidth: controls.range(2, 0, 10, 1),
})

watch(arrowsReactiveRef, () => {
  if (!arrowsReactiveRef.value || !arrowsReactiveRef.width) return

  story.props.centerX = arrowsReactiveRef.width / 2
  story.props.centerY = arrowsReactiveRef.height / 2
})
</script>
