<template>
  <!-- nx-button -->
  <button
    :nx-theme="props.type ?? props.theme"
    class="vsg-unset flex items-center justify-center gap-1 text-center transition-all"
    :class="[
      hasButtonLeft ? 'py-1 pl-1 pr-2' : '',
      hasButtonRight ? 'py-1 pl-2 pr-1' : '',
      hasButtonCenter ? [currentSize.width] : '',
      !hasButtonLeft && !hasButtonRight && !hasButtonCenter ? 'py-1 pl-2 pr-2' : '',
      currentTheme?.idle,
      currentStateTheme,
      currentSize.height,
      currentSize.text,
      currentShape,
    ]"
    :tt="currentTheme?.label ?? props.label"
    v-bind="props.disabled ? { disabled: true } : {}"
  >
    <!-- @slot Slot for rendering an icon element on the left side of the button. -->
    <slot name="icon-left" v-if="$slots['icon-left']"></slot>
    <!-- @slot Slot for rendering an icon element on the center of the button. -->
    <slot name="icon-center" v-if="$slots['icon-center']"></slot>
    <!-- @slot Default slot to render the label of the button, if there is a button on its right. -->
    <slot v-if="hasButtonRight && props.showLabel">{{ currentTheme?.label ?? props.label }}</slot>
    <!-- @slot Slot for rendering an icon element. The position of the icon is determined by `props.iconPlacement` -->
    <slot name="icon" v-if="$slots['icon']"></slot>

    <!-- Icon displayed by using `props.icon` instead of the icon slot -->
    <div v-if="currentTheme?.icon || props.icon" :class="currentTheme?.icon ?? props.icon"></div>

    <!-- @slot Default slot to render the label of the button, if there isn't a button on its right. -->
    <slot v-if="!hasButtonRight && props.showLabel">{{ currentTheme?.label ?? props.label }}</slot>
    <!-- @slot Slot for rendering an icon element on the right side of the button. -->
    <slot name="icon-right" v-if="$slots['icon-right']"></slot>
  </button>
</template>

<script setup lang="ts">
// Inspired from https://material.angular.io/components/button/overview

import { useTheme } from '@hauru/common'
import { computed, useSlots } from 'vue'

type IButtonSize = 'xs' | 'sm' | 'md' | 'base' | 'lg' | 'custom'
type IButtonShape = 'rounded' | 'rectangle' | 'circle'

interface IProps {
  /**
   * Allows to manually bypass the theme set as default, among the themes provided by the theme config
   */
  theme?: string
  /**
   * The type of the button:
   * - basic: just text
   * - flat: text + bg color
   * - outlined: text + outline
   * Custom types can also be defined in the theme config
   */
  type?: string
  /**
   * The shape of the button:
   * - rectangle: no rounded corners
   * - rounded: rectangular with a bit rounded corners
   * - circle: fully rounded corners
   */
  shape?: IButtonShape
  /**
   * The size of the button:
   * - xs: 20px
   * - sm: 24px
   * - md: 28px
   * - base: 32px
   * - lg: 36px
   */
  size?: IButtonSize
  /**
   * The text to display on the button.
   */
  label?: string
  /**
   * The icon class to display on the button.
   * ex. 'i-[mdi/sort] h-3 h-3'
   */
  icon?: string | string[]
  /**
   * The placement of the icon on the button. Can be left, right or center.
   */
  iconPlacement?: 'left' | 'right' | 'center'
  /**
   * Allows manually setting the state among the states provided by the theme config
   * Default states are: idle, hover, active, focus, disabled, selected
   * Custom states can also be defined in the theme config.
   */
  state?: string
  /**
   * Allows to manually set the state of the button to disabled
   */
  disabled?: boolean
  /**
   * Allows to manually set the state of the button to hover
   */
  hover?: boolean
  /**
   * Allows to manually set the state of the button to active
   */
  active?: boolean
  /**
   * Allows to manually set the state of the button to focus
   */
  focus?: boolean
  /**
   * Allows to manually set the state of the button to selected
   */
  selected?: boolean
  /**
   *Boolean indicating if label is showed
   */
  showLabel?: boolean
}

const props = withDefaults(defineProps<IProps>(), {
  shape: 'rounded',
  size: 'base',
  iconPlacement: 'center',
  showLabel: true,
})

const themeConfig = useTheme()

const slots = useSlots()

const currentTheme = themeConfig.computedThemeType('button', props)
const currentSize = computed(() => calculateSizes(currentTheme.value?.size ?? props.size))
const currentShape = computed(() => calculateShape(currentTheme.value?.shape ?? props.shape))

const currentStateTheme = computed(() => {
  const state = props.disabled
    ? 'disabled'
    : props.selected
      ? 'selected'
      : props.active
        ? 'active'
        : props.focus
          ? 'focus'
          : props.hover
            ? 'hover'
            : props.state ?? 'idle'

  let themeState = currentTheme.value?.[state]

  if (state === 'disabled' && !themeState) themeState = 'cursor-default text-gray-400'
  return themeState
})

const calcIconPlacelent = computed(() => {
  // If the button has a text label, then iconPlacement is left by default
  if ((currentTheme.value?.iconPlacement ?? props.iconPlacement) === 'center')
    return currentTheme.value?.label || props.label || slots.default ? 'left' : 'center'
  return currentTheme.value?.iconPlacement ?? props.iconPlacement
})

const hasButtonLeft = computed(
  () =>
    props.showLabel &&
    (slots['icon-left'] ||
      ((slots.icon || currentTheme.value?.icon || props.icon) && calcIconPlacelent.value === 'left')),
)
const hasButtonRight = computed(
  () =>
    props.showLabel &&
    (slots['icon-right'] ||
      ((slots.icon || currentTheme.value?.icon || props.icon) && calcIconPlacelent.value === 'right')),
)
const hasButtonCenter = computed(
  () =>
    slots['icon-center'] ||
    ((slots.icon || currentTheme.value?.icon || props.icon) && calcIconPlacelent.value === 'center') ||
    !props.showLabel,
)

/**
 * Calculates the CSS classes for the size of the button.
 * @param size The size of the button.
 * @returns An object containing the height, width, and text CSS classes.
 */
function calculateSizes(size: IButtonSize) {
  const [height = '', width = '', text = ''] = {
    custom: ['', ''],
    base: ['h-8', 'w-8'],
    xs: ['h-5', 'w-5'],
    sm: ['h-6', 'w-6', 'font-medium text-sm'],
    md: ['h-7', 'w-7'],
    lg: ['h-9', 'w-9'],
  }[size]

  return {
    height,
    width,
    text,
  }
}

/**
 * Calculates the CSS classes for a given button shape.
 * @param shape The shape for which to return the value.
 * @returns A string of CSS classes that define the button shape.
 */
function calculateShape(shape: IButtonShape) {
  const shapes = {
    rectangle: 'rounded-0',
    circle: 'rounded-full',
    rounded: 'rounded',
  }

  return shapes[shape] ?? shapes.rounded
}
</script>
