<template>
  <lfr-block :data="data" :options="options">
    <p class="space-y-2 p-2.5 text-justify leading-[11.2px]" :class="options?.class" v-html="html"></p>
  </lfr-block>
</template>

<script setup>
import { marked } from 'marked'
import insane from 'insane'
import { computed } from 'vue'
const { data, options } = defineProps(['data', 'options'])
const html = computed(() => {
  if (data === options.data) return
  if (typeof data !== 'string') return data
  return insane(marked(data))
})
</script>
