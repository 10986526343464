<template>
  <div class="flex-1">
    <div v-if="imageFileName === 'error'" class="rounded-md !bg-red-50 p-2 !outline !outline-red-500">
      Error with image
    </div>
    <img v-else class="m-auto h-full w-full object-contain" :src="imageFileName" />
  </div>
</template>
<script setup lang="ts">
import { computed } from 'vue'
import { BuilderProps } from '../composables/builderComponent'

const props = defineProps<BuilderProps>()

const imageFileName = computed(() => {
  return props.context.layout.theme.assets.find(d => d.name === props?.data?.name)?.src || 'error'
})
</script>
