<template>
  <story-template :story="story" class="flex-col bg-white" :max-examples="3">
    <div class="builder-table-story h-full">
      <builderinternals-table :data="story.props.data" :columns="story.props.columns"></builderinternals-table>
    </div>
  </story-template>
</template>
<script setup lang="ts">
import { createStory, controls } from '@storytime'
import { defaultStory, outputStories } from './builder-table.story'

const story = createStory('builder-table')
story.setProps(defaultStory).createDefaultExample('Default')
Object.entries(outputStories).forEach(([name, example]) => {
  story.createExample(name).setExampleProps(example)
})

// Controls
// function onControlChange(key: string) {
//   return (v: any) => (story.props.options[key] = v)
// }
// story.createState('Controls', {
//   showTotal: controls.boolean(false).onChange(onControlChange('showTotal')),
//   showSubtotals: controls.boolean(true).onChange(onControlChange('showSubtotals')),
//   // sort: controls.boolean(false).onChange(v => (story.props.options.sort = v)),
//   // limit: controls.number(15, 5, 15, 1).onChange(v => (story.props.options.limit = v)),
//   // other: controls.boolean(false).onChange(v => (story.props.options.other = v)),
// })
// story.createExample('Base').setExampleProps(table.props)
</script>
<style>
.builder-table-story .all-table {
  --level-indent: 8px;
}
.builder-table-story .table-header-cell {
  font-weight: bold;
  border: 1px solid #e2e8f0;
}
.builder-table-story .column-0,
.builder-table-story .column-0 {
  text-align: left;
}
.builder-table-story .level-0 {
  font-weight: bold;
}
/* .builder-table-story .table-col .table-header {
  border: 1px solid #e2e8f0;
} */

.builder-table-story .table-cell {
  padding: 4px;
  text-align: center;
}

.builder-table-story .table-header-cell {
  padding: 4px;
  text-align: center;
}
.builder-table-story .all-table .table-row.striped {
  background-color: #e2e8f0;
}
</style>
