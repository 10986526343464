<template lang="pug">
brick.no-bold.main_table_esg(:title="t['IMPACT_INDICATOR_ESG_TITLE'] || 'IMPACT_INDICATOR_ESG_TITLE'" :subtitle="t.impact_esg_table_upper_text || 'impact_esg_table_upper_text'" )
  pdf-table(v-bind:class="{ universe: displayUniverse }" :data="esgTableImpactData")
  div.esg_bottom_text {{t[fundData.monitored_esg_indicators] || 'impact_esg_table_monitored_esg_indicators'}}
  div.esg_bottom_text {{t.impact_esg_table_bottom_text || 'impact_esg_table_bottom_text'}}

</template>

<script setup lang="ts">
import { computed, ref, reactive } from 'vue'

const props = defineProps({
  characteristicsData: Object,
  mapping: Object,
})

const fundData = props.characteristicsData
const universeData = reactive(props.mapping[props.characteristicsData.benchmark_esg] || {})
const displayUniverse = ref(Object.keys(universeData).length ? true : false)

const esgTableImpactData = computed(() => {
  return createImpactEsgDataTable(fundData, universeData, displayUniverse.value, $root.t)
})

const splitData = data => {
  return data ? data.split(',').map(item => item.trim()) : []
}

const buildHeaders = (displayUniverseColumn, t, criteria) => {
  if (displayUniverseColumn) {
    return [
      [
        t[criteria] || 'criteria',
        t.impact_indicator_esg || 'impact_indicator_esg',
        t.measure || 'measure',
        '',
        t.coverage_rate || 'coverage_rate',
        '',
      ],
      [
        '',
        '',
        t.esg_funds || 'esg_funds',
        t.esg_univers || 'esg_univers',
        t.esg_funds || 'esg_funds',
        t.esg_univers || 'esg_univers',
      ],
    ]
  } else {
    return [
      [
        t[criteria] || 'criteria',
        t.impact_indicator_esg || 'impact_indicator_esg',
        t.measure || 'measure',
        t.coverage_rate || 'coverage_rate',
      ],
    ]
  }
}

function processImpactEsg(
  ESGcriterion,
  impactParam,
  fundData,
  translation,
  impactEsgFormatArray,
  universeData,
  displayUniverse,
) {
  const result = []
  for (let impactEsg of splitData(impactParam || '')) {
    const criteria = translation[ESGcriterion] || ESGcriterion
    const indicatorImpactEsg = translation.impactEsg || impactEsg

    const impactEsgFormat = impactEsgFormatArray[indicatorImpactEsg] || '.2f'

    const fundValue = window.format(impactEsgFormat)(fundData[indicatorImpactEsg]) || 'NA'
    const universeValue = window.format(impactEsgFormat)(universeData[indicatorImpactEsg]) || 'NA'

    // always %
    const fundCoverage = window.format('.2%')(fundData[indicatorImpactEsg + '_coverage']) || 'NA'
    const universeCoverage = window.format('.2%')(universeData[indicatorImpactEsg + '_coverage']) || 'NA'

    const line = [criteria, impactEsg, fundValue, universeValue, fundCoverage, universeCoverage]
    // if universeData is empty, drop fourth and six column
    if (!displayUniverse) {
      line.splice(5, 1)
      line.splice(3, 1)
    }
    result.push(line)
  }
  return result
}

function applyStripping(esgDataTable, displayUniverse) {
  let nextLabel = 'even'
  let previousSecondValue = null
  const table = [...esgDataTable]
  table.forEach((subArray, index) => {
    if (displayUniverse && index < 2) {
      subArray.unshift(nextLabel)
      nextLabel = index === 1 ? 'odd' : 'even'
    } else {
      // case where criteria are not the same from before
      if (subArray[0] !== previousSecondValue) {
        subArray.unshift(nextLabel)
        nextLabel = nextLabel === 'even' ? 'odd' : 'even'
      } // case where criteria are the same as before so color is kept and criteria is deleted
      else {
        nextLabel = nextLabel === 'even' ? 'odd' : 'even'
        subArray[0] = ''
        subArray.unshift(nextLabel)
        nextLabel = nextLabel === 'even' ? 'odd' : 'even'
      }
      previousSecondValue = subArray[1]
    }
  })
  return table
}

const createImpactEsgDataTable = (fundData, universeData, displayUniverse, t) => {
  const criteria = [
    'esg_impact_table_envrionnemental',
    'esg_impact_table_societal',
    'esg_impact_table_gouvernance',
    'esg_impact_table_humanRights',
    'esg_impact_table_humanDev',
    'esg_impact_table_governanceEffectiveness',
    'esg_impact_table_voiceAccountability',
  ]
  const impactEsgFormatArray = {
    carbon_intensity: '.2f',
    pct_brd_members_women: '.2%',
    esg_linked_bonus: '.2%',
    human_rights_policy: '.2%',
    human_dev_index: '.2f',
    governance_effectiveness: '.2f',
    voice_accountability: '.2f',
    S_Politique_Protection_Lanceur_Alertes: '.2%',
  }

  const headers = buildHeaders(displayUniverse, t, criteria)
  const content = []
  criteria.forEach(ESGcriterion => {
    const fundCriteriaDataParam = fundData[ESGcriterion]
    if (fundCriteriaDataParam) {
      const newLines = processImpactEsg(
        ESGcriterion,
        fundCriteriaDataParam,
        fundData,
        t,
        impactEsgFormatArray,
        universeData,
        displayUniverse,
      )
      content.push(newLines)
    }
  })

  let esgDataTable = []
  esgDataTable.push(...headers, ...content.flat())

  const esgDataWithStripping = applyStripping(esgDataTable, displayUniverse)

  return esgDataWithStripping
}
</script>

<style>
.main_table_esg .esg_bottom_text {
  margin-top: 5px;
}
.main_table_esg .universe .line:first-child .cell:nth-child(4) :first-child {
  width: 184px;
}
.main_table_esg .universe .line:first-child .cell:nth-child(6) :first-child {
  width: 184px;
}
.main_table_esg .universe .line:nth-child(n + 2) .cell:nth-child(4) {
  border-left: 1px solid var(--eighth);
}
.main_table_esg .universe .line:nth-child(n + 2) .cell:nth-child(5),
.main_table_esg .universe .line:nth-child(n + 2) .cell:nth-child(7) {
  border-right: 1px solid var(--eighth);
}
.main_table_esg .universe .line:nth-child(2) .cell:nth-child(2) div,
.main_table_esg .universe .line:nth-child(2) div {
  font-weight: bold !important;
}
.main_table_esg .cell:nth-child(2) div {
  justify-content: left !important;
}
.main_table_esg .line :has(.odd) {
  background-color: #eff8fb !important;
}
.main_table_esg .line :has(.even) {
  background-color: #ffffff !important;
}
.pdf .pdf-table .main_table_esg .line .cell.odd,
.pdf .main_table_esg .pdf-table .line .cell.even {
  display: none;
}
.main_table_esg .line div {
  white-space: normal;
  word-wrap: break-word;
  text-align: center;
  vertical-align: middle;
}
.main_table_esg .cell:nth-child(3) {
  width: 250px !important;
  max-width: unset !important;
}
</style>
