<template lang="pug" v-if="loaded">
.row
  h1 {{t.dqc_rules || 'dqc_rules' }} ({{rules?.length || 0}})
.row
  subtitle(style="font-style: italic; margin-top: -10px;") {{ t['dqc_rules_subtitle'] || 'dqc_rules_subtitle'}}
.block.expand
  spreadsheet.stripped.expand.nosort(:data="rules" :options="{ columns: ['Rules Name', 'Usage in DQC Reports', 'Actions'], editable: false }")
    template(v-slot:cell-rules-name="{ column, line }")
      div {{ line.name }}
    template(v-slot:cell-usage-in-dqc-reports="{ column, line }")
      div {{ line.dataQualityCheckReports?.length || 0}}
    template(v-slot:cell-actions="{ column, line }")
      .row
        button.ghost(:tt="t.edit" @click="edit(line)")
          svg-icon(name="pt-icon-edit")
        button.ghost(:tt="t.duplicate" @click="duplicate(line)")
          svg-icon(name="ic_file_copy")
        button.ghost(v-if="line.dataQualityCheckReports?.length === 0" :tt="t.delete" @click="confirmRemoveId=line.id")
          svg-icon(name="pt-icon-trash")
  .row
    button.primary(@click="createRule") {{t['dqc_create_rule'] || 'dqc_create_rule'}}
  popup(:show="confirmRemoveId")
    template(v-slot:header)
      | {{t["confirmation_remove"]}}
    template(v-slot:content)
      | {{t["text_remove"]}}
    template(v-slot:action)
      button.secondary-action(@click="confirmRemoveId = null") {{t['confirmation_btn_close']}}
      button.main-action(@click="deleteDr(confirmRemoveId)") {{t["confirmation_btn_delete"]}}
</template>

<script>
import { ref, onMounted } from 'vue'
import { useDataQualityRule } from '../composables/useDataQualityRule'
import dataQualityRuleService from '../../../services/DataQualityRuleService'

export default {
  data() {
    return {
      confirmRemoveId: null,
    }
  },
  setup() {
    const { rules, loaded } = useDataQualityRule()
    onMounted(() => {})
    return {
      rules,
      loaded,
    }
  },
  methods: {
    createRule() {
      this.$router.push({ path: $root.appath + 'data-quality-rule', query: { new: true } })
    },
    edit(line) {
      this.$router.push({ path: $root.appath + 'data-quality-rule', query: { id: line.id } })
    },
    async duplicate(line) {
      const rule = await dataQualityRuleService.get(line.id)
      const newName = prompt(
        'Enter rule name',
        `${name.replace(/ \d\d\d\d-\d\d-\d\d \d\d:\d\d/, '')} ${new Date().format('YYYY-MM-DD hh:mm')}`,
      )
      if (newName && newName.length) {
        try {
          const createdRule = await dataQualityRuleService.createDataQualityRule(newName, rule.dqcJavaScriptFunction)
          this.$router.push({ path: $root.appath + 'data-quality-rule', query: { id: createdRule.id } })
        } catch (error) {
          if (error.message) {
            let displayMessage = error.message
            if (error.message.includes('already exists')) {
              displayMessage = $root.t.rule_already_exists
            } else if (error.message.includes('must not be null')) {
              displayMessage = $root.t['error_null_value']
            }
            $root.toast({ description: displayMessage, type: 'error', timeout: 5000 })
          }
        }
      } else {
        $root.toast({ description: $root.t.missing_mandatory_fields, type: 'error', timeout: 5000 })
      }
    },
    async deleteDr(id) {
      try {
        await dataQualityRuleService.deleteDataQualityRule(id)
      } catch (e) {
        $root.toast({ description: e.message, type: 'error', timeout: 5000 })
        return
      }
      this.rules.splice(
        this.rules.findIndex(q => q.id === id),
        1,
      )
      this.confirmRemoveId = null
      await this.init()
    },
  },
}
</script>

<style scoped></style>
