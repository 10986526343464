import { PlatformBuilder } from '@100-m/hauru/src/utils/platformBuilder'
import BaseApp from '@100-m/hauru/src/App.vue'
import BaseModule from '@100-m/hauru/src/module'
import Switch from '@100-m/hauru/src/applications/switch/module'
import AssetManagement from '@100-m/hauru/src/applications/asset-management/module'
import ClientEdition from '@100-m/hauru/src/applications/client-edition/module'
import Builder from '@100-m/hauru/src/applications/builder/module'
import auth0Login from '@100-m/hauru/src/features/auth/auth0-login.js'
import config, { extendConfig } from '@100-m/hauru/src/config.js'

import LaFrancaiseBaseModule from './applications/base/module'
import LaFrancaiseSwitch from '../src/applications/switch/module'
import LaFrancaiseAssetManagement from '../src/applications/asset-management/module'
import LaFrancaiseClientEdition from './applications/client-edition/module'
import LaFrancaiseBuilder from '../src/applications/builder/module'
import clientConfig from './config'
import { downloadMixin } from './features/translation'
import xfilter from '@100-m/hauru/src/features/xfilter.js'

import styleTailwind from '@100-m/hauru/src/applications/builder/index.css?inline'
import style1 from '@100-m/hauru/src/assets/css/platform.css?inline'
import style2 from '@100-m/hauru/src/applications/asset-management/index.css?inline'
import style3 from '@100-m/hauru/src/applications/client-edition/index.css?inline'
import style4 from './index.css?inline'
const stylePlatform = [style1, style2, style3, style4].join('\n\n')
const styleMixin = {
  computed: {
    style() {
      // TODO: add VITE_RAWJS_OBJECT_NOEXTEND for every app
      if (['builder', 'design'].includes($root.app)) {
        Object.keys(Object).map(k => delete Object.prototype[k])
      } else {
        Object.extend()
      }
      if (['builder', 'design'].includes($root.app)) return styleTailwind
      return stylePlatform
    },
  },
}
BaseApp.mixins = (BaseApp.mixins || []).concat(styleMixin).concat(downloadMixin).concat(xfilter)

extendConfig(clientConfig)
const authConfig = auth0Login(config.auth)
const platform = new PlatformBuilder()
  .initFirebase(config.firebase)
  .setBaseApp(BaseApp)
  .addApp(BaseModule, LaFrancaiseBaseModule)
  .addApp(Switch, LaFrancaiseSwitch)
  .addApp(AssetManagement, LaFrancaiseAssetManagement)
  .addApp(ClientEdition, LaFrancaiseClientEdition)
  .addApp(Builder, LaFrancaiseBuilder)
  .setAuth(authConfig)
const app = platform.build()
app.mount('main')

window.app = app
window.platform = platform
