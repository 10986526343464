export function generateUniqueName(name: string, existingNames: string[], counter?: number) {
  const suffix = (counter ?? 0) > 0 ? `(${counter})` : ''
  const uniqueName = `${name}${suffix}`

  if (existingNames.includes(uniqueName)) {
    return generateUniqueName(name, existingNames, (counter ?? 0) + 1)
  }

  return uniqueName
}
