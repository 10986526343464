import { GraphQlService } from './GraphQlService'

/**
 * Transactions service
 */
export class TransactionService extends GraphQlService {
  /**
   * List transactions of a share
   * @param fund Fund name
   * @param share Share name
   * @param domain Date range
   * @return {Promise<*>} Array of Transaction
   */
  async list({ fund, share, domain }) {
    if (!fund) throw new Error('Missing parameters')

    return (
      await this.query(
        `
        query TransactionQuery($fund: String! $domain: [String]) {
          transactions(fund: $fund domain: $domain)
        }
    `,
        { fund, share, domain },
      )
    ).data.transactions
  }
}
const transactionService = new TransactionService()
export default transactionService
