import { WatchArrayCallback } from '@vueuse/core'
import { watch, WatchOptions, WatchSource, WatchStopHandle } from 'vue'

export function watchImmediate<T, Immediate extends true>(
  source: any,
  cb: WatchArrayCallback<T[], Immediate extends true ? T[] | undefined : T[]>,
  options?: WatchOptions<Immediate>,
): WatchStopHandle

export function watchImmediate(source: any, cb: any, options: any) {
  return watch(source, cb, { ...options, immediate: true })
}
