import { computed, ref } from 'vue'

export function useAutocomplete() {
  const allowedDimensionsInternal = ref()
  const dataSetInternal = ref()

  const initAutocomplete = (dataSet, allowedDimensions) => {
    // allowedDimensions: Arr with dimensions expected in autocomplete (what you want filter)
    // dataSet: DataSet with following format: [obj, obj, obj]
    allowedDimensionsInternal.value = allowedDimensions
    dataSetInternal.value = dataSet
  }

  const autocompleteAvailableData = computed(() => {
    return getAutocompleteAvailableData(allowedDimensionsInternal.value, dataSetInternal.value)
  })

  const activeAutocompleteFilters = computed(() => {
    const scopedQuery = $root.query.filter((d, k) => allowedDimensionsInternal.value.includes(k))
    return unGroupQueryParam(scopedQuery)
  })

  const onFiltersUpdateAutoComplete = $event => {
    const otherFilters = $root.query.filter((d, k) => !allowedDimensionsInternal.value.v().includes(k))
    if ($event.length === 0) {
      $root.$router.push({
        query: otherFilters,
      })
      return
    }
    $root.$router.push({
      query: {
        ...otherFilters,
        ...groupQueryParam($event),
      },
    })
  }
  // ##### UTILS #####
  const getAutocompleteAvailableData = (allowedDimensions, dataSet) => {
    // Retrun strange output for autocomplete data
    // return {
    //  dim1: {dim1Value1: 'dim1Value1', dim1Value2: 'dim1Value2'}
    //  dim2: {dim2Value1: 'dim2Value1', dim2Value2: 'dim2Value2'}
    // }
    return allowedDimensions.reduce((acc, dimension) => {
      const dimensionValues = dataSet.map(element => element.axis[dimension] || element[dimension]).unique()
      acc[dimension] = arrToObj(dimensionValues)
      return acc
    }, {})
  }
  const groupQueryParam = arr => {
    return arr.reduce((acc, v) => {
      const key = v.split('.')[0]
      if (Object.keys(acc).includes(key)) {
        acc[key] = [acc[key], v.split('.')[1]].join('|')
      } else {
        acc[key] = v.split('.')[1]
      }

      return acc
    }, {})
  }

  const unGroupQueryParam = query => {
    return query
      .reduce((acc, v, k) => {
        v.split('|').forEach(value => {
          acc.push([k, value].join('.'))
        })
        return acc
      }, [])
      .unique()
  }
  const arrToObj = arr =>
    arr.reduce((acc, v) => {
      acc[v] = v
      return acc
    }, {})

  return {
    initAutocomplete,
    autocompleteAvailableData,
    activeAutocompleteFilters,
    onFiltersUpdateAutoComplete,
  }
}
