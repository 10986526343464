<template>
  <!-- arrow-position-selector -->
  <circle
    class="cursor-grab fill-gray-400"
    :cx="props.x"
    :cy="props.y"
    :r="props.size + 2"
    stroke="white"
    stroke-width="4"
    @mousedown="onMousedown"
    @mouseup="onMouseup"
  />
</template>

<script setup lang="ts">
import { onMounted, onUnmounted, ref } from 'vue'

interface IProps {
  x: number
  y: number
  refEl?: HTMLElement
  size?: number
}

const props = withDefaults(defineProps<IProps>(), {
  size: 8,
})

const emit = defineEmits<{
  (e: 'update:x', value: number): void
  (e: 'update:y', value: number): void
}>()

const isClicked = ref(false)

function updateCoords(e: any) {
  if (isClicked.value) {
    emit('update:x', e.clientX - (props.refEl?.getBoundingClientRect().x ?? 0))
    emit('update:y', e.clientY - (props.refEl?.getBoundingClientRect().y ?? 0))
  }
}

function onMousedown(e: any) {
  isClicked.value = true
}

function onMouseup(e: any) {
  isClicked.value = false
}

onMounted(() => window.addEventListener('mousemove', updateCoords))
onUnmounted(() => window.removeEventListener('mousemove', updateCoords))
</script>
