export function usePerformance() {
  const getPerformanceData = async (params, token) => {
    console.log('getPerformanceData')
    const resp = await axios({
      url: '/dr',
      method: 'post',
      data: {
        variables: params,
        query: `query PerformanceQuery(
          $domain: Domain
          $isinShare: String!
          $metric: Metric
          $asof: Asof
        ) {
          share(isin_share: $isinShare, asof: $asof) {
            characteristics
            slug
            benchmark {
              label
              id
            }
            analytics {
              domain_historical(domain: $domain) {
                date
                fund
                benchmark
                benchmark2
                benchmark3
              }
              yearly_performances(domain: $domain) {
                date
                fund
                benchmark
                benchmark2
                benchmark3
                month
                year
                diff
                risk_free
                real_nav
                aum_fund_currency
              }
              monthly_performances(domain: $domain) {
                date
                fund
                benchmark
                benchmark2
                benchmark3
                month
                year
                diff
                risk_free
                real_nav
                aum_fund_currency
              }
              domain_performance(domain: $domain) {
                date
                fund
                benchmark
                benchmark2
                benchmark3
                month
                year
                diff
                risk_free
                real_nav
                aum_fund_currency
              }
              volatility(domain: $domain) {
                fund
                benchmark
                benchmark2
                benchmark3
              }
              drawdown_details(domain: $domain, metric: $metric) {
                drawdown
                begin
                begin_nav
                end
                end_nav
                recovery
                duration
              }
              tracking_error(domain: $domain, metric: $metric)
              information_ratio(domain: $domain, metric: $metric)
              domain_sharpe_ratio(domain: $domain)
              domain_sharpe_ratio_benchmark(domain: $domain)
            }
          }
        }`,
      },
    })
    return resp.data.data
  }
  return {
    getPerformanceData,
  }
}
