<script setup lang="ts">
import * as ChartJS from 'chart.js'
import { reactive, watch } from 'vue'
const props = defineProps<{ data: []; options: {} }>()
const emit = defineEmits(['seriesHover', 'seriesClick'])
const state = reactive({
  ref: null,
  // width: 0,
  // height: 0,
  // padding: 0,
  // ratio: 0,
})
const resize = ref => {
  if (!ref) return
  state.ref = ref
  // state.width = ref.clientWidth - state.padding * 2
  // state.height = ref.clientHeight - state.padding * 2
  // new ResizeObserver(() => {
  //   state.width = ref.clientWidth - state.padding * 2
  //   state.height = ref.clientHeight - state.padding * 2
  // }).observe(ref)
}
let chart
watch([state, props], () => {
  if (!state.ref) return
  // if (!state.ref || !state.width || !state.height) return
  // const minwh = Math.min(state.width, state.height)
  // const width = state.ratio ? minwh * state.ratio : state.width
  // const height = state.ratio ? minwh : state.height
  const palette = props.options.palette
  const chartjsOptions: ChartJS.ChartConfiguration = {
    type: props.options.viz.replace('pie', 'doughnut').replace('area', 'line').replace('dot', 'scatter'),
    data: {
      labels: props.data.map(d => d[props.options.x]),
      datasets: props.options.category
        ? Object.keys(
            props.data.reduce((acc, d) => {
              Object.keys(d).forEach(k => {
                if (k !== props.options.x) acc[k] = (acc[k] || 0) + d[k]
              })
              return acc
            }, {}),
          ).map((category, i) => ({
            label: category,
            data: props.data.map(d => d[category]),
            backgroundColor: palette[i],
            borderColor: palette[i],
            borderWidth: 1,
            ...(props.options.viz === 'area' && { fill: 'start' }),
          }))
        : [
            {
              label: props.options.y,
              data: props.data.map(d => d[props.options.y]),
              backgroundColor: palette[0],
              borderColor: palette[0],
              borderWidth: 1,
              ...(props.options.viz === 'area' && { fill: 'start' }),
            },
          ],
    },
    options: {
      indexAxis: props.options.horizontal ? 'y' : undefined,
      animation: false,
      maintainAspectRatio: false,
      responsive: true,
      plugins: {
        legend: {
          display: false,
        },
        tooltip: {
          enabled: false,
        },
      },
      ...(props.options.viz === 'pie' && {
        scales: {
          [props.options.horizontal ? 'y' : 'x']: {
            ...(props.data[0][props.options.x] instanceof Date && { type: 'time' }),
            ticks: {
              ...(props.options.formatX && { callback: props.options.formatX }),
            },
          },
          [props.options.horizontal ? 'x' : 'y']: {
            stacked: props.options.stack,
            ticks: {
              ...(props.options.formatY && { callback: props.options.formatY }),
            },
          },
        },
      }),
      onHover: e => console.log('onHover', e),
      onClick: e => console.log('onClick', e),
    },
  }
  const transformer = props.options.transformer || (v => v)
  if (chart) chart.destroy()
  chart = new ChartJS.Chart(state.ref, transformer(chartjsOptions))
})
</script>

<template>
  <canvas class="min-w-full max-w-full" :ref="resize"></canvas>
</template>
