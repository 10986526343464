<template>
  <lfr-block :data="data" :options="options">
    <div class="lfr-pie flex flex-1 flex-col flex-wrap justify-center gap-2">
      <nx-colors-legend v-if="options.legend" :legends="legends" />
      <nx-pie :data="data.slice(0, options.limit || Infinity)" :options="options"></nx-pie>
      <nx-legend :data="data.slice(0, options.limit || Infinity)" :options="options"></nx-legend>
    </div>
  </lfr-block>
</template>

<script setup>
import useLegends from './useLegends'
const { data, options } = defineProps(['data', 'options'])
const legends = useLegends(
  options.y.slice(0, 2),
  options.data.data.header.domain,
  options.data.data.header.domainPreviousMonth,
  options.data.translation,
)
</script>

<script>
export default {
  api: {
    x: {
      label: 'X Axis',
      autocomplete: ({ data_component }) =>
        Object.entries(data_component[0] || {})
          .filter(([k, v]) => v?.constructor === String)
          .map(([k, v]) => k),
      default: ({ data_component }) =>
        Object.entries(data_component[0] || {})
          .filter(([k, v]) => v?.constructor === String)
          .map(([k, v]) => k)[0],
      attrs: {
        required: true,
      },
    },
    y: {
      label: 'Y Axis',
      autocomplete: ({ data_component }) =>
        Object.entries(data_component[0] || {})
          .filter(([k, v]) => v?.constructor === Number)
          .map(([k, v]) => k),
      default: ({ data_component }) =>
        Object.entries(data_component[0] || {})
          .filter(([k, v]) => v?.constructor === Number)
          .map(([k, v]) => k),
      attrs: {
        required: true,
        multiple: true,
      },
    },
    unit: {
      label: 'Unit',
      default: () => '%',
    },
    digit: {
      label: 'Decimals',
      default: () => 2,
      attrs: {
        type: 'number',
      },
    },
    limit: {
      label: 'Limit',
      default: () => 10,
      attrs: {
        type: 'number',
      },
    },
    legend: {
      label: 'Legend',
      default: () => true,
      attrs: {
        type: 'checkbox',
        class: 'none',
      },
    },
  },
}
</script>
