<template>
  <section class="px-8">
    <div class="flex flex-col items-center gap-4">
      <button
        class="flex h-8 w-36 items-center justify-center gap-1 rounded bg-neutral-600 py-2 text-sm hover:bg-neutral-400 active:bg-[#00d9b8]/75"
        @click="pageAdd(0)"
      >
        <ui-asset :width="20" :height="20" name="icon_plus_circle"></ui-asset>
        <div>Page Top</div>
      </button>
      <button
        class="flex h-8 w-36 items-center justify-center gap-1 rounded bg-red-600 py-2 text-sm hover:bg-red-900"
        :class="{ 'pointer-events-none opacity-50': template.pages?.length < 2 }"
        @click="pageDel()"
      >
        <ui-asset :width="20" :height="20" name="icon_trash"></ui-asset>
        <div>Delete Page</div>
      </button>
      <button
        class="flex h-8 w-36 items-center justify-center gap-1 rounded bg-neutral-600 py-2 text-sm hover:bg-neutral-400 active:bg-[#00d9b8]/75"
        @click="pageAdd()"
      >
        <ui-asset :width="20" :height="20" name="icon_plus_circle"></ui-asset>
        <div>Page Bottom</div>
      </button>
    </div>
    <!-- <div>{{ containerActive }}</div> -->
  </section>

  <section class="grid grid-cols-3 gap-4 border-t border-gray-500 px-8 pt-8">
    <div></div>
    <button
      class="flex h-8 w-24 items-center justify-center gap-1 rounded bg-neutral-600 text-sm hover:bg-neutral-400 active:bg-[#00d9b8]/75"
      @click="blockAdd('top')"
    >
      <ui-asset :width="20" :height="20" name="icon_plus_circle"></ui-asset>
      <div>Top</div>
    </button>
    <button
      class="flex h-8 w-24 items-center justify-center gap-1 rounded bg-neutral-600 text-sm hover:bg-neutral-400 active:bg-[#00d9b8]/75"
      @click="blockAdd('overlay')"
    >
      <ui-asset :width="20" :height="20" name="icon_plus_circle"></ui-asset>
      <div>Overlay</div>
    </button>
    <button
      class="flex h-8 w-24 items-center justify-center gap-1 rounded bg-neutral-600 text-sm hover:bg-neutral-400 active:bg-[#00d9b8]/75"
      @click="blockAdd('left')"
    >
      <ui-asset :width="20" :height="20" name="icon_plus_circle"></ui-asset>
      <div>Left</div>
    </button>
    <button
      class="flex h-8 w-24 items-center justify-center gap-1 rounded bg-red-600 text-sm hover:bg-red-900"
      @click="blockDel()"
    >
      <ui-asset :width="20" :height="20" name="icon_trash"></ui-asset>
      <div>Delete</div>
    </button>
    <button
      class="flex h-8 w-24 items-center justify-center gap-1 rounded bg-neutral-600 text-sm hover:bg-neutral-400 active:bg-[#00d9b8]/75"
      @click="blockAdd('right')"
    >
      <ui-asset :width="20" :height="20" name="icon_plus_circle"></ui-asset>
      <div>Right</div>
    </button>
    <div></div>
    <button
      class="flex h-8 w-24 items-center justify-center gap-1 rounded bg-neutral-600 text-sm hover:bg-neutral-400 active:bg-[#00d9b8]/75"
      @click="blockAdd('bottom')"
    >
      <ui-asset :width="20" :height="20" name="icon_plus_circle"></ui-asset>
      <div>Bottom</div>
    </button>
    <div></div>
  </section>

  <section class="border-t border-gray-500 px-8 pt-8" v-if="blockActive">
    <div class="flex flex-col gap-2">
      <div>Selected</div>
      <div class="flex flex-row items-center justify-between">
        <div>{{ blockActive.type?.titleize() }}</div>
        <button @click="selectParent" v-if="store.active.length >= 3">Select parent</button>
      </div>
    </div>
    <div class="my-3 flex flex-col space-y-5 text-sm">
      <template v-if="parentNode?.type === 'overlay'">
        <div class="flex items-center gap-2">
          <div class="w-28 shrink-0">Presets positions</div>
          <select
            class="w-82 rounded bg-neutral-600 p-1 [font-size:inherit] focus:outline-none focus-visible:ring-1"
            @input="ev => setPreset(ev)"
          >
            <option
              v-for="preset in ['spread', 'top-right', 'top-left', 'center', 'bottom-right', 'bottom-left']"
              :value="preset"
              :key="preset"
            >
              {{ preset.titleize() }}
            </option>
          </select>
        </div>
        <div class="flex items-center gap-2" v-if="blockActive.position">
          <div class="w-28 shrink-0">Position</div>
          <div class="flex flex-col gap-1" v-for="position in ['top', 'right', 'bottom', 'left']" :key="position">
            <label class="text-[12px]">{{ position.titleize() }}</label>
            <input
              type="text"
              class="flex h-8 w-12 rounded bg-neutral-600 p-1 [font-size:inherit] focus:outline-none focus-visible:ring-1"
              v-model="blockActive.position[position]"
            />
          </div>
        </div>
      </template>
      <div class="flex items-center gap-2">
        <div class="w-28 shrink-0">Block Width</div>
        <button
          class="h-8 w-16 rounded bg-neutral-600 px-3 py-1 text-sm hover:bg-neutral-400"
          :class="[
            blockActive.width === 'fit' ? '!bg-[#00d9b8]/75' : '',
            parentNode?.type !== 'overlay' ? 'cursor-not-allowed bg-neutral-800' : '',
          ]"
          @click="blockActive.width = 'fit'"
        >
          Fit
        </button>
        <button
          class="h-8 w-16 rounded bg-neutral-600 px-3 py-1 text-sm hover:bg-neutral-400"
          :class="!blockActive.width ? '!bg-[#00d9b8]/75' : ''"
          @click="blockActive.width = ''"
        >
          Auto
        </button>
        <input
          type="text"
          class="flex h-8 w-16 rounded bg-neutral-600 p-1 [font-size:inherit] focus:outline-none focus-visible:ring-1"
          v-model="blockActive.width"
          aria-label="Block width"
        />
      </div>
      <div class="flex items-center gap-2">
        <div class="w-28 shrink-0">Block Height</div>
        <button
          class="h-8 w-16 rounded bg-neutral-600 px-3 py-1 text-sm hover:bg-neutral-400"
          :class="blockActive.height === 'fit' ? '!bg-[#00d9b8]/75' : ''"
          @click="blockActive.height = 'fit'"
        >
          Fit
        </button>
        <button
          class="h-8 w-16 rounded bg-neutral-600 px-3 py-1 text-sm hover:bg-neutral-400"
          :class="!blockActive.height ? '!bg-[#00d9b8]/75' : ''"
          @click="blockActive.height = ''"
        >
          Auto
        </button>
        <input
          type="text"
          class="flex h-8 w-16 rounded bg-neutral-600 p-1 [font-size:inherit] focus:outline-none focus-visible:ring-1"
          v-model="blockActive.height"
          aria-label="Block height"
        />
      </div>
      <div class="flex flex-col">
        <div class="flex items-center gap-4">
          <div class="flex w-40 justify-between text-sm">
            <span>Margin</span>
            <pre class="text-neutral-400">{{ blockActive.margin ? `${blockActive.margin * 4}px` : 'Default' }}</pre>
          </div>
          <input
            class="w-48 border-transparent bg-transparent"
            type="range"
            min="0"
            max="12"
            step="0.5"
            v-model="blockActive.margin"
          />
        </div>
        <button class="ml-auto text-sm hover:underline" v-if="blockActive.margin" @click="blockActive.margin = null">
          Reset to default
        </button>
      </div>
    </div>
  </section>

  <section class="!mt-auto flex flex-col gap-4 border-t border-t-gray-500 p-8 text-xs">
    <div class="flex items-center gap-4">
      <div class="flex w-40 justify-between text-sm">
        <span>Blueprint</span>
      </div>
      <button
        class="h-8 w-16 rounded bg-neutral-600 px-3 py-1 text-sm hover:bg-neutral-400"
        :class="blueprint ? '!bg-[#00d9b8]/75' : ''"
        @click="emit('update:blueprint', true)"
      >
        ON
      </button>
      <button
        class="h-8 w-16 rounded bg-neutral-600 px-3 py-1 text-sm hover:bg-neutral-400"
        :class="!blueprint ? '!bg-[#00d9b8]/75' : ''"
        @click="emit('update:blueprint', false)"
      >
        OFF
      </button>
    </div>
    <div class="flex items-center gap-4">
      <div class="flex w-40 justify-between text-sm">
        <span>Zoom</span>
        <pre class="text-neutral-400">{{ Math.round(zoom * 100) }}%</pre>
      </div>
      <input
        class="w-48 border-transparent bg-transparent"
        type="range"
        min="0.5"
        max="1.5"
        step="0.1"
        :value="zoom"
        @input="ev => emit('update:zoom', (ev?.target as HTMLInputElement).value)"
      />
    </div>
    <!-- <div class="text-lg font-bold">BLOCK CSS</div>
    <textarea class="h-28 w-full resize-none rounded-lg bg-black p-2 text-sm text-white" v-model="blockActive.style"></textarea> -->
  </section>
</template>
<script setup lang="ts">
import { computed, nextTick, ref, watch } from 'vue'
import { TemplateLayout, LayoutNode, ComponentNode } from '../../builder'
import { debounce } from '../../lib/utils'
import useLayout from '../../composables/layout'
const props = defineProps(['store', 'zoom', 'blueprint'])
const template = defineModel<TemplateLayout>()
const emit = defineEmits(['update:storeActive', 'update:zoom', 'update:blueprint'])
const blockActive = ref<ComponentNode | LayoutNode>()

const { pageAdd, pageDel, blockAdd, blockDel, getNode, updateNode, updateLayout } = useLayout(props.store, template)

function selectParent() {
  if (!props.store.active || !props.store.active.length) return
  const parent = props.store.active.slice(0, -1)
  emit('update:storeActive', parent)
}
const parentNode = computed(() => getNode(props.store.active.slice(0, -1)))
function setPreset(ev: any) {
  const preset = ev.target.value
  if (preset === 'top-right') {
    blockActive.value.position = { top: '0', right: '0' }
    blockActive.value.width = 'fit'
    blockActive.value.height = ''
  } else if (preset === 'top-left') {
    blockActive.value.position = { top: '0', left: '0' }
    blockActive.value.width = 'fit'
    blockActive.value.height = ''
  } else if (preset === 'center') {
    blockActive.value.position = { top: '50%' }
    blockActive.value.width = 'auto'
  } else if (preset === 'bottom-right') {
    blockActive.value.position = { bottom: '0', right: '0' }
    blockActive.value.width = 'fit'
    blockActive.value.height = ''
  } else if (preset === 'bottom-left') {
    blockActive.value.position = { bottom: '0', left: '0' }
    blockActive.value.width = 'fit'
    blockActive.value.height = ''
  } else if (preset === 'spread') {
    blockActive.value.position = {}
    blockActive.value.width = ''
    blockActive.value.height = '100%'
  }
}
let init = true
watch(
  () => props.store.active,
  () => {
    if (!props.store.active || !props.store.active.length) return
    init = true
    blockActive.value = JSON.parse(JSON.stringify(getNode(props.store.active)))
    if (parentNode.value?.type === 'overlay' && !blockActive.value.position) {
      blockActive.value.position = {}
    }
    nextTick(() => (init = false))
  },
  { immediate: true },
)

function _emitUpdate() {
  updateNode(props.store.active, JSON.parse(JSON.stringify(blockActive.value)))
  updateLayout(props.store.active)

  // init = true
}
const emitUpdate = debounce(_emitUpdate, 300)
watch(
  () => [blockActive.value.width, blockActive.value.height, blockActive.value.position, blockActive.value.margin],
  () => {
    if (init) return
    emitUpdate()
  },
  { deep: true },
)
</script>
