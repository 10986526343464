<template>
  <div class="flex flex-row gap-12 p-8">
    <div class="flex flex-col gap-4">
      <div class="font-bold">Partial inputs</div>
      <div class="flex flex-row gap-4">
        <dr-variable-input
          v-for="partial in partials"
          :variableDefinition="partial"
          :inputData="inputData?.[partial.name]"
          :missingDependencies="missingDependencies[partial.name]"
          :value="variables[partial.name]"
          @update:value="value => updateVariable(partial, value)"
          :key="partial.name"
        ></dr-variable-input>
      </div>
      <div class="font-bold">Computed partials</div>

      <div class="flex flex-row gap-2" v-for="partial in partialValues" :key="partial.name">
        <label>{{ partial.name }}:</label>
        <div>
          {{
            missingDependencies[partial.name]
              ? `Missing dep ${missingDependencies[partial.name].join(', ')}`
              : partial.value
          }}
        </div>
      </div>
      <div class="font-bold">Run parameter inputs</div>
      <div class="flex flex-row gap-4">
        <dr-variable-input
          v-for="runParam in runParameters"
          :variableDefinition="runParam"
          :inputData="inputData?.[runParam.name]"
          :missingDependencies="missingDependencies[runParam.name]"
          :value="variables[runParam.name]"
          @update:value="value => updateVariable(runParam, value)"
          :key="runParam.name"
        ></dr-variable-input>
      </div>
      <div class="font-bold">Computed runParameters</div>
      <div class="flex flex-row" v-for="runParam in runParameterValues" :key="runParam.name">
        <label>{{ runParam.name }}:</label>
        <div>
          {{
            missingDependencies[runParam.name]
              ? `Missing dep ${missingDependencies[runParam.name].join(', ')}`
              : runParam.value
          }}
        </div>
      </div>
    </div>
    <div>
      <div>DR Variables</div>
      <code>
        <pre>{{ variables }}</pre>
      </code>
    </div>
  </div>
  <div class="flex flex-row gap-4 p-8">
    <button class="rounded bg-slate-200 p-2" @click="changeSettings">Switch to other dr</button>
    <button class="rounded bg-slate-200 p-2" @click="initDrPartials">Init variables with partials all set</button>
  </div>
</template>
<script setup lang="ts">
import { computed } from 'vue'
import {
  defaultVariableSettings,
  useDrVariables,
  variableTypes,
} from '@100-m/hauru/src/components/form/drVariables/lib'
const { variables, updateVariable, partials, inputData, missingDependencies, initDrVariables, runParameters } =
  useDrVariables()

initDrVariables(defaultVariableSettings)
const partialValues = computed(() => {
  return partials.value
    .filter(partial => {
      const varType = variableTypes[partial.variableType]
      return varType.inputType === 'readonly'
    })
    .map(partial => ({ name: partial.name, value: variables[partial.name] }))
})
const runParameterValues = computed(() => {
  return runParameters.value
    .filter(runParameter => {
      const varType = variableTypes[runParameter.variableType]
      return varType.inputType === 'readonly'
    })
    .map(runParameter => ({ name: runParameter.name, value: variables[runParameter.name] }))
})
function changeSettings() {
  initDrVariables([
    { name: 'dsq', variableType: 'fundId', scope: 'partial' },
    { name: 'AAA', variableType: 'shareId', scope: 'partial' },
  ])
}

function initDrPartials() {
  initDrVariables(
    [
      { name: 'fundId', variableType: 'fundId', scope: 'partial' },
      { name: 'shareId', variableType: 'shareId', scope: 'partial' },
      { name: 'horizon', variableType: 'shareInfo', scope: 'partial', argument: 'horizon' },
      { name: 'axis_1', variableType: 'shareInfoAxis', scope: 'partial', argument: 1 },
      { name: 'axis_2', variableType: 'shareInfoAxis', scope: 'partial', argument: 2 },
      { name: 'lang', variableType: 'langList', scope: 'partial' },
      { name: 'domain', variableType: 'domainFreeText', scope: 'runParameter' },
      { name: 'endDate', variableType: 'parsedFromDomain', scope: 'runParameter', argument: 'endDate' },
    ],
    {
      fundId: 'L2872',
      shareId: 'DE356514567',
      horizon: '5y',
      axis_1: 'asset_class',
      axis_2: 'sector_icb_1',
      lang: 'en',
    },
  )
}
</script>
