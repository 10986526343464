import config from '../config'
import { setTranslation } from '@100-m/hauru/src/features/translation'

/**
 * Get parsed query params
 * @return {{}}
 */
function getQueries() {
  return location.search
    .replace('?', '')
    .split('&')
    .reduce((acc, b) => {
      const q = b.split('=')
      acc[q[0]] = q[1] || undefined
      return acc
    }, {})
}

let last_downloaded
export const downloadMixin = {
  methods: {
    async getTranslations(lang) {
      const resp = await axios({
        url: '/dr',
        method: 'post',
        data: {
          variables: { lang: lang.toLowerCase() }, //! hack => resolve correctly
          query: `query TranslationsQuery($lang: Lang!) {
            translation(lang: $lang)
          }`,
        },
      })
      if (resp?.data?.data?.translation) {
        setTranslation({ [lang]: resp.data.data.translation }, 'digital-edition')
      }
    },
  },
  watch: {
    '$root.lang': {
      handler(lang) {
        if (last_downloaded === lang) return
        last_downloaded = lang
        this.getTranslations(lang)
      },
      immediate: true,
    },
    '$root.profile': {
      handler(newProfile, oldProfile) {
        if (!oldProfile && newProfile) {
          const query = getQueries()
          const lang = query.lang || config.defaultLang || localStorage.LANG || 'en'
          this.getTranslations(lang)
        }
      },
      immediate: true,
    },
  },
}
