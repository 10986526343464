import { INxChartOptions } from '../nx-chart'

export function getUniqueCategories(data: any[], options: INxChartOptions) {
  if (!options.category) return [options.y]
  const values = data.flatMap(d => d.group.map(g => g[options.category]))
  const uniqueValues = Array.from(new Set(values))
  return uniqueValues
}

export function pivotData(data: any[], options: INxChartOptions, categories: string[]) {
  if (!options.category) {
    return data
  }
  return data.map(d => {
    return {
      [options.x]: d[options.x],
      ...categories.reduce((acc: any, cat) => {
        const item = d.group.find(g => g[options.category] === cat)
        acc[cat] = item?.[options.y] || 0
        return acc
      }, {}),
    }
  })
}
