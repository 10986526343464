<template>
  <!-- grid-header-column -->
  <nx-dropdown
    :nx-header-container="props.header.index"
    type="grid.header"
    class="relative shrink-0 grow-0"
    :class="[gridTheme?.header?.column?.container]"
    :style="{
      gridRow: `span ${gridRowSpan}`,
      gridColumn: `span ${gridColumnSpan}`,
    }"
    @mouseover="isButtonRendered = true"
  >
    <template #button="{ shown, show }">
      <div
        class="group flex h-full border-r border-t border-solid"
        :class="[
          gridTheme?.header?.column?.idle,
          shown ? gridTheme?.header?.column?.selected : '',
          props.isFirstColumn ? 'border-l' : '',
          !header.visible.length ? 'border-b' : '',
        ]"
        @contextmenu.prevent="
          () => {
            hideAllPoppers()
            show()
          }
        "
      >
        <nx-button
          :qa-header-toggle="header.isExpanded"
          v-if="header.isExpandable.value"
          theme="grid.header.toggle"
          :icon="header.isExpanded ? gridTheme?.icons?.columnCollapse : gridTheme?.icons?.columnExpand"
          @click.stop="header.toggleExpanded()"
        ></nx-button>
        <input
          qa-header-select-all
          v-if="props.state.showCheckbox && props.level === 0 && props.isFirstColumn"
          :class="[
            gridTheme?.header?.checkbox?.idle,
            props.state.selection.items.isAll() ? gridTheme?.header?.checkbox?.checked : '',
            props.state.selection.items.list.length > 0 ? gridTheme?.header?.checkbox?.anyChecked : '',
          ]"
          type="checkbox"
          :checked="props.state.selection.items.isAll()"
          @click.stop="handleAllSelect"
        />
        <div
          nx-header
          :class="[
            gridTheme?.header?.column?.content,
            currentSortLabel && state.columns.sortBy.getOrder(currentSortLabel)
              ? gridTheme?.header?.column?.sorted
              : '',
          ]"
        >
          <slot name="header" :header="header">
            {{ header.label }}
          </slot>
        </div>
        <div
          v-if="
            currentSortLabel && state.columns.sortBy.getOrder(currentSortLabel) && state.columns.sortBy.list.length > 1
          "
          :class="gridTheme?.header?.column?.sortOrder"
        >
          {{ state.columns.sortBy.list.findIndex(item => item.label === currentSortLabel) + 1 }}
        </div>
        <nx-button
          :qa-header-sort="state.columns.sortBy.getOrder(currentSortLabel)"
          v-if="isButtonRendered && currentSortLabel && isExpanded"
          theme="grid.header.action"
          :icon="
            currentSortLabel && state.columns.sortBy.getOrder(currentSortLabel) === -1
              ? gridTheme?.icons?.columnSortDescending
              : gridTheme?.icons?.columnSortAscending
          "
          :state="currentSortLabel && state.columns.sortBy.is(currentSortLabel) ? 'selected' : 'idle'"
          @click.stop="cycle(currentSortLabel!)"
        ></nx-button>
        <div
          :class="gridTheme?.resizer?.container"
          @pointerdown.stop="onResizeStart"
          @click.stop
          @dblclick.stop="header.autoSize()"
        >
          <div :class="gridTheme?.resizer?.content"></div>
        </div>
      </div>
    </template>
    <div class="flex flex-col" :class="gridTheme?.header?.dropdown?.container">
      <div class="flex shrink-0 grow-0 basis-auto" :class="gridTheme?.header?.dropdown?.content">
        <div class="flex grow flex-col">
          <nx-list-item qa-sort-ascending v-if="currentSortLabel" type="grid" @click="toggle(currentSortLabel, 1)">
            <div :class="gridTheme?.icons?.columnSortAscending"></div>
            {{ gridTheme?.labels.columnSortAscending }}
          </nx-list-item>
          <nx-list-item qa-sort-descending v-if="currentSortLabel" type="grid" @click="toggle(currentSortLabel, -1)">
            <div :class="gridTheme?.icons?.columnSortDescending"></div>
            {{ gridTheme?.labels.columnSortDescending }}
          </nx-list-item>
          <!-- divider -->
          <template v-if="!isLastColumnLevel">
            <nx-list-item qa-group-by-option type="grid" @click="state?.columns.groupBy.toggle(header.labelPath)">
              <div
                :class="
                  state?.columns.groupBy.is(header.label)
                    ? gridTheme?.icons?.columnUngroup
                    : gridTheme?.icons?.columnGroup
                "
              ></div>
              {{
                state?.columns.groupBy.is(header.labelPath)
                  ? gridTheme?.labels.columnUngroup
                  : gridTheme?.labels.columnGroup
              }}
            </nx-list-item>
            <template v-if="props.isFreezed">
              <!-- <nx-list-item type="grid" @click="removeFromFreezed()">
                <div :class="gridTheme?.icons?.columnUnfreeze"></div>
                {{ gridTheme?.labels.columnUnfreeze }}
              </nx-list-item> -->
              <nx-list-item qa-freeze-up-to-option type="grid" @click="removeAllFromFreezed()">
                <div :class="gridTheme?.icons?.columnUnfreezeUpTo"></div>
                {{ gridTheme?.labels.columnUnfreezeUpTo }}
              </nx-list-item>
            </template>
            <template v-else>
              <!-- <nx-list-item type="grid" @click="addToFreezed()">
                <div :class="gridTheme?.icons?.columnFreeze"></div>
                {{ gridTheme?.labels.columnFreeze }}
              </nx-list-item> -->
              <nx-list-item qa-add-all-to-freezed type="grid" @click="addAllToFreezed()">
                <div :class="gridTheme?.icons?.columnFreezeUpTo"></div>
                {{ gridTheme?.labels.columnFreezeUpTo }}
              </nx-list-item>
            </template>
          </template>
          <nx-list-item qa-toggle-show v-if="props.state.display !== 'pivot'" type="grid" @click="header.toggleShow()">
            <div :class="gridTheme?.icons?.columnHide"></div>
            {{ gridTheme?.labels.columnHide }}
          </nx-list-item>
        </div>
      </div>
    </div>
  </nx-dropdown>
</template>

<script setup lang="ts">
import { IGridHeader, IGridState, IMoveEvent, useMove, useTheme, watchImmediate } from '@hauru/common'
import { hideAllPoppers } from 'floating-vue'
import { computed, ref } from 'vue'

interface IProps {
  /**
   * Allows to manually bypass the theme set as default, among the themes provided by the theme config
   */
  theme?: string
  /**
   * The type of the dropdown among the types defined in the theme config
   */
  type?: string
  state: IGridState
  header: IGridHeader
  level: number
  isFirstColumn: boolean
  isFirstColumnLevel: boolean
  isLastColumnLevel: boolean
  isFreezed: boolean
}

const props = withDefaults(defineProps<IProps>(), {})

const currentSortLabel = computed(() => {
  return props.header.visibleOrDefaultCollapsed.length === 0
    ? props.header.labelPath
    : props.header.visibleOrDefaultCollapsed.length === 1
      ? props.header.visibleOrDefaultCollapsed[0].labelPath
      : undefined
})

const isButtonRendered = ref<boolean>()

watchImmediate([currentSortLabel, () => props.state.columns.sortBy.list.length], () => {
  if (currentSortLabel.value && props.state.columns.sortBy.getOrder(currentSortLabel.value) !== 0)
    isButtonRendered.value = true
})

const themeConfig = useTheme()
const gridTheme = themeConfig.computedThemeType('grid', props)

const gridColumnSpan = computed(() => {
  return !props.isFreezed && props.isFirstColumnLevel && props.state.columns.visibleFreezed.length >= props.header.index
    ? props.header.columnCount - (props.state.columns.visibleFreezed.length - props.header.index)
    : props.header.columnCount
})

const gridRowSpan = computed(() => {
  return props.header.visible.length ? 1 : props.state.columns.depth - props.level
})

const isExpanded = computed(() => props.header.isExpanded)

const resizer = useMove({
  onMove: onResize,
})
let initialWidth = 0
let resizeHeader: IGridHeader | undefined

function onResizeStart(e: PointerEvent) {
  resizeHeader = props.header.findRightmostVisible()
  initialWidth = resizeHeader?.width ?? 0
  resizer.moveStart(e)
}

function onResize({ deltaX }: IMoveEvent) {
  resizeHeader?.setWidth(initialWidth + deltaX)
}

function addAllToFreezed() {
  const righmostHeader = props.header.findRightmostVisible()
  if (!righmostHeader) return
  props.state.columns.setFreezedCount(righmostHeader?.index + 1)
}

function removeAllFromFreezed() {
  props.state.columns.setFreezedCount(props.header.index)
}

// function addToFreezed() {
//   props.state.columns.headers.move(props.header.indexPath.at(-1)!, props.state.columns.freezedCount)
//   props.state.columns.setFreezedCount(props.state.columns.freezedCount + 1)
// }

// function removeFromFreezed() {
//   props.state.columns.headers.move(props.header.indexPath.at(-1)!, props.state.columns.freezedCount - 1)
//   props.state.columns.setFreezedCount(props.state.columns.freezedCount - 1)
// }

function cycle(labelPath: string) {
  props.state.selection.select()
  props.state?.columns.sortBy.cycle(labelPath, props.state.display === 'pivot' ? props.header.labelPathArray : null)
  moveSortedToFirst(labelPath)
}

function toggle(labelPath: string, order: 1 | -1 | undefined) {
  props.state?.columns.sortBy.toggle(labelPath, order, props.header.labelPathArray)
  moveSortedToFirst(labelPath)
}

function moveSortedToFirst(labelPath: string) {
  const from = props.state?.columns.sortBy.list.findIndex(item => item.label === labelPath)
  if (from !== undefined && from !== -1) {
    props.state?.columns.sortBy.move(from, 0)
  }
}

function handleAllSelect() {
  if (props.state.selection.items.isAll()) {
    props.state.selection.items.deselectAll()
  } else {
    props.state.selection.items.selectAll()
  }
}
</script>
