import { table } from './stories'
const context = {
  ...table.props.context,
  t: new Proxy(table.props.context.translations, {
    get(target: any, prop: string) {
      return prop
    },
  }),
}
const defaultProps = {
  ...table.props,
  context,
}
const drilldownData = {
  columns: [
    ['indicator', 'expoBrute'],
    ['indicator', 'expoNet'],
  ],
  rows: [
    // [],
    ['FR'],
    ['FR', 'Distribution'],
    ['FR', 'Distribution', 'FR000009'],
    ['FR', 'Industry'],
    ['FR', 'Industry', 'FR000007'],
    ['FR', 'Industry', 'FR000008'],
    ['US'],
    ['US', 'TECH'],
    ['US', 'TECH', 'FR000001'],
    ['US', 'TECH', 'FR000002'],
  ],
  labels: ['country', 'sector', 'isin'],
  data: [
    // [0.73, 0.47],
    [0.43, 0.24],
    [0.01, 0.03],
    [0.01, 0.03],
    [0.42, 0.21],
    [0.02, 0.2],
    [0.4, 0.01],
    [0.3, 0.23],
    [0.3, 0.23],
    [0.1, 0.22],
    [0.2, 0.01],
  ],
}

export const defaultStory = {
  ...defaultProps,
  options: {
    display: 'compact',
    showSubtotal: true,
  },
  data: drilldownData,
}
export const pivotStories = {
  'Drilldown (compact)': {
    ...defaultProps,
    options: {
      display: 'compact',
      showSubtotal: true,
    },
    data: drilldownData,
  },
}
